import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ReadMoreModal from "../../utils/Modals/ReadMoreModal";
import { ucFirst } from "../../utils/UcFirst";
import SummaryNavLinks from "../dashboard/SummaryNavLinks";
import apiClient from "../../services/ApiClient";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useAuth } from "../../context/AuthContext";

const ChartsTableForClientR = ({
  charts,
  viewType,
  timeLine,
  setCharts,
  coderInfo,
  setCoderInfo,
  // teamsInfo,
  // filterByTeam,
  // selectTeamID,
  pageCount,
  handlePageClick,
  dataCount,
  setPageCount,
  setDataCount,
  dataFrom,
  dataEnd,
  setDataFrom,
  setDataEnd,
  isShowPaginate,
  setIsShowPaginate,
  offset,
  projectId,
  setprojectId,
}) => {
  const [showReadMoreModal, setshowReadMoreModal] = useState(false);
  const [comments, setComments] = useState("");
  const [oldComments, setOldComments] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const params = useParams();

  const auth = useAuth();

  const coderProjectID = projectId;

  useEffect(() => {
    if (params.timeLine === "filter") {
      filterHandler();
    }
  }, [offset]);

  // Date wise Filter Function
  const filterHandler = async (e) => {
    const startedDate = startDate.toLocaleDateString("en-US");
    // let tempEndDate = endDate.getDate() + 1;
    const endedDate = endTime ? endDate.toLocaleDateString("en-US") : endDate.toLocaleDateString("en-US");
    // const endedDate = endTime ? endDate.toLocaleDateString("en-US") : new Date(endDate.getTime() + 86400000).toLocaleDateString("en-US");
    const startedTime = startTime ? startTime.toLocaleTimeString() : "12:00:00 AM";
    const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";

    const tempId =
      params.id && params.userLogId
        ? `user_log_id=${params.userLogId}`
        : params.id && viewType == "team"
        ? `team_id=${params.id}`
        : params.id && viewType == "user" && `user_id=${params.id}`;

    const endPoint =
      viewType === "user"
        ? `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`
        : viewType === "team" &&
          `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`;
    try {
      const response = await apiClient.get(`${endPoint}`);
      // console.log(response);
      setCharts((prevValue) => response.data.data);
      setCoderInfo({
        coderName: response.data.data[0].coder_name,
      });
      setPageCount(response.data.meta.last_page);
      setDataCount(response.data.meta.total);
      let showPaginate = response.data.meta.total / response.data.meta.per_page > 1;
      setIsShowPaginate(showPaginate);
      setDataFrom(response.data.meta.from);
      setDataEnd(response.data.meta.to);
    } catch (err) {
      // console.log(err);
    }
  };

  // Date wise Filter Reset Function
  const filterReset = () => {
    setCharts([]);
    setDateRange([null, null]);
    setStartTime();
    setEndTime();
    setPageCount("");
    setDataCount("");
    setIsShowPaginate(false);
    setDataFrom("");
    setDataEnd("");
  };

  // ReadMore Modal Functions
  const closeReadMoreModal = () => {
    setshowReadMoreModal(false);
  };

  const readMoreModalFunc = (comments, oldComments = null) => {
    setshowReadMoreModal(true);
    setComments(comments);
    setOldComments(oldComments);
  };

  // Log Details Row Display
  const showRow = (id) => {
    const hiddenRowElements = document.querySelectorAll(`#hiddenRow_${id}`);

    if (hiddenRowElements) {
      for (const key in hiddenRowElements) {
        // console.log(key);
        hiddenRowElements[key].classList.contains("d-none")
          ? hiddenRowElements[key].classList.remove("d-none")
          : hiddenRowElements[key].classList.add("d-none");
      }
    }
  };
  const exportHandler = async () => {
    let tempId;
    if (timeLine === "filter") {
      const startedDate = startDate.toLocaleDateString("en-US");
      // let tempEndDate = endDate.getDate() + 1;
      const endedDate = endTime ? endDate.toLocaleDateString("en-US") : endDate.toLocaleDateString("en-US");
      const startedTime = startTime ? startTime.toLocaleTimeString() : "12:00:00 AM";
      const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";
      tempId =
        params.id && params.userLogId
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&team_id=${params.id}`
          : params.id && viewType == "user" && `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_id=${params.id}`;
    } else {
      tempId =
        params.id && params.userLogId
          ? `user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `team_id=${params.id}`
          : params.id && viewType == "user" && `user_id=${params.id}`;
    }

    const { data } = await apiClient.get(`export/user-chart?timeline=${params.timeLine !== "filter" ? params.timeLine : "custom"}&${tempId}`);
    window.open(data, "_blank");
  };

  return (
    <>
      <SummaryNavLinks />

      {timeLine === "filter" && (
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end">
              <div style={{ width: "220px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={Date.now()}
                  // dateFormat='MMMM d, yyyy h:mm aa'
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                />
              </div>
              <div style={{ width: "110px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selected={startTime}
                  onChange={(time) => setStartTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Start Time"
                  // minTime={Date.now() - 10000 * 60 * 60}
                  // maxTime={Date.now() - 1000 * 60 * 60}
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="Start Time"
                />
              </div>

              <div style={{ width: "110px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selected={endTime}
                  onChange={(time) => setEndTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  // minTime={startTime ? startTime : Date.now()}
                  // maxTime={Date.now()}
                  timeCaption="End Time"
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="End Time"
                />
              </div>

              <button onClick={filterHandler} className="btn btn-sm btn-primary me-2 shadow-none">
                Filter
              </button>

              <button onClick={filterReset} className="btn btn-sm btn-danger shadow-none">
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header py-3 d-flex justify-content-between align-items-center font-size13">
          <h6 className="mb-0 font-size13">
            <i className="fas fa-file-alt me-2"></i>
            Charts
            {dataCount ? <span className="badge count-badge-success ms-1 px-2 py-1">{dataCount}</span> : null}
          </h6>

          {viewType === "user" && coderInfo.coderName && (
            <p
              className="mb-0 d-flex align-items-center"
              // style={{ color: "#5ab9c9" }}
            >
              <span className="text-nowrap">
                <span className="text-light">Coder Name:</span> {ucFirst(`${coderInfo.coderName}`)}
              </span>
            </p>
          )}
          {params.id && (
            <button onClick={exportHandler} className="btn btn-sm btn-info shadow-none ms-2">
              Export
            </button>
          )}
        </div>
        <div className="card-body ">
          <div className="table-responsive">
            <table className="table">
              <thead className="font-size13">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Team ID</th>
                  <th scope="col">Project Name</th>
                  <th scope="col">Sub Project</th>
                  <th scope="col">Login Name</th>
                  {viewType !== "user" && <th scope="col">User Name</th>}
                  <th scope="col">Chase ID</th>
                  <th scope="col">Member Name</th>
                  <th scope="col">D.O.B</th>
                  <th scope="col">Total HCC Category Reviewed</th>
                  <th scope="col">Total Pages</th>
                  <th scope="col">Comments</th>
                  <th scope="col" className="text-center">
                    Quality
                  </th>
                  <th scope="col">Coding Date</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Log</th>
                </tr>
              </thead>
              <tbody className="font-size13 font-weight-light">
                {charts &&
                  charts.map((chart, index) => (
                    <>
                      <tr key={chart.id}>
                        {dataFrom ? <td>{dataFrom + index}</td> : <td>{index + 1}</td>}
                        <td>HSTM {chart.team_id}</td>
                        <td>{chart.project_name}</td>
                        <td>{chart.sub_project}</td>
                        <td>{chart.coder_login_name}</td>

                        {viewType !== "user" && <td>{ucFirst(chart.coder_name)}</td>}
                        <td>{chart.chart_id}</td>

                        <td>{ucFirst(chart.member_name)}</td>
                        <td>{new Date(chart.dob).toLocaleDateString()}</td>

                        <td className="text-center">{chart.icd}</td>

                        <td className="text-center">{chart.pages}</td>
                        <td className="text-center">
                          {chart.sub_project === "Recode 2.0" && (
                            <span>
                              {/* {chart.comments} */}
                              {chart.comments && chart.comments.length > 10
                                ? ucFirst(chart.comments.slice(0, 10).concat("..."))
                                : chart.comments && chart.comments !== null && ucFirst(chart.comments)}
                              {chart.comments && chart.comments.length > 10 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>Read More</Tooltip>}>
                                  <button
                                    className="btn btn-sm i-success ms-1 shadow-none"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      readMoreModalFunc(ucFirst(chart.comments));
                                    }}
                                  >
                                    {/* <i className='fas fa-comment-medical'></i> */}
                                    {/* <i class="fas fa-solid fa-comment-dots"></i> */}
                                    <img src="/images/comment-logo.png" width="12px" alt="comment-logo" />
                                  </button>
                                </OverlayTrigger>
                              )}
                              {!chart.comments && <span className="text-muted">NA</span>}
                            </span>
                          )}
                          {/* {JSON.stringify(chart.comments)} */}
                        </td>
                        <td className="text-center">{chart?.chart_quality}</td>
                        <td>{new Date(chart.coding_date).toDateString().slice(4)}</td>
                        <td>{new Date(chart.coding_at).toDateString().slice(4)}</td>
                        <td>{new Date(chart.coding_at).toTimeString().split(" ").shift()}</td>

                        <td>
                          {chart.logs.length ? (
                            <OverlayTrigger placement="top" overlay={<Tooltip>View Log</Tooltip>}>
                              <button type="button" className="btn btn-sm i-secondary shadow-none ml-2" onClick={() => showRow(chart.chart_id)}>
                                <i className="fas fa-book-medical"></i>
                                {chart.logs.length ? <span className="badge bg-success ms-1  rounded-pill">{chart.logs.length}</span> : null}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                        </td>
                      </tr>
                      {chart.logs.length
                        ? chart.logs.map((log) => (
                            <tr key={log.id} className="d-none hidden_row" id={`hiddenRow_${chart.chart_id}`} style={{ backgroundColor: "#05084b" }}>
                              <td>
                                <i class="fas fa-hand-point-up"></i>
                              </td>
                              <td>HSTM {chart.team_id}</td>
                              <td>{chart.project_name}</td>
                              <td>{chart.sub_project}</td>
                              <td>{chart.coder_login_name}</td>
                              {viewType !== "user" && <td>{chart.coder_name}</td>}
                              <td className={log.properties.old.chart_id !== log.properties.attributes.chart_id ? "text-warning" : null}>
                                {log.properties.old.chart_id !== log.properties.attributes.chart_id ? (
                                  <span>
                                    {log.properties.old.chart_id}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.chart_id}
                                  </span>
                                ) : (
                                  log.properties.old.chart_id
                                )}
                              </td>

                              <td className={log.properties.old.member_name !== log.properties.attributes.member_name ? "text-warning" : null}>
                                {log.properties.old.member_name !== log.properties.attributes.member_name ? (
                                  <span>
                                    {ucFirst(log.properties.old.member_name)}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {ucFirst(log.properties.attributes.member_name)}
                                  </span>
                                ) : (
                                  ucFirst(log.properties.old.member_name)
                                )}
                              </td>
                              <td className={log.properties.old.dob !== log.properties.attributes.dob ? "text-warning" : null}>
                                {log.properties.old.dob !== log.properties.attributes.dob ? (
                                  <span>
                                    {new Date(log.properties.old.dob).toLocaleDateString()}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {new Date(log.properties.attributes.dob).toLocaleDateString()}
                                  </span>
                                ) : (
                                  new Date(log.properties.old.dob).toLocaleDateString()
                                )}
                              </td>

                              <td className={log.properties.old.icd !== log.properties.attributes.icd ? "text-center text-warning" : "text-center"}>
                                {log.properties.old.icd !== log.properties.attributes.icd ? (
                                  <span>
                                    {log.properties.old.icd}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.icd}
                                  </span>
                                ) : (
                                  log.properties.old.icd
                                )}
                              </td>

                              <td className={log.properties.old.pages !== log.properties.attributes.pages ? "text-center text-warning" : "text-center"}>
                                {log.properties.old.pages !== log.properties.attributes.pages ? (
                                  <span>
                                    {log.properties.old.pages}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.pages}
                                  </span>
                                ) : (
                                  log.properties.old.pages
                                )}
                              </td>

                              <td className={log.properties.old.comments !== log.properties.attributes.comments ? "text-warning" : null}>
                                {log.properties.old.comments !== log.properties.attributes.comments ? (
                                  <span>
                                    {log.properties.old.comments && log.properties.old.comments.length > 10
                                      ? ucFirst(log.properties.old.comments.slice(0, 10).concat("..."))
                                      : ucFirst(log.properties.old.comments)}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.comments && log.properties.attributes.comments.length > 10
                                      ? ucFirst(log.properties.attributes.comments.slice(0, 10).concat("..."))
                                      : ucFirst(log.properties.attributes.comments)}
                                    {log.properties.attributes.comments && log.properties.attributes.comments.length > 10 && (
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Read More</Tooltip>}>
                                        <button
                                          className="btn btn-sm i-success ms-1 shadow-none"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            readMoreModalFunc(ucFirst(log.properties.attributes.comments), ucFirst(log.properties.old.comments));
                                          }}
                                        >
                                          {/* <i className='fas fa-comment-medical'></i> */}
                                          {/* <i class="fas fa-solid fa-comment-dots"></i> */}
                                          <img src="/images/comment-logo.png" width="12px" alt="comment-logo" />
                                        </button>
                                      </OverlayTrigger>
                                    )}
                                  </span>
                                ) : log.properties.old.comments && log.properties.old.comments.length > 10 ? (
                                  ucFirst(log.properties.old.comments.slice(0, 10).concat("..."))
                                ) : (
                                  ucFirst(log.properties.old.comments)
                                )}
                              </td>
                              <td></td>
                              <td>{new Date(log.properties.attributes.coding_at).toDateString().slice(4)}</td>
                              <td>{new Date(log.properties.attributes.created_at).toDateString().slice(4)}</td>
                              <td
                                className={
                                  new Date(log.properties.old.coding_at).toTimeString().split(" ").shift() !==
                                  new Date(log.properties.attributes.coding_at).toTimeString().split(" ").shift()
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {new Date(log.properties.old.coding_at).toTimeString().split(" ").shift()}
                                <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                {new Date(log.properties.attributes.coding_at).toTimeString().split(" ").shift()}
                              </td>
                              <td></td>
                            </tr>
                          ))
                        : null}
                      <ReadMoreModal
                        showReadMoreModal={showReadMoreModal}
                        closeReadMoreModal={closeReadMoreModal}
                        comments={comments}
                        oldComments={oldComments}
                        setshowReadMoreModal={setshowReadMoreModal}
                        title="Comments"
                      />
                    </>
                  ))}

                {!charts.length && (
                  <tr className="text-center font-size13">
                    <td colSpan={25}>No data available at this moment</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isShowPaginate && (
            <div className="d-flex justify-content-between align-items-center flex-row-reverse pt-3 ">
              <div>
                <ReactPaginate
                  key={timeLine}
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              {dataFrom && dataEnd && (
                <span className="ms-2 showing font-size13 grey-clr">
                  <i class="fas fa-book-open me-1"></i>
                  Showing {dataFrom} - {dataEnd} of {dataCount} entries
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChartsTableForClientR;
