import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import apiClient from "../../services/ApiClient";
import { useMutation, useQuery } from "react-query";

import { useAuth } from "../../context/AuthContext";

const AddUserChart = ({ refetch }) => {
  // const [projects, setProjects] = useState("");
  const auth = useAuth();
  const coderProjectId = auth.user.project_id;

  useEffect(() => {
    formik.setFieldValue("project_id", localStorage.getItem("project"));

    // fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await apiClient.get(`user-chart/projects`, {
        componentName: "fetchProjects",
      });
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  // Use react-query useQuery hook to fetch data
  const { data: projects = [], isFetching } = useQuery(["fetchProjects"], () => fetchProjects(), {
    // keepPreviousData: true,
    staleTime: Infinity,
  });

  // const { fetchData } = props;

  const formik = useFormik({
    initialValues: {
      project_id: "",
      // chart_id: coderProjectId === `${process.env.REACT_APP_LHP_PROJECT_ID}` ? "encounter_number" : "chart_id",
      chart_id: "",
      member_name: "",
      dob: "",
      dos: "",
      icd: "",
      pages: "",
      action: "code_completed",
      comments: "",

      [coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "date_of_service"]: coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "",

      [coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "facility_name"]: coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "",

      [coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "type_of_visit"]: coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "",

      [coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "cpt_codes"]: coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "",

      [coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "state"]: coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` && "fl",
    },
    validationSchema: yup.object({
      chart_id: yup.number().typeError("Must be a number").required("Chart ID is required"),
      member_name: yup.string().min(3, "Too Short!").max(25, "Must be 25 characters or less").required("Name is required"),
      dob: yup.date().typeError("Must be a date").required("D.O.B is required"),

      dos: yup.number().typeError("Must be a number").required("DOS is required"),
      icd: yup.number().typeError("Must be a number").required("ICD is required"),
      pages: yup.number().typeError("Must be a number").required("Pages is required"),
      // comments: yup.string().required("Comments is required"),
    }),
    onSubmit: async (values) => {
      localStorage.setItem("project", values.project_id);
      // try {
      //   const response = await apiClient.post("user-chart/store", values);
      //   // console.log(response.data.data);
      //   fetchData();
      //   formik.resetForm();
      //   formik.setFieldValue("project_id", localStorage.getItem("project"));
      // } catch (err) {
      //   formik.setErrors(err.response.data.error);
      // }
      await mutation.mutateAsync(values);
    },
  });

  const mutation = useMutation((values) => apiClient.post("user-chart/store", values, { componentName: "addUserChart" }), {
    onSuccess: () => {
      refetch();
      formik.resetForm();
      formik.setFieldValue("project_id", localStorage.getItem("project"));
    },
    onError: (err) => {
      // Handle errors
      const { response } = err;
      if (response) {
        const { data } = response;
        formik.setErrors(data.response.data.error);
      }
    },
  });

  // Comments Required Label Added dynmically when change the coding action
  useEffect(() => {
    if (coderProjectId != `${process.env.REACT_APP_LHP_PROJECT_ID}`) {
      if (formik.values.action === "rejected" || formik.values.action === "SAR") {
        document.getElementById("comments_label").classList.add("required");
      } else {
        document.getElementById("comments_label").classList.remove("required");
      }
    }
  }, [formik.values.action]);

  async function checkChartidUnique() {
    try {
      const response = await apiClient.post(`/user-chart/validate/chart-id`, {
        chart_id: formik.values.chart_id,
        project_id: coderProjectId,
      });
    } catch (err) {
      formik.setFieldError("chart_id", err.response.data.error.chart_id);
    }
  }

  useEffect(() => {
    if (coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}`) {
      if (formik.values.chart_id.length === 5) {
        checkChartidUnique();
      }
      if (formik.values.chart_id.length === 6) {
        checkChartidUnique();
      }
      if (formik.values.chart_id.length === 7) {
        checkChartidUnique();
      }
    } else {
      if (formik.values.chart_id.length === 9) {
        checkChartidUnique();
      }
    }
  }, [formik.values.chart_id]);

  return (
    <div className="card mb-3">
      <div className="card-body">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="row align-items-center justify-content-start px-lg-2">
            <div className="col-lg-2 px-1">
              <label htmlFor="chart_id" className="form-label required">
                Select Project
              </label>
              {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
                <select
                  className={`form-select form-control ${formik.touched.project_id && formik.errors.project_id && "is-invalid"}`}
                  id="selectProject"
                  name="project_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_id}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {projects && <option value={coderProjectId}>LHP</option>}
                </select>
              ) : (
                <select
                  className={`form-select form-control  font-size13  ${formik.touched.project_id && formik.errors.project_id && "is-invalid"}`}
                  id="selectProject"
                  name="project_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_id}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {projects &&
                    projects.map((project) => (
                      <option value={project.id} key={project.id}>
                        {project.project_name} - {project.project_code}
                      </option>
                    ))}
                </select>
              )}
              {formik.touched.project_id && formik.errors.project_id ? (
                <div id="project_id" className="invalid-feedback">
                  {formik.errors.project}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>
            <div className="col-lg-2 px-1">
              <label htmlFor="chart_id" className="form-label required">
                {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? "Encounter Number" : "Chart Id"}
              </label>
              <input
                type="text"
                name="chart_id"
                className={`form-control  font-size13  ${formik.touched.chart_id && formik.errors.chart_id && "is-invalid"}`}
                id="chart_id"
                // placeholder='Chart ID'
                maxLength={coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? 7 : 9}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.chart_id}
              />
              {formik.touched.chart_id && formik.errors.chart_id ? (
                <div id="chart_id" className="invalid-feedback">
                  {formik.errors.chart_id}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>
            {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="date_of_service" className="form-label required">
                  Date of service <small>(MM/DD/YYYY)</small>
                </label>
                <DatePicker
                  className="form-control font-size13 "
                  // dateFormat='dd/MM/yyyy'
                  selected={formik.values.date_of_service}
                  maxDate={new Date()}
                  onChange={(value) => {
                    formik.setFieldValue("date_of_service", value);
                  }}
                />
                {/* {formik.errors.date_of_service} */}
                {formik.errors.date_of_service ? (
                  <div id="date_of_service" className="invalid-feedback">
                    {formik.errors.date_of_service}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}
            <div className="col-lg-2 px-1">
              <label htmlFor="member_name" className="form-label required">
                {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? "Patient Name" : "Member Name"}
              </label>
              <input
                type="text"
                name="member_name"
                className={`form-control font-size13 ${formik.touched.member_name && formik.errors.member_name && "is-invalid"}`}
                id="member_name"
                // placeholder='Member Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.member_name}
              />
              {formik.touched.member_name && formik.errors.member_name ? (
                <div id="member_name" className="invalid-feedback">
                  {formik.errors.member_name}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>
            <div className="col-lg-2 px-1">
              <label htmlFor="dob" className="form-label required">
                D.O.B <small>(MM/DD/YYYY)</small>
              </label>
              {/* <input
                type='date'
                // placeholder='D.O.B'
                name='dob'
                className={`form-control ${formik.touched.dob && formik.errors.dob && "is-invalid"}`}
                id='dob'
                // placeholder='D.O.B'
                onChange={formik.handleChange}
                // onFocus={(e) => (e.target.type = "date")}
                onBlur={formik.handleBlur}
                value={formik.values.dob}
              /> */}
              <DatePicker
                className="form-control font-size13 "
                // dateFormat='dd/MM/yyyy'
                selected={formik.values.dob}
                maxDate={new Date()}
                onChange={(value) => {
                  formik.setFieldValue("dob", value);
                }}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div id="dob" className="invalid-feedback">
                  {formik.errors.dob}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>

            <div className="col-lg-2 px-1">
              <label htmlFor="dos" className="form-label required">
                {/* Total DOS */}
                {coderProjectId == `${process.env.REACT_APP_OSCAR_PROJECT_ID}`
                  ? "No of Claim DX"
                  : coderProjectId == `${process.env.REACT_APP_AETNA_PROJECT_ID}`
                  ? "All Claim Code"
                  : "Total DOS"}
              </label>
              <input
                type="text"
                name="dos"
                className={`form-control font-size13  ${formik.touched.dos && formik.errors.dos && "is-invalid"}`}
                id="dos"
                // placeholder='DOS'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dos}
              />
              {formik.touched.dos && formik.errors.dos ? (
                <div id="dos" className="invalid-feedback">
                  {formik.errors.dos}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>
            <div className="col-lg-2 px-1">
              <label htmlFor="icd" className="form-label required">
                {coderProjectId == `${process.env.REACT_APP_OSCAR_PROJECT_ID}`
                  ? "Added No of DX"
                  : coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}`
                  ? "ICD Codes"
                  : coderProjectId == `${process.env.REACT_APP_AETNA_PROJECT_ID}`
                  ? "Added codes"
                  : "Total ICD"}
              </label>
              <input
                type="text"
                name="icd"
                className={`form-control  font-size13 ${formik.touched.icd && formik.errors.icd && "is-invalid"}`}
                id="icd"
                // placeholder='ICD'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.icd}
              />
              {formik.touched.icd && formik.errors.icd ? (
                <div id="icd" className="invalid-feedback">
                  {formik.errors.icd}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>

            {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="cpt_codes" className="form-label required">
                  CPT Codes
                </label>
                <input
                  type="text"
                  name="cpt_codes"
                  className={`form-control  font-size13 ${formik.touched.cpt_codes && formik.errors.cpt_codes && "is-invalid"}`}
                  id="cpt_codes"
                  // placeholder='No of facility_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpt_codes}
                />
                {formik.touched.cpt_codes && formik.errors.cpt_codes ? (
                  <div id="cpt_codes" className="invalid-feedback">
                    {formik.errors.cpt_codes}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}

            <div className="col-lg-2 px-1">
              <label htmlFor="pages" className="form-label required">
                {coderProjectId == `${process.env.REACT_APP_AETNA_PROJECT_ID}` ? "No of Pages" : "Total pages"}
              </label>
              <input
                type="text"
                name="pages"
                className={`form-control font-size13  ${formik.touched.pages && formik.errors.pages && "is-invalid"}`}
                id="pages"
                // placeholder='No of pages'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pages}
              />
              {formik.touched.pages && formik.errors.pages ? (
                <div id="pages" className="invalid-feedback">
                  {formik.errors.pages}
                </div>
              ) : (
                <div className="invisible">
                  <span>invisible</span>
                </div>
              )}
            </div>

            {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="type_of_visit" className="form-label required">
                  Type of visit
                </label>
                <select
                  name="type_of_visit"
                  className={`form-select form-control font-size13 `}
                  id="type_of_visit"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type_of_visit}
                >
                  <option selected disabled value="">
                    Select a value
                  </option>
                  <option value="Annual Comprehensive">Annual Comprehensive</option>
                  <option value="Initial Comprehensive">Initial Comprehensive</option>
                  <option value="Initial Encounter">Initial Encounter</option>
                  <option value="Scheduled Follow Up">Scheduled Follow Up</option>
                  <option value="Acute">Acute</option>
                  <option value="Acute Post-Fall">Acute Post-Fall</option>
                  <option value="Readmissions">Readmissions</option>
                </select>

                {formik.touched.type_of_visit && formik.errors.type_of_visit ? (
                  <div id="type_of_visit" className="invalid-feedback">
                    {formik.errors.type_of_visit}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}

            {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="date_of_service" className="form-label required">
                  Facilty Name
                </label>
                <input
                  type="text"
                  name="facility_name"
                  className={`form-control font-size13  ${formik.touched.facility_name && formik.errors.facility_name && "is-invalid"}`}
                  id="facility_name"
                  // placeholder='No of facility_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.facility_name}
                />
                {formik.touched.facility_name && formik.errors.facility_name ? (
                  <div id="facility_name" className="invalid-feedback">
                    {formik.errors.facility_name}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}
            {coderProjectId == `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="state" className="form-label required">
                  State
                </label>
                <select
                  name="state"
                  className={`form-select form-control font-size13 `}
                  id="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <option value="fl">FL</option>
                  <option value="mi">MI</option>
                  <option value="ny">NY</option>
                </select>
                {formik.touched.state && formik.errors.state ? (
                  <div id="state" className="invalid-feedback">
                    {formik.errors.state}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}

            {coderProjectId != `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="action" className="form-label required">
                  Coding Status
                </label>
                <select
                  name="action"
                  className={`form-select form-control font-size13 `}
                  id="action"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.action}
                >
                  <option value="code_completed">Completed</option>
                  <option value="rejected">Rejected</option>
                  <option value="SAR">SAR</option>
                </select>
                {formik.touched.action && formik.errors.action ? (
                  <div id="action" className="invalid-feedback">
                    {formik.errors.action}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}

            {coderProjectId != `${process.env.REACT_APP_LHP_PROJECT_ID}` ? (
              <div className="col-lg-2 px-1">
                <label htmlFor="comments" className="form-label" id="comments_label">
                  Comments
                </label>
                <textarea
                  name="comments"
                  id="comments"
                  style={{ height: "10px" }}
                  // placeholder='Comments'
                  className={`form-control font-size13  ${formik.touched.comments && formik.errors.comments && "is-invalid"}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comments}
                ></textarea>
                {formik.touched.comments && formik.errors.comments ? (
                  <div id="comments" className="invalid-feedback">
                    {formik.errors.comments}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="px-1 pt-2 text-end">
            {/* <button type="submit" className="btn btn-sm btn-success shadow-none ml-2 font-size13">
              <i className="fas fa-plus" aria-hidden="true" /> ADD
            </button> */}
            {mutation.isLoading ? (
              <button class="btn custom-btn-primary font-size13 py-1 px-4" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                <span role="status">Loading...</span>
              </button>
            ) : (
              <button type="submit" className="btn custom-btn-primary font-size13 py-1 px-4">
                Add Chart
              </button>
            )}
            <div className="invisible">
              <span>invisible</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserChart;
