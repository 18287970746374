import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import apiClient from "../services/ApiClient";
import { useAuth } from "../context/AuthContext";
import { useEffect } from "react";
const Sso = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ssoPath = "/sso/"; // The part you want to remove
        const authType = location.pathname.substring(ssoPath.length);
        const response = await apiClient.get(`sso/login/${authType}/callback${location.search}`);
       
        auth.setUser(response.data.user);

        // if (response.data.user_log.length) {
        //   let logDetails = await response.data.user_log.filter((log) => {
        //     return log.status === "active";
        //   });

        //   auth.setLog(logDetails[0].id);

        // localStorage.setItem("log", logDetails[0].id);
        // }

        auth.setToken(response.data.token);
        localStorage.setItem("auth-token", response.data.token);
        // let tempUser = {};
        // tempUser.name = response.data.user.name;
        // tempUser.login_name = response.data.user.login_name;
        // tempUser.email = response.data.user.email;
        // tempUser.role = response.data.user.role;
        // tempUser.team_id = response.data.user.team_id;
        localStorage.setItem("user", JSON.stringify(response.data.user));

        if (response.data.user.role === "super_admin") {
          navigate("/", { replace: true });
        } else if (response.data.user.role === "manager") {
          navigate("/add-teams", { replace: true });
        } else if (response.data.user.role === "lead") {
          navigate("/user/dashboard", { replace: true });
        } else if (response.data.user.role === "user") {
          navigate("/user/dashboard", { replace: true });
        } else if (response.data.user.role === "auditor") {
          navigate("/user/dashboard", { replace: true });
        }
      } catch (err) {
        // console.log(err);
        if (err) {
          navigate("/userNotFound", { replace: true });
        }
      }
    };

    fetchData();
  }, []);

  return <div></div>;
};

export default Sso;
