import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import ReadMoreModal from "../../utils/Modals/ReadMoreModal";
import { ucFirst } from "../../utils/UcFirst";
import SummaryNavLinks from "../dashboard/SummaryNavLinks";
import apiClient from "../../services/ApiClient";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useAuth } from "../../context/AuthContext";

const ChartsTable = ({
  charts,
  viewType,
  timeLine,
  setCharts,
  coderInfo,
  setCoderInfo,
  // teamsInfo,
  // filterByTeam,
  // selectTeamID,
  pageCount,
  handlePageClick,
  dataCount,
  setPageCount,
  setDataCount,
  dataFrom,
  dataEnd,
  setDataFrom,
  setDataEnd,
  isShowPaginate,
  setIsShowPaginate,
  offset,
  projectId,
  setprojectId,
}) => {
  const [showReadMoreModal, setshowReadMoreModal] = useState(false);
  const [comments, setComments] = useState("");
  const [oldComments, setOldComments] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const params = useParams();

  const auth = useAuth();

  const coderProjectID = projectId;

  const isLHPProject =
    Number(coderProjectID) ===
    Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  // console.log(coderProjectID);

  // const [searchParams] = useSearchParams();

  // const viewType = searchParams.get("viewBy");

  // console.log(startTime < endTime);

  useEffect(() => {
    if (params.timeLine === "filter") {
      filterHandler();
    }
  }, [offset]);

  // Date wise Filter Function
  const filterHandler = async (e) => {
    const startedDate = startDate.toLocaleDateString("en-US");
    // let tempEndDate = endDate.getDate() + 1;
    const endedDate = endTime
      ? endDate.toLocaleDateString("en-US")
      : endDate.toLocaleDateString("en-US");
    // const endedDate = endTime ? endDate.toLocaleDateString("en-US") : new Date(endDate.getTime() + 86400000).toLocaleDateString("en-US");
    const startedTime = startTime
      ? startTime.toLocaleTimeString()
      : "12:00:00 AM";
    const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";
    // endTime ? setEndTime(endTime) : setEndTime("09:00:00 AM");

    // console.log(startedTime);
    // console.log(startedDate);
    // console.log(endedTime);
    // console.log(endedDate);

    const tempId =
      params.id && params.userLogId
        ? `user_log_id=${params.userLogId}`
        : params.id && viewType == "team"
        ? `team_id=${params.id}`
        : params.id && viewType == "user" && `user_id=${params.id}`;

    const endPoint =
      viewType === "user"
        ? `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`
        : viewType === "team" &&
          `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`;
    try {
      const response = await apiClient.get(`${endPoint}`);
      // console.log(response);
      setCharts((prevValue) => response.data.data);
      setCoderInfo({
        coderName: response.data.data[0].coder_name,
      });
      setPageCount(response.data.meta.last_page);
      setDataCount(response.data.meta.total);
      let showPaginate =
        response.data.meta.total / response.data.meta.per_page > 1;
      setIsShowPaginate(showPaginate);
      setDataFrom(response.data.meta.from);
      setDataEnd(response.data.meta.to);
    } catch (err) {
      // console.log(err);
    }
  };

  // Date wise Filter Reset Function
  const filterReset = () => {
    setCharts([]);
    setDateRange([null, null]);
    setStartTime();
    setEndTime();
    setPageCount("");
    setDataCount("");
    setIsShowPaginate(false);
    setDataFrom("");
    setDataEnd("");
  };

  // ReadMore Modal Functions
  const closeReadMoreModal = () => {
    setshowReadMoreModal(false);
  };

  const readMoreModalFunc = (comments, oldComments = null) => {
    setshowReadMoreModal(true);
    setComments(comments);
    setOldComments(oldComments);
  };

  // Log Details Row Display
  const showRow = (id) => {
    const hiddenRowElements = document.querySelectorAll(`#hiddenRow_${id}`);

    if (hiddenRowElements) {
      for (const key in hiddenRowElements) {
        // console.log(key);
        hiddenRowElements[key].classList.contains("d-none")
          ? hiddenRowElements[key].classList.remove("d-none")
          : hiddenRowElements[key].classList.add("d-none");
      }
    }
  };
  const exportHandler = async () => {
    let tempId;
    if (timeLine === "filter") {
      const startedDate = startDate.toLocaleDateString("en-US");
      // let tempEndDate = endDate.getDate() + 1;
      const endedDate = endTime
        ? endDate.toLocaleDateString("en-US")
        : endDate.toLocaleDateString("en-US");
      // const endedDate = endTime ? endDate.toLocaleDateString("en-US") : new Date(endDate.getTime() + 86400000).toLocaleDateString("en-US");
      const startedTime = startTime
        ? startTime.toLocaleTimeString()
        : "12:00:00 AM";
      const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";
      // endTime ? setEndTime(endTime) : setEndTime("09:00:00 AM");
      tempId =
        params.id && params.userLogId
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&team_id=${params.id}`
          : params.id &&
            viewType == "user" &&
            `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_id=${params.id}`;
    } else {
      tempId =
        params.id && params.userLogId
          ? `user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `team_id=${params.id}`
          : params.id && viewType == "user" && `user_id=${params.id}`;
    }

    // const endPoint =
    //   viewType === "user"
    //     ? `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`
    //     : viewType === "team" &&
    //       `/filter/user-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`;

    // if (params.id && params.userLogId) {
    //   return (tempId = `user_log_id=${params.id}`);
    // } else if (params.id && viewType == "team") {
    //   return (tempId = `team_id=${params.id}`);
    // } else if (params.id && viewType == "user") {
    //   return (tempId = `user_id=${params.id}`);
    // }

    const { data } = await apiClient.get(
      `export/user-chart?timeline=${
        params.timeLine !== "filter" ? params.timeLine : "custom"
      }&${tempId}`
    );
    window.open(data, "_blank");
  };
  // let userName = coderInfo.name;
  // let projectName = coderInfo.projectName;
  // charts.map((chart) => {
  //   userName = chart.user.name;
  //   projectName = chart.user.team.project.project_name;
  // });
  // let tempObj = {};
  // // if (charts) {
  // //   charts.foreach((chart) => {
  // //     tempObj.coderName = charts[0].user.name;
  // //     tempObj.status = charts[0].user.status;
  // //     tempObj.teamId = charts[0].user.team.id;
  // //     tempObj.projectName = charts[0].user.team.project.project_name;
  // //     tempObj.status = charts[0].user.team.project.client.client_name;
  // //     tempObj.status = charts[0].user.team.project.client.client_code;
  // //   });
  // // }

  return (
    <>
      <SummaryNavLinks />

      {timeLine === "filter" && (
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end">
              <div
                style={{ width: "220px" }}
                className="rounded-3 me-2 input-bg d-flex align-content-center"
              >
                <i class="fa fa-solid fa-calendar-week ps-2 margin-top-ten text-secondary"></i>
                <DatePicker
                  className="form-control input-bg "
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={Date.now()}
                  // dateFormat='MMMM d, yyyy h:mm aa'
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                />
              </div>
              <div
                style={{ width: "110px" }}
                className="rounded-3 me-2 input-bg d-flex align-content-center"
              >
                <i class="far fa-clock ps-2 margin-top-ten text-secondary"></i>
                <DatePicker
                  className="form-control input-bg"
                  selected={startTime}
                  onChange={(time) => setStartTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Start Time"
                  // minTime={Date.now() - 10000 * 60 * 60}
                  // maxTime={Date.now() - 1000 * 60 * 60}
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="Start Time"
                />
              </div>

              <div
                style={{ width: "110px" }}
                className="rounded-3 me-2 input-bg d-flex justify-content-center align-content-center"
              >
                <i class="far fa-clock ps-2 margin-top-ten text-secondary"></i>
                <DatePicker
                  className="form-control input-bg"
                  selected={endTime}
                  onChange={(time) => setEndTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  // minTime={startTime ? startTime : Date.now()}
                  // maxTime={Date.now()}
                  timeCaption="End Time"
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="End Time"
                />
              </div>

              <button
                onClick={filterHandler}
                className="btn btn-sm filter-ui-bg me-2 shadow-none text-light font-size13 py-2 px-4"
              >
                Filter
              </button>

              <button
                onClick={filterReset}
                className="btn btn-sm btn-secondary me-2 shadow-none text-light font-size13 py-2 px-4"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <div class='row mb-3'>
        <div class='col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0'>
          <div class='card'>
            <div class='card-body'>
              <div class='row'>
                <div class='col-lg-2 col-md-12'>
                  <i class='text-secondary fas fa-clinic-medical fa-4x'></i>
                </div>
                <div class='col-lg-10 col-md-12'>
                  <span class='text-end'>
                    <h6> {coderInfo.clientNmae}</h6>
                    <h6 class='pl-2'> {coderInfo.clientCode}</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0'>
          <div class='card'>
            <div class='card-body'>
              <div class='row'>
                <div class='col-lg-2 col-md-12'>
                  <i class='text-secondary fas fa-project-diagram fa-4x'></i>
                </div>
                <div class='col-lg-10 col-md-12'>
                  <span class='text-end'>
                    <h6> {coderInfo.projectName}</h6>
                    <h6 class='pl-2'> {coderInfo.projectCode}</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0'>
          <div class='card'>
            <div class='card-body'>
              <div class='row'>
                <div class='col-lg-2 col-md-12'>
                  <i class='text-secondary fas fa-users fa-4x'></i>
                </div>
                <div class='col-lg-10 col-md-12'>
                  <span class='text-end'>
                    <h6>HSTM {coderInfo.teamId}</h6>
                    <h6 class='pl-2'>3</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0'>
          <div class='card'>
            <div class='card-body'>
              <div class='row'>
                <div class='col-lg-2 col-md-12'>
                  <i class='text-secondary fas fa-user fa-4x'></i>
                </div>
                <div class='col-lg-10 col-md-12'>
                  <span class='text-end'>
                    <h6>{coderInfo.coderName}</h6>
                    <h6 class='pl-2'>{coderInfo.status}</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="card">
        <div className="card-header pb-0 border-0 pt-3 d-flex justify-content-between align-items-center">
          <p className="mb-0 font-size13 ">
            {/* <i className='fas fa-file-alt me-2'></i> */}
            <img
              src="/images/user_chart_png.png"
              alt="user_charts"
              width="20px"
            />
            Charts
            {dataCount ? (
              <span className="badge count-badge-success ms-1 px-2 py-1">
                {dataCount}
              </span>
            ) : null}
          </p>
          <div className="d-flex">
            {viewType === "user" && coderInfo.coderName && (
              <p
                className="mb-0 d-flex align-items-center font-size13 "
                // style={{ color: "#5ab9c9" }}
              >
                <span className="text-nowrap light-text-op1">
                  <span>
                    <i class="fas fs-6 fa-user me-2 result-key"></i>Coder Name:
                  </span>{" "}
                  {ucFirst(`${coderInfo.coderName}`)}
                </span>
                {/* {params.userLogId !== undefined && timeLine === "all" && (
                <select
                  name='team_projects'
                  id='team_projects_select'
                  className='form-control ms-3 form-select'
                  value={selectTeamID}
                  onChange={(e) => {
                    filterByTeam(e.target.value);
                  }}
                >
                  <option value='all'>All Teams</option>
                  {teamsInfo.map((item) => (
                    <option value={`${item.id}`} key={item.id}>
                      HSTM {item.id} - {item.project.project_name}
                    </option>
                  ))}
                </select>
              )} */}
              </p>
            )}
            {params.id && (
              <button
                onClick={exportHandler}
                className="btn btn-sm export-bg-success text-light shadow-none ms-4"
              >
                <img
                  src="/images/Icon-feather-arrow-up-circle.png"
                  width="14px"
                  alt="up-arrow"
                />{" "}
                Export
              </button>
            )}
            {/* {auth && auth.user.role === "lead" && (
            <button onClick={exportHandler} className='btn btn-sm btn-info shadow-none ms-2'>
              Export
            </button>
          )} */}
            {/* {viewType === "user" && coderInfo.projectName && (
            <p className='mb-0' style={{ color: "#5ab9c9" }}>
              Project Name: <span>{ucFirst(`${coderInfo.projectName}`)}</span>
            </p>
          )} */}

            {/* {viewType === "team" && coderInfo.projectName && (
            <p className='mb-0' style={{ color: "#5ab9c9" }}>
              Project Name: <span>{ucFirst(`${coderInfo.projectName}`)}</span>
            </p>
          )} */}
          </div>
        </div>
        <div className="card-body px-0">
          <div className="table-responsive ">
            <table className="table">
              <thead className="font-size13">
                <tr>
                  <th scope="col" className=" ps-2 text-center">
                    S.No{isLHPProject}
                  </th>
                  <th scope="col" className="text-nowrap">
                    Team ID
                  </th>
                  <th scope="col" className="text-nowrap">
                    Project Name
                  </th>
                  <th scope="col" className="text-nowrap ">
                    Login Name
                  </th>
                  {viewType !== "user" && (
                    <th scope="col" className="text-nowrap">
                      User Name
                    </th>
                  )}
                  <th scope="col">
                    {isLHPProject ? "Encounter Number" : "Chart ID"}
                  </th>
                  {isLHPProject && (
                    <th scope="col" className="text-nowrap">
                      Date of service
                    </th>
                  )}
                  <th scope="col" className="text-nowrap ">
                    {isLHPProject ? "Patient Name" : "Member Name"}
                  </th>
                  <th scope="col" className="text-nowrap ">
                    D.O.B
                  </th>
                  <th scope="col" className="text-nowrap">
                    Total DOS
                  </th>
                  <th scope="col" className="text-nowrap ">
                    {isLHPProject ? "ICD Codes" : "Total ICD"}
                  </th>
                  {isLHPProject && (
                    <th scope="col" className="text-nowrap">
                      CPT Codes
                    </th>
                  )}
                  <th scope="col" className="text-nowrap">
                    Total Pages
                  </th>
                  {isLHPProject && (
                    <th scope="col" className="text-nowrap">
                      Type of visit
                    </th>
                  )}
                  {isLHPProject && (
                    <th scope="col" className="text-nowrap">
                      Facilty Name
                    </th>
                  )}
                  {isLHPProject && (
                    <th scope="col" className="text-nowrap">
                      State
                    </th>
                  )}
                  {!isLHPProject && (
                    <th scope="col" className="text-nowrap text-center">
                      Coding Status
                    </th>
                  )}
                  {!isLHPProject && (
                    <th scope="col" className="">
                      Comments
                    </th>
                  )}
                  {!isLHPProject && (
                    <th scope="col" className="text-center">
                      Quality
                    </th>
                  )}
                  <th scope="col" className="text-nowrap ">
                    Coding Date
                  </th>
                  <th scope="col" className="text-nowrap ">
                    Date
                  </th>
                  <th scope="col" className="text-nowrap">
                    Time
                  </th>
                  <th scope="col" className="text-nowrap pe-4">
                    Log
                  </th>
                </tr>
              </thead>
              <tbody className="font-size13 font-weight-light">
                {charts &&
                  charts.map((chart, index) => (
                    <>
                      <tr key={chart.id}>
                        {dataFrom ? (
                          <td className="text-nowrap text-center">
                            {dataFrom + index}
                          </td>
                        ) : (
                          <td className="text-center">{index + 1}</td>
                        )}
                        <td className="text-nowrap ps-3">
                          HSTM {chart.team_id}
                        </td>
                        <td className="text-nowrap">{chart.project_name}</td>
                        <td className="text-nowrap">
                          {chart.coder_login_name}
                        </td>

                        {viewType !== "user" && (
                          <td>{ucFirst(chart.coder_name)}</td>
                        )}
                        <td
                          className={`${
                            chart.action === "rejected" && "text-danger"
                          }`}
                        >
                          {chart.chart_id}
                        </td>
                        {isLHPProject && (
                          <td>
                            {new Date(chart.date_of_service).toLocaleDateString(
                              "en-US"
                            )}
                          </td>
                        )}

                        <td className="text-nowrap">
                          {ucFirst(chart.member_name)}
                        </td>
                        {/* <td>{new Date(chart.dob).toLocaleDateString("en-us")}</td> */}
                        <td className="text-nowrap">
                          {new Date(chart.dob).toLocaleDateString()}
                        </td>
                        <td className="text-center">{chart.dos}</td>
                        <td className="text-center">{chart.icd}</td>
                        {isLHPProject && <td>{chart.cpt_codes}</td>}
                        <td className="text-center">{chart.pages}</td>
                        {isLHPProject && <td>{chart.type_of_visit}</td>}
                        {isLHPProject && <td>{chart.facility_name}</td>}
                        {isLHPProject && (
                          <td className="text-uppercase">{chart.state}</td>
                        )}
                        {!isLHPProject && (
                          <td className={`text-center`}>
                            <span className={`badge ${chart.action}`}>
                              {chart.action === "code_completed"
                                ? "Completed"
                                : ucFirst(chart.action).split("_").join(" ")}
                              {/* {ucFirst(chart.action).split("_").join(" ")} */}
                            </span>
                          </td>
                        )}
                        {!isLHPProject && (
                          <td className="fit text-nowrap ps-4">
                            {chart.comments && chart.comments.length > 10
                              ? ucFirst(
                                  chart.comments.slice(0, 10).concat("...")
                                )
                              : chart.comments &&
                                chart.comments !== null &&
                                ucFirst(chart.comments)}
                            {chart.comments && chart.comments.length > 10 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Read More</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm i-success ms-1 shadow-none"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    readMoreModalFunc(ucFirst(chart.comments));
                                  }}
                                >
                                  {/* <i className='fas fa-comment-medical'></i> */}
                                  {/* <i class="fas fa-solid fa-comment-dots"></i> */}
                                  <img
                                    src="/images/comment-logo.png"
                                    width="12px"
                                    alt="comment-logo"
                                  />
                                </button>
                              </OverlayTrigger>
                            )}
                            {!chart.comments && (
                              <span className="text-muted">NA</span>
                            )}
                          </td>
                        )}
                        {!isLHPProject && (
                          <td className="text-center">
                            {chart?.chart_quality}
                          </td>
                        )}
                        <td className="text-nowrap">
                          {new Date(chart.coding_date).toDateString().slice(4)}
                        </td>
                        <td className="text-nowrap">
                          {new Date(chart.coding_at).toDateString().slice(4)}
                        </td>
                        <td className="text-nowrap">
                          {new Date(chart.coding_at)
                            .toTimeString()
                            .split(" ")
                            .shift()}
                        </td>
                        <td>
                          {chart.logs.length ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>View Log</Tooltip>}
                            >
                              <button
                                type="button"
                                className="btn btn-sm i-secondary shadow-none ml-2"
                                onClick={() => showRow(chart.chart_id)}
                              >
                                <i className="fas fa-book-medical"></i>
                                {chart.logs.length ? (
                                  <span className="badge bg-success ms-1  rounded-pill">
                                    {chart.logs.length}
                                  </span>
                                ) : null}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                        </td>
                      </tr>
                      {chart.logs.length
                        ? chart.logs.map((log) => (
                            <tr
                              key={log.id}
                              className="d-none hidden_row"
                              id={`hiddenRow_${chart.chart_id}`}
                              style={{ backgroundColor: "#05084b" }}
                            >
                              <td>
                                <i class="fas fa-hand-point-up"></i>
                              </td>
                              <td>HSTM {chart.team_id}</td>
                              <td>{chart.project_name}</td>
                              <td>{chart.coder_login_name}</td>
                              {viewType !== "user" && (
                                <td>{chart.coder_name}</td>
                              )}
                              <td
                                className={
                                  log.properties.old.chart_id !==
                                  log.properties.attributes.chart_id
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {log.properties.old.chart_id !==
                                log.properties.attributes.chart_id ? (
                                  <span>
                                    {log.properties.old.chart_id}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.chart_id}
                                  </span>
                                ) : (
                                  log.properties.old.chart_id
                                )}
                              </td>
                              {isLHPProject && (
                                <td
                                  className={
                                    log.properties.old.date_of_service !==
                                    log.properties.attributes.date_of_service
                                      ? "text-warning"
                                      : null
                                  }
                                >
                                  {log.properties.old.date_of_service !==
                                  log.properties.attributes.date_of_service ? (
                                    <span>
                                      {new Date(
                                        log.properties.old.date_of_service
                                      ).toLocaleDateString("en-US")}
                                      <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                      {new Date(
                                        log.properties.old.date_of_service
                                      ).toLocaleDateString("en-US")}
                                    </span>
                                  ) : (
                                    <span>
                                      {new Date(
                                        log.properties.old.date_of_service
                                      ).toLocaleDateString("en-US")}
                                    </span>
                                  )}
                                </td>
                              )}

                              <td
                                className={
                                  log.properties.old.member_name !==
                                  log.properties.attributes.member_name
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {log.properties.old.member_name !==
                                log.properties.attributes.member_name ? (
                                  <span>
                                    {ucFirst(log.properties.old.member_name)}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {ucFirst(
                                      log.properties.attributes.member_name
                                    )}
                                  </span>
                                ) : (
                                  ucFirst(log.properties.old.member_name)
                                )}
                              </td>
                              <td
                                className={
                                  log.properties.old.dob !==
                                  log.properties.attributes.dob
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {log.properties.old.dob !==
                                log.properties.attributes.dob ? (
                                  <span>
                                    {new Date(
                                      log.properties.old.dob
                                    ).toLocaleDateString()}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {new Date(
                                      log.properties.attributes.dob
                                    ).toLocaleDateString()}
                                  </span>
                                ) : (
                                  new Date(
                                    log.properties.old.dob
                                  ).toLocaleDateString()
                                )}
                              </td>
                              <td
                                className={
                                  log.properties.old.dos !==
                                  log.properties.attributes.dos
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {/* <img src="/images/log(1).png" width="50px" alt="log-image"/> */}
                                {log.properties.old.dos !==
                                log.properties.attributes.dos ? (
                                  <span>
                                    {log.properties.old.dos}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.dos}
                                  </span>
                                ) : (
                                  log.properties.old.dos
                                )}
                              </td>
                              <td
                                className={
                                  log.properties.old.icd !==
                                  log.properties.attributes.icd
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {log.properties.old.icd !==
                                log.properties.attributes.icd ? (
                                  <span>
                                    {log.properties.old.icd}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.icd}
                                  </span>
                                ) : (
                                  log.properties.old.icd
                                )}
                              </td>
                              {isLHPProject && (
                                <>
                                  <td
                                    className={
                                      log.properties.old.cpt_codes !==
                                      log.properties.attributes.cpt_codes
                                        ? "text-warning"
                                        : null
                                    }
                                  >
                                    {log.properties.old.cpt_codes !==
                                    log.properties.attributes.cpt_codes ? (
                                      <span>
                                        {log.properties.old.cpt_codes}
                                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                        {log.properties.attributes.cpt_codes}
                                      </span>
                                    ) : (
                                      log.properties.old.cpt_codes
                                    )}
                                  </td>
                                </>
                              )}
                              <td
                                className={
                                  log.properties.old.pages !==
                                  log.properties.attributes.pages
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {log.properties.old.pages !==
                                log.properties.attributes.pages ? (
                                  <span>
                                    {log.properties.old.pages}
                                    <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                    {log.properties.attributes.pages}
                                  </span>
                                ) : (
                                  log.properties.old.pages
                                )}
                              </td>

                              {isLHPProject && (
                                <td
                                  className={
                                    log.properties.old.type_of_visit !==
                                    log.properties.attributes.type_of_visit
                                      ? "text-warning"
                                      : null
                                  }
                                >
                                  {log.properties.old.type_of_visit !==
                                  log.properties.attributes.type_of_visit ? (
                                    <span>
                                      {log.properties.old.type_of_visit}
                                      <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                      {log.properties.attributes.type_of_visit}
                                    </span>
                                  ) : (
                                    log.properties.old.type_of_visit
                                  )}
                                </td>
                              )}
                              {isLHPProject && (
                                <td
                                  className={
                                    log.properties.old.facility_name !==
                                    log.properties.attributes.facility_name
                                      ? "text-warning"
                                      : null
                                  }
                                >
                                  {log.properties.old.facility_name !==
                                  log.properties.attributes.facility_name ? (
                                    <span>
                                      {log.properties.old.facility_name}
                                      <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                      {log.properties.attributes.facility_name}
                                    </span>
                                  ) : (
                                    log.properties.old.facility_name
                                  )}
                                </td>
                              )}

                              {isLHPProject && (
                                <td
                                  className={
                                    log.properties.old.state !==
                                    log.properties.attributes.state
                                      ? "text-warning"
                                      : null
                                  }
                                >
                                  {log.properties.old.state !==
                                  log.properties.attributes.state ? (
                                    <span>
                                      {log.properties.old.state}
                                      <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                      {log.properties.attributes.state}
                                    </span>
                                  ) : (
                                    log.properties.old.state
                                  )}
                                </td>
                              )}

                              {!isLHPProject && (
                                <td
                                  className={
                                    log.properties.old.comments !==
                                    log.properties.attributes.comments
                                      ? "text-warning"
                                      : null
                                  }
                                >
                                  {log.properties.old.comments !==
                                  log.properties.attributes.comments ? (
                                    <span>
                                      {log.properties.old.comments &&
                                      log.properties.old.comments.length > 10
                                        ? ucFirst(
                                            log.properties.old.comments
                                              .slice(0, 10)
                                              .concat("...")
                                          )
                                        : ucFirst(log.properties.old.comments)}
                                      <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                      {log.properties.attributes.comments &&
                                      log.properties.attributes.comments
                                        .length > 10
                                        ? ucFirst(
                                            log.properties.attributes.comments
                                              .slice(0, 10)
                                              .concat("...")
                                          )
                                        : ucFirst(
                                            log.properties.attributes.comments
                                          )}
                                      {log.properties.attributes.comments &&
                                        log.properties.attributes.comments
                                          .length > 10 && (
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip>Read More</Tooltip>
                                            }
                                          >
                                            <button
                                              className="btn btn-sm i-success ms-1 shadow-none"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                readMoreModalFunc(
                                                  ucFirst(
                                                    log.properties.attributes
                                                      .comments
                                                  ),
                                                  ucFirst(
                                                    log.properties.old.comments
                                                  )
                                                );
                                              }}
                                            >
                                              {/* <i className='fas fa-comment-medical'></i> */}
                                              {/* <i class="fas fa-solid fa-comment-dots"></i> */}
                                              <img
                                                src="/images/comment-logo.png"
                                                width="12px"
                                                alt="comment-logo"
                                              />
                                            </button>
                                          </OverlayTrigger>
                                        )}
                                    </span>
                                  ) : log.properties.old.comments &&
                                    log.properties.old.comments.length > 10 ? (
                                    ucFirst(
                                      log.properties.old.comments
                                        .slice(0, 10)
                                        .concat("...")
                                    )
                                  ) : (
                                    ucFirst(log.properties.old.comments)
                                  )}
                                </td>
                              )}
                              <td></td>
                              <td>
                                {new Date(log.properties.attributes.coding_at)
                                  .toDateString()
                                  .slice(4)}
                              </td>
                              <td
                                className={
                                  new Date(log.properties.old.coding_at)
                                    .toTimeString()
                                    .split(" ")
                                    .shift() !==
                                  new Date(log.properties.attributes.coding_at)
                                    .toTimeString()
                                    .split(" ")
                                    .shift()
                                    ? "text-warning"
                                    : null
                                }
                              >
                                {new Date(log.properties.old.coding_at)
                                  .toTimeString()
                                  .split(" ")
                                  .shift()}
                                <i className="fas fa-long-arrow-alt-right mx-2"></i>
                                {new Date(log.properties.attributes.coding_at)
                                  .toTimeString()
                                  .split(" ")
                                  .shift()}
                              </td>
                              <td></td>
                            </tr>
                          ))
                        : null}
                      <ReadMoreModal
                        showReadMoreModal={showReadMoreModal}
                        closeReadMoreModal={closeReadMoreModal}
                        comments={comments}
                        oldComments={oldComments}
                        setshowReadMoreModal={setshowReadMoreModal}
                        title="Comments"
                      />
                    </>
                  ))}

                {!charts.length && (
                  <tr className="text-center font-size13">
                    <td colSpan={25}>No data available at this moment</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isShowPaginate && (
            <div className="d-flex justify-content-between align-items-center flex-row-reverse pt-3 px-3">
              <div>
                <ReactPaginate
                  key={timeLine}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              {dataFrom && dataEnd && (
                <span className="ms-2 showing font-size13 grey-clr">
                  <i class="fas fa-book-open me-1"></i>
                  Showing {dataFrom} - {dataEnd} of {dataCount} entries
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChartsTable;
