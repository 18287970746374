import React from "react";

const CardBody = ({ children }) => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBody;
