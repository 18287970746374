import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import apiClient from "../../services/ApiClient";
import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddUserChart from "./AddUserChart";
import ReactPaginate from "react-paginate";
import { useAuth } from "../../context/AuthContext";
import ErrorFeedback from "../ErrorFeedback";
import { useMutation, useQueryClient } from "react-query";
import LoadingIndicator from "../../shared/LoadingIndicator";

const UserChartTable = ({
  charts,
  isFetching,
  setCharts,
  refetch,
  pageCount,
  handlePageClick,
  dataCount,
  setPageCount,
  setDataCount,
  dataFrom,
  dataEnd,
  setDataFrom,
  setDataEnd,
  isShowPaginate,
  setIsShowPaginate,
  timeLine,
  projectId,
}) => {
  const [editChartId, setEditChartId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [editFormErrors, setEditFormErrors] = useState(null);
  const queryClient = useQueryClient();

  const auth = useAuth();
  const coderProjectID = auth.user.project_id;
  const isLHPProject = Number(coderProjectID) === Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);
  const isAetnaProject = Number(coderProjectID) === Number(`${process.env.REACT_APP_AETNA_PROJECT_ID}`);

  const handleEditClick = (event, chart) => {
    // console.log(chart);
    event.preventDefault();
    setEditChartId(chart.id);

    const formValues = {
      ...chart,
    };

    setEditFormData(formValues);
    setEditFormErrors(null);
  };

  const handleCancel = () => {
    setEditChartId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    if (!isLHPProject) {
      delete newFormData.facility_name;
      delete newFormData.date_of_service;
      delete newFormData.state;
      delete newFormData.type_of_visit;
    }

    setEditFormData(newFormData);
  };
  // Edit Functions Starts
  const editMutation = useMutation(
    (editedChart) =>
      apiClient.post(`user-chart/edit/${editChartId}`, editedChart, {
        componentName: "edituserchart",
      }),
    {
      onSuccess: () => {
        console.log(queryClient);
        queryClient.invalidateQueries("userchart");
        setEditChartId(null);
        setEditFormErrors(null);
      },
      onError: (err) => {
        setEditFormErrors(err.response.data.error);
      },
    }
  );
  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    const editedChart = {
      ...editFormData,
    };

    // try {
    //   const response = await apiClient.post(`user-chart/edit/${editChartId}`, editedChart);
    //   fetchData();
    //   setEditChartId(null);
    // } catch (err) {
    //   setEditFormErrors(err.response.data.error);
    //   console.log(err);
    // }
    await editMutation.mutateAsync(editedChart);
  };

  return (
    <>
      {auth.user.is_user_error_free === "true" ? (
        <>
          <AddUserChart refetch={refetch} />

          <div className="card">
            <div className="card-header pb-0 border-0 pt-3">
              <h6 className="mb-0 font-size13">
                <i className="fas fa-file-alt me-2"></i>
                Charts
                {dataCount ? <span className="badge count-badge-success ms-1 px-2 py-1">{dataCount}</span> : null}
              </h6>
            </div>
            <div className="card-body px-0">
              <div className="table-responsive">
                <form onSubmit={handleEditFormSubmit}>
                  <table className="table">
                    <thead className="font-size13">
                      <tr>
                        <th scope="col" className="text-center">
                          S.No
                        </th>
                        <th scope="col" className="text-center">
                          {isLHPProject ? "Encounter Number" : "Chart ID"}
                        </th>
                        {isLHPProject && <th scope="col">Date of service</th>}
                        <th scope="col" className="text-center">
                          {isLHPProject ? "Patient Name" : "Member Name"}
                        </th>
                        <th scope="col" className="text-center">
                          D.O.B
                        </th>
                        <th scope="col" className="text-center">
                          {isAetnaProject ? "All Claim Code" : "Total DOS"}
                        </th>
                        <th scope="col" className="text-center">
                          {isLHPProject ? "ICD Codes" : isAetnaProject ? "Added codes" : "Total ICD"}
                        </th>
                        {isLHPProject && <th scope="col">CPT Codes</th>}
                        <th scope="col" className="text-center">
                          {isAetnaProject ? "No of Pages" : "Total Pages"}
                        </th>
                        {isLHPProject && <th scope="col">Type of visit</th>}
                        {isLHPProject && <th scope="col">Facilty Name</th>}
                        {isLHPProject && <th scope="col">State</th>}
                        {!isLHPProject && (
                          <th scope="col" className="text-center fit">
                            Coding Status
                          </th>
                        )}
                        {!isLHPProject && (
                          <th scope="col" className="text-center">
                            Comments
                          </th>
                        )}
                        <th scope="col" className="text-center">
                          Date
                        </th>
                        <th scope="col" className="text-center">
                          Project Name
                        </th>
                        <th scope="col" className="text-center">
                          Login Name
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="font-size13 font-weight-light">
                      {isFetching && <LoadingIndicator />}
                      {charts &&
                        charts.map((chart, index) => (
                          <>
                            {editChartId === chart.id ? (
                              <EditOnlyRow
                                key={index}
                                editFormData={editFormData}
                                index={index}
                                handleEditFormChange={handleEditFormChange}
                                handleCancel={handleCancel}
                                editFormErrors={editFormErrors}
                                setEditFormData={setEditFormData}
                                dataFrom={dataFrom}
                                isLoading={editMutation.isLoading}
                              />
                            ) : (
                              <ReadOnlyRow key={index} chart={chart} index={index} handleEditClick={handleEditClick} dataFrom={dataFrom} />
                            )}
                          </>
                        ))}

                      {!charts.length && (
                        // <tr className="text-center font-size13">
                        //   <td colSpan={25}>No data available at this moment</td>
                        // </tr>
                        <tr className="text-center font-size13">
                          <td colSpan={25}>{isFetching ? "Loading..." : "No data available at this moment"}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </form>
              </div>
              {/* {isShowPaginate && ( */}
              <div className="d-flex justify-content-between align-items-center flex-row-reverse pt-3 pe-3">
                <div>
                  <ReactPaginate
                    key={timeLine}
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
                {dataFrom && dataEnd && (
                  <span className="ms-3 showing  font-size13 grey-clr">
                    <i class="fas fa-book-open me-1"></i>
                    Showing {dataFrom} - {dataEnd} of {dataCount} entries
                  </span>
                )}
              </div>
              {/* )} */}
            </div>
          </div>
        </>
      ) : (
        <ErrorFeedback />
      )}
    </>
  );
};

export default UserChartTable;
