import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";

import Tooltip from "react-bootstrap/Tooltip";
// import { useFormik } from "formik";
// import * as yup from "yup";

import { useAuth } from "../../context/AuthContext";

const EditOnlyRow = ({ index, editFormData, handleCancel, handleEditFormChange, editFormErrors, setEditFormData, dataFrom, isLoading }) => {
  const auth = useAuth();

  const coderProjectID = auth.user.project_id;

  return (
    <tr key={index}>
      {dataFrom ? <td className="text-center">{dataFrom + index}</td> : <td className="text-center">{index + 1}</td>}
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter a Chart ID"
          name="chart_id"
          className="form-control font-size13 "
          value={editFormData.chart_id}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.chart_id && <div className="invalid-feedback d-block">{editFormErrors.chart_id}</div>}
      </td>
      {coderProjectID == `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <DatePicker
            className="form-control"
            dateFormat="MM/dd/yyyy"
            selected={Date.parse(editFormData.date_of_service)}
            maxDate={new Date()}
            name="date_of_service"
            onChange={(date) => setEditFormData({ ...editFormData, date_of_service: date })}
          />
          {editFormErrors && editFormErrors.date_of_service && (
            <div id="date_of_service" className="invalid-feedback d-block">
              {editFormErrors.date_of_service}
            </div>
          )}
        </td>
      )}
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter Project Code"
          name="member_name"
          className="form-control font-size13 "
          value={editFormData.member_name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.member_name && (
          <div id="member_name" className="invalid-feedback d-block">
            {editFormErrors.member_name}
          </div>
        )}
      </td>

      <td>
        {/* <input
          type='date'
          required='required'
          placeholder='Enter D.O.B'
          name='dob'
          className='form-control'
          value={editFormData.dob}
          onChange={handleEditFormChange}
        ></input> */}

        <DatePicker
          className="form-control font-size13 "
          dateFormat="MM/dd/yyyy"
          selected={Date.parse(editFormData.dob)}
          maxDate={new Date()}
          name="dob"
          onChange={(date) => setEditFormData({ ...editFormData, dob: date })}
        />
        {editFormErrors && editFormErrors.dob && (
          <div id="dob" className="invalid-feedback d-block">
            {editFormErrors.dob}
          </div>
        )}
      </td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter DOS"
          name="dos"
          className="form-control font-size13 "
          value={editFormData.dos}
          onChange={handleEditFormChange}
          style={{ width: "70px", margin: "auto" }}
        ></input>
        {editFormErrors && editFormErrors.dos && (
          <div id="dos" className="invalid-feedback d-block">
            {editFormErrors.dos}
          </div>
        )}
      </td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter ICD"
          name="icd"
          className="form-control font-size13 "
          value={editFormData.icd}
          onChange={handleEditFormChange}
          style={{ width: "70px", margin: "auto" }}
        ></input>
        {editFormErrors && editFormErrors.icd && (
          <div id="icd" className="invalid-feedback d-block">
            {editFormErrors.icd}
          </div>
        )}
      </td>
      {coderProjectID == `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <input
            type="text"
            required="required"
            placeholder="Enter CPT Codes"
            name="cpt_codes"
            className="form-control font-size13 "
            value={editFormData.cpt_codes}
            onChange={handleEditFormChange}
            style={{ width: "70px", margin: "auto" }}
          ></input>
          {editFormErrors && editFormErrors.cpt_codes && (
            <div id="cpt_codes" className="invalid-feedback d-block">
              {editFormErrors.cpt_codes}
            </div>
          )}
        </td>
      )}
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter no of pages"
          name="pages"
          className="form-control font-size13 "
          value={editFormData.pages}
          onChange={handleEditFormChange}
          style={{ width: "70px", margin: "auto" }}
        ></input>
        {editFormErrors && editFormErrors.pages && (
          <div id="pages" className="invalid-feedback d-block">
            {editFormErrors.pages}
          </div>
        )}
      </td>

      {coderProjectID == `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <select
            name="type_of_visit"
            className={`form-select form-control font-size13 `}
            id="type_of_visit"
            onChange={handleEditFormChange}
            value={editFormData.type_of_visit}
            defaultValue={editFormData.type_of_visit}
          >
            <option value="Annual Comprehensive">Annual Comprehensive</option>
            <option value="Initial Comprehensive">Initial Comprehensive</option>
            <option value="Initial Encounter">Initial Encounter</option>
            <option value="Scheduled Follow Up">Scheduled Follow Up</option>
            <option value="Acute">Acute</option>
            <option value="Acute Post-Fall">Acute Post-Fall</option>
            <option value="Readmissions">Readmissions</option>
          </select>
          {editFormErrors && editFormErrors.type_of_visit && (
            <div id="type_of_visit" className="invalid-feedback d-block">
              {editFormErrors.type_of_visit}
            </div>
          )}
        </td>
      )}

      {coderProjectID == `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <input
            type="text"
            required="required"
            placeholder="Facility Name"
            name="facility_name"
            className="form-control font-size13 "
            value={editFormData.facility_name}
            onChange={handleEditFormChange}
            style={{ width: "70px", margin: "auto" }}
          ></input>
          {editFormErrors && editFormErrors.facility_name && (
            <div id="facility_name" className="invalid-feedback d-block">
              {editFormErrors.facility_name}
            </div>
          )}
        </td>
      )}

      {coderProjectID == `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <select
            name="state"
            className={`form-select form-control font-size13 `}
            id="state"
            onChange={handleEditFormChange}
            value={editFormData.state}
            defaultValue={editFormData.state}
          >
            <option value="fl">FL</option>
            <option value="mi">MI</option>
            <option value="ny">NY</option>
          </select>
          {editFormErrors && editFormErrors.state && (
            <div id="state" className="invalid-feedback d-block">
              {editFormErrors.state}
            </div>
          )}
        </td>
      )}

      {coderProjectID != `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          <select
            name="action"
            className={`form-select form-control font-size13 `}
            id="action"
            onChange={handleEditFormChange}
            value={editFormData.action}
            defaultValue={editFormData.action}
          >
            <option value="code_completed">Completed</option>
            <option value="rejected">Rejected</option>
            <option value="SAR">SAR</option>
          </select>
          {editFormErrors && editFormErrors.action && (
            <div id="action" className="invalid-feedback d-block">
              {editFormErrors.action}
            </div>
          )}
        </td>
      )}

      {coderProjectID != `${process.env.REACT_APP_LHP_PROJECT_ID}` && (
        <td>
          {/* <input
          type='text'
          required='required'
          placeholder='Enter Comments'
          name='comments'
          className='form-control'
          value={editFormData.comments}
          onChange={handleEditFormChange}
        ></input> */}
          <textarea
            name="comments"
            id="comments"
            placeholder="Enter Comments"
            className={`form-control font-size13 `}
            value={editFormData.comments}
            onChange={handleEditFormChange}
            style={{ height: "10px" }}
          ></textarea>
          {editFormErrors && editFormErrors.comments && (
            <div id="clientName" className="invalid-feedback d-block">
              {editFormErrors.comments}
            </div>
          )}
        </td>
      )}
      {/* <td>{editFormData.coding_date}</td> */}
      <td className="text-center">{new Date(editFormData.coding_date).toDateString().slice(4)}</td>
      {/* <td></td> */}
      <td className="text-center">{editFormData.project_name}</td>
      <td className="text-center">{editFormData.coder_login_name}</td>

      <td className="text-nowrap ">
        {isLoading ? (
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip>Save Chart</Tooltip>}>
              <button type="submit" className="btn btn-sm i-success shadow-none ml-2">
                <i className="fas fa-arrow-alt-circle-right" aria-hidden="true" />
              </button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Cancel Edit</Tooltip>}>
              <button type="button" className="btn btn-sm i-primary font-15 shadow-none ml-2" onClick={handleCancel}>
                <i className="fa fa-times" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </>
        )}
        {/* <OverlayTrigger placement="top" overlay={<Tooltip>Save Chart</Tooltip>}>
          <button type="submit" className="btn btn-sm i-success shadow-none ml-2">
            <i className="fas fa-arrow-alt-circle-right" aria-hidden="true" />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={<Tooltip>Cancel Edit</Tooltip>}>
          <button type="button" className="btn btn-sm i-primary font-15 shadow-none ml-2" onClick={handleCancel}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </OverlayTrigger> */}
      </td>
    </tr>
  );
};

export default EditOnlyRow;
