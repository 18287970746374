import { useState } from "react";

import React, { useContext } from "react";
import { CommonContext } from "../../context/CommonContext";

import apiClient from "../../services/ApiClient";
import DeleteModal from "../../utils/Modals/DeleteModal";
import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import AddTeamUsersModal from "../../utils/Modals/Add-TeamUsersModal";
import AddTeamAuditorsModal from "../../utils/Modals/AddTeamAuditorsModal";
import RemoveTeamUsersModal from "../../utils/Modals/RemoveTeamUsersModal";
import LoadingIndicator from "../../shared/LoadingIndicator";

const TeamsTable = ({ fetchData, isFetching }) => {
  const [editId, setEditId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [teamUserId, setteamUserId] = useState(null);
  const [currentModalRole, setCurrentModalRole] = useState(null);
  const [showAddTeamUserModal, setshowAddTeamUserModal] = useState(false);
  const [showAddTeamAuditorModal, setshowAddTeamAuditorModal] = useState(false);
  const [showRemoveTeamUserModal, setshowRemoveTeamUserModal] = useState(false);

  // Edit Function Starts
  const handleEditClick = (event, team) => {
    event.preventDefault();
    setEditId(team.id);

    const formValues = {
      ...team,
    };

    setEditFormData(formValues);
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
  };
  // Edit Function Ends

  // Delete  Function Starts
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteModalFunc = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const addTeamUsersModalFunc = (id) => {
    setshowAddTeamUserModal(true);
    setteamUserId(id);
  };

  const closeAddTeamUserModal = () => {
    setshowAddTeamUserModal(false);
  };

  const addTeamAuditorsModalFunc = (id) => {
    setshowAddTeamAuditorModal(true);
    setteamUserId(id);
  };

  const closeAddTeamAuditorModal = () => {
    setshowAddTeamAuditorModal(false);
  };

  // Remove User modal based on role
  const removeTeamUsersModalFunc = async (team_id, role) => {
    let users = await getUsersByRole(team_id, role);
    setshowRemoveTeamUserModal(true);
    setCurrentModalRole(role);

    setteamUserId(team_id);
    setEditFormData(users.data.data);
  };

  const closeRemoveTeamUserModal = () => {
    setshowRemoveTeamUserModal(false);
  };

  // Delete  Function
  const handleDelete = async () => {
    try {
      let id = deleteId;
      const response = await apiClient.delete(`team/disable/${id}`);

      fetchData();
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Get Users based on role
  const getUsersByRole = async (team_id, role) => {
    try {
      return await apiClient.get(`team/users/${team_id}/${role}`);
    } catch (err) {
      console.log(err);
    }
  };

  const context = useContext(CommonContext);
  const {
    teams,
    setTeams,
    teamLeads,
    setTeamLeads,
    projects,
    setProjects,
    users,
    setUsers,
    auditors,
    setAuditors,
  } = context;

  return (
    <>
      <div className="card">
        <div className="card-header  pb-0 border-0 pt-3 ">
          <p className="mb-0 font-size13">
            <i className="fas fa-users me-2"></i>
            Teams
            {teams.length ? (
              <span className="badge count-badge-success ms-1 px-2 py-1">
                {teams.length}
              </span>
            ) : null}
          </p>
        </div>
        <div className="card-body px-0">
          <div className="table-responsive">
            <table className="table">
              <thead className="font-size13">
                <tr>
                  <th scope="col" className="text-center">
                    S.No
                  </th>

                  <th scope="col">Team ID</th>
                  <th scope="col">Team Lead</th>
                  <th scope="col">Project Name</th>
                  <th scope="col" className="text-center">
                    Coders
                  </th>
                  <th scope="col" className="text-center">
                    Auditors
                  </th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="font-size13 font-weight-light">
                {isFetching && <LoadingIndicator />}
                {teams &&
                  teams.map((team, index) => (
                    <tr
                      key={index}
                      className={`${
                        team.status === "inactive" && "user-select-none pe-none"
                      }`}
                    >
                      {editId === team.id ? (
                        <EditOnlyRow
                          key={index}
                          editFormData={editFormData}
                          index={index}
                          handleEditFormChange={handleEditFormChange}
                          handleCancel={handleCancel}
                        />
                      ) : (
                        <ReadOnlyRow
                          key={index}
                          team={team}
                          index={index}
                          handleEditClick={handleEditClick}
                          deleteModalFunc={deleteModalFunc}
                          addTeamUsersModalFunc={addTeamUsersModalFunc}
                          addTeamAuditorsModalFunc={addTeamAuditorsModalFunc}
                          removeTeamUsersModalFunc={removeTeamUsersModalFunc}
                        />
                      )}
                    </tr>
                  ))}
                {!teams.length && (
                  <tr className="text-center font-size13">
                    <td colSpan={10}>
                      {isFetching
                        ? "Loading..."
                        : "No data available at this moment"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        title="Team"
      />
      <AddTeamUsersModal
        showAddTeamUserModal={showAddTeamUserModal}
        closeAddTeamUserModal={closeAddTeamUserModal}
        users={users}
        teamUserId={teamUserId}
        fetchData={fetchData}
      />
      <AddTeamAuditorsModal
        showAddTeamAuditorModal={showAddTeamAuditorModal}
        closeAddTeamAuditorModal={closeAddTeamAuditorModal}
        auditors={auditors}
        teamUserId={teamUserId}
        fetchData={fetchData}
      />
      <RemoveTeamUsersModal
        showRemoveTeamUserModal={showRemoveTeamUserModal}
        closeRemoveTeamUserModal={closeRemoveTeamUserModal}
        editFormData={editFormData}
        teamUserId={teamUserId}
        fetchData={fetchData}
        setshowRemoveTeamUserModal={setshowRemoveTeamUserModal}
        role={currentModalRole}
      />
    </>
  );
};

export default TeamsTable;
