import React from "react";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import { ucFirst } from "../../utils/UcFirst";

const ReadOnlyRow = ({
  team,
  index,
  handleEditClick,
  deleteModalFunc,
  addTeamUsersModalFunc,
  removeTeamUsersModalFunc,
  addTeamAuditorsModalFunc,
}) => {

  return (
    <>
      <td className="text-center">{index + 1}</td>

      <td>HSTM {team.id}</td>
      <td>{team.lead_name}</td>
      <td>{team.project_name}</td>
      <td className='text-center text-nowrap'>
        {team.coder_count > 0 ? (
          <OverlayTrigger placement='top' overlay={<Tooltip>Remove Coders</Tooltip>}>
            <button
              type='button'
              className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-primary"} font-15 shadow-none ml-2`}
              onClick={() => {
                removeTeamUsersModalFunc(team.id, "user");
              }}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement='top' overlay={<Tooltip>Remove Coders</Tooltip>}>
            <button
              type='button'
              className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-primary"} font-15 shadow-none ml-2 invisible`}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </OverlayTrigger>
        )}

        <Link
          to={`/all-users/${team.id}`}
          className={`btn-sm ${team.status === "inactive" ? "btn-secondary" : "btn-primary"} font-size13 text-decoration-none`}
        >
          {team.coder_count}
        </Link>
        <OverlayTrigger placement='top' overlay={<Tooltip>Add Users</Tooltip>}>
          <button
            type='button'
            className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-success"} font-15 shadow-none ml-2`}
            onClick={() => {
              addTeamUsersModalFunc(team.id);
            }}
          >
            <i className='fa fa-plus' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>

      <td className='text-center text-nowrap'>
        {team.auditor_count > 0 ? (
          <OverlayTrigger placement='top' overlay={<Tooltip>Remove Auditors</Tooltip>}>
            <button
              type='button'
              className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-primary"} font-15 shadow-none ml-2`}
              onClick={() => {
                removeTeamUsersModalFunc(team.id, "auditor");
              }}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement='top' overlay={<Tooltip>Remove Auditors</Tooltip>}>
            <button
              type='button'
              className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-primary"} font-15 shadow-none ml-2 invisible`}
            >
              <i className='fa fa-minus' aria-hidden='true' />
            </button>
          </OverlayTrigger>
        )}

        <Link
          to={`/auditor-users/${team.id}`}
          className={`btn-sm ${team.status === "inactive" ? "btn-secondary" : "btn-primary"} font-size13 text-decoration-none`}
        >
          {team.auditor_count}
        </Link>
        <OverlayTrigger placement='top' overlay={<Tooltip>Add Auditors</Tooltip>}>
          <button
            type='button'
            className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-success"}  font-15 shadow-none ml-2`}
            onClick={() => {
              addTeamAuditorsModalFunc(team.id);
            }}
          >
            <i className='fa fa-plus' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>

      <td className='text-center'>
        <span className={`badge ${team.status === "active" ? "bg-success" : "bg-danger"}`}>{ucFirst(team.status)}</span>
      </td>
      <td>{new Date(team.created_at).toDateString().slice(4)}</td>
      <td className='text-nowrap'>
        {/* <OverlayTrigger placement='top' overlay={<Tooltip>Edit team</Tooltip>}>
          <button type='button' className='btn btn-sm i-secondary shadow-none ml-2' onClick={(event) => handleEditClick(event, team)}>
            <i className='fa fa-edit' />
          </button>
        </OverlayTrigger> */}

        <OverlayTrigger placement='top' overlay={<Tooltip>Disable team</Tooltip>}>
          <button
            type='button'
            className={`btn btn-sm ${team.status === "inactive" ? "text-secondary" : "i-primary"}  font-15 shadow-none ml-2`}
            onClick={() => {
              deleteModalFunc(team.id);
            }}
          >
            <span data-toggle='tooltip' data-placement='top' title='Remove Employee'>
              {team.status === "inactive" ? <i className='fa fa-ban'></i> : <i className='fa fa-trash' />}
            </span>
          </button>
        </OverlayTrigger>
      </td>
    </>
  );
};

export default ReadOnlyRow;
