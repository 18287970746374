import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";
// import CardBody from "./CardBody";
// import CardHeader from "./CardHeader";
import { useAuth } from "../../context/AuthContext";
// import { Breadcrumb } from "react-bootstrap";
import { ucFirst } from "../../utils/UcFirst";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import LoadingIndicator from "../../shared/LoadingIndicator";

const UserCard = () => {
  const [users, setUsers] = useState([]);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isShowPaginate, setIsShowPaginate] = useState(false);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const auth = useAuth();

  const navigate = useNavigate();

  const params = useParams();
  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(
    ["usercard", offset, params?.teamId],
    () => fetchData(offset),
    {
      // keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const endPoint = params.teamId
    ? `user/team/${params.teamId}?role=user&page=${offset}`
    : `user/team?role=user&page=${offset}`;

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`${endPoint}`, {
        componentName: "usercard",
      });
      // let filteredUsers = response.data.filter((item) => {
      //   // console.log(item.user ? item.user.role : item.user);
      //   return item.user_role === "user";
      // });
      return response.data;
      // setUsers(response.data.data);
      // let showPaginate = response.data.total / response.data.per_page > 1;
      // setIsShowPaginate(showPaginate);
      // setPageCount(response.data.last_page);
      // setDataCount(response.data.total);
      // setDataFrom(response.data.from);
      // setDataEnd(response.data.to);

      // let filteredUsers = response.data.filter((user) => {
      //   if (user.team_user_chart) {
      //     return user.user.role === "user";
      //   } else {
      //     return user.role === "user";
      //   }
      // });

      // const modifiedUsers = filteredUsers.map((item, index) => {
      //   let tempObj = { ...item };
      //   // tempObj.value = item.id;
      //   // tempObj.label = item.name;
      //   if (item.team_user_chart) {
      //     tempObj.totalPagesCount = item.user.todayschart.reduce(function (prev, current) {
      //       return prev + +current.pages;
      //     }, 0);
      //     tempObj.totalIcdCount = item.user.todayschart.reduce(function (prev, current) {
      //       return prev + +current.icd;
      //     }, 0);
      //   } else {
      //     tempObj.totalPagesCount = item.todayschart.reduce(function (prev, current) {
      //       return prev + +current.pages;
      //     }, 0);
      //     tempObj.totalIcdCount = item.todayschart.reduce(function (prev, current) {
      //       return prev + +current.icd;
      //     }, 0);
      //     tempObj.team_user_chart = item.userchart;
      //     tempObj.user = item;
      //   }
      //   // console.log(tempObj);
      //   return tempObj;
      // });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // useEffect(() => {
  //   fetchData();
  // }, [offset]);
  useEffect(() => {
    if (data) {
      let showPaginate = data.total / data.per_page > 1;
      setUsers(data.data);
      setIsShowPaginate(showPaginate);
      setPageCount(data.last_page);
      setDataCount(data.total);
      setDataFrom(data.from);
      setDataEnd(data.to);
    }
  });

  let teamId = params.teamId || auth.user.active_team_id;
  // let teamId;
  // users.map((item, index) => {
  //   console.log(item.user.team_id);
  //   teamId = item.user.team_id;
  // });

  // console.log(users);

  // const breadcrumbs = ["home", "about", "users"];
  // Change TabTitle
  changeTabTitle("Users");

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <ul className="nav nav-pills  me-auto">
                  <li className="nav-item">
                    {params.teamId ? (
                      <NavLink
                        to={`/all-users/${params.teamId}`}
                        className="nav-link me-2 py-1"
                      >
                        Coders
                        <img
                          className="tri-img-content"
                          style={{ left: "26px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    ) : (
                      <NavLink to={`/all-users`} className="nav-link me-2 py-1">
                        Coders
                        <img
                          className="tri-img-content"
                          style={{ left: "26px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    )}
                  </li>
                  <li className="nav-item">
                    {params.teamId ? (
                      <NavLink
                        to={`/auditor-users/${params.teamId}`}
                        className="nav-link me-2 py-1"
                      >
                        Auditors
                        <img
                          className="tri-img-content"
                          style={{ left: "32px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/auditor-users`}
                        className="nav-link me-2 py-1"
                      >
                        Auditors
                        <img
                          className="tri-img-content"
                          style={{ left: "32px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    )}
                  </li>
                </ul>
                {auth && auth.user && auth.user.role === "lead" ? (
                  <button className="btn btn-danger btn-sm invisible ms-auto">
                    Go Back
                  </button>
                ) : (
                  <div className="text-end">
                    <button
                      className="btn btn-danger btn-sm ms-auto "
                      onClick={() => navigate(-1)}
                    >
                      Go Back
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div
              className={`card-header mb-3 pb-0 border-0 pt-3 d-flex justify-content-between align-items-center ${
                params.teamId ? null : "py-3"
              }`}
            >
              <h6 className="font-size13 ">
                <i className="fas fa-user me-2 "></i>
                Coders
                {dataCount ? (
                  <span className="badge bg-success ms-1  rounded-pill">
                    {dataCount}
                  </span>
                ) : null}
              </h6>
              {/*  <span className='mb-0 me-2 i-primary'>
                      <span className="white">Lead Name : </span> 
                       {users[0]?.lead_name}
                      </span> */}
              {/* {users[0].lead_name} */}
              <div>
                {params.teamId || auth.user.role === "lead" ? (
                  <Link
                    to={`/charts/${teamId}/today?viewBy=team`}
                    className="btn  export-bg-success text-light shadow-none ms-4 btn-sm  font-size13 "
                  >
                    Team Summary
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CardHeader title='Users' count={users.length} icon={<i className='fas fa-user me-2'></i>} showGoBack={true} /> */}
      {/* <CardBody> */}
      {/* {users.map((item) => (
          <div className='col-xl-4 col-lg-6 mb-4' key={item.id}>
            <div className='card view-card'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <p className='mb-0'>
                  <i className='fas fa-user me-2'></i> {ucFirst(item.user_name)}
                </p>
                {teamId ? (
                  <span
                    className={`badge ${
                      item.team_status === "active" ? "bg-success" : "bg-danger"
                    }`}
                  >
                    {ucFirst(item.team_status)}
                  </span>
                ) : (
                  <span
                    className={`badge ${
                      item.user_status === "active" ? "bg-success" : "bg-danger"
                    }`}
                  >
                    {ucFirst(item.user_status)}
                  </span>
                )}
              </div>
              <div className='card-body d-flex justify-content-center align-items-center'>
                <div className='col-md-12 p-2'>
                  <div className='row'> */}
      {/* <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Login Name */}
      {/* </p> */}
      {/* <p className='col-12'>
                      {item.login_name ? (
                        <span style={{ color: "#5ca360" }}>
                          {item.login_name}
                        </span>
                      ) : (
                        <span className="text-muted">NA</span>
                      )}
                    </p> */}
      {/* </div>
                  <div className='row'>
                    <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Charts
                    </p>
                    <p className="col-4">
                      {item.today_chart_count ? (
                        <span>{item.today_chart_count}</span>
                      ) : (
                        <span className="text-muted">NA</span>
                      )}
                    </p>
                  </div>
                  <div className="row">
                    <p
                      className="col-8 text-nowrap"
                      style={{ color: "#9190ff" }}
                    >
                      Pages
                    </p>
                    <p className="col-4 ">
                      {item.today_pages ? (
                        item.today_pages
                      ) : (
                        <span className="text-muted">NA</span>
                      )}
                    </p>
                  </div>
                  <div className="row ">
                    <p className="col-8" style={{ color: "#9190ff" }}>
                      ICD
                    </p>
                    <p className='col-4 '>{item.today_icd ? item.today_icd : <span className='text-muted'>NA</span>}</p> */}
      {/* <p className='col-6'>
                      <span className='text-muted'>NA</span>
                    </p> */}
      {/* </div>
                  <div className='row '>
                    <p className='col-8' style={{ color: "#9190ff" }}></p>
                    <p className='col-4 '>
                      {item.total_chart_count ? (
                        item.total_chart_count ? (
                          <Link
                            to={`/charts/${item.user_id}/today/${item.user_log_id}/?viewBy=user`}
                            className="btn btn-success btn-sm"
                          >
                            View
                          </Link>
                        ) : (
                          <Link
                            to={`/charts/${item.user_log_id}/today/${item.user_id}/?viewBy=user`}
                            className="btn btn-success btn-sm"
                          >
                            View
                          </Link>
                        )
                      ) : (
                        <button className="btn btn-secondary btn-sm" disabled>
                          View
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-between align-items-center'> */}
      {/* <p className='mb-0'>{ucFirst(item.name)}</p> */}
      {/* <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex'>
                    <p className='mb-0 me-2' style={{ color: "#9190ff" }}>
                      Lead
                    </p>
                    <p className="mb-0">
                      {item.lead_name ? (
                        ucFirst(item.lead_name)
                      ) : (
                        <span className="text-muted">NA</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="text-end">
                  <span style={{ color: "#9190ff" }}>Total Charts</span>
                  {item.total_chart_count ? (
                    <span className="badge bg-secondary ms-2">
                      {item.total_chart_count}
                    </span>
                  ) : (
                    <span className="text-muted ms-2">NA</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        {!users.length && <p className='text-center'>No data available at this moment</p>}
      </CardBody> */}
      <div className="table-responsive">
        <table className="table mb-0 ">
          <thead className="font-size13">
            <tr>
              <th scope="col" className="text-center">
                S.No
              </th>

              <th scope="col">Coder Name</th>
              <th scope="col">Login Name</th>
              <th scope="col">Lead Name</th>
              <th scope="col" className="text-center">
                Today Charts
              </th>
              <th scope="col" className="text-center">
                {" "}
                Today Pages
              </th>
              <th scope="col" className="text-center">
                Today ICD Count
              </th>
              <th scope="col">Total Charts</th>
              <th scope="col" className="text-center">
                View
              </th>
            </tr>
          </thead>
          <tbody className="font-size13 font-weight-light">
            {isFetching && <LoadingIndicator />}
            {users &&
              users.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-center">{dataFrom + index}</td>
                  <td> {ucFirst(item.user_name)}</td>
                  <td>{item.login_name}</td>
                  <td>
                    {item.lead_name ? (
                      ucFirst(item.lead_name)
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">{item.today_chart_count}</td>
                  <td className="text-center">
                    {item.today_pages ? (
                      item.today_pages
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.today_icd ? (
                      item.today_icd
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td>
                    {item.total_chart_count ? (
                      <span className="badge custom-btn-pink  ms-4">
                        {item.total_chart_count}
                      </span>
                    ) : (
                      <span className="text-muted ms-4">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.total_chart_count ? (
                      item.total_chart_count ? (
                        <Link
                          to={`/charts/${item.user_id}/today/${item.user_log_id}/?viewBy=user`}
                        >
                          <i class="fa fa-solid fa-eye i-info"></i>
                        </Link>
                      ) : (
                        <Link
                          to={`/charts/${item.user_log_id}/today/${item.user_id}/?viewBy=user`}
                        >
                          <i class="fa fa-solid fa-eye i-info"></i>
                        </Link>
                      )
                    ) : (
                      <button className="btn " disabled>
                        <i class="fa fa-solid fa-eye text-muted"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            {!users.length && (
              <tr className="text-center font-size13">
                <td colSpan={25}>
                  {isFetching
                    ? "Loading..."
                    : "No data available at this moment"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="td-bg-clr pt-4">
        {isShowPaginate && (
          <div className="d-flex justify-content-between align-items-center flex-row-reverse px-4 pb-4">
            <div>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            {dataFrom && dataEnd && (
              <span className="ms-2 showing font-size13 grey-clr">
                <i class="fas fa-book-open me-1"></i>
                Showing {dataFrom} - {dataEnd} of {dataCount} entries
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserCard;
