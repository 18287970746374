import React from "react";
import { useState } from "react";
import { ucFirst } from "../utils/UcFirst";

const IdleUsers = ({ idleUsers }) => {
  return (
    <div class="card">
      <div class="card-header border-0 pt-3 pb-0 d-flex justify-content-between align-items-center">
        <h6 class="mb-0 mt-2 font-size13">
          <i class="fas fa-user me-2"></i>Idle Users on {new Date(Date.now() - 86400000).toDateString()}
          {idleUsers.length ? <span className="badge count-badge-success ms-1 px-2 py-1">{idleUsers.length}</span> : null}
        </h6>
        {/* <button class='btn btn-sm btn-info shadow-none ms-2'>Export</button> */}
      </div>
      <div class="card-body px-0">
        <div class="table-responsive">
          <table class="table">
            <thead className="font-size13">
              <tr>
                <th scope="col" className="ps-4">
                  S.No
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
              </tr>
            </thead>
            <tbody className="font-size13">
              {idleUsers &&
                idleUsers.map((user, index) => (
                  <tr>
                    <td className="ps-4">{index + 1}</td>
                    <td>{ucFirst(user.name)}</td>
                    <td>{user.email}</td>
                    <td>{user.role === "user" ? "Coder" : ucFirst(user.role)}</td>
                  </tr>
                ))}

              {!idleUsers.length && (
                <tr class="text-center font-size13">
                  <td colspan="25">No data available at this moment</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IdleUsers;
