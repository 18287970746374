import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import apiClient from "../../services/ApiClient";
import { ucFirst } from "../../utils/UcFirst";
import { Line } from "react-chartjs-2";
import { useLayoutEffect } from "react";
import DatePicker from "react-datepicker";
import { OverlayTrigger, Popover } from "react-bootstrap";
import moment from "moment/moment";
import LoadingIndicator from "../../shared/LoadingIndicator";
import ChartDiagram from "../ChartDiagram";

const Profile = ({ isLoading }) => {
  const location = useLocation();
  const user_id = location.state.user_id;

  const [active, setActive] = useState();
  const [profile, setProfile] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectSummary, setProjectSummary] = useState({});
  const [timeLogs, setTimeLogs] = useState([]);
  const [chartQuality, setChartQuality] = useState(null);
  const [timeLine, setTimeLine] = useState("all");
  const [attendanceLogFilterType, setAttendanceLogFilterType] = useState("week");

  const [selectedDate, setSelectedDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);

  // Get the current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  // Set the maximum date to the current month and year
  const maxDate = new Date(currentYear, currentMonth);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const fetchData = async (e) => {
    try {
      setLoading(true);
      const [profileResponse, projectsResponse, attendanceResponse] = await Promise.all([
        apiClient.get(`/user/profile/${user_id}`, {
          // other request config...
          componentName: "userSummary",
        }),
        apiClient.get(`/user/projects/${user_id}`, {
          // other request config...
          componentName: "testSummary",
        }),

        apiClient.get(`/user/attendance/month/${user_id}`, {
          // other request config...
          componentName: "attendanceSummary",
        }),
      ]);

      // Destructure data from responses
      const { data: profileData } = profileResponse;
      const { data: projectsData } = projectsResponse;
      const { data: attendanceData } = attendanceResponse;
      // const { data: qualityData } = qualityResponse;
      setLoading(false);

      setProfile(profileData);
      setProjects(projectsData);
      setTimeLogs(attendanceData);
      // setChartQuality(qualityData);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Date wise Filter Function
  const filterHandler = async (e) => {
    const startedDate = startDate ? moment(startDate).format("DD-MM-YYYY") : null;
    const endedDate = endDate ? moment(endDate).format("DD-MM-YYYY") : null;

    let endPoint;

    if (startDate && endDate) {
      endPoint = `/user/attendance/${startedDate}_${endedDate}/${user_id}`;
    } else if (selectedDate) {
      endPoint = `/user/attendance/${moment(selectedDate).format("MM-YYYY")}/${user_id}`;
    } else {
      endPoint = `/user/attendance/${attendanceLogFilterType}/${user_id}`;
    }

    try {
      const response = await apiClient.get(`${endPoint}`, {
        // other request config...
        componentName: "attendanceSummary",
      });
      setTimeLogs(response.data);
    } catch (err) {}
  };

  const getProjectSummary = async (selectedProjectId) => {
    try {
      setProjectLoading(true);

      const response = await apiClient.get(`/user/user_log/details/${selectedProjectId}`, {
        // other request config...
        componentName: "projectSummary",
      });
      setProjectSummary(response.data);
      setProjectLoading(false);
    } catch (error) {
      console.log(error);
      setProjectLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.state.user_id]);

  useEffect(() => {
    if (attendanceLogFilterType === "monthPicker") {
      setDateRange([null, null]);
    } else if (attendanceLogFilterType === "datePicker") {
      setSelectedDate(null);
    }
  }, [attendanceLogFilterType]);

  useEffect(() => {
    if (selectedProject) {
      getProjectSummary(selectedProject.id);
    } else if (projects.length) {
      getProjectSummary(projects[0].id);
    }
  }, [selectedProject, projects]);

  useEffect(async () => {
    try {
      const response = await apiClient.get(`/chart-quality?timeline=${timeLine}&user_id=${user_id}`, {
        // other request config...
        componentName: "ChartQuality",
      });
      setChartQuality(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [timeLine, location.state.user_id]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            {/* <div className="card-header d-flex align-items-ceneter">
              <h6>
                User Profile
                <span className="badge ms-4 bg-success">Active</span>
              </h6>
            </div> */}
            <div className="card-body">
              <div className="container-fluid px-0">
                <div className="row gx-2 ">
                  <div className="col-lg-3 col-md-6 col-xs-12">
                    <div className="">
                      <div className="card border border-secondary px-0 font-size13">
                        <div className="card-header showing d-flex justify-content-between py-2">
                          <LoadingIndicator name="userSummary" />
                          <div>
                            <p className="mt-1 fs-6 mb-0">
                              <i class="fas fa-user me-2 "></i>
                              {profile.name ?? <span className="text-muted">NA</span>}
                            </p>
                          </div>
                          {/* <h6 className="mt-1 mb-0">{profile.role === "user" ? "Coder" : ucFirst(profile.role) ?? <span className="text-muted">NA</span>}</h6> */}
                          {/* <div>
                              <span className="mb-0 violet-blue">Emp ID : </span>
                              <span>{profile.employee_id ?? <span className="text-muted">NA</span>}</span>
                            </div>
                            <div>
                              <span className="mb-0 violet-blue">Role : </span>
                              <span>{profile.role === "user" ? "Coder" : ucFirst(profile.role) ?? <span className="text-muted">NA</span>}</span>
                            </div> */}
                        </div>
                        <div className="card-body px-0 pb-2">
                          <LoadingIndicator name="userSummary" />
                          <div className="d-flex justify-content-between border-bottom border-secondary px-3 pb-2 pt-0co">
                            <div>
                              <span className="mb-0 violet-blue">Emp ID : </span>
                              <span>{profile.employee_id ?? <span className="text-muted">NA</span>}</span>
                            </div>
                            <div>
                              <span className="mb-0 violet-blue">Role : </span>
                              <span>{profile.role === "user" ? "Coder" : ucFirst(profile.role) ?? <span className="text-muted">NA</span>}</span>
                            </div>
                          </div>
                          <div className="ps-3 pt-3">
                            <p className="mb-0  violet-blue">Email</p>
                            <p className="mb-2">{profile.email ?? <span className="text-muted">NA</span>}</p>
                            <p className="mb-0  violet-blue">Login Name</p>
                            <p className="mb-2">{profile.login_name ?? <span className="text-muted">NA</span>}</p>
                            <p className="mb-0 violet-blue">Login Mail</p>
                            <p className="mb-2">{profile.login_email ?? <span className="text-muted">NA</span>}</p>
                            <p className="mb-0 violet-blue">Lead Name</p>
                            <p className="mb-2">
                              {projects.length ? profile.active_lead_name ?? <span className="text-muted">NA</span> : <span className="text-muted">NA</span>}
                            </p>
                            <p className="mb-0 violet-blue">Project Name</p>
                            <p className="mb-0">
                              {projects.length ? projects[0]?.project_name ?? <span className="text-muted">NA</span> : <span className="text-muted">NA</span>}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {profile.role === "user" ? (
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <div className="">
                        <div className="card border border-secondary px-0">
                          <div className="card-header showing">Chart Details</div>
                          <div className="card-body font-size13 d-flex justify-content-center align-items-center">
                            <LoadingIndicator name="userSummary" />
                            <div className="my-2 mb-0 pb-0">
                              {/* <h3 className="violet-blue mb-2 pb-1">Total {profile.role === "auditor" ? "Audits" : "Charts"}</h3> */}
                              <h3 className="violet-blue mb-2 pb-1">Total Charts</h3>
                              <h3 className="text-center fw-bold fs-2 mb-0 ">{profile.total_charts_count ?? 0}</h3>
                            </div>
                          </div>
                          <div className="card-footer font-size13 p-0">
                            <div className="d-flex justify-content-between align-items-center p-0 m-0">
                              <div className="text-center w-50 border border-0 border-bottom border-end border-secondary">
                                <p className="violet-blue mb-1 mt-1 fs-6">Audited</p>
                                <p className="fw-bold fs-2 mb-2">{profile.coder_charts_audited ?? 0}</p>
                              </div>
                              <div className="text-center w-50 border border-0 border-bottom border-secondary">
                                <p className="violet-blue mb-1 mt-1 fs-6">Error</p>
                                <p className="fw-bold fs-2 mb-2">{profile.coder_error_charts ?? 0}</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="text-center w-50  border border-0 border-end border-secondary">
                                <p className="violet-blue mb-1 mt-1 fs-6">Today</p>
                                <p className="fw-bold fs-2 mb-2">{profile.today_charts_count ?? 0}</p>
                              </div>
                              <div className="text-center w-50">
                                <p className="violet-blue mb-1 fs-6 mt-1">Yesterday</p>
                                <p className="fw-bold fs-2 mb-2">{profile.yesterday_charts_count ?? 0}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <div className="">
                        <div className="card border border-secondary px-0">
                          <div className="card-header showing">Audit Count</div>
                          <div className="card-body font-size13 d-flex justify-content-center align-items-center">
                            <LoadingIndicator name="userSummary" />
                          </div>
                          <div className=" p-0 m-0">
                            <div className="text-center  border border-0 border-bottom  border-secondary">
                              {/* <p className="violet-blue mb-2 mt-1 fs-5">Audited</p>
                              <p className="fw-bold fs-2 mb-2">{profile.coder_charts_audited ?? 0}</p> */}
                              <p className="violet-blue mb-1 pt-2 mt-2 fs-4">Today</p>
                              <p className="fw-bold fs-2 mb-4">{profile.today_charts_count ?? 0}</p>
                            </div>
                          </div>
                          <div className="">
                            <div className="text-center ">
                              <p className="violet-blue mb-1 fs-4 pt-1 mt-4 ">Yesterday</p>
                              <p className="fw-bold fs-2 mb-4">{profile.yesterday_charts_count ?? 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {profile.role === "user" ? (
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <div className="">
                        <div className="card border border-secondary px-0">
                          <div className="card-header showing">Quality</div>
                          <div className="card-body font-size13 ">
                            <LoadingIndicator name="ChartQuality" />
                            <div className="pt-2 d-flex w-100 justify-content-end">
                              <select
                                class="form-select border-0 font-size13 me-2"
                                style={{ width: "120px" }}
                                aria-label=".form-select-sm example"
                                onChange={(e) => setTimeLine(e.target.value)}
                              >
                                <option selected value="all">
                                  All
                                </option>
                                <option value="weekly">This Week</option>
                                <option value="monthly">This Month</option>
                              </select>
                            </div>
                            <div className="my-5 d-flex flex-column justify-content-center align-items-center ">
                              <h3 className="violet-blue mt-3 mb-4 pt-1">Chart Quality</h3>
                              <p className="fw-bold fs-2 mb-2">
                                {chartQuality == 0 ? "NA" : chartQuality}
                                {chartQuality ? "%" : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-3 col-md-6 col-xs-12">
                      <div className="">
                        <div className="card border border-secondary px-0">
                          <div className="card-header showing">Audit Count</div>
                          <div className="card-body font-size13 ">
                            <LoadingIndicator name="ChartQuality" />
                            <div className="my-5 d-flex flex-column justify-content-center align-items-center pt-1">
                              {/* <h3 className="violet-blue mt-3 mb-4 pt-4">Total {profile.role === "auditor" ? "Audits" : "Charts"}</h3> */}
                              <h3 className="violet-blue mt-3 mb-4 pt-4">Total Audits</h3>
                              <p className="fw-bold fs-2 mb-4">{profile.total_charts_count ?? 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="col-lg-3 col-md-6 col-xs-12">
                    <div className="">
                      <div className="card border border-secondary px-0">
                        <div className="card-header showing">Chart Details</div>
                        <div className="card-body font-size13 d-flex justify-content-center align-items-center">
                          <LoadingIndicator name="userSummary" />
                          <div className="my-2 mb-0 pb-0">
                            <h3 className="violet-blue mb-2 pb-1">Total {profile.role === "auditor" ? "Audits" : "Charts"}</h3>
                            <h3 className="text-center fw-bold fs-2 mb-0 ">{profile.total_charts_count ?? 0}</h3>
                          </div>
                        </div>
                        <div className="card-footer font-size13 p-0">
                          <div className="d-flex justify-content-between align-items-center p-0 m-0">
                            <div className="text-center w-50 border border-0 border-bottom border-end border-secondary">
                              <p className="violet-blue mb-1 mt-1 fs-6">Audited</p>
                              <p className="fw-bold fs-2 mb-2">{profile.coder_charts_audited ?? 0}</p>
                            </div>
                            <div className="text-center w-50 border border-0 border-bottom border-secondary">
                              <p className="violet-blue mb-1 mt-1 fs-6">Error</p>
                              <p className="fw-bold fs-2 mb-2">{profile.coder_error_charts ?? 0}</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="text-center w-50  border border-0 border-end border-secondary">
                              <p className="violet-blue mb-1 mt-1 fs-6">Today</p>
                              <p className="fw-bold fs-2 mb-2">{profile.today_charts_count ?? 0}</p>
                            </div>
                            <div className="text-center w-50">
                              <p className="violet-blue mb-1 fs-6 mt-1">Yesterday</p>
                              <p className="fw-bold fs-2 mb-2">{profile.yesterday_charts_count ?? 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6 col-xs-12">
                    <div className="">
                      <div className="card border border-secondary px-0">
                        <div className="card-header showing">Quality</div>
                        <div className="card-body font-size13 ">
                          <LoadingIndicator name="ChartQuality" />
                          <div className="pt-2 d-flex w-100 justify-content-end">
                            <select
                              class="form-select form-select-sm w-25 border-secondary"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setTimeLine(e.target.value)}
                            >
                              <option selected value="all">
                                All
                              </option>
                              <option value="weekly">This Week</option>
                              <option value="monthly">This Month</option>
                            </select>
                          </div>
                          <div className="my-5 d-flex flex-column justify-content-center align-items-center ">
                            <h3 className="violet-blue mt-3 mb-4 pt-1">Chart Quality</h3>
                            <p className="fw-bold fs-2 mb-2">
                              {chartQuality ?? "NA"}
                              {chartQuality && "%"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-3 col-md-6 col-xs-12">
                    <div className="">
                      <div className="card border border-secondary px-0">
                        <div className="card-header showing">Project List</div>
                        <div className="card-body py-0 ps-1 font-size13 ">
                          {/* <LoadingIndicator name="projectSummary" /> */}
                          {projects.length ? (
                            <>
                              {projectSummary && (
                                // <div className="d-flex justify-content-between">
                                <div className="row px-2 ">
                                  {/* <div className="d-flex flex-column w-25 pt-3 px-2  border border-secondary proj-list"> */}
                                  <div className="col-4 border border-0 border-secondary proj-list pt-2">
                                    {projects.map((project, index) => (
                                      <div key={index}>
                                        <button
                                          className={`d-block text-nowrap w-100 m-auto ${
                                            selectedProject?.id == project?.id ? "bg-success" : index == 0 && !selectedProject ? "bg-success" : null
                                          }  text-center py-2 mt-1 mb-4 border border-secondary rounded-3  `}
                                          onClick={() => handleProjectClick(project)}
                                          // key={project.id}
                                        >
                                          {project.project_name}
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="col-8  pt-2 font-size13 ">
                                    <h6 className="mt-2 mb-4 violet-blue">{projectSummary.project_name}</h6>
                                    <p>
                                      <span className=" violet-blue">Lead Name :</span> {projectSummary.lead_name}
                                    </p>
                                    <p>
                                      <span className=" violet-blue">Total Charts :</span> {projectSummary.total_charts}
                                    </p>
                                    <p>
                                      <span className=" violet-blue">From :</span> {projectSummary.from}
                                    </p>
                                    {/* <p>
                                      <span className=" violet-blue">To :</span>{" "}
                                      {projectSummary.to ?? "Present"}
                                    </p> */}
                                    <p>
                                      <span className=" violet-blue">status :</span>
                                      <span className="badge ms-2 bg-success">Active</span>
                                      {projectSummary.status}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                          {projectLoading && !loading && (
                            <div className="justify-content-center d-flex align-items-center">
                              <LoadingIndicator />
                            </div>
                          )}
                          {/* {!projectLoading && !projects.length && (
                            <div className="justify-content-center d-flex align-items-center">
                              <p className="text-center font-size13 ">No data availble at this moment.</p>
                            </div>
                          )} */}
                          <div>
                            {loading && !projectLoading && (
                              <div className="justify-content-center d-flex align-items-center" style={{ height: "300px" }}>
                                <LoadingIndicator />
                              </div>
                            )}
                            {!loading && !projects.length && !projectLoading && (
                              <div className="justify-content-center d-flex align-items-center" style={{ height: "300px" }}>
                                <p className="text-center font-size13 ">No data availble at this moment.</p>
                              </div>
                            )}
                          </div>

                          {/* {!projects.length && "NA"} */}

                          {/* {!projects.length && <p className="text-center font-size13">No data availble at this moment.</p>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-xs-12">
                    <div className="">
                      <div className="card border border-secondary px-0"> */}
                  {/* <div className="card-header showing">Graph</div> */}
                  {/* <div className="card-body ">
                          <ChartDiagram userId={user_id} /> */}
                  {/* {profile ? (
                            <div style={{ height: "220px", margin: "auto" }} className="px-3">
                              <Line
                                data={chartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  plugins: {
                                    title: {
                                      display: true,
                                      position: "bottom",
                                    },
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            <div className=" d-flex justify-content-center align-items-center font-size13" style={{ height: "100%" }}>
                              <span>Loading...</span>
                            </div>
                          )} */}
                  {/* </div>
                      </div>
                    </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="container-fluid px-0 mt-3">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="card-body border border-secondary pt-0 px-0 rounded-3">
                      <ChartDiagram userID={user_id} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="card border border-secondary px-0 ">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h6 className="showing mb-0">Attendance</h6>
                        <div className=" d-flex align-items-center justify-content-end">
                          <select className="form-select border-0 font-size13 me-2" onChange={(e) => setAttendanceLogFilterType(e.target.value)}>
                            <option value="week" className="font-size13 ">
                              This Week
                            </option>
                            <option value="month">This Month</option>
                            <option value="datePicker">Date Picker</option>
                          </select>

                          {attendanceLogFilterType === "datePicker" && (
                            <DatePicker
                              className="form-control input-bg font-size13 width175px"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={Date.now()}
                              minDate={profile && new Date(profile.registered_at)}
                              onChange={(update) => {
                                setDateRange(update);
                              }}
                              placeholderText="Select Date Range"
                              style={{ padding: "4px 11px" }}
                            />
                          )}

                          {attendanceLogFilterType === "monthPicker" && (
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              dateFormat="MM/yyyy" // Format for displaying selected month and year
                              showMonthYearPicker
                              className="form-control input-bg ms-3 w-auto"
                              maxDate={maxDate}
                              placeholderText="Select Month"
                            />
                          )}

                          <button className="btn btn-sm btn-primary  font-size13" onClick={filterHandler}>
                            Filter
                          </button>
                        </div>
                      </div>
                      <div className="card-body px-0 pb-0 ">
                        <div className="table-responsive atten-log">
                          <table className="table">
                            <thead className="font-size13">
                              <tr>
                                <th className="ps-3">Date</th>
                                {/* <th>Effective Hours</th>
                                <th>Gross Hours</th> */}
                                <th>Active Hours</th>
                                <th>Time Log</th>
                              </tr>
                            </thead>
                            <tbody className="font-size13 font-weight-light">
                              <LoadingIndicator name="attendanceSummary" />

                              {timeLogs &&
                                timeLogs.map((timeLog) => (
                                  <tr key={timeLog.id}>
                                    <td>
                                      <p className="ms-2 my-2">{timeLog.date}</p>
                                    </td>
                                    {/* <td>{timeLog.totalHours}</td>*/}
                                    <td>{timeLog.totalHours}</td>
                                    <td>
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                          <Popover id="popover-basic">
                                            <Popover.Header as="h3" className="text-dark">
                                              Time Logs
                                            </Popover.Header>
                                            <Popover.Body>
                                              <div key={timeLog.id}>
                                                {timeLog.validInOutLogs.length ? (
                                                  timeLog.validInOutLogs.map((item, index) => (
                                                    <div key={index}>
                                                      <span>
                                                        <img src="/images/green-arrow.png" width="8px" alt="in" className="me-2" />
                                                        {item.start_time}
                                                      </span>
                                                      <span className="ms-3">
                                                        <img src="/images/red-arrow.png" width="8px" alt="out" className="me-2" />
                                                        {item.end_time}
                                                      </span>
                                                    </div>
                                                  ))
                                                ) : (
                                                  <span>No valid logs available.</span>
                                                )}
                                              </div>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <i className="far fa-id-card ms-3" role="button"></i>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))}
                              {!timeLogs.length && (
                                <tr>
                                  <td colSpan={10} className="text-center">
                                    No Time Logs at this moment.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
