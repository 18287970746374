import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

const ReadOnlyRow = ({
  client,
  index,
  handleEditClick,

  dataFrom,
}) => {
  return (
    <>
      {dataFrom ? (
        <td className="text-center">{dataFrom + index}</td>
      ) : (
        <td className="text-center">{index + 1}</td>
      )}
      <td>{client.client_name}</td>
      <td>{client.client_code}</td>
      <td className="text-center">
        {client.project_count ? (
          <Link
            to={`/all-projects/${client.id}`}
            className="btn-sm btn-primary font-size13 text-decoration-none"
          >
            {client.project_count}
          </Link>
        ) : (
          <span className="text-muted">NA</span>
        )}
      </td>

      <td>{new Date(client.created_at).toDateString().slice(4)}</td>
      <td className="text-nowrap">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Edit Client</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-sm i-secondary shadow-none ml-2"
            onClick={(event) => handleEditClick(event, client)}
          >
            <i className="fa fa-edit" aria-hidden="true" />
          </button>
        </OverlayTrigger>

        {/* <OverlayTrigger placement='top' overlay={<Tooltip>Edit Client</Tooltip>}>
          <button type='button' className='btn btn-sm i-secondary shadow-none ml-2' onClick={() => handleEdit(client)}>
            <i className='fa fa-edit' aria-hidden='true' />
          </button>
        </OverlayTrigger> */}

        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Delete Client</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-sm i-primary font-15 shadow-none ml-2"
            onClick={() => {
              deleteModalFunc(client.id);
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </OverlayTrigger> */}
      </td>
    </>
  );
};

export default ReadOnlyRow;
