import { Modal, Button } from "react-bootstrap";

const DeleteModal = (props) => {
  const { showDeleteModal, closeDeleteModal, handleDelete, title } = props;
  return (
    <Modal show={showDeleteModal} onHide={closeDeleteModal} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <h6 className='mb-0 font-size13'>Please Confirm!</h6>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center font-size13'>Are you sure want to disable {title}?</p>
        <div className='text-center'>
          <Button variant='success' className='me-2 font-size13' onClick={handleDelete}>
            Yes
          </Button>
          <Button variant='danger' className="font-size13" onClick={closeDeleteModal}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
