import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Line } from "react-chartjs-2";

// import { Chart } from "chart.js/auto";
import apiClient from "../services/ApiClient";
import { useLocation } from "react-router";
import LoadingIndicator from "../shared/LoadingIndicator";

const ChartDiagram = ({ userId }) => {
  const location = useLocation();
  const user_id = location?.state?.user_id;
  const [dataChart, setDataChart] = useState({});
  const [dataChartData, setDataChartData] = useState([]);
  const [viewBy, setviewBy] = useState("today");
  const [loading, setLoding] = useState(false);
  const fetchData = async () => {
    try {
      setLoding(true);
      let endPoint = user_id ? `dashboard/chart/count?view_by=${viewBy}&user_id=${user_id}` : `dashboard/chart/count?view_by=${viewBy}`;
      console.log(endPoint);
      const response = await apiClient.get(endPoint, {
        // other request config...
        componentName: "Chart",
      });
      setLoding(false);

      setDataChart(response.data);
      setDataChartData(response.data.datasets);
    } catch (err) {
      console.log(err);
      // setDataChart([]);
      setLoding(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [viewBy, location?.state?.user_id]);

  const chartData = {
    labels: dataChart.labels,
    datasets: dataChartData,
    // datasets: [
    //   {
    //     label: "today",
    //     data: ["455", "345", "323", "35", "42", "34", "123", "345", "454", "345", "343", "345"],
    //     borderColor: "rgb(53, 162, 235)",
    //     backgroundColor: "rgba(53, 162, 235, 0.5)",
    //   },
    //   {
    //     label: "yesterday",
    //     data: ["45", "35", "23", "5", "2", "34", "23", "45", "44", "5", "3", "35"],
    //     borderColor: "rgb(53, 232, 25)",
    //     backgroundColor: "rgba(53, 232, 25, o.5)",
    //   },
    // ],
  };
  return (
    <>
      <div className="col-12 mb-3">
        <div className="card" style={{ height: "400px" }}>
          <div className="card-header d-flex justify-content-between align-items-center py-3">
            <h6 className={`mb-0 font-size13 ${user_id ? "showing" : ""}`}>Chart Statistics</h6>
            <select name="" id="" className="font-size13" onChange={(e) => setviewBy(e.target.value)}>
              <option value="today">Today</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div className="card-body  ">
            <LoadingIndicator name="Chart" />
            {dataChartData.length ? (
              <div style={{ height: "300px", margin: "auto" }} className="p-3">
                <Line
                  data={chartData}
                  // options={{
                  //   responsive: true,
                  //   maintainAspectRatio: false,
                  // }}

                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      title: {
                        display: true,
                        text: dataChart.xlabel,
                        position: "bottom",
                      },
                    },
                    //   scales: {
                    //     yAxes: {
                    //       grid: {
                    //         drawBorder: true,
                    //         color: "#9f9f9f",
                    //         display: false,
                    //       },
                    //       ticks: {
                    //         beginAtZero: true,
                    //         color: "#9f9f9f",
                    //         fontSize: 12,
                    //       },
                    //     },
                    //     xAxes: {
                    //       grid: {
                    //         drawBorder: true,
                    //         color: "#9f9f9f",
                    //         display: false,
                    //       },
                    //       ticks: {
                    //         beginAtZero: true,
                    //         color: "#9f9f9f",
                    //         fontSize: 12,
                    //       },
                    //     },
                    //   },
                  }}
                />
              </div>
            ) : null}
            {loading && (
              <div className=" d-flex justify-content-center align-items-center font-size13" style={{ height: "100%" }}>
                <span>Loading...</span>
              </div>
            )}
            {!loading && !dataChartData.length && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                <p className=" font-size13">No data availble at this moment.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartDiagram;
