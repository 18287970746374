import { Modal } from "react-bootstrap";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import apiClient from "../services/ApiClient";
// import DisableChartModal from "../utils/Modals/DisableChartModal";
import { ucFirst } from "../utils/UcFirst";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";

function SearchByLead() {
  const [showAuditsTable, setShowAuditsTable] = useState(false);
  const [showChartsTable, setShowChartsTable] = useState(false);
  const [showUserLoginTable, setUserLoginTable] = useState(false);
  const [audits, setAudits] = useState([]);
  const [charts, setCharts] = useState([]);
  const [loginData, setLoginData] = useState();
  const [value, setValue] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [userTypeError, setUserTypeError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [projectId, setProjectId] = useState("");
  // let isClientRProject = false;

  // if (projectId) {
  //   isClientRProject = projectId == process.env.REACT_APP_R_PROJECT_ID;
  // }

  // const [disableChartId, setDisableChartId] = useState(null);
  const [showDisableChartModal, setShowDisableChartModal] = useState(false);

  // Delete  Function Starts
  const closeDisableChartModal = () => {
    setShowDisableChartModal(false);
  };

  const disableChartModalFunc = (chart_id, team_id) => {
    setShowDisableChartModal(true);
    // setDisableId(id);
    formik.setFieldValue("chart_id", chart_id);
    formik.setFieldValue("team_id", team_id);
  };

  const formik = useFormik({
    initialValues: {
      comments: "",
      chart_id: "",
      team_id: "",
    },
    validationSchema: yup.object({
      comments: yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      try {
        if (searchBy === "coder") {
          const response = await apiClient.post("user-chart/disable", values);
          setCharts(response.data.chart.data);
        }
        if (searchBy === "auditor") {
          const response = await apiClient.post("audit/disable", values);
          setAudits(response.data.chart.data);
        }

        // fetchData();
        formik.resetForm();
        setShowDisableChartModal(false);
        // window.location.reload();
      } catch (err) {
        formik.setErrors({
          comments: err.response.data.error.comments,
        });
      }
    },
  });

  // console.log(formik.values);

  // const handleDisable = async () => {
  //   try {
  //     let id = disableId;
  //     const response = await apiClient.delete(`client/delete/${id}`);
  //     // fetchData();
  //     setShowDisableChartModal(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const auth = useAuth();

  //   useEffect(() => {
  //     if (searchBy === "coder") {
  //       setShowChartsTable(true);
  //       setShowAuditsTable(false);
  //     } else if (searchBy === "auditor") {
  //       setShowAuditsTable(true);
  //       setShowChartsTable(false);
  //     }
  //   }, [searchBy]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAudits([]);
    setCharts([]);

    if (searchBy && value) {
      setValueError(false);
      setUserTypeError(false);

      let endPoint;
      if (searchBy === "coder") {
        endPoint = `user-chart?search_term=chart_id&value=${value}&team_id=${auth.user.active_team_id}`;
      }
      if (searchBy === "auditor") {
        endPoint = `audit?search_term=chart_id&value=${value}&team_id=${auth.user.active_team_id}`;
      }
      if (searchBy === "login_email") {
        endPoint = `user-login/search/${value}`;
      }

      const { data } = await apiClient.get(`${endPoint}`);

      if (searchBy === "coder") {
        setProjectId(data.data[0].chart_project_id);
        setCharts(data.data);
        // console.log(data.project_id);
        setShowChartsTable(true);
        setShowAuditsTable(false);
        setUserLoginTable(false);
      }
      if (searchBy === "auditor") {
        setProjectId(data.data[0].chart_project_id);
        setAudits(data.data);
        setShowChartsTable(false);
        setShowAuditsTable(true);
        setUserLoginTable(false);
      }
      if (searchBy === "login_email") {
        setLoginData(data);
        setShowChartsTable(false);
        setShowAuditsTable(false);
        setUserLoginTable(true);
      }
    } else if (!value && !searchBy) {
      setValueError(true);
      setUserTypeError(true);
    } else if (!value) {
      setValueError(true);
      setUserTypeError(false);
    } else if (!searchBy) {
      setUserTypeError(true);
      setValueError(false);
    }
  };

  // useEffect(() => {
  //   console.log(projectId);
  // }, [projectId]);

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center justify-content-start">
              <div className="col-lg-4 me-2">
                <select
                  onChange={(e) => setSearchBy(e.target.value)}
                  value={searchBy}
                  className="form-control form-select font-size13"
                >
                  <option value="" disabled>
                    Search By
                  </option>
                  <option value="coder">Coder chart</option>
                  <option value="auditor">Auditor chart</option>
                  <option value="login_email">Login email</option>
                </select>
                {userTypeError ? (
                  <div className="invalid-feedback d-block">
                    The field is required
                  </div>
                ) : (
                  <div className="invisible">The field is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control font-size13"
                  placeholder="Enter value"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
                {valueError ? (
                  <div className="invalid-feedback d-block">
                    The field is required
                  </div>
                ) : (
                  <div className="invisible">The field is required</div>
                )}
              </div>
              <div>
                <button className="btn btn-sm btn-primary ms-2 shadow-none font-size13">
                  Search
                </button>
                <div className="invisible">The field is required</div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showChartsTable && projectId == process.env.REACT_APP_R_PROJECT_ID ? (
        <>
          <div className="card mb-3">
            <div className="card-body px-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className="font-size13">
                    <tr>
                      <th scope="col">Chase ID</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">D.O.B</th>
                      <th scope="col" className="text-center">
                        Total HCC Category Reviewed
                      </th>
                      <th scope="col" className="text-center">
                        Total Pages
                      </th>
                      <th scope="col">Date</th>
                      <th scope="col">Project Name</th>
                      <th scope="col">Sub Project</th>
                      <th scope="col">Login Name</th>
                      <th scope="col">Chart status</th>
                      <th scope="col">Audit Status</th>

                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font-size13">
                    {charts &&
                      charts.map((chart, index) => (
                        <>
                          <tr
                            key={chart.id}
                            className="font-size13 font-weight-light"
                          >
                            <td>{chart.chart_id}</td>
                            <td>{ucFirst(chart.member_name)}</td>
                            <td>
                              {new Date(chart.dob).toLocaleDateString("en-US")}
                            </td>
                            <td className="text-center">{chart.icd}</td>
                            <td className="text-center">{chart.pages}</td>
                            <td>
                              {new Date(chart.coding_at)
                                .toDateString()
                                .slice(4)}
                            </td>
                            <td>{chart.project_name}</td>
                            <td>{chart.sub_project}</td>
                            <td>{chart.coder_login_name}</td>
                            <td>
                              {chart.status === "inactive" ? (
                                <span className="badge bg-danger">
                                  Disabled
                                </span>
                              ) : (
                                <span className="badge bg-success">Active</span>
                              )}
                            </td>
                            <td>
                              {chart.is_chart_audited ? (
                                <span className="badge bg-danger">Audited</span>
                              ) : (
                                <span className="badge bg-success">
                                  Not Audited
                                </span>
                              )}
                            </td>

                            <td>
                              {new Date(chart.coding_at)
                                .toDateString()
                                .slice(4)}
                            </td>
                            <td>
                              {new Date(chart.coding_at)
                                .toTimeString()
                                .split(" ")
                                .shift()}
                            </td>
                            {!chart.is_chart_audited &&
                            chart.status === "active" ? (
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Disable Chart</Tooltip>}
                                >
                                  <button
                                    type="button"
                                    className={`btn btn-sm text-danger font-15 shadow-none ml-2`}
                                    onClick={() => {
                                      disableChartModalFunc(
                                        chart.id,
                                        chart.team_id
                                      );
                                    }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Remove Employee"
                                    >
                                      <i className="fa fa-trash" />
                                    </span>
                                  </button>
                                </OverlayTrigger>
                              </td>
                            ) : (
                              <td>
                                {/* {chart.is_chart_audited && <span className='badge bg-success'>Audited</span>}
                              {chart.status === "inactive" && <span className='badge bg-danger'>Disabled</span>} */}
                              </td>
                            )}
                          </tr>
                        </>
                      ))}

                    {!charts.length && (
                      <tr className="text-center font-size13">
                        <td colSpan={18}>No data available at this moment</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        showChartsTable && (
          <>
            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="font-size13">
                      <tr>
                        <th scope="col" className="text-center">
                          S.No
                        </th>
                        <th scope="col" className="text-nowrap">
                          Team ID
                        </th>
                        <th className="text-nowrap">Lead Name</th>
                        <th scope="col" className="text-nowrap">
                          Project Name
                        </th>
                        <th scope="col" className="text-nowrap">
                          Coder Name
                        </th>
                        <th scope="col">Chart ID</th>
                        <th scope="col" className="text-nowrap">
                          Member Name
                        </th>
                        <th scope="col">D.O.B</th>
                        <th scope="col">Total DOS</th>
                        <th scope="col">Total ICD</th>
                        <th scope="col">Total Pages</th>
                        <th scope="col" className="text-center">
                          Coding Status
                        </th>
                        {/* <th scope="col">Comments</th> */}
                        <th scope="col">Chart status</th>
                        <th scope="col">Audit Status</th>
                        <th scope="col" className="text-nowrap">
                          Coding Date
                        </th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {charts &&
                        charts.map((chart, index) => (
                          <>
                            <tr
                              key={chart.id}
                              className="font-size13 font-weight-light"
                            >
                              <td className="text-center">{index + 1}</td>
                              <td>HSTM {chart.team_id}</td>
                              <td>{chart.lead_name}</td>
                              <td>{chart.project_name}</td>
                              <td>{ucFirst(chart.coder_name)}</td>
                              <td
                                className={`${
                                  chart.action === "rejected" && "text-danger"
                                }`}
                              >
                                {chart.chart_id}
                              </td>
                              <td>{ucFirst(chart.member_name)}</td>
                              {/* <td>{new Date(chart.dob).toLocaleDateString("en-us")}</td> */}
                              <td>
                                {new Date(chart.dob).toLocaleDateString()}
                              </td>
                              <td>{chart.dos}</td>
                              <td>{chart.icd}</td>
                              <td>{chart.pages}</td>
                              <td className={`text-center`}>
                                <span className={`badge ${chart.action}`}>
                                  {chart.action === "code_completed"
                                    ? "Completed"
                                    : ucFirst(chart.action)
                                        .split("_")
                                        .join(" ")}
                                  {/* {ucFirst(chart.action).split("_").join(" ")} */}
                                </span>
                              </td>
                              {/* <td>
                            {chart.comments && chart.comments.length > 10
                              ? ucFirst(
                                  chart.comments.slice(0, 10).concat("...")
                                )
                              : chart.comments &&
                                chart.comments !== null &&
                                ucFirst(chart.comments)}

                            {!chart.comments && (
                              <span className="text-muted">NA</span>
                            )}
                          </td> */}
                              <td>
                                {chart.status === "inactive" ? (
                                  <span className="badge bg-danger">
                                    Disabled
                                  </span>
                                ) : (
                                  <span className="badge bg-success">
                                    Active
                                  </span>
                                )}
                              </td>
                              <td>
                                {chart.is_chart_audited ? (
                                  <span className="badge bg-danger">
                                    Audited
                                  </span>
                                ) : (
                                  <span className="badge bg-success">
                                    Not Audited
                                  </span>
                                )}
                              </td>
                              <td>
                                {new Date(chart.coding_date)
                                  .toDateString()
                                  .slice(4)}
                              </td>
                              <td>
                                {new Date(chart.coding_at)
                                  .toDateString()
                                  .slice(4)}
                              </td>
                              <td>
                                {new Date(chart.coding_at)
                                  .toTimeString()
                                  .split(" ")
                                  .shift()}
                              </td>
                              {!chart.is_chart_audited &&
                              chart.status === "active" ? (
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Disable Chart</Tooltip>}
                                  >
                                    <button
                                      type="button"
                                      className={`btn btn-sm text-danger font-15 shadow-none ml-2`}
                                      onClick={() => {
                                        disableChartModalFunc(
                                          chart.id,
                                          chart.team_id
                                        );
                                      }}
                                    >
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Remove Employee"
                                      >
                                        <i className="fa fa-trash" />
                                      </span>
                                    </button>
                                  </OverlayTrigger>
                                </td>
                              ) : (
                                <td>
                                  {/* {chart.is_chart_audited && <span className='badge bg-success'>Audited</span>}
                              {chart.status === "inactive" && <span className='badge bg-danger'>Disabled</span>} */}
                                </td>
                              )}
                            </tr>
                          </>
                        ))}

                      {!charts.length && (
                        <tr className="text-center font-size13">
                          <td colSpan={18}>No data available at this moment</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <DisableChartModal
            showDisableChartModal={showDisableChartModal}
            closeDisableChartModal={closeDisableChartModal}
            handleDisable={handleDisable}
            title='Chart'
          /> */}
            </div>
          </>
        )
      )}

      <Modal
        show={showDisableChartModal}
        onHide={closeDisableChartModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h6 className="mb-0">Are you sure want to disable Chart?</h6>
        </Modal.Header>
        <Modal.Body>
          {/* <p className='text-center'>Are you sure want to disable Chart?</p> */}
          <div className="text-center">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {/* <div class='row align-items-center justify-content-start px-lg-2'> */}
              <div className="col-lg-12 px-1">
                <label
                  htmlFor="clientName"
                  className="form-label text-start required d-block"
                >
                  Comments
                </label>
                <textarea
                  rows={3}
                  className={`form-control ${
                    formik.touched.comments &&
                    formik.errors.comments &&
                    "is-invalid"
                  }`}
                  id="comments"
                  name="comments"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comments}
                ></textarea>
                {formik.touched.comments && formik.errors.comments && (
                  <div id="comments" className="invalid-feedback text-start">
                    {formik.errors.comments}
                  </div>
                )}
              </div>

              <div class="col-lg-12 w-auto px-1 mt-4 pt-2 ms-2 mb-2">
                <button
                  type="submit"
                  className="btn custom-btn-primary py-1 px-4"
                >
                  Confirm
                </button>
              </div>
              {/* </div> */}
            </form>
            {/* <Button variant='success' className='me-2' onClick={handleDisable}>
                  Yes
                </Button> */}
            {/* <Button variant='danger' onClick={closeDisableChartModal}>
                  Cancel
                </Button> */}
          </div>
        </Modal.Body>
      </Modal>
      {showAuditsTable && projectId == process.env.REACT_APP_R_PROJECT_ID ? (
        <>
          <div className="card mb-3">
            <div className="card-body">
              <div className="table-responsive" style={{ overflowX: "auto" }}>
                <table className="table text-nowrap">
                  <thead>
                    <th></th>
                    <th scope="col">status</th>
                    <th scope="col">Team ID</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Sub Project</th>
                    <th scope="col">Chase ID </th>
                    <th scope="col" className="text-nowrap">
                      Coder Name
                    </th>
                    <th scope="col" className="text-wrap">
                      Coder Login Name
                    </th>
                    <th scope="col" className="text-wrap text-center">
                      Coding Complete Date
                    </th>
                    <th scope="col" className="text-wrap text-center">
                      Audit Complete Date
                    </th>
                    <th scope="col">Auditor Name</th>
                    <th scope="col">Auditor Login Name</th>
                    <th scope="col" className="text-wrap text-center">
                      Total HCC Category Reviewed
                    </th>

                    <th scope="col" className="text-wrap">
                      Total Pages
                    </th>
                    <th scope="col" className="text-wrap">
                      Total Errors
                    </th>
                    {/* <th scope="col" className="text-wrap">
                      Auditor Comment
                    </th> */}

                    <th scope="col" className="text-wrap">
                      QAScore
                    </th>

                    {/* <th scope="col" className="text-nowrap">
                      Coding Date
                    </th> */}

                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                  </thead>
                  <tbody>
                    {audits &&
                      audits.map((audit, index) => (
                        <tr
                          key={audit.id}
                          className="font-size13 font-weight-light"
                        >
                          {audit.status == "active" ? (
                            <>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Disable Chart</Tooltip>}
                                >
                                  <button
                                    type="button"
                                    className={`btn btn-sm text-danger font-15 shadow-none ml-2`}
                                    onClick={() => {
                                      disableChartModalFunc(
                                        audit.id,
                                        audit.team_id
                                      );
                                    }}
                                  >
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Remove Employee"
                                    >
                                      <i className="fa fa-trash" />
                                    </span>
                                  </button>
                                </OverlayTrigger>
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                            </>
                          )}
                          <td>
                            {audit.status === "inactive" ? (
                              <span className="badge bg-danger">Disabled</span>
                            ) : (
                              <span className="badge bg-success">Active</span>
                            )}
                          </td>
                          <td>HSTM {audit.team_id}</td>

                          <td>{audit.project_name}</td>
                          <td>{audit.sub_project}</td>
                          <td>{audit.chart_id}</td>
                          <td>{ucFirst(audit.coder_name)}</td>
                          <td>{ucFirst(audit.coder_login_name)}</td>
                          <td className="text-center">
                            {new Date(
                              audit.coding_complete_date
                            ).toLocaleDateString()}
                          </td>
                          <td className="text-center">
                            {new Date(
                              audit.audit_complete_date
                            ).toLocaleDateString()}
                          </td>

                          <td>{ucFirst(audit.auditor_name)}</td>

                          <td>{ucFirst(audit.auditor_login_name)}</td>

                          <td className="text-center">
                            {audit.original_code_found}
                          </td>
                          <td>{audit.total_pages}</td>
                          <td>{audit?.total_errors}</td>
                          {/* <td className="text-wrap">
                            {audit.error_count == null && audit.audit_comment}

                            {audit.error_count > 0 && (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Read More</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm i-success ms-1 shadow-none"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    readMoreModalFunc(audit.error_feedbacks);
                                  }}
                                >
                                  Errors
                                </button>
                              </OverlayTrigger>
                            )}
                          </td> */}
                          <td>{audit.icd_qa_score}</td>

                          {/* <td>
                            {new Date(audit.coding_date)
                              .toDateString()
                              .slice(4)}
                          </td> */}

                          <td>
                            {new Date(audit.coding_at).toDateString().slice(4)}
                          </td>
                          <td>
                            {new Date(audit.coding_at)
                              .toTimeString()
                              .split(" ")
                              .shift()}
                          </td>
                        </tr>
                      ))}

                    {!audits.length && (
                      <tr className="text-center">
                        <td colSpan={15}>No data available at this moment</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        showAuditsTable && (
          <>
            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive" style={{ overflowX: "auto" }}>
                  <table className="table text-nowrap">
                    <thead>
                      <tr>
                        {/* <th scope='col'></th>
                    <th scope='col'>Log</th> */}
                        <th scope="col"></th>
                        {/* <th scope="col">S.No</th> */}
                        <th scope="col">status</th>
                        <th scope="col">Team ID</th>
                        <th>Lead Name</th>
                        <th scope="col">Project Name</th>
                        <th scope="col">Chart ID</th>
                        <th scope="col">Coder Name</th>
                        <th scope="col" className="text-wrap">
                          Coding Complete Date
                        </th>
                        <th scope="col" className="text-wrap">
                          Audit Complete Date
                        </th>
                        <th scope="col">Auditor Name</th>
                        <th scope="col" className="text-wrap">
                          Total Pages
                        </th>
                        {/* <th scope="col">Auditor Comment</th> */}
                        <th scope="col">Audit Type</th>

                        <th scope="col" className="text-wrap">
                          Chart Level QAScore
                        </th>
                        <th scope="col" className="text-wrap">
                          DX Level QAScore
                        </th>
                        <th scope="col" className="text-wrap">
                          Original Codes Found
                        </th>
                        <th scope="col" className="text-wrap">
                          Total Absolute Errors
                        </th>
                        <th scope="col" className="text-wrap">
                          No of DX codes Error
                        </th>
                        <th scope="col" className="text-wrap">
                          No of Admin errors
                        </th>
                        <th scope="col" className="text-wrap">
                          Codes Added
                        </th>
                        <th scope="col" className="text-wrap">
                          Codes Deleted
                        </th>
                        <th scope="col" className="text-wrap">
                          DX Codes Updated
                        </th>
                        <th scope="col" className="text-wrap">
                          DOS Corrected
                        </th>
                        <th scope="col" className="text-wrap">
                          POS Corrected
                        </th>
                        <th scope="col" className="text-wrap">
                          DX Level Comment Code Corrected
                        </th>
                        <th scope="col" className="text-wrap">
                          Rendering Provider Corrected
                        </th>
                        <th scope="col" className="text-wrap">
                          Coding Date
                        </th>

                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {audits &&
                        audits.map((audit, index) => (
                          <tr
                            key={audit.id}
                            className="font-size13 font-weight-light"
                          >
                            {audit.status == "active" ? (
                              <>
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Disable Chart</Tooltip>}
                                  >
                                    <button
                                      type="button"
                                      className={`btn btn-sm text-danger font-15 shadow-none ml-2`}
                                      onClick={() => {
                                        disableChartModalFunc(
                                          audit.id,
                                          audit.team_id
                                        );
                                      }}
                                    >
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Remove Employee"
                                      >
                                        <i className="fa fa-trash" />
                                      </span>
                                    </button>
                                  </OverlayTrigger>
                                </td>
                              </>
                            ) : (
                              <>
                                <td></td>
                              </>
                            )}

                            {/* <td>{index + 1}</td> */}
                            <td>
                              {audit.status === "inactive" ? (
                                <span className="badge bg-danger">
                                  Disabled
                                </span>
                              ) : (
                                <span className="badge bg-success">Active</span>
                              )}
                            </td>
                            <td>HSTM {audit.team_id}</td>
                            <td>{audit.lead_name}</td>
                            <td>{audit.project_name}</td>
                            <td>{audit.chart_id}</td>
                            <td>{ucFirst(audit.coder_name)}</td>
                            <td>
                              {new Date(
                                audit.coding_complete_date
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(
                                audit.audit_complete_date
                              ).toLocaleDateString()}
                            </td>
                            <td>{ucFirst(audit.auditor_name)}</td>
                            <td>{audit.total_pages}</td>
                            {/* <td className="text-wrap">
                          {audit.audit_comment.length > 10
                            ? ucFirst(
                                audit.audit_comment.slice(0, 10).concat("...")
                              )
                            : ucFirst(audit.audit_comment)}
                        </td>
                        <td>
                          {ucFirst(audit.audit_type.split("_").join(" "))}
                        </td> */}
                            <td></td>
                            <td>{audit.chart_qa_score}</td>
                            <td>{audit.dx_qa_score}</td>
                            <td>{audit.original_code_found}</td>
                            <td>{audit.total_errors}</td>
                            <td>{audit.dx_codes_error}</td>
                            <td>{audit.admin_error}</td>
                            <td>{audit.codes_added}</td>
                            <td>{audit.codes_deleted}</td>
                            <td>{audit.dx_codes_updated}</td>
                            <td>{audit.dos_corrected}</td>
                            <td>{audit.pos_corrected}</td>
                            <td>{audit.dx_level_comment_code_corrected}</td>
                            <td>{audit.rendering_provider_corrected}</td>
                            <td>
                              {new Date(audit.coding_date)
                                .toDateString()
                                .slice(4)}
                            </td>
                            {auth && auth.user.role !== "auditor" && (
                              <>
                                <td>
                                  {new Date(audit.coding_at)
                                    .toDateString()
                                    .slice(4)}
                                </td>
                                <td>
                                  {new Date(audit.coding_at)
                                    .toTimeString()
                                    .split(" ")
                                    .shift()}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      {!audits.length && (
                        <tr className="text-center">
                          <td colSpan={15}>No data available at this moment</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )
      )}

      {showUserLoginTable && (
        <div className="card mb-3">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead className="font-size13">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col" className="text-nowrap">
                      Login name
                    </th>
                    <th className="text-nowrap">Login email</th>
                    <th scope="col" className="text-nowrap">
                      Status
                    </th>
                    <th scope="col" className="text-nowrap">
                      User Name
                    </th>
                    <th scope="col" className="text-nowrap">
                      User email
                    </th>
                    <th scope="col" className="text-nowrap">
                      Lead name
                    </th>
                  </tr>
                </thead>
                <tbody className="font-size13">
                  <tr
                    key={loginData.id}
                    className="font-size13 font-weight-light"
                  >
                    <td>{1}</td>
                    <td>{loginData.login_name}</td>
                    <td>{loginData.login_email}</td>
                    <td>{ucFirst(loginData.status)}</td>
                    <td>{loginData.user_name}</td>
                    <td>{loginData.user_email}</td>
                    <td>{loginData.team_lead}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchByLead;
