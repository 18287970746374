import { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

const DashboardContext = createContext(null);

export const DasboardProvider = ({ children }) => {
  const [showClient, setShowClient] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [showUser, setShowUser] = useState(false);

  return (
    <DashboardContext.Provider
      value={{ showClient, setShowClient, showProject, setShowProject, showTeam, setShowTeam, showUser, setShowUser }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  return useContext(DashboardContext);
};
