import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const MobileSidebar = ({ showMobSidebar }) => {
  const auth = useAuth();
  return (
    <nav className={`mob-sidebar ${showMobSidebar ? "" : "toggled"}`}>
      <ul className="list-unstyled">
        {auth && auth.user && auth.user.role === "super_admin" && (
          <>
            <li>
              <NavLink to="/">
                <i className="fas fa-th-large me-2"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-clients">
                <i className="fas fa-clinic-medical me-2"></i>
                <span>Clients</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-projects">
                <i className="fas fa-project-diagram me-2"></i>
                <span>Projects</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-teams">
                <i className="fas fa-users me-2"></i>
                <span>Teams</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-users" end>
                <i className="fas fa-user me-2"></i>
                <span>Users</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/chart'>
                <i className='fas fa-file-alt'></i>
                <span>Chart</span>
              </NavLink>
            </li> */}
          </>
        )}

        {/* <li>
          <NavLink to='/managers'>
            <i className='fas fa-users'></i>
            <span>Managers</span>
          </NavLink>
        </li> */}
        {auth && auth.user && auth.user.role === "manager" && (
          <>
            {/* <li>
                <NavLink to='/'>
                  <i className='fas fa-th-large'></i>
                  <span>Dashboard</span>
                </NavLink>
              </li> */}
            <li>
              <NavLink to="/add-teams">
                <i className="fas fa-users me-2"></i>
                <span>Teams</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-users">
                <i className="fas fa-user me-2"></i>
                <span>Users</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/chart'>
                <i className='fas fa-file-alt'></i>
                <span>Chart</span>
              </NavLink>
            </li> */}
          </>
        )}
        {auth && auth.user && auth.user.role === "lead" && (
          <>
            <li>
              <NavLink to="/all-users">
                <i className="fas fa-user me-2"></i>
                <span>Users</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-loginName">
                <i className="fas fa-upload"></i>
                <span>Add Name</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/search">
                <i className="fas fa-search"></i>
                <span>Search</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/chart'>
                <i className='fas fa-file-alt'></i>
                <span>Chart</span>
              </NavLink>
            </li> */}
          </>
        )}
        {auth && auth.user && auth.user.role === "user" && (
          <>
            <li>
              <NavLink to="/chart">
                <i className="fas fa-file-alt me-2"></i>
                <span>Chart</span>
              </NavLink>
            </li>
          </>
        )}

        {auth && auth.user && auth.user.role === "auditor" && (
          <>
            <li>
              <NavLink to="/audits">
                <i className="fas fa-file-alt me-2"></i>
                <span>Audit</span>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              auth && auth.logout();
            }}
          >
            <i className="fas fa-power-off me-2"></i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileSidebar;
