import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Sso from "./components/Sso";
import WithNav from "./components/protectedNav/WithNav";
import WithoutNav from "./components/protectedNav/WithoutNav";
import { useAuth } from "./context/AuthContext";
import NotFound from "./errors/NotFound";
import Login from "./pages/auth/Login";
import Clients from "./pages/Clients";
import Dashboard from "./pages/Dashboard";
// import Managers from "./pages/Managers";
import Projects from "./pages/Projects";
import Teams from "./pages/Teams";
import UserChart from "./pages/UserChart";
import AuditorCharts from "./pages/AuditorCharts";
import Users from "./pages/Users";
import apiClient from "./services/ApiClient";
import RequireAuth from "./utils/RequireAuth";
import Charts from "./pages/Charts";
import AddBulkUsers from "./components/users/bulkUser/AddBulkUsers";
import TeamsUsers from "./pages/TeamsUsers";
import ClientCard from "./components/dashboard/ClientCard";
import ProjectCard from "./components/dashboard/ProjectCard";
import TeamCard from "./components/dashboard/TeamCard";
import UserCard from "./components/dashboard/UserCard";
import AuditCard from "./components/dashboard/AuditCard";
import Auditor from "./pages/Auditor";
import AddLoginName from "./pages/AddLoginName";
import AutoLogout from "./utils/AutoLogout";
import SearchByLead from "./pages/SearchByLead";
import UserNotFound from "./errors/UserNotFound";
import AccessDenied from "./errors/AccessDenied";
import AddUsersbyLead from "./pages/AddUsersbyLead";
// import ErrorFeedback from "./components/ErrorFeedback";
import UserDashboard from "./pages/UserDashboard";
import AuditsTable from "./components/audits/AuditsTable";
import AddAudits from "./components/audits/AddAudits";
import Profile from "./components/users/Profile";
// import ErrorBoundary from "./errors/ErrorBoundary";

function App() {
  const auth = useAuth();

  // const breadcrumbs = ["add-clients"];
  // add headers for token
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("auth-token") || auth.token}`;

  // Condition based Routes
  let conditionalRoutes;
  if (auth.user && auth.token && auth.user.role === "super_admin") {
    conditionalRoutes = <Navigate to="/" />;
  } else if (auth.user && auth.token && auth.user.role === "manager") {
    conditionalRoutes = <Navigate to="/add-teams" />;
  } else if (auth.user && auth.token && auth.user.role === "lead") {
    conditionalRoutes = <Navigate to="/all-users" />;
  } else if (auth.user && auth.token && auth.user.role === "user") {
    conditionalRoutes = <Navigate to="/chart" />;
  } else if (auth.user && auth.token && auth.user.role === "auditor") {
    conditionalRoutes = <Navigate to="/audits" />;
  } else {
    conditionalRoutes = <Login />;
  }

  return (
    <>
      <AutoLogout>
        <Routes>
          {/* <ol className='breadcrumb'>
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={breadcrumb.key}>
              <NavLink to={breadcrumb.props.match.url}>{breadcrumb}</NavLink>
            </li>
          ))}
        </ol> */}
          <Route element={<WithoutNav />}>
            {/* <Route path='/' element={conditionalRoutes} /> */}
            <Route path="/login" element={conditionalRoutes} />
            <Route path="/sso/microsoft" element={<Sso />} />
            <Route path="/sso/zoho" element={<Sso />} />
            <Route path="/userNotFound" element={<UserNotFound />} />
            <Route path="/accessDenied" element={<AccessDenied />} />
          </Route>
          <Route element={<WithNav />}>
            <Route
              path="/"
              element={
                auth && auth.user && auth.user.role === "super_admin" ? (
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Route>
          {/* <Route path='/' element={<WithNav />}>
          {auth && auth.user && auth.user.role === "super_admin" ? (
            <>
              <Route
                path='/'
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
            </>
          ) : (
            <Route path='/login' element={<Login />} />
          )}
        </Route> */}

          <Route element={<WithNav />}>
            {auth && auth.user && auth.user.role === "super_admin" && (
              <>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-clients"
                  element={
                    <RequireAuth>
                      <ClientCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-projects/"
                  element={
                    <RequireAuth>
                      <ProjectCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-projects/:clientId"
                  element={
                    <RequireAuth>
                      <ProjectCard />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/all-teams/:projectId"
                  element={
                    <RequireAuth>
                      <TeamCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-teams"
                  element={
                    <RequireAuth>
                      <TeamCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-users/:teamId"
                  element={
                    <RequireAuth>
                      <UserCard />
                    </RequireAuth>
                  }
                />
                {/* <Route
                path='/summary/:teamId/:timeLine'
                element={
                  <RequireAuth>
                    <Summary />
                  </RequireAuth>
                }
              /> */}
                <Route
                  path="/all-users"
                  element={
                    <RequireAuth>
                      <UserCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-users/"
                  element={
                    <RequireAuth>
                      <AuditCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-users/:teamId"
                  element={
                    <RequireAuth>
                      <AuditCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-clients"
                  element={
                    <RequireAuth>
                      <Clients />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-projects"
                  element={
                    <RequireAuth>
                      <Projects />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/add-teams"
                  element={
                    <RequireAuth>
                      <Teams />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/teams-users/:teamId"
                  element={
                    <RequireAuth>
                      <TeamsUsers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-users"
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-bulkusers/"
                  element={
                    <RequireAuth>
                      <AddBulkUsers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/:name/profile"
                  element={
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  }
                />
              </>
            )}
            {/* <Route
            path='/managers'
            element={
              <RequireAuth>
                <Managers />
              </RequireAuth>
            }
          /> */}
            {auth && auth.user && auth.user.role === "manager" && (
              <>
                {/* <Route
                path='/'
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              /> */}
                <Route
                  path="/add-teams"
                  element={
                    <RequireAuth>
                      <Teams />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-users/:teamId"
                  element={
                    <RequireAuth>
                      <UserCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-users/:teamId"
                  element={
                    <RequireAuth>
                      <AuditCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-users"
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-bulkusers/"
                  element={
                    <RequireAuth>
                      <AddBulkUsers />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/:name/profile"
                  element={
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  }
                />
              </>
            )}
            {auth && auth.user && auth.user.role === "lead" && (
              <>
                <Route
                  path="/user/dashboard"
                  element={
                    <RequireAuth>
                      <UserDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/:name/profile"
                  element={
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-loginName"
                  element={
                    <RequireAuth>
                      <AddLoginName />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-users"
                  element={
                    <RequireAuth>
                      <AddUsersbyLead />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-users"
                  element={
                    <RequireAuth>
                      <UserCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/all-users/:teamId"
                  element={
                    <RequireAuth>
                      <UserCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-users/:teamId"
                  element={
                    <RequireAuth>
                      <AuditCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-users/"
                  element={
                    <RequireAuth>
                      <AuditCard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <Charts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine/:userLogId"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/auditor-charts/:id/:timeLine"
                  element={
                    <RequireAuth>
                      <AuditorCharts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <RequireAuth>
                      <SearchByLead />
                    </RequireAuth>
                  }
                />
              </>
            )}
            {auth && auth.user && auth.user.role === "user" && (
              <>
                <Route
                  path="/user/dashboard"
                  element={
                    <RequireAuth>
                      <UserDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/chart"
                  element={
                    <RequireAuth>
                      <UserChart />
                    </RequireAuth>
                  }
                />
              </>
            )}

            {auth && auth.user && auth.user.role === "auditor" && (
              <>
                <Route
                  path="/user/dashboard"
                  element={
                    <RequireAuth>
                      <UserDashboard />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/audits"
                  element={
                    <RequireAuth>
                      <Auditor />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/add-audits"
                  element={
                    <RequireAuth>
                      <AddAudits />
                    </RequireAuth>
                  }
                />
              </>
            )}
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AutoLogout>
      <ToastContainer />
    </>
  );
}

export default App;
