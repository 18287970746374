import { useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("auth-token");
  if (!user && !token) {
    navigate("/login", { replace: true });
  } else {
    return children;
  }
};

export default RequireAuth;
