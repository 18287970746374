import React from "react";
import { NavLink, useParams, useNavigate, useSearchParams } from "react-router-dom";
import CardBody from "./CardBody";

const SummaryNavLinks = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const viewType = searchParams.get("viewBy");
  return (
    <>
      <header className='mb-3'>
        <CardBody>
          <ul className='nav nav-pills ms-2'>
            <li className='nav-item'>
              {params.userLogId ? (
                <NavLink
                  to={`/charts/${params.id}/all/${params.userLogId}?viewBy=${searchParams.get("viewBy")}`}
                  className='nav-link px-3 py-1'
                >All
                <img className="tri-img-content" style={{left:"16px"}}  src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              ) : (
                <NavLink to={`/charts/${params.id}/all?viewBy=${searchParams.get("viewBy")}`} className='nav-link px-3 py-1'>
                  All
                  <img  className="tri-img-content" style={{left:"16px"}}  src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              )}
            </li>
            <li className='nav-item'>
              {params.userLogId ? (
                <NavLink
                  to={`/charts/${params.id}/monthly/${params.userLogId}?viewBy=${searchParams.get("viewBy")}`}
                  className='nav-link px-3 py-1'
                >
                  Monthly
                <img className="tri-img-content" style={{left:"30px"}} src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              ) : (
                <NavLink to={`/charts/${params.id}/monthly?viewBy=${searchParams.get("viewBy")}`} className='nav-link px-3 py-1'>
                  Monthly
                <img className="tri-img-content" style={{left:"30px"}} src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              )}
            </li>
            <li className='nav-item'>
              {params.userLogId ? (
                <NavLink
                  to={`/charts/${params.id}/weekly/${params.userLogId}?viewBy=${searchParams.get("viewBy")}`}
                  className='nav-link px-3 py-1'
                >
                  Weekly
                <img className="tri-img-content" src="/images/blue-tri.png" style={{left:"27px"}}  width="15px" alt="triangle-img"/>
                </NavLink>
              ) : (
                <NavLink to={`/charts/${params.id}/weekly?viewBy=${searchParams.get("viewBy")}`} className='nav-link px-3 py-1'>
                  Weekly
                <img className="tri-img-content" src="/images/blue-tri.png" style={{left:"27px"}}  width="15px" alt="triangle-img"/>
                </NavLink>
              )}
            </li>
            <li className='nav-item'>
              {params.userLogId ? (
                <NavLink
                  to={`/charts/${params.id}/today/${params.userLogId}?viewBy=${searchParams.get("viewBy")}`}
                  className='nav-link px-3 py-1'
                >
                  Today
                <img className="tri-img-content" style={{left:"25px"}} src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              ) : (
                <NavLink to={`/charts/${params.id}/today?viewBy=${searchParams.get("viewBy")}`} className='nav-link px-3 py-1'>
                  Today
                <img className="tri-img-content" style={{left:"25px"}} src="/images/blue-tri.png" width="15px" alt="triangle-img"/>
                </NavLink>
              )}
            </li>
            <li className='nav-item ms-auto'>
              {params.userLogId ? (
                <NavLink
                  to={`/charts/${params.id}/filter/${params.userLogId}?viewBy=${searchParams.get("viewBy")}`}
                  className='btn filter-btn-bg text-light font-size13 px-3 py-1'
                >
                  Filters
                </NavLink>
              ) : (
                <NavLink to={`/charts/${params.id}/filter?viewBy=${searchParams.get("viewBy")}`} className='btn filter-btn-bg px-3 text-light font-size13 py-1'>
                  Filters
                </NavLink>
              )}
            </li>

            {/* <button className='btn btn-danger btn-sm ms-3 me-2' onClick={() => navigate(-1)}>
              Go Back
            </button> */}
          </ul>
        </CardBody>
      </header>
    </>
  );
};

export default SummaryNavLinks;
