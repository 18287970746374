import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { changeTabTitle } from "../utils/changeTabTitle";

import AuditsTable from "../components/audits/AuditsTable";
import apiClient from "../services/ApiClient";

const Auditor = () => {
  const [audits, setAudits] = useState([]);
  const [coderInfo, setCoderInfo] = useState({});

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [isShowPaginate, setIsShowPaginate] = useState(false);
  const [projectId, setprojectId] = useState("");

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // console.log(projectId);

  const auth = useAuth();
  const fetchData = async () => {
    try {
      const response = await apiClient.get(
        `audit?timeline=today&user_log_id=${auth.user.active_log_id}&page=${offset}`
      );
      setAudits(response.data.data);
      setPageCount(response.data.meta.last_page);
      setprojectId(response.data.project_id);
      let showPaginate =
        response.data.meta.total / response.data.meta.per_page > 1;
      setIsShowPaginate(showPaginate);
      setDataCount(response.data.meta.total);
      setDataFrom(response.data.meta.from);
      setDataEnd(response.data.meta.to);
      // setCoderInfo({
      //   coderName: response.data.data[0].user.name,
      // });
      // console.log(coderInfo);
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(projectId);
  // console.log(audits);

  useEffect(() => {
    fetchData();
  }, [offset]);

  // Change TabTitle
  changeTabTitle("Audits Chart");

  return (
    <div className="row">
      <div className="col-md-12">
        {auth.user.active_team_id ? (
          auth.user.active_login_id ? (
            <AuditsTable
              audits={audits}
              setAudits={setAudits}
              fetchData={fetchData}
              coderInfo={coderInfo}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              dataCount={dataCount}
              setPageCount={setPageCount}
              setDataCount={setDataCount}
              dataFrom={dataFrom}
              dataEnd={dataEnd}
              isShowPaginate={isShowPaginate}
              setIsShowPaginate={setIsShowPaginate}
              setDataEnd={setDataEnd}
              setDataFrom={setDataFrom}
              offset={offset}
              projectId={projectId}
              setprojectId={setprojectId}
            />
          ) : (
            <div className="card">
              <div className="card-body">
                <p className="mb-0 text-center">
                  Login name not assigned. Please contact your team lead.
                </p>
              </div>
            </div>
          )
        ) : (
          <div className="card">
            <div className="card-body">
              <p className="mb-0 text-center">
                You are not assigned to any team
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auditor;
