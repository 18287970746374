import { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../services/ApiClient";
import { useAuth } from "../context/AuthContext";
import { changeTabTitle } from "../utils/changeTabTitle";
import UserChartTableForClientR from "../components/userChartForClientR/UserChartTableForClientR";
import UserChartTable from "../components/userChart/UserChartTable";
import { useQuery } from "react-query";

const UserChart = () => {
  // const [charts, setCharts] = useState([]);

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [isShowPaginate, setIsShowPaginate] = useState(false);
  const [projectId, setprojectId] = useState("");

  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(["userchart", offset], () => fetchData(offset), {
    // keepPreviousData: true,
    staleTime: 60000,
  });

  const auth = useAuth();

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // let logDetails = auth.log.filter((log) => {
  //   return log.status === "active";
  // });

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`user-chart?timeline=today&user_log_id=${auth.user.active_log_id}&page=${offset}`, {
        componentName: "userchart",
      });
      return response.data;
      // setCharts(response.data.data);
      // setPageCount(response.data.meta.last_page);
      // let showPaginate = response.data.meta.total / response.data.meta.per_page > 1;
      // setIsShowPaginate(showPaginate);
      // setDataCount(response.data.meta.total);
      // setprojectId(response.data.project_id);
      // setDataFrom(response.data.meta.from);
      // setDataEnd(response.data.meta.to);
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect for changing tab title
  useEffect(() => {
    changeTabTitle("Users Chart");
  }, []);

  useEffect(() => {
    // fetchData();
    if (data) {
      // setCharts(data.data);
      setPageCount(data.meta.last_page);
      // let showPaginate = data.meta.total / data.meta.per_page > 1;
      // setIsShowPaginate(showPaginate);
      setDataCount(data.meta.total);
      // setprojectId(data.project_id);
      setDataFrom(data.meta.from);
      setDataEnd(data.meta.to);
    }
  }, [data]);

  // Change TabTitle
  // changeTabTitle("Users Chart");

  return (
    <div className="row">
      {/* <div className='col-md-3'>
        <AddUserChart setCharts={setCharts} charts={charts} fetchData={fetchData} />
      </div> */}

      <div className="col-md-12">
        {auth.user.active_team_id ? (
          auth.user.active_login_id ? (
            auth.user.project_id == process.env.REACT_APP_R_PROJECT_ID ? (
              <UserChartTableForClientR
                charts={data?.data || []}
                refetch={refetch}
                isFetching={isFetching}
                // setCharts={setCharts}
                // fetchData={fetchData}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                dataCount={dataCount}
                setPageCount={setPageCount}
                setDataCount={setDataCount}
                dataFrom={dataFrom}
                dataEnd={dataEnd}
                isShowPaginate={isShowPaginate}
                setIsShowPaginate={setIsShowPaginate}
                setDataEnd={setDataEnd}
                setDataFrom={setDataFrom}
                offset={offset}
                projectId={projectId}
              />
            ) : (
              <UserChartTable
                // charts={charts}
                charts={data?.data || []}
                refetch={refetch}
                isFetching={isFetching}
                // setCharts={setCharts}
                // fetchData={fetchData}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                dataCount={dataCount}
                setPageCount={setPageCount}
                setDataCount={setDataCount}
                dataFrom={dataFrom}
                dataEnd={dataEnd}
                isShowPaginate={isShowPaginate}
                setIsShowPaginate={setIsShowPaginate}
                setDataEnd={setDataEnd}
                setDataFrom={setDataFrom}
                offset={offset}
                projectId={projectId}
              />
            )
          ) : (
            <div className="card">
              <div className="card-body">
                <p className="mb-0 text-center">Login name not assigned. Please contact your team lead.</p>
              </div>
            </div>
          )
        ) : (
          <div className="card">
            <div className="card-body">
              <p className="mb-0 text-center">You are not assigned to any team</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserChart;
