import { useState } from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../../errors/ErrorBoundary";
import MobileSidebar from "../../shared/MobileSidebar";

import Sidebar from "../../shared/Sidebar";
import TopNavbar from "../../shared/TopNavbar";

const WithNav = () => {
  const [showMobSidebar, setShowMobSidebar] = useState(false);

  const showMobileSidebar = () => {
    setShowMobSidebar(!showMobSidebar);
  };

  return (
    <>
      <Sidebar />
      <MobileSidebar showMobSidebar={showMobSidebar} />

      <main>
        <TopNavbar showMobileSidebar={showMobileSidebar} />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </>
  );
};

export default WithNav;
