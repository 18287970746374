import React from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAuth } from "../../context/AuthContext";
import { ucFirst } from "../../utils/UcFirst";

const ReadOnlyRow = ({
  user,
  index,
  handleEditClick,
  deleteModalFunc,
  page,
  dataFrom,
}) => {
  const auth = useAuth();
  const access =
    (auth && auth.user && auth.user.role === "super_admin") ||
    auth.user.role === "manager";

  return (
    <>
      {dataFrom ? (
        <td className="text-center">{dataFrom + index}</td>
      ) : (
        <td className="text-center">{index + 1}</td>
      )}

      <td>{user.employee_id}</td>
      <td>{ucFirst(user.name)}</td>
      <td>{user && ucFirst(user.login_name)}</td>
      <td>{user.email}</td>

      <td>
        {user.role.charAt(0).toUpperCase() +
          user.role.slice(1).split("_").join(" ")}
      </td>
      {user.team_id && <td>HSTM{user.team_id} </td>}
      {!user.team_id && (
        <td>
          <span className="text-muted">NA</span>
        </td>
      )}
      <td>
        {user.client_name ? (
          user.client_name
        ) : (
          <span className="text-muted">NA</span>
        )}
        {/* <span>NA</span> */}
      </td>

      <td>
        {user.project_name ? (
          user.project_name
        ) : (
          <span className="text-muted">NA</span>
        )}
        {/* <span>NA</span> */}
      </td>

      {/* {page === "dashboard" ? null : ( */}
      <td className="text-center">
        {user.role === "user" && user.user_chart_count ? (
          <Link to={`/charts/${user.id}/all?viewBy=user`} className="i-link">
            {user.user_chart_count}
          </Link>
        ) : null}
        {user.role === "auditor" && user.user_chart_count ? (
          <Link
            to={`/auditor-charts/${user.id}/all?viewBy=user`}
            className="i-link"
          >
            {user.user_chart_count}
          </Link>
        ) : null}
        {!user.user_chart_count && !user.user_chart_count && (
          <span className="text-muted">NA</span>
        )}
      </td>
      <td className="text-center">
        {user.role === "user" ? (
          <Link
            to={`/${user.name.trim()}/profile`}
            state={{ user_id: user.id }}
            className="i-link"
          >
            <i className="fa fa-user" aria-hidden="true" />
          </Link>
        ) : null}
        {user.role === "auditor" ? (
          <Link
            to={`/${user.name.trim()}/profile`}
            state={{ user_id: user.id }}
            className="i-link"
          >
            <i className="fa fa-user" aria-hidden="true" />
          </Link>
        ) : null}
      </td>

      {page === "dashboard" ? null : access ? (
        <td className="text-nowrap">
          {user.role !== "super_admin" && (
            <>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit User</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm i-secondary shadow-none ml-2"
                  onClick={(event) => handleEditClick(event, user)}
                >
                  <i className="fa fa-edit" aria-hidden="true" />
                </button>
              </OverlayTrigger>
              {/* <OverlayTrigger placement='top' overlay={<Tooltip>Delete User</Tooltip>}>
                    <button
                      type='button'
                      className='btn btn-sm i-primary font-15 shadow-none ml-2'
                      onClick={() => {
                        deleteModalFunc(user.id);
                      }}
                    >
                      <i className='fa fa-trash' aria-hidden='true' />
                    </button>
                  </OverlayTrigger>  */}
            </>
          )}
        </td>
      ) : (
        <td></td>
      )}
    </>
  );
};

export default ReadOnlyRow;
