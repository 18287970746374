import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import apiClient from "../services/ApiClient";
import { ucFirst } from "../utils/UcFirst";

const AddLoginName = () => {
  const [users, setUsers] = useState([]);
  const [loginNames, setLoginNames] = useState([]);
  const [selectLoginName, setSelectLoginName] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const [fileError, setFileError] = useState("");
  const [excelError, setExcelError] = useState("");

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`user/team`);
      const loginNameResponse = await apiClient.get(`user-login`);
      setLoginNames(loginNameResponse.data.data);
      setUsers(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fileSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("import_login_details", fileValue);
    try {
      const response = await apiClient.post("user-login/import", formData);
      fetchData();
      document.getElementById("import_login_details").value = "";
      setExcelError(null);
    } catch (err) {
      const errors = err.response.data.error[0];
      const file_error = err.response.data.error.import_user && err.response.data.error.import_user[0];
      if (file_error) {
        setFileError(file_error);
      }
      const excel_errors = "Row " + errors.row + " : " + errors.values[1] + " " + errors.errors[0];
      // console.log(excel_errors);
      if (excel_errors) {
        setExcelError(excel_errors);
      }
    }
  };

  const selectLoginSubmitHandler = async (logId) => {
    try {
      if (selectLoginName) {
        const response = await apiClient.post(`user-login/assign/${logId}`, {
          login_id: selectLoginName,
        });
        fetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disableLoginNameHandler = async (logId) => {
    try {
      // if (selectLoginName) {
      const response = await apiClient.post(`user-login/disable/${logId}`, {
        login_id: selectLoginName,
      });
      fetchData();
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      login_name: "",
      login_email: "",
    },
    validationSchema: yup.object({
      login_name: yup.string().required("This field is required"),
      login_email: yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await apiClient.post("user-login/store", values);

        fetchData();
        formik.resetForm();
      } catch (err) {
        formik.setErrors({
          login_name: err.response.data.error.login_name,
          login_email: err.response.data.error.login_email,
        });
      }
    },
  });
  return (
    <div className='row'>
      <div className='col-lg-6 mb-3'>
        <div className='card'>
          <div className='card-header py-3'>
            <h6 className='mb-0 font-size13'>Login Name File Upload</h6>
          </div>
          <div className='card-body'>
            <form onSubmit={fileSubmit}>
              <div className='d-flex align-items-center'>
                <div>
                  <div className='form-group mb-3'>
                    <label htmlFor='name' className='form-label font-size13'>
                      Import Login Name File
                    </label>
                    <input
                      type='file'
                      name='import_login_details'
                      accept='.xls,.xlsx'
                      className='form-control  font-size13'
                      id='import_login_details'
                      onChange={(e) => setFileValue(e.currentTarget.files[0])}
                    />
                  </div>
                  <p className=" font-size13">
                    <Link to='/files/Login_deatils.xlsx' target='_blank' download className='me-1'>
                      Click Here
                    </Link>
                    to download Sample Template
                  </p>
                </div>

                <div className='text-end mb-4 ms-3'>
                  <button type='submit' className='btn custom-btn-primary px-4 mt-2 font-size13'>
                    Upload
                  </button>
                </div>
              </div>
              {excelError && <span className='alert invalid-feedback d-block p-0 m-0'>{excelError}</span>}
              {fileError && <span className='alert invalid-feedback d-block p-0 m-0'>{fileError}</span>}
            </form>
          </div>
        </div>
      </div>

      <div className='col-lg-6 mb-3'>
        <div className='card'>
          <div className='card-header py-3'>
            <h6 className='mb-0 font-size13'>Add Login Name</h6>
          </div>
          <div className='card-body pb-4'>
            <form onSubmit={formik.handleSubmit}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex justify-content-around align-items-center'>
                  <div className='form-group me-2'>
                    <label htmlFor='name' className='form-label font-size13'>
                      Login Name
                    </label>
                    <input
                      type='text'
                      name='login_name'
                      className={`form-control font-size13 ${formik.touched.login_name && formik.errors.login_name && "is-invalid"}`}
                      id='clientName'
                      // placeholder='Enter Login Name'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.login_name}
                    />
                    {formik.touched.login_name && formik.errors.login_name ? (
                      <>
                        <div id='clientName' className='invalid-feedback'>
                          {formik.errors.login_name}
                        </div>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='form-group'>
                    <label htmlFor='name' className='form-label font-size13'>
                      Login Email
                    </label>
                    <input
                      type='text'
                      name='login_email'
                      className={`form-control font-size13 ${formik.touched.login_email && formik.errors.login_email && "is-invalid"}`}
                      id='clientCode'
                      // placeholder='Enter Login Email'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.login_email}
                    />
                    {formik.touched.login_email && formik.errors.login_email ? (
                      <>
                        <div id='clientName' className='invalid-feedback'>
                          {formik.errors.login_email}
                        </div>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='ms-3'>
                    <button type='submit' className='btn custom-btn-primary btn-sm px-4 mt-2 font-size13'>
                      Add
                    </button>
                    <div className='invisible'>
                      <span>invisible</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <div className='col-md-12'>
        <div className='card'>
          <div className='card-header py-3'>
            <h6 className='mb-0'>Users</h6>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>S.No</th>
                    <th scope='col'>Name</th>
                    <th scope='col'>Email</th>
                    <th scope='col'>Role</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Login Name</th>
                    <th scope='col' className='fit'></th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.user.name}</td>

                      <td>{item.user.email}</td>
                      <td>{ucFirst(item.user.role)}</td>
                      <td>
                        <span className={`badge ${item.team_status === "active" ? "bg-success" : "bg-danger"}`}>
                          {ucFirst(item.team_status)}
                        </span>
                      </td>
                      <td>
                        {item.login_name
                          ? item.login_name
                          : item.team_status === "active" && (
                              <select
                                onChange={(e) => {
                                  setSelectLoginName(e.target.value);
                                }}
                                className='form-control form-select'
                                // value={selectLoginName}
                              >
                                <option value=''>Please Select Login name</option>
                                {loginNames.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.login_name} - {item.login_email}
                                  </option>
                                ))}
                              </select>
                            )}
                      </td>

                      <td>
                        {!item.login_name
                          ? item.team_status === "active" && (
                              <OverlayTrigger placement='top' overlay={<Tooltip>Save Changes</Tooltip>}>
                                <button
                                  className='btn btn-sm i-success shadow-none ml-2'
                                  onClick={() => {
                                    selectLoginSubmitHandler(item.user_log_id);
                                  }}
                                >
                                  <i className='fas fa-arrow-alt-circle-right' aria-hidden='true'></i>
                                </button>
                              </OverlayTrigger>
                            )
                          : null}
                      </td>

                      <td>
                        {!item.login_name ? (
                          item.team_status === "active" && null
                        ) : (
                          <OverlayTrigger placement='top' overlay={<Tooltip>Reset Login Name</Tooltip>}>
                            <button
                              className='btn btn-sm i-success shadow-none ml-2'
                              onClick={() => {
                                disableLoginNameHandler(item.user.user_login.id);
                              }}
                            >
                              <i class='fas fa-redo'></i>
                            </button>
                          </OverlayTrigger>
                        )}
                      </td>
                    </tr>
                  ))}
                  {!users.length && (
                    <tr className='text-center'>
                      <td colSpan={10}>No data available at this moment</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AddLoginName;
