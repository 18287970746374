import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReadMoreModal from "../../utils/Modals/ReadMoreModal";
import { ucFirst } from "../../utils/UcFirst";
import { useAuth } from "../../context/AuthContext";

const ReadOnlyRow = ({ chart, index, handleEditClick, dataFrom }) => {
  const [showReadMoreModal, setshowReadMoreModal] = useState(false);
  const [comments, setComments] = useState("");

  const auth = useAuth();

  const coderProjectID = auth.user.project_id;
  const isLHPProject = Number(coderProjectID) === Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  const closeReadMoreModal = () => {
    setshowReadMoreModal(false);
  };

  const readMoreModalFunc = (comments) => {
    setshowReadMoreModal(true);
    setComments(comments);

    // console.log(showReadMoreModal);
  };
  return (
    <>
      <tr key={index}>
        {dataFrom ? <td className="text-center">{dataFrom + index}</td> : <td className="text-center">{index + 1}</td>}
        <td className={`${chart.action === "rejected" && "text-danger"} text-center`}>{chart.chart_id}</td>
        {isLHPProject && <td className="text-center">{new Date(chart.date_of_service).toLocaleDateString("en-US")}</td>}
        <td className="text-center">{ucFirst(chart.member_name)}</td>
        <td className="text-center">{new Date(chart.dob).toLocaleDateString("en-US")}</td>
        <td className="text-center">{chart.dos}</td>
        <td className="text-center">{chart.icd}</td>
        {isLHPProject && <td>{chart.cpt_codes}</td>}
        <td className="text-center">{chart.pages}</td>
        {isLHPProject && <td>{chart.type_of_visit}</td>}
        {isLHPProject && <td>{chart.facility_name}</td>}
        {isLHPProject && <td className="text-uppercase">{chart.state}</td>}
        {!isLHPProject && (
          <td className={`text-center`}>
            <span className={`badge ${chart.action}`}>
              {chart.action === "code_completed" ? "Completed" : ucFirst(chart.action).split("_").join(" ")}
              {/* {ucFirst(chart.action).split("_").join(" ")} */}
            </span>
          </td>
        )}
        {!isLHPProject && (
          <td className={`text-center`}>
            {chart.comments ? (
              chart.comments.length > 10 ? (
                ucFirst(chart.comments.slice(0, 10).concat("..."))
              ) : (
                ucFirst(chart.comments)
              )
            ) : (
              <span className="text-muted">NA</span>
            )}

            {chart.comments && chart.comments.length > 10 && (
              <OverlayTrigger placement="top" overlay={<Tooltip>Read More</Tooltip>}>
                <button
                  className="btn btn-sm i-success ms-1 shadow-none"
                  onClick={(e) => {
                    e.preventDefault();
                    readMoreModalFunc(ucFirst(chart.comments));
                  }}
                >
                  <i className="fas fa-comment-medical"></i>
                </button>
              </OverlayTrigger>
            )}
          </td>
        )}

        <td className="text-center">{new Date(chart.coding_at).toDateString().slice(4)}</td>
        {/* <td>{chart.coding_date}</td> */}
        <td className="text-center">{chart.project_name}</td>
        <td className="text-center">{chart.coder_login_name}</td>
        {!chart.is_chart_audited ? (
          <td className="text-nowrap">
            <OverlayTrigger placement="top" overlay={<Tooltip>Edit chart</Tooltip>}>
              <button type="button" className="btn btn-sm i-secondary shadow-none ml-2" onClick={(event) => handleEditClick(event, chart)}>
                <i className="fa fa-edit" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </td>
        ) : (
          <td></td>
        )}
      </tr>

      <ReadMoreModal
        showReadMoreModal={showReadMoreModal}
        closeReadMoreModal={closeReadMoreModal}
        comments={comments}
        setshowReadMoreModal={setshowReadMoreModal}
        title="Comments"
      />
    </>
  );
};

export default ReadOnlyRow;
