import { Modal, Button } from "react-bootstrap";

const ResetAuditorErrFields = (props) => {
  const { showModal, hideModal, resetErrFileds } = props;
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <h6 className="mb-0 font-size13">Confirmation !</h6>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center font-size13">
          Are you sure want to "Proceed"? On proceeding further the Errors you
          entered will be lost..
        </p>
        <div className="text-center ">
          <Button variant="danger" className="me-2 font-size13" onClick={resetErrFileds}>
            Proceed
          </Button>
          <Button variant="success" className="font-size13" onClick={hideModal}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResetAuditorErrFields;
