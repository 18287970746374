import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";

const EditOnlyRow = ({ index, editFormData, handleCancel, handleEditFormChange, editFormErrors }) => {
  return (
    <>
      <td>{index + 1}</td>
      <td>
        <input
          type='text'
          required='required'
          placeholder='Enter a Project Name'
          name='project_name'
          className='form-control'
          value={editFormData.project_name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.project_name && (
          <div id='clientName' className='invalid-feedback d-block'>
            {editFormErrors.project_name}
          </div>
        )}
      </td>
      <td>
        <input
          type='text'
          required='required'
          placeholder='Enter Project Code'
          name='project_code'
          className='form-control'
          value={editFormData.project_code}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.project_code && (
          <div id='clientName' className='invalid-feedback d-block'>
            {editFormErrors.project_code}
          </div>
        )}
      </td>
      <td className='text-center'>{editFormData.client_name}</td>
      <td className='text-center'>{editFormData.teams_count}</td>
      <td>{new Date(editFormData.created_at).toDateString().slice(4)}</td>
      <td className='text-nowrap'>
        <OverlayTrigger placement='top' overlay={<Tooltip>Save Project</Tooltip>}>
          <button type='submit' className='btn btn-sm i-success shadow-none ml-2'>
            <i className='fas fa-arrow-alt-circle-right' aria-hidden='true' />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Cancel Edit</Tooltip>}>
          <button type='button' className='btn btn-sm i-primary font-15 shadow-none ml-2' onClick={handleCancel}>
            <i className='fa fa-times' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>
    </>
  );
};

export default EditOnlyRow;
