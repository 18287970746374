import React from "react";
import { Navigate } from "react-router";

class ErrorBoundary extends React.Component {
  // Constructor for initializing Variables etc in a state
  // Just similar to initial line of useState if you are familiar
  // with Functional Components
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // This method is called if any error is encountered
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and
    // re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // You can also log error messages to an error
    // reporting service here
  }

  // This will render this component wherever called
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className='d-flex justify-content-center align-items-center flex-column' style={{ height: "85vh" }}>
          <div className='text-left'>
            <p className='fs-5 text-center'>
              Something went wrong. <br />
              <span className='fs-6'>Please contact IT support.</span> <br />
              <a href='mailto:itsupport@secqureone.com' className='fs-6'>
                itsupport@secqureone.com
              </a>
            </p>
            <div className='text-center'>
              <button
                className='btn btn-primary'
                // onClick={() => {
                //    navigate("/", { replace: true });
                // }}
              >
                Go Back
              </button>
            </div>
            {/* <h6>An Error Has Occurred</h6>
            <p> {this.state.error && this.state.error.toString()}</p> */}
            {/* <details>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
          </div>
        </div>
      );
    }
    // Normally, just render children, i.e. in
    // case no error is Found
    return this.props.children;
  }
}

export default ErrorBoundary;
