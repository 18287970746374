import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReadMoreModal from "../../utils/Modals/ReadMoreModal";
import { ucFirst } from "../../utils/UcFirst";
import { useAuth } from "../../context/AuthContext";

const ReadOnlyRow = ({ chart, index, handleEditClick, dataFrom }) => {
  const [showReadMoreModal, setshowReadMoreModal] = useState(false);
  const [comments, setComments] = useState("");

  const auth = useAuth();

  const closeReadMoreModal = () => {
    setshowReadMoreModal(false);
  };

  const readMoreModalFunc = (comments) => {
    setshowReadMoreModal(true);
    setComments(comments);

    // console.log(showReadMoreModal);
  };
  return (
    <>
      <tr key={index} className="font-size13 font-weight-light">
        {dataFrom ? <td className="text-center">{dataFrom + index}</td> : <td className="text-center">{index + 1}</td>}
        <td>{chart.chart_id}</td>
        <td>{ucFirst(chart.member_name)}</td>
        <td>{new Date(chart.dob).toLocaleDateString("en-US")}</td>
        <td className="text-center">{chart.icd}</td>
        <td className="text-center">{chart.pages}</td>
        <td>
          {chart.sub_project === "Recode 2.0" && (
            <span>
              {/* {chart.comments} */}
              {chart.comments && chart.comments.length > 10
                ? ucFirst(chart.comments.slice(0, 10).concat("..."))
                : chart.comments && chart.comments !== null && ucFirst(chart.comments)}
              {chart.comments && chart.comments.length > 10 && (
                <OverlayTrigger placement="top" overlay={<Tooltip>Read More</Tooltip>}>
                  <button
                    className="btn btn-sm i-success ms-1 shadow-none"
                    onClick={(e) => {
                      e.preventDefault();
                      readMoreModalFunc(ucFirst(chart.comments));
                    }}
                  >
                    {/* <i className='fas fa-comment-medical'></i> */}
                    {/* <i class="fas fa-solid fa-comment-dots"></i> */}
                    <img src="/images/comment-logo.png" width="12px" alt="comment-logo" />
                  </button>
                </OverlayTrigger>
              )}
              {!chart.comments && <span className="text-muted">NA</span>}
            </span>
          )}
          {/* {JSON.stringify(chart.comments)} */}
        </td>
        <td>{new Date(chart.coding_at).toDateString().slice(4)}</td>
        <td>{chart.project_name}</td>
        <td>{chart.sub_project}</td>
        <td>{chart.coder_login_name}</td>
        {/* <td>comments</td> */}
        {!chart.is_chart_audited ? (
          <td className="text-nowrap">
            <OverlayTrigger placement="top" overlay={<Tooltip>Edit chart</Tooltip>}>
              <button type="button" className="btn btn-sm i-secondary shadow-none ml-2" onClick={(event) => handleEditClick(event, chart)}>
                <i className="fa fa-edit" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </td>
        ) : (
          <td></td>
        )}
      </tr>

      <ReadMoreModal
        showReadMoreModal={showReadMoreModal}
        closeReadMoreModal={closeReadMoreModal}
        comments={comments}
        setshowReadMoreModal={setshowReadMoreModal}
        title="Comments"
      />
    </>
  );
};

export default ReadOnlyRow;
