import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";
import { ucFirst } from "../../utils/UcFirst";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";

const TeamCard = () => {
  const [teams, setTeams] = useState([]);

  const navigate = useNavigate();

  const params = useParams();
  const endPoint = params.projectId ? `team/${params.projectId}/project` : "team";

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`${endPoint}`);
      setTeams(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Change TabTitle
  changeTabTitle("Teams");
  return (
    <>
      <div className="text-end">
        <button className="btn btn-danger btn-sm mb-3" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
      <CardHeader title="Teams" count={teams.length} icon={<i className="fas fa-users me-2 pb-0 border-0  "></i>} showGoBack={true} />
      <CardBody>
        {teams.map((item, index) => (
          <div className="col-md-3 mb-md-3 font-size13" key={index}>
            <div className="card view-card">
              {/* <div className='text-end mt-3 pe-3'>
                <span className={`badge py-2 ${item.status === "active" ? "bg-success" : "bg-danger"}`}>{ucFirst(item.status)}</span>
              </div> */}
              <div className="card-body d-flex justify-content-center align-items-center" style={{ height: "220px" }}>
                <div className="col-md-6  p-2">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="font-size13" style={{ color: "#9190ff" }}>
                        {" "}
                        Team ID
                      </h5>
                      <p>HSTM {item.id}</p>
                    </div>
                    <div className="col-12">
                      <h5 className="font-size13" style={{ color: "#9190ff" }}>
                        Lead Name
                      </h5>
                      <p className="mb-0">{item.lead_name}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center p-2">
                  <span className={`badge rotational-status ${item.status === "active" ? "bg-success" : "bg-danger"}`}>{ucFirst(item.status)}</span>

                  <h1 style={{ fontSize: "3.5rem" }}>{item.coder_count + item.auditor_count}</h1>
                  <p>Users</p>

                  {item.coder_count + item.auditor_count ? (
                    <Link to={`/all-users/${item.id}`} className="btn btn-success btn-sm font-size13">
                      View
                    </Link>
                  ) : (
                    <button className="btn btn-secondary btn-sm font-size13" disabled>
                      View
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        {!teams.length && <p className="text-center font-size13">No data available at this moment</p>}
      </CardBody>
    </>
  );
};

export default TeamCard;
