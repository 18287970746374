import { Modal, Button } from "react-bootstrap";

const AgreeModal = (props) => {
  const {
    showAgreeModal,
    closeAgreeModal,
    handleAgree,
    handleFiles,
    setEvidence,
    auth,
    agreeErrors,
    isClientRProject,
  } = props;
  return (
    <Modal
      show={showAgreeModal}
      onHide={closeAgreeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <h6 className="mb-0 font-size13">Please Confirm!</h6>
      </Modal.Header>
      <Modal.Body>
        {auth.user.role == "user" && !isClientRProject && (
          <div className="mb-3 font-size13">
            <label htmlFor="evidence" className="form-label required">
              Evidence
            </label>
            <input
              type="file"
              multiple
              className="form-control"
              id="evidence"
              name="evidences"
              onChange={(e) =>
                handleFiles(Array.prototype.slice.call(e.target.files))
              }
            />
            <div id="evidenceHelp" className="form-text">
              <small className="text-muted">
                Do not upload any sensitive data. Supported file
                types:jpeg.jpg,png
              </small>
              <br />
              {agreeErrors && (
                <small className="text-danger">{agreeErrors.evidence}</small>
              )}
            </div>
          </div>
        )}
        <div className="text-center">
          <Button variant="success" className="me-2 font-size13" onClick={handleAgree}>
            Agree
          </Button>
          <Button variant="danger" className="font-size13" onClick={closeAgreeModal}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgreeModal;
