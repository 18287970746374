import React, { useContext } from "react";
import { CommonContext } from "../../context/CommonContext";

import { useFormik } from "formik";
import Select from "react-select";
import * as yup from "yup";

import apiClient from "../../services/ApiClient";
import { useEffect, useRef, useState } from "react";

const AddTeam = ({ fetchData }) => {
  // const [teamLeads, setTeamLeads] = useState("");
  // const [projects, setProjects] = useState("");
  // const [users, setUsers] = useState([]);
  const [selectedusers, setSelectedUsers] = useState(null);
  const [selectedAuditors, setSelectedAuditors] = useState(null);
  const selectInputRef = useRef();
  const auditorInputRef = useRef();

  const context = useContext(CommonContext);

  const {
    teams,
    setTeams,
    teamLeads,
    setTeamLeads,
    projects,
    setProjects,
    users,
    setUsers,
    auditors,
    setAuditors,
  } = context;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const [leadRequest, userRequest, projectsRequest] = await Promise.all([
  //         apiClient.get(`user/role/leads`),
  //         apiClient.get(`user/role/users`),
  //         apiClient.get(`project`),
  //       ]);
  //       setTeamLeads(leadRequest.data.data);
  //       setUsers(userRequest.data.data);
  //       setProjects(projectsRequest.data.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  // }, [teams]);

  // console.log(auditors);
  const userOptions = users.map((user) => {
    let tempobj = {};
    tempobj.value = user.id;
    tempobj.label = `${user.name} - ${user.email}`;
    return tempobj;
  });

  const auditorOptions = auditors.map((auditor) => {
    let tempobj = {};
    tempobj.value = auditor.id;
    tempobj.label = `${auditor.name} - ${auditor.email}`;
    return tempobj;
  });

  // if (selectedusers) {
  //   let UsersValues = selectedusers.map((user) => user.value);
  // }

  // if (selectedAuditors) {
  //   let auditorValues = selectedAuditors.map((user) => user.value);
  // }

  const formik = useFormik({
    initialValues: {
      project_id: "",
      team_lead: "",
    },
    validationSchema: yup.object({
      project_id: yup.string().required("Project is required"),
      team_lead: yup.string().required("Team Lead is required"),
    }),
    onSubmit: async (values) => {
      // if (selectedusers && selectedAuditors) {
      let usersValues =
        selectedusers && selectedusers.map((user) => user.value);
      let auditorValues =
        selectedAuditors && selectedAuditors.map((user) => user.value);
      const combinedValues = {
        ...values,
        team_users: usersValues,
        team_auditors: auditorValues,
      };
      try {
        const response = await apiClient.post("/team/store", combinedValues);
        fetchData();
        formik.resetForm();

        selectInputRef.current.clearValue();
        auditorInputRef.current.clearValue();
      } catch (err) {
        formik.setErrors({
          project_id: err.response.data.error.project_id,
          team_lead: err.response.data.error.team_lead,
        });
      }
      // }
    },
  });

  return (
    <>
      <div className="card">
        {/* <div className='card-header py-3'>
          <h6 className='mb-0'>
            <i className='fas fa-users me-2'></i> Add Team
          </h6>
        </div> */}
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div class="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="selectProject" className="form-label required">
                  Select Project
                </label>

                <select
                  className={`form-select form-control  font-size13 ${
                    formik.touched.project_id &&
                    formik.errors.project_id &&
                    "is-invalid"
                  }`}
                  id="selectproject"
                  name="project_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_id}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {projects &&
                    projects.map((project) => (
                      <option value={project.id} key={project.id}>
                        {project.project_name} - {project.project_code}
                      </option>
                    ))}
                </select>

                {formik.touched.project_id && formik.errors.project_id ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.project_id}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="projectName" className="form-label required">
                  Select Team Lead
                </label>
                <select
                  className={`form-select form-control  font-size13 ${
                    formik.touched.team_lead &&
                    formik.errors.team_lead &&
                    "is-invalid"
                  }`}
                  id="selectproject"
                  name="team_lead"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.team_lead}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {teamLeads &&
                    teamLeads.map((teamLead) => (
                      <option value={teamLead.id} key={teamLead.id}>
                        {teamLead.name} - {teamLead.email}
                      </option>
                    ))}
                </select>

                {formik.touched.team_lead && formik.errors.team_lead ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.team_lead}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label className="form-label">Select Users</label>

                <Select
                  ref={selectInputRef}
                  isClearable={true}
                  options={userOptions}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  isSearchable
                  onChange={setSelectedUsers}
                  // style={{ border:"0" }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      text: "orangered",

                      primary25: "#cecece",
                      primary: "transparent",

                      neutral0: "#5957B7",
                      // neutral0: "#062549",
                      neutral10: "#062549",
                    },
                  })}
                />

                <div className="invisible">
                  <span>invisible</span>
                </div>
              </div>

              <div className="col-lg px-1">
                <label className="form-label">Select Auditors</label>

                <Select
                  ref={auditorInputRef}
                  isClearable={true}
                  options={auditorOptions}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  isSearchable
                  onChange={setSelectedAuditors}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                      ...theme.colors,
                      text: "orangered",

                      primary25: "#cecece",
                      primary: "transparent",

                      neutral0: "#5957B7",
                      // neutral0: "#062549",
                      neutral10: "#062549",
                    },
                  })}
                />

                <div className="invisible">
                  <span>invisible</span>
                </div>
              </div>

              <div class="col-lg-1 w-auto px-1 mt-4 pt-2 ms-2">
                <button
                  type="submit"
                  className="btn custom-btn-primary py-1 px-4 font-size13"
                >
                  Add Team
                </button>
                <div class="invisible">
                  <span>invisible</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTeam;
