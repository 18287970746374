import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { Link } from "react-router-dom";
import ChartDiagram from "../components/ChartDiagram";
import IdleUsers from "../components/IdleUsers";
import { useAuth } from "../context/AuthContext";
import apiClient from "../services/ApiClient";

// import Offcanvas from "react-bootstrap/Offcanvas";
// import Echo from "laravel-echo";
// import Pusher from "pusher-js";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import LoadingIndicator from "../shared/LoadingIndicator";

const UserDashboard = () => {
  const params = useParams();

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [data, setData] = useState({});
  const [idleUsers, setIdleUsers] = useState([]);
  // const [show, setShow] = useState(false);
  // const [usersWithStatus, setUsersWithStatus] = useState([]);
  // const [showCanvas, setShowCanvas] = useState(false);
  // const [usersOnlineCount, setUsersOnlineCount] = useState("");
  // const [usersOfflineCount, setUsersOfflineCount] = useState("");

  const auth = useAuth();

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`dashboard`, {
        componentName: "userdashboard",
      });
      return response.data;
      // setData(response.data.data);
      // setIdleUsers(response.data.idle_users);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRealTimeStatusForTeamUsers = async () => {
    try {
      let teamId = params.teamId || auth.user.active_team_id;
      const response = await apiClient.get(`/real-time/count/${teamId}`, {
        componentName: "realTimeStatusCount",
      });
      return response.data;
      // setUsersOnlineCount(response.data.online);
      // setUsersOfflineCount(response.data.offline);
    } catch (error) {
      // console.log(error);
    }
  };

  const { data, isFetching: dataFetching } = useQuery("userdashboard", fetchData, {
    // keepPreviousData: true,
    staleTime: 30000,
  });
  const { data: realTimeStatusCount, isFetching: realTimeStatusFetching } = useQuery("realTimeStatusCount", fetchRealTimeStatusForTeamUsers, {
    // keepPreviousData: true,
    staleTime: 30000,
  });

  // window.Echo = new Echo({
  //   broadcaster: "pusher",
  //   key: "bda103311c3a12156769",
  //   cluster: "ap2",
  //   forceTLS: true,
  //   // authEndpoint : "http://localhost:8000/broadcasting/auth",
  //   // auth: {
  //   //   headers: {
  //   //     Authorization: `Bearer ${token}`,
  //   //     Accept: 'application/json',
  //   //   },
  //   // },
  // });

  // var channel = window.Echo.channel("real-time-status");

  // // Pusher.logToConsole = true;

  // channel.listen(".user-live-status", async function (data) {
  //   // console.log("0");
  //   // console.log(data);
  //   if (data.event_data) {
  //     // console.log("1");
  //     // console.log(data.event_data);
  //     setUsersOnlineCount(data.event_data.online);
  //     setUsersOfflineCount(data.event_data.offline);
  //     // toast.info(data.event_data.notification);

  //     const updatedUsers = await usersWithStatus.map((user) => {
  //       // console.log(user.index);
  //       // console.log(data.event_data.user_id);

  //       if (user.index === data.event_data.user_id) {
  //         // Create a new object with the updated status
  //         // console.log("2");
  //         return { ...user, live_status: data.event_data.status };
  //       }
  //       // For other users, return the original object
  //       return user;
  //     });

  //     if (updatedUsers) {
  //       // console.log("3");
  //       setUsersWithStatus(updatedUsers);
  //     }
  //     // console.log(updatedUsers);
  //   }
  // });

  // useEffect(() => {
  //   fetchData();
  //   // setData(data.data);
  //   // setIdleUsers(data.idle_users);
  //   // fetchRealTimeStatusForTeamUsers();
  //   // setUsersOnlineCount(data.online);
  //   // setUsersOfflineCount(data.offline);
  // }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i className="text-secondary fas fa-chart-pie fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <div className="text-light">
                      <h6 className="font-size14">Today Charts</h6>
                      <h3 className="pl-2">
                        {data?.data?.today_charts_count ?? 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i className="text-secondary fas fa-chart-pie fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <div className="text-light">
                      <h6 className="font-size14">Yesterday Charts</h6>
                      <h3 className="pl-2">
                        {data?.data?.yesterday_charts_count ?? 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i class="fas fa-chart-line text-secondary fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <div className="text-light">
                      <h6 className="font-size14">This Month</h6>
                      <h3 className="pl-2">
                        {data?.data?.current_month_charts_count ?? 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i class="fas fa-chart-line text-secondary fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <div className="text-light">
                      <h6 className="font-size14">Previous Month</h6>
                      <h3 className="pl-2 ">
                        {data?.data?.previous_month_charts_count ?? 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {auth && auth.user.role === "lead" && (
        <>
          <div className="row mb-3">
            <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
              <div className="card">
                <div className="card-body">
                  {realTimeStatusFetching && <LoadingIndicator />}
                  <div className="row">
                    <div className="col-lg-2 col-md-12">
                      {/* <img src='/images/teams.png' alt='' /> */}
                      <i className="text-secondary fas fa-user fa-4x"></i>
                    </div>
                    <div className="col-lg-10 col-md-12">
                      <span className="text-end">
                        {/* <Link className="text-light" to="/all-users"> */}
                        <h6>Users coding now</h6>
                        <h3 className="pl-2">{realTimeStatusCount?.online ?? 0}</h3>
                        {/* <span class="position-relative bg-transparent">
                        <i
                          class="fas fa-user-check fa-lg"
                          style={{ color: "#7CB524" }}
                        ></i>
                        <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill text-light bg-gradient font-14">
                          {usersOnlineCount}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      </span> */}
                        {/* </Link> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
              <div className="card">
                <div className="card-body">
                  {realTimeStatusFetching && <LoadingIndicator />}

                  <div className="row">
                    <div className="col-lg-2 col-md-12">
                      {/* <img src='/images/teams.png' alt='' /> */}
                      <i className="text-secondary fas fa-user fa-4x"></i>
                    </div>
                    <div className="col-lg-10 col-md-12">
                      <span className="text-end">
                        {/* <Link className="text-light" to="/all-users"> */}
                        <h6>Users away</h6>
                        {/* <span class="position-relative bg-transparent">
                        <i class="fas fa-user-slash text-danger fa-lg"></i>
                        <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-gradient">
                          {usersOfflineCount}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      </span> */}
                        <h3 className="pl-2">{realTimeStatusCount?.offline ?? 0}</h3>
                        {/* </Link> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ChartDiagram />

      {auth && auth.user.role === "lead" && <IdleUsers idleUsers={data ? data.idle_users : []} />}
    </>
  );
};

export default UserDashboard;
