import { useEffect, useState } from "react";
import { useParams } from "react-router";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";

const Login = () => {
  const [msLoginUrl, setMsLoginUrl] = useState("");
  const [zohoLoginUrl, setZohoLoginUrl] = useState("");

  const params = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ms_response = await apiClient.get("sso/login/azure");
        const zoho_response = await apiClient.get("sso/login/zoho");
        setMsLoginUrl(ms_response.data.url);
        setZohoLoginUrl(zoho_response.data.url);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  // Change TabTitle
  changeTabTitle("Login");

  return (
    <>
      <div className='vh-100 vw-100 d-flex justify-content-center align-items-center' id='login-container'>
        <div className='d-flex justify-content-end align-items-center w-75'>
          <h1 className='text-end pe-5 me-5 py-4 fw-normal' style={{ borderRight: "8px solid #fff" }}>
            <span className='fs-2'>Welcome to</span> <br /> <span className=''>Pro1 Health Systems</span>
          <p className="fs-6 mt-3 text-muted">Start managing your medical codes faster and better</p>
          </h1>
          <div>
          {msLoginUrl && (
            // <a className='text-decoration-none' href={msLoginUrl} id='loginLink'>
            //   Sign in with Microsoft Email
            //   <img src='/images/ms-logo.png' alt='microsoft logo' width={40} className='ms-2 mb-2' />
            //   <i className='fas fa-arrow-circle-right ms-2'></i>
            // </a>
            <a className='btn btn-primary text-nowrap d-block mb-4 text-start  d-flex align-items-center' href={msLoginUrl} style={{minWidth: 300, backgroundColor: '#0d6efd55'}}>
               <div className="bg-light rounded-circle px-1 pb-1 me-2" >
              <img src='/images/ms-logo.png' alt='microsoft logo' width={18} className=" align-bottom"/>
               </div>
             Sign in with Microsoft Email
             <i className='fas fa-arrow-circle-right ms-auto'></i>
           </a>
            
          )}
          {zohoLoginUrl && (
            // <a className='text-decoration-none' href={zohoLoginUrl} id='loginLink'>
            //   Sign in with Zoho Email
            //   <img src='/images/ms-logo.png' alt='microsoft logo' width={40} className='ms-2 mb-2' />
            //   <i className='fas fa-arrow-circle-right ms-2'></i>
            // </a>
            <a className='btn btn-success text-nowrap text-start d-flex align-items-center' href={zohoLoginUrl} style={{minWidth: 300, backgroundColor: '#19875475'}}>
              <div className="bg-light rounded-circle px-1 pb-1 me-2" >
            <img src='/images/zoho-mail.png' alt='microsoft logo' width={21} className='' />
              </div>
            Sign in with Zoho Email
           <i className='fas fa-arrow-circle-right ms-auto'></i>
         </a>
          )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
