import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import apiClient from "../services/ApiClient";
import { ucFirst } from "../utils/UcFirst";
import { useAuth } from "../context/AuthContext";
import ReactPaginate from "react-paginate";
import LoadingIndicator from "../shared/LoadingIndicator";

const AddUsersbyLead = (user) => {
  const [users, setUsers] = useState([]);
  const [loginNames, setLoginNames] = useState([]);
  const [selectLoginName, setSelectLoginName] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const access =
    (auth && auth.user && auth.user.role === "super_admin") ||
    auth.user.role === "manager";

  const formik = useFormik({
    initialValues: {
      name: "",
      login_id: "",
      email: "",
      employee_id: "",
      role: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(3, "Too Short!")
        .max(25, "Must be 25 characters or less")
        .required("Name is required"),
      // login_id: yup.string().required("Login Name is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      employee_id: yup
        .number()
        .typeError("Must be a number")
        .required("Employee ID is required"),
      role: yup.string().required("Role is required"),
    }),
    onSubmit: async (values) => {
      const combinedValues = { ...values, team_id: auth.user.active_team_id };
      console.log();
      try {
        const response = await apiClient.post("team/add/user", combinedValues);
        // console.log(response.data.data);
        fetchData(offset);
        formik.resetForm();
      } catch (err) {
        formik.setErrors({
          name: err.response.data.error.name,
          email: err.response.data.error.email,
          employee_id: err.response.data.error.employee_id,
          role: err.response.data.error.role,
          login_id: err.response.data.error.login_id,
        });
      }
    },
  });

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  const fetchData = async (offset) => {
    try {
      setLoading(true);
      let endPoint = searchTerm
        ? `team/get/users?search=${searchTerm}`
        : `team/get/users?page=${offset}`;
      const [teamResponse, loginNameResponse] = await Promise.all([
        apiClient.get(endPoint, { componentName: "users" }),
        apiClient.get("user-login", { componentName: "user-login" }),
      ]);
      setLoading(false);
      setUsers(teamResponse.data.data);
      setLoginNames(loginNameResponse.data.data);
      setPageCount(teamResponse.data.meta.last_page);
      setDataCount(teamResponse.data.meta.total);
      setDataFrom(teamResponse.data.meta.from);
      setDataEnd(teamResponse.data.meta.to);
      // console.log(teamResponse.data);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(offset);
  }, [searchTerm, offset]);

  const selectLoginSubmitHandler = async (logId) => {
    try {
      if (selectLoginName) {
        const response = await apiClient.post(`user-login/assign/${logId}`, {
          login_id: selectLoginName,
        });
        fetchData(offset);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disableLoginNameHandler = async (logId) => {
    try {
      // if (selectLoginName) {
      const response = await apiClient.post(`user-login/disable/${logId}`, {
        login_id: selectLoginName,
      });
      fetchData(offset);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div class="row align-items-center justify-content-start px-lg-2">
                <div className="col-lg px-1">
                  <label htmlFor="name" className="form-label required">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`form-control font-size13 ${
                      formik.touched.name && formik.errors.name && "is-invalid"
                    }`}
                    id="name"
                    // placeholder='Enter Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div id="name" className="invalid-feedback">
                      {formik.errors.name}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>

                <div className="col-lg-3 px-1">
                  <label htmlFor="email" className="form-label required">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`form-control font-size13 ${
                      formik.touched.email &&
                      formik.errors.email &&
                      "is-invalid"
                    }`}
                    id="email"
                    // placeholder='Enter Email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div id="email" className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>

                <div className="col-lg px-1">
                  <label htmlFor="employee_id" className="form-label required">
                    Employee ID
                  </label>
                  <input
                    type="text"
                    name="employee_id"
                    className={`form-control font-size13 ${
                      formik.touched.employee_id &&
                      formik.errors.employee_id &&
                      "is-invalid"
                    }`}
                    id="employee_id"
                    // placeholder='Enter employee_id'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.employee_id}
                  />
                  {formik.touched.employee_id && formik.errors.employee_id ? (
                    <div id="employee_id" className="invalid-feedback">
                      {formik.errors.employee_id}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>

                <div className="col-lg px-1">
                  <label htmlFor="role" className="form-label required">
                    Select Role
                  </label>
                  <select
                    name="role"
                    className={`form-select form-control font-size13 ${
                      formik.touched.role && formik.errors.role && "is-invalid"
                    }`}
                    id="role"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                  >
                    <option disabled value="">
                      Select...
                    </option>
                    <option value="auditor">Auditor</option>
                    <option value="user">Coder</option>
                  </select>
                  {formik.touched.role && formik.errors.role ? (
                    <div id="role" className="invalid-feedback">
                      {formik.errors.role}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>

                <div className="col-lg px-1">
                  <label htmlFor="login_id" className="form-label">
                    Select Login Name
                  </label>
                  <select
                    className={`form-select form-control font-size13 ${
                      formik.touched.role && formik.errors.role && "is-invalid"
                    }`}
                    id="login_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.login_id}
                  >
                    <option value="">Please Select Login name</option>
                    {loginNames.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.login_name} - {item.login_email}
                      </option>
                    ))}
                  </select>
                  {formik.touched.login_id && formik.errors.login_id ? (
                    <div id="login_id" className="invalid-feedback">
                      {formik.errors.login_id}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>

                <div class="col-lg-1 w-auto px-1 mt-4 pt-2 ms-2">
                  <button
                    type="submit"
                    className="btn custom-btn-primary py-1 px-4  font-size13"
                  >
                    Add User
                  </button>
                  <div class="invisible">
                    <span>invisible</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="card">
          <div className="card-header d-flex align-items-center pb-0 border-0">
            <div className="w-25">
              <h6 className="mb-0 font-size13 pt-2">
                Users{" "}
                {dataCount ? (
                  <span className="badge count-badge-success ms-1 px-2 py-1">
                    {dataCount}
                  </span>
                ) : null}
              </h6>
            </div>
            <div className="ms-5 w-25 mt-2">
              <div className="input-group">
                <input
                  type="text"
                  className={`form-control font-size13`}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by name / email..."
                />
                <button
                  className="btn custom-btn-primary shadow-none outline-none border-0"
                  type="button"
                  id="button-addon2"
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body px-0">
            <div className="table-responsive">
              <table className="table">
                <thead className=" font-size13">
                  <tr>
                    <th scope="col" className="text-center">
                      S.No
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Employee ID</th>
                    <th scope="col">Role</th>
                    <th scope="col">Status</th>
                    <th scope="col">Login Name</th>
                    <th scope="col" className="fit"></th>
                    <th scope="col">Action</th>
                    <th scope="col">Profile</th>
                  </tr>
                </thead>
                <tbody className="font-size13 font-weight-light">
                  {loading && <LoadingIndicator />}
                  {users.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{dataFrom + index}</td>
                      <td>{item.name}</td>

                      <td>{item.email}</td>
                      <td>{item.employee_id}</td>
                      <td>{ucFirst(item.role)}</td>
                      <td>
                        <span
                          className={`badge ${
                            item.status === "active"
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {ucFirst(item.status)}
                        </span>
                      </td>
                      <td>
                        {item.login_name
                          ? item.login_name
                          : item.status === "active" && (
                              <select
                                onChange={(e) => {
                                  setSelectLoginName(e.target.value);
                                }}
                                className="form-control form-select font-size13"
                                // value={selectLoginName}
                              >
                                <option value="">
                                  Please Select Login name
                                </option>
                                {loginNames.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.login_name} - {item.login_email}
                                  </option>
                                ))}
                              </select>
                            )}
                      </td>

                      <td>
                        {!item.login_name
                          ? item.status === "active" && (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Save Changes</Tooltip>}
                              >
                                <button
                                  className="btn btn-sm i-success shadow-none ml-2"
                                  onClick={() => {
                                    selectLoginSubmitHandler(item.id);
                                  }}
                                >
                                  <i
                                    className="fas fa-arrow-alt-circle-right"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </OverlayTrigger>
                            )
                          : null}
                      </td>

                      <td>
                        {!item.login_name ? (
                          item.status === "active" && null
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Reset Login Name</Tooltip>}
                          >
                            <button
                              className="btn btn-sm i-success shadow-none ml-2"
                              onClick={() => {
                                disableLoginNameHandler(item.id);
                              }}
                            >
                              <i class="fas fa-redo"></i>
                            </button>
                          </OverlayTrigger>
                        )}
                      </td>
                      <td className="text-center">
                        {item.role === "user" ? (
                          <Link
                            to={`/${item.name}/profile`}
                            state={{ user_id: item.id }}
                            className="i-link"
                          >
                            <i className="fa fa-user" aria-hidden="true" />
                          </Link>
                        ) : null}
                        {item.role === "auditor" ? (
                          <Link
                            to={`/${item.name}/profile`}
                            state={{ user_id: item.id }}
                            className="i-link"
                          >
                            <i className="fa fa-user" aria-hidden="true" />
                          </Link>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                  {!users.length && (
                    <tr className="text-center font-size13">
                      <td colSpan={10}>No data available at this moment</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center flex-row-reverse px-3 pt-2">
              <div>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              {dataFrom && dataEnd && (
                <span className="ms-2 showing  font-size13 grey-clr">
                  <i class="fas fa-book-open me-1"></i>
                  Showing {dataFrom} - {dataEnd} of {dataCount} entires
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsersbyLead;
