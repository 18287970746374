import { Modal, Button } from "react-bootstrap";
import { ucFirst } from "../UcFirst";

const ReadMoreModal = (props) => {
  const { showReadMoreModal, closeReadMoreModal, errorFeedbacks, openImage, title, comments, isClientRProject } = props;
  return (
    <Modal size="lg" show={showReadMoreModal} onHide={closeReadMoreModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <h6 className="mb-0 font-size13">{title}</h6>
      </Modal.Header>
      <Modal.Body>
        <>
          {comments && <p className="font-size13"> {comments}</p>}
          {errorFeedbacks && !isClientRProject && (
            <>
              <div className="d-flex justify-content-between font-size13">
                <div>
                  <i class="fas fa-plus-circle text-success m-2"></i> Added
                </div>
                <div>
                  <i class="fas fa-minus-circle text-danger m-2"></i> Deleted
                </div>
                <div>
                  <i class="fas fa-arrow-circle-up text-secondary m-2"></i>
                  Updated
                </div>
                <div>
                  <i class="fas fa-user-cog text-warning m-2"></i> Admin
                </div>
              </div>
              <hr />
            </>
          )}
          {errorFeedbacks &&
            errorFeedbacks.map((feedback, index) => (
              <div className="row px-2 py-2" key={index}>
                <div className="d-flex justify-content-between font-size13">
                  <p className="mb-3">
                    {feedback.error_status === "added" && <i class="fas fa-plus-circle text-success m-2"></i>}
                    {feedback.error_status === "deleted" && <i class="fas fa-minus-circle text-danger m-2"></i>}
                    {feedback.error_status === "updated" && <i class="fas fa-arrow-circle-up text-secondary m-2"></i>}
                    {feedback.error_status === "admin" && <i class="fas fa-user-cog text-warning m-2"></i>}
                    {feedback.comments.split("_").join(" ")}
                  </p>
                </div>

                <div className="px-5">
                  {feedback.coder_status && (
                    <p>
                      <span className="text-info">Coder Status : </span>
                      {ucFirst(feedback.coder_status)}
                    </p>
                  )}
                  {feedback.coder_status && feedback.coder_status == "disagree" && (
                    <p>
                      <span className="text-info">Coder comment : </span>
                      {ucFirst(feedback.coder_comment)}
                    </p>
                  )}
                  {feedback.auditor_status && (
                    <p>
                      <span className="text-info">Auditor Status : </span>
                      {ucFirst(feedback.auditor_status)}
                    </p>
                  )}
                  {feedback.auditor_status && feedback.auditor_status == "disagree" && (
                    <p>
                      <span className="text-info">Auditor comment : </span>
                      {ucFirst(feedback.auditor_comment)}
                    </p>
                  )}
                </div>
                {feedback.evidences.length > 0 && (
                  <div className="px-5">
                    <p className="text-info">Evidences</p>
                    {feedback.evidences &&
                      feedback.evidences.map((evidence, index) => (
                        <button className="btn btn-sm btn-primary mb-4 me-3" onClick={() => openImage(evidence.evidence)}>
                          <i class="far fa-file-pdf"></i> {index + 1}
                        </button>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ReadMoreModal;
