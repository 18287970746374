import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../context/AuthContext";

const EditOnlyRow = ({ index, editFormData, handleCancel, handleEditFormChange, editFormErrors, dataFrom }) => {
  const auth = useAuth();
  const adminAccess = auth && auth.user && auth.user.role === "super_admin";
  return (
    <>
      <td className="text-center">{dataFrom + index}</td>
      <td>{editFormData.employee_id}</td>
      <td>
        <input
          type='text'
          // required='required'
          placeholder='Enter Name'
          name='name'
          className='form-control  font-size13 '
          value={editFormData.name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.name && (
          <div id='clientName' className='invalid-feedback d-block'>
            {editFormErrors.name}
          </div>
        )}
      </td>
      <td>{editFormData.login_name}</td>
      <td>
        <input
          type='email'
          required='required'
          placeholder='Enter Email'
          name='email'
          className='form-control font-size13 '
          value={editFormData.email}
          onChange={handleEditFormChange}
        ></input>
      </td>

      <td>
        {
          !editFormData.team_id && (
            <> 
            <select name='role' className='form-select form-control font-size13 ' id='role' onChange={handleEditFormChange} value={editFormData.role}>
              <option disabled value=''>
                Please Select Role
              </option>
            {adminAccess && <option value='manager'>Manager</option>} 
              <option value='lead'>Lead</option>
              <option value='user'>Coder</option>
              <option value='auditor'>Auditor</option>
            </select>
            {editFormErrors && editFormErrors.role && (
              <div id='clientName' className='invalid-feedback d-block'>
                {editFormErrors.role}
              </div>
            )}
            </>
          )
        }
        {
          editFormData.team_id && (
            <>
            {editFormData.role.charAt(0).toUpperCase() + editFormData.role.slice(1).split("_").join(" ")}
            </>
          )
        }
      </td>
      <td>{ editFormData.team_id ? <span>HSTM{ editFormData.team_id}</span> : <span className="text-muted">NA</span>  }</td>
      {/* <td className="text-center">
      {client.projects && client.projects.length
        ? client.projects.length
        : 0}
    </td> */}
      <td>
        {editFormData.team && editFormData.team.project && editFormData.team.project.client ? (
          editFormData.team.project.client.client_name
        ) : (
          <span className='text-muted'>NA</span>
        )}
      </td>
      <td>
        {editFormData.team && editFormData.team.project ? editFormData.team.project.project_name : <span className='text-muted'>NA</span>}
      </td>
      <td></td>
      <td></td>
      <td className='text-nowrap'>
        <OverlayTrigger placement='top' overlay={<Tooltip>Save User</Tooltip>}>
          <button type='submit' className='btn btn-sm i-success shadow-none ml-2'>
            <i className='fas fa-arrow-alt-circle-right' aria-hidden='true' />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Cancel Edit</Tooltip>}>
          <button type='button' className='btn btn-sm i-primary font-15 shadow-none ml-2' onClick={handleCancel}>
            <i className='fa fa-times' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>
    </>
  );
};

export default EditOnlyRow;
