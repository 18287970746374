// import { useEffect, useState } from "react";
// import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
// import apiClient from "../../services/ApiClient";
// import { ucFirst } from "../UcFirst";

// const RemoveTeamUsersModal = (props) => {
//   const { showRemoveTeamUserModal, closeRemoveTeamUserModal, editFormData, setshowRemoveTeamUserModal, fetchData, role } = props;
//   const [usersList, setUsersList] = useState([]);

//   const handleDelete = async (id) => {
//     try {
//       const response = await apiClient.delete(`user/unassign/team/${id}`);
//       fetchData();
//       setshowRemoveTeamUserModal(false);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     setUsersList(editFormData);
//   }, [editFormData]);

//   return (
//     <Modal size="lg" show={showRemoveTeamUserModal} onHide={closeRemoveTeamUserModal} backdrop="static" keyboard={false}>
//       <Modal.Header closeButton>
//         <h6 className="mb-0 font-size13">
//           Remove Team {ucFirst(role)} - {editFormData?.length}
//         </h6>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="table-responsive text-light">
//           <table className="table text-light">
//             <thead className="font-size13">
//               <th>S.No</th>
//               <th>Name</th>
//               <th>Email</th>
//               <th>Action</th>
//             </thead>
//             <tbody className="font-size13">
//               {usersList &&
//                 usersList.map((user, index) => (
//                   <tr key={user.id} className={`${user.status === "inactive" && "user-select-none pe-none"}`}>
//                     <td>{index + 1}</td>
//                     <td>{user.name}</td>
//                     <td>{user.email}</td>
//                     <td>
//                       <OverlayTrigger placement="top" overlay={<Tooltip>Remove User</Tooltip>}>
//                         <button
//                           type="button"
//                           className={`btn btn-sm ${user.status === "inactive" ? "text-secondary" : "i-primary"}  font-15 shadow-none ml-2`}
//                           onClick={() => {
//                             handleDelete(user.id);
//                           }}
//                         >
//                           <span data-toggle="tooltip" data-placement="top" title="Remove Employee">
//                             <i className="fa fa-trash" />
//                           </span>
//                         </button>
//                       </OverlayTrigger>
//                     </td>
//                   </tr>
//                 ))}
//             </tbody>
//           </table>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default RemoveTeamUsersModal;
import React, { useEffect, useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import apiClient from "../../services/ApiClient";
import { ucFirst } from "../UcFirst";

const RemoveTeamUsersModal = (props) => {
  const { showRemoveTeamUserModal, closeRemoveTeamUserModal, editFormData, setshowRemoveTeamUserModal, fetchData, role } = props;
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleDelete = async (id) => {
    try {
      const response = await apiClient.delete(`user/unassign/team/${id}`);
      fetchData();
      setUsersList((prevUsersList) => prevUsersList.filter((user) => user.id !== id));
      setSelectedUsers((prevSelected) => prevSelected.filter((user) => user.id !== id));
      // setshowRemoveTeamUserModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAll = async () => {
    try {
      // Assuming each user in the list has a unique ID
      const userIds = selectedUsers.map((user) => user.id);
      await Promise.all(userIds.map((id) => apiClient.delete(`user/unassign/team/${id}`)));
      fetchData();
      setUsersList((prevUsersList) => prevUsersList.filter((user) => !userIds.includes(user.id)));
      setSelectedUsers([]);
      setSelectAll(false);
      // setshowRemoveTeamUserModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckboxChange = (id) => {
    const isSelected = selectedUsers.some((user) => user.id === id);

    if (isSelected) {
      setSelectedUsers((prevSelected) => prevSelected.filter((user) => user.id !== id));
    } else {
      setSelectedUsers((prevSelected) => [...prevSelected, usersList.find((user) => user.id === id)]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers([...usersList]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setUsersList(editFormData);
  }, [editFormData]);

  return (
    <Modal size="lg" show={showRemoveTeamUserModal} onHide={closeRemoveTeamUserModal} backdrop="static" keyboard={false}>
      <Modal.Header className="border-0" closeButton>
        <h6 className="mb-0  font-size13">
          Remove Team {ucFirst(role)} - {editFormData?.length}
        </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive text-light">
          <table className="table text-light">
            <thead className="font-size13">
              <tr>
                <th>
                  <input type="checkbox" className="ms-1" onChange={handleSelectAll} checked={selectAll} />
                </th>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="font-size13">
              {usersList &&
                usersList.map((user, index) => (
                  <tr key={user.id} className={`${user.status === "inactive" && "user-select-none pe-none"}`}>
                    <td>
                      <input type="checkbox"  onChange={() => handleCheckboxChange(user.id)} checked={selectedUsers.some((u) => u.id === user.id)} />
                    </td>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <OverlayTrigger placement="top" overlay={<Tooltip>Remove User</Tooltip>}>
                        <button
                          type="button"
                          className={`btn btn-sm ${user.status === "inactive" ? "text-secondary" : "i-primary"}  font-15 shadow-none ml-2`}
                          onClick={() => handleDelete(user.id)}
                        >
                          <span data-toggle="tooltip" data-placement="top" title="Remove Employee">
                            <i className="fa fa-trash dlt-icon" />
                          </span>
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="mt-3 font-size13 ">
          {/* {selectedUsers.length > 0 && (
            <>
              <span>{selectedUsers.length} Rows Selected</span> 
              <Button variant="danger" className="font-size13 me-4 dlt-icon-bg" onClick={handleDeleteAll}>
                Delete
              </Button>
            </>
          )} */}
          {selectedUsers.length > 0 && (
            <>
            <hr/>
              <div className="d-flex justify-content-between">
              <span>{selectedUsers.length == 1 ?(<span>{selectedUsers.length} Row Selected</span>):(<span>{selectedUsers.length} Rows Selected</span>)} </span> 
              <Button variant="danger" className="font-size13 me-4 dlt-icon-bg" onClick={handleDeleteAll}>
                Delete
                </Button>
                </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveTeamUsersModal;
