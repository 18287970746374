import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import apiClient from "../services/ApiClient";

import { changeTabTitle } from "../utils/changeTabTitle";

const TeamsUsers = () => {
  const [users, setUsers] = useState([]);
  const params = useParams();

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`user/${params.teamId}/team`);
      setUsers(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Change TabTitle
  changeTabTitle("Teams-Users");

  return (
    <div className='row'>
      <div className={`col-md-12`}>
        <div className='card'>
          <div className='card-header py-3 d-flex justify-content-between align-items-center'>
            <h6 className='mb-0'>Users</h6>
          </div>
          <div className='card-body'>
            {/* <form onSubmit={handleEditFormSubmit}> */}
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>S.No</th>
                    <th scope='col'>Name</th>
                    <th scope='col'>Email</th>
                    <th scope='col'>Role</th>

                    <th scope='col' className='text-center'>
                      Charts
                    </th>
                    {/* <th scope='col'>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((user, index) => (
                      <tr key={user.id}>
                        <td>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>

                        <td>{user.role.charAt(0).toUpperCase() + user.role.slice(1).split("_").join(" ")}</td>

                        <td className='text-center'>
                          {user.role === "user" ? (
                            <Link to={`/charts/${user.id}`} className='i-link'>
                              <i className='fas fa-file-alt'></i>
                            </Link>
                          ) : (
                            <span className='text-muted'>NA</span>
                          )}
                        </td>

                        {/* <td className='text-nowrap'>
                        <OverlayTrigger placement='top' overlay={<Tooltip>Edit User</Tooltip>}>
                          <button
                            type='button'
                            className='btn btn-sm i-secondary shadow-none ml-2'
                            //   onClick={(event) => handleEditClick(event, user)}
                          >
                            <i className='fa fa-edit' aria-hidden='true' />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger placement='top' overlay={<Tooltip>Delete User</Tooltip>}>
                          <button
                            type='button'
                            className='btn btn-sm i-primary font-15 shadow-none ml-2'
                            //   onClick={() => {
                            //     deleteModalFunc(user.id);
                            //   }}
                          >
                            <i className='fa fa-trash' aria-hidden='true' />
                          </button>
                        </OverlayTrigger>
                      </td> */}
                      </tr>
                    ))}
                  {!users && (
                    <tr className='text-center font-size13'>
                      <td colSpan={10}>No data available at this moment</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsUsers;
