import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import apiClient from "../services/ApiClient";
import { useAuth } from "../context/AuthContext";
import { changeTabTitle } from "../utils/changeTabTitle";
import AuditsTable from "../components/audits/AuditsTable";
import SummaryAuditNavLink from "../components/dashboard/SummaryAuditNavLink";
import AuditsTableForClientR from "../components/auditsForClientR/AuditsTableForClientR";

const AuditorCharts = () => {
  const [audits, setAudits] = useState([]);

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [dataCount, setDataCount] = useState("");
  const [isShowPaginate, setIsShowPaginate] = useState(false);

  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");

  const [coderInfo, setCoderInfo] = useState({});
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [selectTeamID, setSelectTeamID] = useState("all");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [projectId, setprojectId] = useState("");

  // Get ID from URL
  const params = useParams();
  const auth = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // console.log(params);

  const viewType = searchParams.get("viewBy");

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // useEffect(async () => {
  //   if (params.timeLine !== "filter") {
  //     let endPoint;
  //     if (params.userLogId) {
  //       endPoint = `audit?timeline=${params.timeLine}&user_log_id=${params.userLogId}&page=${offset}`;
  //     } else {
  //       let tempVar = viewType === "team" ? "team_id" : "user_id";
  //       endPoint = `audit?timeline=${params.timeLine}&page=${offset}&${tempVar}=${params.id}`;
  //     }
  //     try {
  //       const response = await apiClient.get(`${endPoint}`);

  //       setAudits((prevValue) => response.data.data);
  //       setPageCount(response.data.last_page);
  //       setDataCount(response.data.total);
  //       let showPaginate = response.data.total / response.data.per_page > 1;
  //       setIsShowPaginate(showPaginate);
  //       setDataFrom(response.data.from);
  //       setDataEnd(response.data.to);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }, [offset]);

  useEffect(() => {
    if (params.timeLine === "filter") {
      filterHandler();
    }
  }, [offset]);

  // Date wise Filter Function
  const filterHandler = async () => {
    const startedDate = startDate.toLocaleDateString("en-US");
    // let tempEndDate = endDate.getDate() + 1;
    const endedDate = endTime
      ? endDate.toLocaleDateString("en-US")
      : endDate.toLocaleDateString("en-US");
    // const endedDate = endTime ? endDate.toLocaleDateString("en-US") : new Date(endDate.getTime() + 86400000).toLocaleDateString("en-US");
    const startedTime = startTime
      ? startTime.toLocaleTimeString()
      : "12:00:00 AM";
    const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";

    const tempId =
      params.id && params.userLogId
        ? `user_log_id=${params.userLogId}`
        : params.id && viewType == "team"
        ? `team_id=${params.id}`
        : params.id && viewType == "user" && `user_id=${params.id}`;

    const endPoint =
      viewType === "user"
        ? `/filter/auditor-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`
        : viewType === "team" &&
          `/filter/auditor-chart?timeline=custom&start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&${tempId}&page=${offset}`;
    try {
      const response = await apiClient.get(`${endPoint}`);
      setAudits(response.data.data);
      setPageCount(response.data.meta.last_page);
      setDataCount(response.data.meta.total);
      let showPaginate =
        response.data.meta.total / response.data.meta.per_page > 1;
      setIsShowPaginate(showPaginate);
      setDataFrom(response.data.meta.from);
      setDataEnd(response.data.meta.to);
    } catch (err) {
      console.log(err);
    }
  };

  // Date wise Filter Reset Function
  const filterReset = () => {
    setAudits([]);
    setDateRange([null, null]);
    setStartTime();
    setEndTime();
    setPageCount("");
    setDataCount("");
    setIsShowPaginate(false);
    setDataFrom("");
    setDataEnd("");
  };

  const fetchData = async () => {
    if (params.timeLine === "filter") {
      setAudits([]);
      setPageCount("");
      setDataCount("");
      setIsShowPaginate(false);
      setDataFrom("");
      setDataEnd("");
    } else {
      let endPoint;
      if (params.userLogId) {
        endPoint = `audit?timeline=${params.timeLine}&user_log_id=${params.userLogId}&page=${offset}`;
      } else {
        let tempVar = viewType === "team" ? "team_id" : "user_id";
        endPoint = `audit?timeline=${params.timeLine}&${tempVar}=${params.id}&page=${offset}`;
      }
      try {
        const response = await apiClient.get(`${endPoint}`);
        // if (!params.userLogId) {
        //   const teamsAndProjects = await apiClient.get(`user/team/list/${params.id}`);
        //   setTeamsInfo(teamsAndProjects.data.data);
        // }
        setAudits(response.data.data);
        setPageCount(response.data.meta.last_page);
        setprojectId(response.data.project_id);
        setDataCount(response.data.meta.total);
        let showPaginate =
          response.data.meta.total / response.data.meta.per_page > 1;
        setIsShowPaginate(showPaginate);

        setDataFrom(response.data.meta.from);
        setDataEnd(response.data.meta.to);
        setCoderInfo({
          coderName: response.data.data[0].auditor_name,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // setSelectTeamID("all");
    setOffset(1);
  }, [`${params.timeLine} ${offset}`]);

  useEffect(() => {
    setOffset(1);
  }, [`${params.timeLine}`]);

  const filterByTeam = async (teamId) => {
    setAudits([]);
    setSelectTeamID(teamId);
    setOffset(1);
    if (teamId === "all") {
      // console.log(teamId);
      const response = await apiClient.get(
        `audit/${viewType}/${params.id}?timeline=${params.timeLine}`
      );
      setAudits(response.data.data);
      setPageCount(response.data.last_page);
      setprojectId(response.data.project_id);
      let showPaginate = response.data.total / response.data.per_page > 1;
      setDataCount(response.data.total);
      setIsShowPaginate(showPaginate);
      setDataFrom(response.data.from);
      setDataEnd(response.data.to);
    } else {
      const response = await apiClient.get(
        `filter/team/chart/${params.id}/${teamId}`
      );
      setAudits(response.data.data);
      setPageCount(response.data.last_page);
      setDataCount(response.data.total);
      let showPaginate = response.data.total / response.data.per_page > 1;

      setIsShowPaginate(showPaginate);
      setDataFrom(response.data.from);
      setDataEnd(response.data.to);
    }
    // console.log(id);
  };
  // console.log(isShowPaginate);

  const exportHandler = async () => {
    let tempId;
    if (params.timeLine === "filter") {
      const startedDate = startDate.toLocaleDateString("en-US");
      // let tempEndDate = endDate.getDate() + 1;
      const endedDate = endTime
        ? endDate.toLocaleDateString("en-US")
        : endDate.toLocaleDateString("en-US");
      const startedTime = startTime
        ? startTime.toLocaleTimeString()
        : "12:00:00 AM";
      const endedTime = endTime ? endTime.toLocaleTimeString() : "11:59:59 PM";
      // endTime ? setEndTime(endTime) : setEndTime("09:00:00 AM");
      tempId =
        params.id && params.userLogId
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&team_id=${params.id}`
          : params.id &&
            viewType == "user" &&
            `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_id=${params.id}`;
    } else {
      tempId =
        params.id && params.userLogId
          ? `user_log_id=${params.userLogId}`
          : params.id && viewType == "team"
          ? `team_id=${params.id}`
          : params.id && viewType == "user" && `user_id=${params.id}`;
    }
    const { data } = await apiClient.get(
      `export/auditor-chart?timeline=${
        params.timeLine !== "filter" ? params.timeLine : "custom"
      }&${tempId}`
    );
    window.open(data, "_blank");
  };

  // Change TabTitle
  changeTabTitle("Charts");

  return (
    <>
      <SummaryAuditNavLink />
      {params.timeLine === "filter" && (
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-end">
              <div style={{ width: "220px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={Date.now()}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Date Range"
                />
              </div>
              <div style={{ width: "110px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selected={startTime}
                  onChange={(time) => setStartTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Start Time"
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="Start Time"
                />
              </div>

              <div style={{ width: "110px" }} className="me-2">
                <DatePicker
                  className="form-control"
                  selected={endTime}
                  onChange={(time) => setEndTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="End Time"
                  dateFormat="h:mm aa"
                  isClearable
                  placeholderText="End Time"
                />
              </div>

              <button
                onClick={filterHandler}
                className="btn btn-sm filter-ui-bg me-2 shadow-none text-light font-size13 py-2 px-4"
              >
                Filter
              </button>
              <button
                onClick={filterReset}
                className="btn btn-sm btn-secondary me-2 shadow-none text-light font-size13 py-2 px-4"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          {audits && projectId == process.env.REACT_APP_R_PROJECT_ID ? (
            <AuditsTableForClientR
              exportHandler={exportHandler}
              audits={audits}
              viewType={viewType}
              coderInfo={coderInfo}
              teamsInfo={teamsInfo}
              filterByTeam={filterByTeam}
              selectTeamID={selectTeamID}
              timeLine={params.timeLine}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              dataCount={dataCount}
              setPageCount={setPageCount}
              setDataCount={setDataCount}
              dataFrom={dataFrom}
              dataEnd={dataEnd}
              isShowPaginate={isShowPaginate}
              setIsShowPaginate={setIsShowPaginate}
              setDataEnd={setDataEnd}
              setDataFrom={setDataFrom}
              projectId={projectId}
              setprojectId={setprojectId}
            />
          ) : (
            <AuditsTable
              exportHandler={exportHandler}
              audits={audits}
              viewType={viewType}
              coderInfo={coderInfo}
              teamsInfo={teamsInfo}
              filterByTeam={filterByTeam}
              selectTeamID={selectTeamID}
              timeLine={params.timeLine}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              dataCount={dataCount}
              setPageCount={setPageCount}
              setDataCount={setDataCount}
              dataFrom={dataFrom}
              dataEnd={dataEnd}
              isShowPaginate={isShowPaginate}
              setIsShowPaginate={setIsShowPaginate}
              setDataEnd={setDataEnd}
              setDataFrom={setDataFrom}
              projectId={projectId}
              setprojectId={setprojectId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AuditorCharts;
