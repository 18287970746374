import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DatePicker from "react-datepicker";

import Tooltip from "react-bootstrap/Tooltip";
// import { useFormik } from "formik";
// import * as yup from "yup";
import { useAuth } from "../../context/AuthContext";

const EditOnlyRow = ({
  index,
  editFormData,
  handleCancel,
  handleEditFormChange,
  editFormErrors,
  setEditFormData,
  dataFrom,
  isLoading,
}) => {
  const auth = useAuth();

  const coderProjectID = auth.user.project_id;

  return (
    <tr key={index} className="font-size13 font-weight-light">
      {dataFrom ? (
        <td className="text-center">{dataFrom + index}</td>
      ) : (
        <td className="text-center">{index + 1}</td>
      )}
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter a Chase ID"
          name="chart_id"
          className="form-control font-size13"
          value={editFormData.chart_id}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.chart_id && (
          <div className="invalid-feedback d-block">
            {editFormErrors.chart_id}
          </div>
        )}
      </td>

      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter Member Name"
          name="member_name"
          className="form-control font-size13"
          value={editFormData.member_name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.member_name && (
          <div id="member_name" className="invalid-feedback d-block">
            {editFormErrors.member_name}
          </div>
        )}
      </td>

      <td>
        {/* <input
          type='date'
          required='required'
          placeholder='Enter D.O.B'
          name='dob'
          className='form-control'
          value={editFormData.dob}
          onChange={handleEditFormChange}
        ></input> */}

        <DatePicker
          className="form-control font-size13"
          dateFormat="MM/dd/yyyy"
          selected={Date.parse(editFormData.dob)}
          maxDate={new Date()}
          name="dob"
          onChange={(date) => setEditFormData({ ...editFormData, dob: date })}
        />
        {editFormErrors && editFormErrors.dob && (
          <div id="dob" className="invalid-feedback d-block">
            {editFormErrors.dob}
          </div>
        )}
      </td>
      <td className="text-center">
        <input
          type="text"
          required="required"
          placeholder="Enter HCC"
          name="icd"
          className="form-control text-center  font-size13"
          value={editFormData.icd}
          onChange={handleEditFormChange}
          style={{ width: "70px", margin: "auto" }}
        ></input>
        {editFormErrors && editFormErrors.icd && (
          <div id="icd" className="invalid-feedback d-block">
            {editFormErrors.icd}
          </div>
        )}
      </td>

      <td className="text-center">
        <input
          type="text"
          required="required"
          placeholder="Enter no of pages"
          name="pages"
          className="form-control text-center font-size13"
          value={editFormData.pages}
          onChange={handleEditFormChange}
          style={{ width: "70px", margin: "auto" }}
        ></input>
        {editFormErrors && editFormErrors.pages && (
          <div id="pages" className="invalid-feedback d-block">
            {editFormErrors.pages}
          </div>
        )}
      </td>
      <td className="text-center">
        {editFormData.sub_project === "Recode 2.0" && (
          <>
            <textarea
              name="comments"
              className={`form-control font-size13 required`}
              id="comments"
              value={editFormData.comments}
              onChange={handleEditFormChange}
              rows={1}
            />
            {editFormErrors && editFormErrors.comments && (
              <div id="comments" className="invalid-feedback d-block">
                {editFormErrors.comments}
              </div>
            )}
          </>
        )}
      </td>

      {/* <td>{editFormData.coding_date}</td> */}
      <td>{new Date(editFormData.coding_date).toDateString().slice(4)}</td>
      {/* <td></td> */}
      <td>{editFormData.project_name}</td>
      <td>{editFormData.sub_project}</td>
      <td>{editFormData.coder_login_name}</td>

      <td className="text-nowrap">
        {/* <OverlayTrigger placement="top" overlay={<Tooltip>Save Chart</Tooltip>}>
          <button
            type="submit"
            className="btn btn-sm i-success shadow-none ml-2"
          >
            <i className="fas fa-arrow-alt-circle-right" aria-hidden="true" />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Cancel Edit</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-sm i-primary font-15 shadow-none ml-2"
            onClick={handleCancel}
          >
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </OverlayTrigger> */}
        {isLoading ? (
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Save Chart</Tooltip>}
            >
              <button
                type="submit"
                className="btn btn-sm i-success shadow-none ml-2"
              >
                <i
                  className="fas fa-arrow-alt-circle-right"
                  aria-hidden="true"
                />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Cancel Edit</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm i-primary font-15 shadow-none ml-2"
                onClick={handleCancel}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </>
        )}
      </td>
    </tr>
  );
};

export default EditOnlyRow;
