import { useState } from "react";

import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import apiClient from "../../services/ApiClient";
import { useAuth } from "../../context/AuthContext";
import { ucFirst } from "../../utils/UcFirst";
import { useParams } from "react-router";
import ReactPaginate from "react-paginate";
// import ErrorFeedback from "../ErrorFeedback";
import AuditsTableForClientR from "../auditsForClientR/AuditsTableForClientR";

const AuditsTable = ({
  audits,
  setAudits,
  fetchData,
  coderInfo,
  viewType,
  teamsInfo,
  filterByTeam,
  selectTeamID,
  pageCount,
  handlePageClick,
  dataCount,
  setPageCount,
  setDataCount,
  timeLine,
  dataFrom,
  dataEnd,
  setDataFrom,
  setDataEnd,
  isShowPaginate,
  setIsShowPaginate,
  exportHandler,
  projectId,
  setprojectId,
}) => {
  // console.log(dataEnd);
  const [editId, setEditId] = useState(null);

  const [editFormData, setEditFormData] = useState(null);

  const [editFormErrors, setEditFormErrors] = useState(null);

  const auth = useAuth();

  const params = useParams();

  console.log(dataCount + "sdf");

  const auditorProjectId = auth.user.project_id || projectId;

  const isLHPProject =
    Number(auditorProjectId) ===
    Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  const handleEditClick = (event, audit) => {
    event.preventDefault();
    setEditId(audit.id);

    const formValues = {
      ...audit,
    };

    setEditFormData(formValues);
    setEditFormErrors(null);
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };

    // // claculation Formula starts
    // let admin_error = Number(editFormData.dos_corrected) + Number(editFormData.rendering_provider_corrected);
    // newFormData.admin_error = admin_error;

    // let dx_codes_error = Number(editFormData.codes_added) + Number(editFormData.dx_codes_updated);
    // newFormData.dx_codes_error = dx_codes_error;

    // let totalAbsoluteErrors = Number(editFormData.dx_codes_error) + Number(editFormData.admin_error);
    // newFormData.dx_codes_error = totalAbsoluteErrors;

    // let dividedValue = totalAbsoluteErrors / Number(editFormData.original_code_found);
    // let chart_qa_score = 100 - dividedValue * 100;
    // newFormData.chart_qa_score = chart_qa_score;

    // let value1 = Number(editFormData.original_code_found) - Number(editFormData.codes_deleted) - Number(editFormData.dx_codes_updated);
    // let value2 = Number(editFormData.original_code_found) + Number(editFormData.codes_added);
    // let dx_qa_score = value1 / value2;
    // newFormData.dx_qa_score = dx_qa_score;

    // // claculation Formula Ends
    newFormData[fieldName] = fieldValue;

    if (isLHPProject) {
      delete newFormData.audit_type;
      delete newFormData.chart_qa_score;
      delete newFormData.original_code_found;
      delete newFormData.total_errors;
      delete newFormData.dx_codes_error;
      delete newFormData.dx_qa_score;
      delete newFormData.admin_error;
      delete newFormData.codes_added;
      delete newFormData.codes_deleted;
      delete newFormData.dx_codes_updated;
      delete newFormData.dos_corrected;
      delete newFormData.pos_corrected;
      delete newFormData.dx_level_comment_code_corrected;
      delete newFormData.rendering_provider_corrected;
    } else {
      delete newFormData.cpt_codes;
      delete newFormData.cpt_errors;
      delete newFormData.cpt_qa_score;
      delete newFormData.icd_codes;
      delete newFormData.icd_errors;
      delete newFormData.icd_qa_score;
    }

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    const editedChart = {
      ...editFormData,
    };

    try {
      const response = await apiClient.post(
        `audit/edit/${editId}`,
        editedChart
      );
      setEditId(null);
      fetchData();
    } catch (err) {
      setEditFormErrors(err.response.data.error);
      // console.log(err);
    }
  };

  // const exportHandler = async () => {
  //   let tempId;
  //   if (timeLine === "filter") {
  //     const startedDate = startDate.toLocaleDateString();
  //     // let tempEndDate = endDate.getDate() + 1;
  //     const endedDate = endTime ? endDate.toLocaleDateString() : new Date(endDate.getTime() + 86400000).toLocaleDateString();
  //     const startedTime = startTime ? startTime.toLocaleTimeString() : "09:00:00 AM";
  //     const endedTime = endTime ? endTime.toLocaleTimeString() : "09:00:00 AM";
  //     // endTime ? setEndTime(endTime) : setEndTime("09:00:00 AM");
  //     tempId =
  //       params.id && params.userLogId
  //         ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_log_id=${params.id}`
  //         : params.id && viewType == "team"
  //         ? `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&team_id=${params.id}`
  //         : params.id &&
  //           viewType == "user" &&
  //           `start_time=${startedDate},${startedTime}&end_time=${endedDate},${endedTime}&user_id=${params.id}`;
  //   } else {
  //     tempId =
  //       params.id && params.userLogId
  //         ? `user_log_id=${params.id}`
  //         : params.id && viewType == "team"
  //         ? `team_id=${params.id}`
  //         : params.id && viewType == "user" && `user_id=${params.id}`;
  //   }
  //   const { data } = await apiClient.get(`export/auditor-chart?timeline=${params.timeLine}&${tempId}`);
  //   window.open(data, "_blank");
  // };

  // console.log("logId" + params.userLogId);
  return (
    <>
      {auth.user.project_id == process.env.REACT_APP_R_PROJECT_ID ? (
        <>
          <AuditsTableForClientR
            audits={audits}
            setAudits={setAudits}
            fetchData={fetchData}
            coderInfo={coderInfo}
            viewType={viewType}
            teamsInfo={teamsInfo}
            filterByTeam={filterByTeam}
            selectTeamID={selectTeamID}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            dataCount={dataCount}
            setPageCount={setPageCount}
            setDataCount={setDataCount}
            timeLine={timeLine}
            dataFrom={dataFrom}
            dataEnd={dataEnd}
            setDataFrom={setDataFrom}
            setDataEnd={setDataEnd}
            isShowPaginate={isShowPaginate}
            setIsShowPaginate={setIsShowPaginate}
            exportHandler={exportHandler}
            projectId={projectId}
            setprojectId={setprojectId}
          />
        </>
      ) : (
        <div className="card">
          <div className="card-header pb-0 border-0 pt-3 d-flex justify-content-between align-items-center">
            <h6 className="mb-0 font-size13">
              <i className="fas fa-file-alt me-2 "></i>
              Audits
              {dataCount ? (
                <span className="badge bg-success ms-1  rounded-pill">
                  {dataCount}
                </span>
              ) : null}
              {/* {auth && auth.user.role === "auditor" && audits.length ? (
    <span className='badge bg-success ms-1  rounded-pill'>{audits.length}</span>
  ) : null} */}
            </h6>

            {auth &&
              auth.user.role !== "auditor" &&
              coderInfo.coderName &&
              viewType === "user" && (
                <p
                  className="mb-0 d-flex align-items-center font-size13"
                  // style={{ color: "#5ab9c9" }}
                >
                  <span className="text-nowrap">
                    <span className="text-light">Auditor Name:</span>{" "}
                    {ucFirst(`${coderInfo.coderName}`)}
                  </span>
                  {/* {params.userLogId !== undefined && timeLine === "all" && (
        <select
          name="team_projects"
          id="team_projects_select"
          className="form-control ms-3 form-select"
          value={selectTeamID}
          onChange={(e) => {
            filterByTeam(e.target.value);
          }}
        >
          <option value="all">All Teams</option>
          {teamsInfo.map((item) => (
            <option value={`${item.id}`} key={item.id}>
              HSTM {item.id} - {item.project.project_name}
            </option>
          ))}
        </select>
      )} */}
                </p>
              )}
            {params.id && (
              <button
                onClick={exportHandler}
                className="btn btn-sm btn-info shadow-none ms-2 font-size13"
              >
                Export
              </button>
            )}

            {/* {auth && auth.user.role === "lead" && (
  <button onClick={exportHandler} className='btn btn-sm btn-info shadow-none ms-2'>
    Export
  </button>
)} */}
          </div>
          <div className="card-body px-0">
            <div className="table-responsive" style={{ overflowX: "auto" }}>
              <form onSubmit={handleEditFormSubmit}>
                <table className="table ">
                  <thead className="font-size13 text-nowrap">
                    <tr>
                      {auth && auth.user.role === "auditor" && (
                        <th scope="col"></th>
                      )}
                      {auth && auth.user.role !== "auditor" && (
                        <th scope="col">Log</th>
                      )}
                      <th scope="col">S.No</th>
                      {auth && auth.user.role !== "auditor" && (
                        <th scope="col">Team ID</th>
                      )}
                      {auth && auth.user.role !== "auditor" && (
                        <th scope="col">Project Name</th>
                      )}
                      {isLHPProject ? (
                        <th scope="col">Encounter Number</th>
                      ) : (
                        <th scope="col">Chart ID </th>
                      )}
                      <th scope="col" className="text-wrap">
                        Coder Name
                      </th>
                      <th scope="col" className="text-wrap">
                        Coder Login Name
                      </th>
                      <th scope="col" className="text-wrap">
                        Coding Complete Date
                      </th>
                      <th scope="col" className="text-wrap">
                        Audit Complete Date
                      </th>
                      {auth &&
                        auth.user.role !== "auditor" &&
                        viewType === "team" && (
                          <th scope="col">Auditor Name</th>
                        )}
                      {auth &&
                        auth.user.role !== "auditor" &&
                        viewType === "team" && (
                          <th scope="col">Auditor Login Name</th>
                        )}
                      <th scope="col" className="text-wrap">
                        Total Pages
                      </th>
                      <th scope="col" className="text-wrap">
                        {isLHPProject ? "Error Comment" : "Auditor Comment"}
                      </th>
                      {isLHPProject ? null : (
                        <th scope="col" className="text-wrap">
                          Audit Type
                        </th>
                      )}
                      {isLHPProject ? null : (
                        <th scope="col" className="text-wrap">
                          Chart Level QAScore
                        </th>
                      )}
                      {isLHPProject ? null : (
                        <th scope="col" className="text-wrap">
                          DX Level QAScore
                        </th>
                      )}
                      {isLHPProject && (
                        <>
                          <th scope="col" className="text-nowrap">
                            No of CPT errors
                          </th>
                          <th scope="col" className="text-nowrap">
                            No of ICD errors
                          </th>
                          <th scope="col" className="text-nowrap">
                            Total CPT codes
                          </th>
                          <th scope="col" className="text-nowrap">
                            Total ICD codes
                          </th>
                          <th scope="col" className="text-nowrap">
                            CPT QA score
                          </th>
                          <th scope="col" className="text-nowrap">
                            ICD QA score
                          </th>
                        </>
                      )}
                      {isLHPProject ? null : (
                        <>
                          <th scope="col" className="text-wrap">
                            Original Codes Found
                          </th>
                          <th scope="col" className="text-wrap">
                            Total Absolute Errors
                          </th>
                          <th scope="col" className="text-wrap">
                            No of DX codes Error
                          </th>
                          <th scope="col" className="text-wrap">
                            No of Admin errors
                          </th>
                          <th scope="col" className="text-wrap">
                            Codes Added
                          </th>
                          <th scope="col" className="text-wrap">
                            Codes Deleted
                          </th>
                          <th scope="col" className="text-wrap">
                            DX Codes Updated
                          </th>
                          <th scope="col" className="text-wrap">
                            DOS Corrected
                          </th>
                          <th scope="col" className="text-wrap">
                            POS Corrected
                          </th>
                          <th scope="col" className="text-wrap">
                            DX Level Comment Code Corrected
                          </th>
                          <th scope="col" className="text-wrap">
                            Rendering Provider Corrected
                          </th>
                        </>
                      )}
                      <th scope="col" className="text-nowrap">
                        Coding Date
                      </th>
                      {auth && auth.user.role !== "auditor" && (
                        <>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="font-size13">
                    {audits &&
                      audits.map((audit, index) => (
                        <>
                          {editId === audit.id ? (
                            <EditOnlyRow
                              key={index}
                              editFormData={editFormData}
                              setEditFormData={setEditFormData}
                              index={index}
                              handleEditFormChange={handleEditFormChange}
                              handleCancel={handleCancel}
                              editFormErrors={editFormErrors}
                              dataFrom={dataFrom}
                            />
                          ) : (
                            <ReadOnlyRow
                              key={index}
                              audit={audit}
                              index={index}
                              handleEditClick={handleEditClick}
                              viewType={viewType}
                              dataFrom={dataFrom}
                              projectId={projectId}
                            />
                          )}
                        </>
                      ))}

                    {!audits.length && (
                      <tr className="text-center font-size13">
                        <td colSpan={25}>No data available at this moment</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
            {isShowPaginate && (
              <div className="d-flex justify-content-between align-items-center flex-row-reverse px-3">
                <div>
                  <ReactPaginate
                    key={timeLine}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
                {dataFrom && dataEnd && (
                  <span className="ms-2 showing font-size13 grey-clr">
                    <i class="fas fa-book-open me-1"></i>
                    Showing {dataFrom} - {dataEnd} of {dataCount} entries
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <>
        {/* {auth && auth.user.role === "auditor" && (
            <>
              <AddAudits fetchData={fetchData} />
            </>
          )} */}
      </>
    </>
  );
};

export default AuditsTable;
