import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../context/AuthContext";

import apiClient from "../../services/ApiClient";
import { useMutation } from "react-query";

const AddUser = ({ refetch }) => {
  const mutation = useMutation(
    (values) =>
      apiClient.post("user/store", values, { componentName: "addUser" }),
    {
      onSuccess: () => {
        refetch();
        formik.resetForm();
      },
      onError: (err) => {
        // Handle errors
        const { response } = err;
        if (response) {
          const { data } = response;
          formik.setErrors({
            name: data.error.name,
            email: data.error.email,
            employee_id: data.error.employee_id,
            role: data.error.role,
          });
        }
      },
    }
  );

  const auth = useAuth();
  const formik = useFormik({
    initialValues: {
      name: "",
      // login_name: "",
      email: "",
      employee_id: "",
      role: "",
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .min(3, "Too Short!")
        .max(25, "Must be 25 characters or less")
        .required("Name is required"),
      // login_name: yup.string().min(3, "Too Short!").max(25, "Must be 25 characters or less").required("Login Name is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      employee_id: yup
        .number()
        .typeError("Must be a number")
        .required("Employee ID is required"),
      role: yup.string().required("Role is required"),
    }),
    onSubmit: async (values) => {
      await mutation.mutateAsync(values);
    },
  });
  return (
    <>
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div class="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="name" className="form-label required">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className={`form-control font-size13  ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  id="name"
                  // placeholder='Enter Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div id="name" className="invalid-feedback">
                    {formik.errors.name}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              {/* <div className='col-lg px-1'>
                <label htmlFor='login_name' className='form-label required'>
                  Login Name
                </label>
                <input
                  type='text'
                  name='login_name'
                  className={`form-control ${formik.touched.login_name && formik.errors.login_name && "is-invalid"}`}
                  id='login_name'
                  // placeholder='Enter login_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.login_name}
                />
                {formik.touched.login_name && formik.errors.login_name ? (
                  <div id='login_name' className='invalid-feedback'>
                    {formik.errors.login_name}
                  </div>
                ) : (
                  <div className='invisible'>
                    <span>invisible</span>
                  </div>
                )}
              </div> */}
              <div className="col-lg-3 px-1">
                <label htmlFor="email" className="form-label required">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className={`form-control font-size13  ${
                    formik.touched.email && formik.errors.email && "is-invalid"
                  }`}
                  id="email"
                  // placeholder='Enter Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div id="email" className="invalid-feedback">
                    {formik.errors.email}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="employee_id" className="form-label required">
                  Employee ID
                </label>
                <input
                  type="text"
                  name="employee_id"
                  className={`form-control font-size13 ${
                    formik.touched.employee_id &&
                    formik.errors.employee_id &&
                    "is-invalid"
                  }`}
                  id="employee_id"
                  // placeholder='Enter employee_id'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employee_id}
                />
                {formik.touched.employee_id && formik.errors.employee_id ? (
                  <div id="employee_id" className="invalid-feedback">
                    {formik.errors.employee_id}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="role" className="form-label required">
                  Select Role
                </label>
                <select
                  name="role"
                  className={`form-select form-control font-size13 ${
                    formik.touched.role && formik.errors.role && "is-invalid"
                  }`}
                  id="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {auth &&
                    auth.user.role &&
                    auth.user.role === "super_admin" && (
                      <option value="super_admin">Super Admin</option>
                    )}
                  <option value="manager">Manager</option>
                  <option value="lead">Lead</option>
                  <option value="auditor">Auditor</option>
                  <option value="user">Coder</option>
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.role}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div class="col-lg-1 w-auto px-1 mt-4 pt-2 ms-2">
                {mutation.isLoading ? (
                  <button
                    class="btn custom-btn-primary font-size13 py-1 px-4"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn custom-btn-primary font-size13 py-1 px-4"
                  >
                    Add User
                  </button>
                )}
                <div class="invisible">
                  <span>invisible</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUser;
