import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import apiClient from "../services/ApiClient";
import { useAuth } from "../context/AuthContext";
import { changeTabTitle } from "../utils/changeTabTitle";
import ChartsTable from "../components/charts/ChartsTable";
import ChartsTableForClientR from "../components/charts/ChartsTableForClientR";

const Charts = () => {
  const [charts, setCharts] = useState([]);
  const [coderInfo, setCoderInfo] = useState({});
  // const [teamsInfo, setTeamsInfo] = useState([]);
  // const [selectTeamID, setSelectTeamID] = useState("all");

  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [isShowPaginate, setIsShowPaginate] = useState(false);
  const [projectId, setprojectId] = useState("");

  // Get ID from URL
  const params = useParams();

  const [searchParams] = useSearchParams();

  const viewType = searchParams.get("viewBy");

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // useEffect(async () => {
  //   if (params.timeLine !== "filter") {
  //     let endPoint;
  //     if (params.userLogId) {
  //       endPoint = `user-chart?timeline=${params.timeLine}&user_log_id=${params.userLogId}&page=${offset}`;
  //     } else {
  //       let tempVar = viewType === "team" ? "team_id" : "user_id";
  //       endPoint = `user-chart?timeline=${params.timeLine}&page=${offset}&${tempVar}=${params.id}`;
  //     }

  //     try {
  //       const response = await apiClient.get(`${endPoint}`);

  //       setCharts((prevValue) => response.data.data);
  //       setPageCount(response.data.last_page);
  //       setDataCount(response.data.total);
  //       setprojectId(response.data.project_id);

  //       let showPaginate = response.data.total / response.data.per_page > 1;
  //       setIsShowPaginate(showPaginate);
  //       setDataFrom(response.data.from);
  //       setDataEnd(response.data.to);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }, [offset]);

  let endPoint;
  if (params.userLogId) {
    endPoint = `user-chart?timeline=${params.timeLine}&user_log_id=${params.userLogId}&page=${offset}`;
  } else {
    let tempVar = viewType === "team" ? "team_id" : "user_id";

    endPoint = `user-chart?timeline=${params.timeLine}&${tempVar}=${params.id}&page=${offset}`;
  }

  const fetchData = async () => {
    if (params.timeLine === "filter") {
      setCharts([]);
      setPageCount("");
      setDataCount("");
      setIsShowPaginate(false);
      setDataFrom("");
      setDataEnd("");
    } else {
      try {
        const response = await apiClient.get(`${endPoint}`);
        // if (!params.userLogId) {
        //   const teamsAndProjects = await apiClient.get(`user/team/list/${params.id}`);
        //   setTeamsInfo(teamsAndProjects.data.data);
        // }
        setCharts(response.data.data);
        setPageCount(response.data.meta.last_page);
        setDataCount(response.data.meta.total);
        setprojectId(response.data.project_id);
        console.log(response.data.project_id);

        let showPaginate =
          response.data.meta.total / response.data.meta.per_page > 1;
        setIsShowPaginate(showPaginate);
        setDataFrom(response.data.meta.from);
        setDataEnd(response.data.meta.to);

        setCoderInfo({
          coderName: response.data.data[0].coder_name,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // setSelectTeamID("all");
  }, [`${params.timeLine} ${offset}`]);

  useEffect(() => {
    setOffset(1);
  }, [`${params.timeLine}`]);

  // const filterByTeam = async (teamId) => {
  //   setSelectTeamID(teamId);
  //   if (teamId === "all") {
  //     const response = await apiClient.get(`user-chart/${viewType}/${params.id}?timeline=${params.timeLine}`);
  //     setCharts(response.data.data);
  //   } else {
  //     const response = await apiClient.get(`filter/team/chart/${params.id}/${teamId}`);
  //     setCharts(response.data.data);
  //   }
  //   // console.log(id);
  // };

  // Change TabTitle
  changeTabTitle("Charts");

  // console.log(projectId && projectId == process.env.REACT_APP_R_PROJECT_ID);

  if (!projectId) return null;

  return (
    <div className="row">
      <div className="col-md-12">
        {charts && projectId == process.env.REACT_APP_R_PROJECT_ID ? (
          <ChartsTableForClientR
            charts={charts}
            viewType={viewType}
            timeLine={params.timeLine}
            setCharts={setCharts}
            coderInfo={coderInfo}
            setCoderInfo={setCoderInfo}
            // teamsInfo={teamsInfo}
            // filterByTeam={filterByTeam}
            // selectTeamID={selectTeamID}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            dataCount={dataCount}
            setPageCount={setPageCount}
            setDataCount={setDataCount}
            dataFrom={dataFrom}
            dataEnd={dataEnd}
            isShowPaginate={isShowPaginate}
            setIsShowPaginate={setIsShowPaginate}
            setDataEnd={setDataEnd}
            setDataFrom={setDataFrom}
            offset={offset}
            projectId={projectId}
            setprojectId={setprojectId}
          />
        ) : (
          <ChartsTable
            charts={charts}
            viewType={viewType}
            timeLine={params.timeLine}
            setCharts={setCharts}
            coderInfo={coderInfo}
            setCoderInfo={setCoderInfo}
            // teamsInfo={teamsInfo}
            // filterByTeam={filterByTeam}
            // selectTeamID={selectTeamID}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            dataCount={dataCount}
            setPageCount={setPageCount}
            setDataCount={setDataCount}
            dataFrom={dataFrom}
            dataEnd={dataEnd}
            isShowPaginate={isShowPaginate}
            setIsShowPaginate={setIsShowPaginate}
            setDataEnd={setDataEnd}
            setDataFrom={setDataFrom}
            offset={offset}
            projectId={projectId}
            setprojectId={setprojectId}
          />
        )}
      </div>
    </div>
  );
};

export default Charts;
