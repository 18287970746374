import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import apiClient from "../services/ApiClient";

const AccessDenied = () => {
  const [timer, setTimer] = useState(new Date());
  const [startTime, setStartTime] = useState([]);
  const [counterTimer, setCounterTimer] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductionTime = async () => {
      try {
        const response = await apiClient.get(`get/production-time`);
        setStartTime(response.data.start_time.split(":"));
      } catch (err) {
        console.log(err);
      }
    };
    fetchProductionTime();
  }, []);

  useEffect(() => {
    let fixedTime = new Date();
    fixedTime.setHours(startTime[0]);
    fixedTime.setMinutes(startTime[1]);
    fixedTime.setSeconds(0);

    if (timer >= fixedTime) {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    var difference = fixedTime - timer;
    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    var minutesDifference = Math.floor((difference / 1000 / 60) % 60);
    var secondsDifference = Math.floor((difference / 1000) % 60);
    setCounterTimer({ hours: Math.abs(hoursDifference), minutes: Math.abs(minutesDifference), seconds: Math.abs(secondsDifference) });
    // console.log(`${hoursDifference} : ${minutesDifference} : ${secondsDifference}`);
  }, [timer]);

  useEffect(() => {
    let interval = setInterval(() => setTimer(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  //   const fetchDate = async () => {
  //     try {
  //       const response = await apiClient.get("/custom-date");

  //       setcustomDate(response.data.date);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const goBackHandler = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div className='vh-100 vw-100 d-flex justify-content-center align-items-end flex-column' id='accessDenied'>
      {/* <p className='fs-2 text-center'>
        We are facing difficulties in signing you. <br />
        Please contact IT support. <br />
        <a href='mailto:itsupport@secqureone.com' className='fs-5'>
          itsupport@secqureone.com
        </a>
      </p> */}
      {/* <div className='d-flex justify-content-center align-items-center mt-5'>
        <br />
        <br />
        <h3 className='text-light mt-5'>Production Time 7.00 AM - 11.59 PM</h3>
        
      </div> */}

      <div className='d-flex flex-column justify-content-center align-items-start w-50'>
        <h1 className='text-danger'>Access Denied</h1>
        <h3 className='text-light'>
          Production Time {startTime[0]} : {startTime[1]} AM - 11.59 PM
        </h3>
        <br />
        <br />
        <h2 className='text-warning'>
          <span className='h4 text-light'>You can resume after</span> <br /> {counterTimer.hours}{" "}
          {counterTimer.hours >= 2 ? "Hours" : "Hour"} : {counterTimer.minutes} {counterTimer.minutes >= 2 ? "Minutes" : "Minute"} :{" "}
          {counterTimer.seconds} {counterTimer.seconds >= 2 ? "Seconds" : "Second"}
        </h2>
      </div>

      {/* <button className='btn btn-success' onClick={goBackHandler}>
        Back to Login
      </button> */}
    </div>
  );
};

export default AccessDenied;
