import React from "react";
import { useNavigate } from "react-router";

const UserNotFound = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate("/login", { replace: true });
  };
  return (
    <div className='vh-100 vw-100 d-flex justify-content-center align-items-center flex-column' id='error_userNotFound'>
      <p className='fs-2 text-center'>
        We are facing difficulties in signing you. <br />
        Please contact IT support. <br />
        <a href='mailto:itsupport@secqureone.com' className='fs-5'>
          itsupport@secqureone.com
        </a>
      </p>
      <button className='btn btn-primary' onClick={goBackHandler}>
        Go Back
      </button>
    </div>
  );
};

export default UserNotFound;
