import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import apiClient from "../../services/ApiClient";
import { useMutation, useQuery } from "react-query";

import { useAuth } from "../../context/AuthContext";

const AddUserChartForClientR = ({ refetch }) => {
  const auth = useAuth();
  const coderProjectId = auth.user.project_id;
  // const [projects, setProjects] = useState("");

  // useEffect(() => {
  //   formik.setFieldValue("project_id", coderProjectId);

  //   const fetchProjects = async () => {
  //     try {
  //       const response = await apiClient.get(`user-chart/projects`);
  //       setProjects(response.data.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchProjects();
  // }, []);

  // const { fetchData } = props;

  const formik = useFormik({
    initialValues: {
      project_id: coderProjectId,
      chart_id: "",
      member_name: "",
      dob: "",
      icd: "",
      pages: "",
      sub_project: "",
      comments: "",
    },
    validationSchema: yup.object({
      chart_id: yup
        .number()
        .typeError("Must be a number")
        .required("Chart ID is required"),
      member_name: yup
        .string()
        .min(3, "Too Short!")
        .max(25, "Must be 25 characters or less")
        .required("Name is required"),
      dob: yup.date().typeError("Must be a date").required("D.O.B is required"),
      icd: yup
        .number()
        .typeError("Must be a number")
        .required("HCC Category Reviewed is required"),
      pages: yup
        .number()
        .typeError("Must be a number")
        .required("Pages is required"),
      comments: yup.string().when("sub_project", {
        is: "Recode 2.0",
        then: yup.string().required("Required for Recode 2.0"),
        otherwise: yup.string().notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      localStorage.setItem("project", values.project_id);
      // try {
      //   const response = await apiClient.post("user-chart/store", values);
      //   // console.log(response.data.data);
      //   fetchData();
      //   formik.resetForm();
      //   formik.setFieldValue("project_id", coderProjectId);
      // } catch (err) {
      //   formik.setErrors(err.response.data.error);
      // }
      await mutation.mutateAsync(values);
    },
  });

  const mutation = useMutation(
    (values) =>
      apiClient.post("user-chart/store", values, {
        componentName: "addUserChart",
      }),
    {
      onSuccess: () => {
        refetch();
        formik.resetForm();
        formik.setFieldValue("project_id", coderProjectId);
      },
      onError: (err) => {
        // Handle errors
        const { response } = err;
        if (response) {
          const { data } = response;
          formik.setErrors(data.response.data.error);
        }
      },
    }
  );

  async function checkChartidUnique() {
    try {
      const response = await apiClient.post(`/user-chart/validate/chart-id`, {
        chart_id: formik.values.chart_id,
        project_id: coderProjectId,
      });
    } catch (err) {
      formik.setFieldError("chart_id", err.response.data.error.chart_id);
    }
  }

  useEffect(() => {
    if (formik.values.chart_id.length >= 8) {
      checkChartidUnique();
    }
  }, [formik.values.chart_id]);

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="chart_id" className="form-label required">
                  Sub Project
                </label>

                <select
                  className={`form-select form-control font-size13 ${
                    formik.touched.sub_project &&
                    formik.errors.sub_project &&
                    "is-invalid"
                  }`}
                  id="selectProject"
                  name="sub_project"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sub_project}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  <option value="2023 BCBSM MA PY2023 Underwritten A">
                    2023 BCBSM MA PY2023 Underwritten A
                  </option>
                  <option value="2023 BCBSM MA PY2023 Underwritten B">
                    2023 BCBSM MA PY2023 Underwritten B
                  </option>
                  <option value="2023 BCBSM MA PY2023 CCDA Vendor A">
                    2023 BCBSM MA PY2023 CCDA Vendor A
                  </option>
                  <option value="2023 BCBSM MA PY2023 CCDA Vendor B">
                    2023 BCBSM MA PY2023 CCDA Vendor B
                  </option>
                  <option value="Recode 2.0">Recode 2.0</option>
                  <option value="2024 BCBSM MA PY2023 Underwritten">
                    2024 BCBSM MA PY2023 Underwritten
                  </option>
                  {/* <option value={project.id} key={project.id}>
                  {project.project_name} - {project.project_code}
                </option> */}
                </select>
                {formik.touched.sub_project && formik.errors.sub_project ? (
                  <div id="project_id" className="invalid-feedback">
                    {formik.errors.sub_project}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label htmlFor="chart_id" className="form-label required">
                  Chase ID
                </label>
                <input
                  type="text"
                  name="chart_id"
                  className={`form-control font-size13 ${
                    formik.touched.chart_id &&
                    formik.errors.chart_id &&
                    "is-invalid"
                  }`}
                  id="chart_id"
                  // placeholder='Chart ID'
                  maxLength={8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.chart_id}
                />
                {formik.touched.chart_id && formik.errors.chart_id ? (
                  <div id="chart_id" className="invalid-feedback">
                    {formik.errors.chart_id}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label htmlFor="member_name" className="form-label required">
                  Member Name
                </label>
                <input
                  type="text"
                  name="member_name"
                  className={`form-control font-size13 ${
                    formik.touched.member_name &&
                    formik.errors.member_name &&
                    "is-invalid"
                  }`}
                  id="member_name"
                  // placeholder='Member Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.member_name}
                />
                {formik.touched.member_name && formik.errors.member_name ? (
                  <div id="member_name" className="invalid-feedback">
                    {formik.errors.member_name}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label
                  htmlFor="dob"
                  className="form-label required font-size13"
                >
                  D.O.B <small>(MM/DD/YYYY)</small>
                </label>
                {/* <input
                type='date'
                // placeholder='D.O.B'
                name='dob'
                className={`form-control ${formik.touched.dob && formik.errors.dob && "is-invalid"}`}
                id='dob'
                // placeholder='D.O.B'
                onChange={formik.handleChange}
                // onFocus={(e) => (e.target.type = "date")}
                onBlur={formik.handleBlur}
                value={formik.values.dob}
              /> */}
                <DatePicker
                  className="form-control font-size13"
                  // dateFormat='dd/MM/yyyy'
                  selected={formik.values.dob}
                  maxDate={new Date()}
                  onChange={(value) => {
                    formik.setFieldValue("dob", value);
                  }}
                />
                {formik.touched.dob && formik.errors.dob ? (
                  <div id="dob" className="invalid-feedback">
                    {formik.errors.dob}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label htmlFor="icd" className="form-label required ">
                  Total HCC Category Reviewed
                </label>
                <input
                  type="text"
                  name="icd"
                  className={`form-control font-size13 ${
                    formik.touched.icd && formik.errors.icd && "is-invalid"
                  }`}
                  id="icd"
                  // placeholder='No of icd'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.icd}
                />
                {formik.touched.icd && formik.errors.icd ? (
                  <div id="icd" className="invalid-feedback">
                    {formik.errors.icd}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label htmlFor="pages" className="form-label required">
                  Total pages
                </label>
                <input
                  type="text"
                  name="pages"
                  className={`form-control font-size13 ${
                    formik.touched.pages && formik.errors.pages && "is-invalid"
                  }`}
                  id="pages"
                  // placeholder='No of pages'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pages}
                />
                {formik.touched.pages && formik.errors.pages ? (
                  <div id="pages" className="invalid-feedback">
                    {formik.errors.pages}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              {formik.values.sub_project === "Recode 2.0" && (
                <div className="col-lg px-1">
                  <label htmlFor="comments" className="form-label required">
                    Comments
                  </label>
                  {/* <input
                    type="text"
                    name="comments"
                    className={`form-control font-size13 required ${
                      formik.touched.comments &&
                      formik.errors.comments &&
                      "is-invalid"
                    }`}
                    id="comments"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                  /> */}

                  <textarea
                    name="comments"
                    className={`form-control font-size13 required ${
                      formik.touched.comments &&
                      formik.errors.comments &&
                      "is-invalid"
                    }`}
                    id="comments"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                    rows={1}
                  />

                  {formik.touched.comments && formik.errors.comments ? (
                    <div id="comments" className="invalid-feedback">
                      {formik.errors.comments}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="px-1 pt-2 text-end">
              {/* <button
              type="submit"
              className="btn btn-sm btn-success shadow-none ml-2 font-size13"
            >
              <i className="fas fa-plus" aria-hidden="true" /> ADD
            </button> */}
              {mutation.isLoading ? (
                <button
                  class="btn custom-btn-primary font-size13 py-1 px-4"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span role="status">Loading...</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn custom-btn-primary font-size13 py-1 px-4"
                >
                  Add Chart
                </button>
              )}
              <div className="invisible">
                <span>invisible</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUserChartForClientR;
