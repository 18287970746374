import { useFormik } from "formik";
import * as yup from "yup";

import apiClient from "../../services/ApiClient";
import { useMutation } from "react-query";

const AddClient = ({ refetch }) => {
  const mutation = useMutation((values) => apiClient.post("client/store", values, { componentName: "addClient" }), {
    onSuccess: () => {
      refetch();
      formik.resetForm();
    },
    onError: (err) => {
      // Handle errors
      const { response } = err;
      if (response) {
        const { data } = response;
        formik.setErrors({
          client_code: data.error.client_code,
          client_name: data.error.client_name,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      client_name: "",
      client_code: "",
    },
    validationSchema: yup.object({
      client_name: yup.string().max(15, "Must be 15 characters or less").required("This field is required"),
      client_code: yup.string().max(15, "Must be 15 characters or less").required("This field is required"),
    }),
    onSubmit: async (values) => {
      await mutation.mutateAsync(values);
    },
  });
  return (
    <>
      <div className="card">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div class="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg-3 px-1">
                <label htmlFor="clientName" className="form-label required">
                  Client Name
                </label>
                <input
                  type="text"
                  name="client_name"
                  className={`form-control  font-size13 ${formik.touched.client_name && formik.errors.client_name && "is-invalid"}`}
                  id="clientName"
                  placeholder="Enter Client Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.client_name}
                />
                {formik.touched.client_name && formik.errors.client_name ? (
                  <div id="clientName" className="invalid-feedback">
                    {formik.errors.client_name}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg-3 px-1">
                <label htmlFor="clientCode" className="form-label required">
                  Client Code
                </label>
                <input
                  type="text"
                  name="client_code"
                  className={`form-control   font-size13 ${formik.touched.client_code && formik.errors.client_code && "is-invalid"}`}
                  id="clientCode"
                  placeholder="Enter Client Code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.client_code}
                />
                {formik.touched.client_code && formik.errors.client_code ? (
                  <div id="clientName" className="invalid-feedback">
                    {formik.errors.client_code}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div class="col-lg-1 w-auto px-1 mt-4 pt-2 ms-2">
                {mutation.isLoading ? (
                  <button class="btn custom-btn-primary font-size13 py-1 px-4" type="button" disabled>
                    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                    <span role="status">Loading...</span>
                  </button>
                ) : (
                  <button type="submit" className="btn custom-btn-primary font-size13 py-1 px-4">
                    Add Client
                  </button>
                )}

                <div class="invisible">
                  <span>invisible</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddClient;
