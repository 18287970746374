import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAuth } from "../../context/AuthContext";
import ReadMoreModal from "../../utils/Modals/ReadMoreModal";
import { ucFirst } from "../../utils/UcFirst";

const ReadOnlyRow = ({
  audit,
  index,
  handleEditClick,
  viewType,
  dataFrom,
  projectId,
}) => {
  const [showReadMoreModal, setshowReadMoreModal] = useState(false);
  const [errorFeedbacks, setErrorFeedbacks] = useState("");
  const auth = useAuth();
  const isClientRProject =
    auth.user.project_id || projectId == process.env.REACT_APP_R_PROJECT_ID;

  const openImage = (evidence) => {
    let evidence_url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${evidence}`;
    const newWindow = window.open(
      evidence_url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const auditorProjectId = auth.user.project_id || projectId;
  const isLHPProject =
    Number(auditorProjectId) ===
    Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  const closeReadMoreModal = () => {
    setshowReadMoreModal(false);
  };

  const readMoreModalFunc = (error_feedbacks) => {
    setshowReadMoreModal(true);
    setErrorFeedbacks(error_feedbacks);
    console.log(errorFeedbacks);
  };

  const showRow = (id) => {
    const hiddenRowElements = document.querySelectorAll(`#hiddenRow_${id}`);

    if (hiddenRowElements) {
      for (const key in hiddenRowElements) {
        hiddenRowElements[key].classList.contains("d-none")
          ? hiddenRowElements[key].classList.remove("d-none")
          : hiddenRowElements[key].classList.add("d-none");
      }
    }
  };
  return (
    <>
      <tr key={audit.id} className="font-size13 font-weight-light">
        {auth && auth.user.role !== "auditor" && (
          <td>
            {audit.logs.length ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>View Log</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm i-secondary shadow-none ml-2"
                  onClick={() => showRow(audit.chart_id)}
                >
                  <i className="fas fa-book-medical"></i>
                  {audit.logs.length ? (
                    <span className="badge bg-success ms-1  rounded-pill">
                      {audit.logs.length}
                    </span>
                  ) : null}
                </button>
              </OverlayTrigger>
            ) : null}
          </td>
        )}
        {/* {auth && auth.user.role === "auditor" && (
          <td className="text-nowrap">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit chart</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm i-secondary shadow-none ml-2"
                onClick={(event) => handleEditClick(event, audit)}
              >
                <i className="fa fa-edit" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </td>
        )} */}

        {dataFrom ? (
          <td className="text-center">{dataFrom + index}</td>
        ) : (
          <td className="text-center">{index + 1}</td>
        )}
        {auth && auth.user.role !== "auditor" && <td>HSTM {audit.team_id}</td>}
        {auth && auth.user.role !== "auditor" && <td>{audit.project_name}</td>}
        <td>{audit.sub_project}</td>
        <td>{audit.chart_id}</td>
        <td>{ucFirst(audit.coder_name)}</td>
        <td>{ucFirst(audit.coder_login_name)}</td>
        <td className="text-center">
          {new Date(audit.coding_complete_date).toLocaleDateString()}
        </td>
        <td className="text-center">
          {new Date(audit.audit_complete_date).toLocaleDateString()}
        </td>
        {auth && auth.user.role !== "auditor" && viewType === "team" && (
          <td>{ucFirst(audit.auditor_name)}</td>
        )}
        {auth && auth.user.role !== "auditor" && viewType === "team" && (
          <td>{ucFirst(audit.auditor_login_name)}</td>
        )}
        <td className="text-center">{audit.original_code_found}</td>
        <td>{audit.total_pages}</td>
        <td>{audit?.total_errors}</td>
        <td className="text-wrap">
          {audit.error_count == null && audit.audit_comment}

          {audit.error_count > 0 && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Read More</Tooltip>}
            >
              <button
                className="btn btn-sm i-success ms-1 shadow-none"
                onClick={(e) => {
                  e.preventDefault();
                  readMoreModalFunc(audit.error_feedbacks);
                }}
              >
                Errors
              </button>
            </OverlayTrigger>
          )}
        </td>
        <td>{audit.icd_qa_score}</td>

        <td>{new Date(audit.coding_date).toDateString().slice(4)}</td>
        {auth && auth.user.role !== "auditor" && (
          <>
            <td>{new Date(audit.coding_at).toDateString().slice(4)}</td>
            <td>
              {new Date(audit.coding_at).toTimeString().split(" ").shift()}
            </td>
          </>
        )}
      </tr>
      {auth && auth.user.role !== "auditor" && (
        <>
          {audit.logs.length
            ? audit.logs.map((log) => (
                <tr
                  key={index}
                  className="d-none hidden_row"
                  id={`hiddenRow_${audit.chart_id}`}
                  style={{ backgroundColor: "#05084b" }}
                >
                  <td></td>
                  <td>
                    <i class="fas fa-hand-point-up"></i>
                  </td>
                  {auth && auth.user.role !== "auditor" && (
                    <td>HSTM {audit.team_id}</td>
                  )}

                  {auth && auth.user.role !== "auditor" && (
                    <td>{audit.project_name}</td>
                  )}

                  <td
                    className={
                      log.properties.old.chart_id !==
                      log.properties.attributes.chart_id
                        ? "text-warning"
                        : null
                    }
                  >
                    {log.properties.old.chart_id !==
                    log.properties.attributes.chart_id ? (
                      <span>
                        {log.properties.old.chart_id}
                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                        {log.properties.attributes.chart_id}
                      </span>
                    ) : (
                      log.properties.old.chart_id
                    )}
                  </td>

                  <td>{ucFirst(audit.coder_name)}</td>
                  <td>{ucFirst(audit.coder_login_name)}</td>

                  {/* <td
                    className={
                      log.properties.old.coding_complete_date !== log.properties.attributes.coding_complete_date ? "text-warning" : null
                    }
                  >
                    {log.properties.old.coding_complete_date !== log.properties.attributes.coding_complete_date ? (
                      <span>
                        {new Date(log.properties.old.coding_complete_date).toLocaleDateString()}
                        <i className='fas fa-long-arrow-alt-right mx-2'></i>
                        {new Date(log.properties.attributes.coding_complete_date).toLocaleDateString()}
                      </span>
                    ) : (
                      <>{new Date(log.properties.old.coding_complete_date).toLocaleDateString()}</>
                    )}
                  </td> */}

                  <td>
                    <>
                      {new Date(
                        log.properties.old.coding_complete_date
                      ).toLocaleDateString()}
                    </>
                  </td>

                  <td
                    className={
                      log.properties.old.audit_complete_date !==
                      log.properties.attributes.audit_complete_date
                        ? "text-warning"
                        : null
                    }
                  >
                    {log.properties.old.audit_complete_date !==
                    log.properties.attributes.audit_complete_date ? (
                      <span>
                        {new Date(
                          log.properties.old.audit_complete_date
                        ).toLocaleDateString()}

                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                        {new Date(
                          log.properties.attributes.audit_complete_date
                        ).toLocaleDateString()}
                      </span>
                    ) : (
                      <>
                        {new Date(
                          log.properties.old.audit_complete_date
                        ).toLocaleDateString()}
                      </>
                    )}
                  </td>

                  {auth &&
                    auth.user.role !== "auditor" &&
                    viewType === "team" && (
                      <td>{ucFirst(audit.auditor_name)}</td>
                    )}
                  {auth &&
                    auth.user.role !== "auditor" &&
                    viewType === "team" && (
                      <td>{ucFirst(audit.auditor_login_name)}</td>
                    )}

                  <td
                    className={
                      log.properties.old.total_pages !==
                      log.properties.attributes.total_pages
                        ? "text-warning"
                        : null
                    }
                  >
                    {log.properties.old.total_pages !==
                    log.properties.attributes.total_pages ? (
                      <span>
                        {log.properties.old.total_pages}
                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                        {log.properties.attributes.total_pages}
                      </span>
                    ) : (
                      log.properties.old.total_pages
                    )}
                  </td>

                  {isLHPProject ? null : (
                    <>
                      <td
                        className={
                          log.properties.old.audit_type !==
                          log.properties.attributes.audit_type
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.audit_type !==
                        log.properties.attributes.audit_type ? (
                          <span>
                            {ucFirst(
                              log.properties.old.audit_type.split("_").join(" ")
                            )}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {ucFirst(
                              log.properties.attributes.audit_type
                                .split("_")
                                .join(" ")
                            )}
                          </span>
                        ) : (
                          ucFirst(
                            log.properties.old.audit_type.split("_").join(" ")
                          )
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.chart_qa_score !==
                          log.properties.attributes.chart_qa_score
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.chart_qa_score !==
                        log.properties.attributes.chart_qa_score ? (
                          <span>
                            {log.properties.old.chart_qa_score}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.chart_qa_score}
                          </span>
                        ) : (
                          log.properties.old.chart_qa_score
                        )}
                      </td>
                    </>
                  )}

                  <td
                    className={
                      log.properties.old.dx_qa_score !==
                      log.properties.attributes.dx_qa_score
                        ? "text-warning"
                        : null
                    }
                  >
                    {log.properties.old.dx_qa_score !==
                    log.properties.attributes.dx_qa_score ? (
                      <span>
                        {log.properties.old.dx_qa_score}
                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                        {log.properties.attributes.dx_qa_score}
                      </span>
                    ) : (
                      log.properties.old.dx_qa_score
                    )}
                  </td>

                  {isLHPProject && (
                    <>
                      <td
                        className={
                          log.properties.old.cpt_errors !==
                          log.properties.attributes.cpt_errors
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.cpt_errors !==
                        log.properties.attributes.cpt_errors ? (
                          <span>
                            {log.properties.old.cpt_errors}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.cpt_errors}
                          </span>
                        ) : (
                          log.properties.old.cpt_errors
                        )}
                      </td>
                      <td
                        className={
                          log.properties.old.icd_errors !==
                          log.properties.attributes.icd_errors
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.icd_errors !==
                        log.properties.attributes.icd_errors ? (
                          <span>
                            {log.properties.old.icd_errors}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.icd_errors}
                          </span>
                        ) : (
                          log.properties.old.icd_errors
                        )}
                      </td>
                      <td>{audit.cpt_codes}</td>
                      <td>{audit.icd_codes}</td>
                      <td
                        className={
                          log.properties.old.cpt_qa_score !==
                          log.properties.attributes.cpt_qa_score
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.cpt_qa_score !==
                        log.properties.attributes.cpt_qa_score ? (
                          <span>
                            {log.properties.old.cpt_qa_score}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.cpt_qa_score}
                          </span>
                        ) : (
                          log.properties.old.cpt_qa_score
                        )}
                      </td>
                      <td
                        className={
                          log.properties.old.icd_qa_score !==
                          log.properties.attributes.icd_qa_score
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.icd_qa_score !==
                        log.properties.attributes.icd_qa_score ? (
                          <span>
                            {log.properties.old.icd_qa_score}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.icd_qa_score}
                          </span>
                        ) : (
                          log.properties.old.icd_qa_score
                        )}
                      </td>
                    </>
                  )}

                  {isLHPProject ? null : (
                    <>
                      <td
                        className={
                          log.properties.old.original_code_found !==
                          log.properties.attributes.original_code_found
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.original_code_found !==
                        log.properties.attributes.original_code_found ? (
                          <span>
                            {log.properties.old.original_code_found}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.original_code_found}
                          </span>
                        ) : (
                          log.properties.old.original_code_found
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.total_errors !==
                          log.properties.attributes.total_errors
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.total_errors !==
                        log.properties.attributes.total_errors ? (
                          <span>
                            {log.properties.old.total_errors}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.total_errors}
                          </span>
                        ) : (
                          log.properties.old.total_errors
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.dx_codes_error !==
                          log.properties.attributes.dx_codes_error
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.dx_codes_error !==
                        log.properties.attributes.dx_codes_error ? (
                          <span>
                            {log.properties.old.dx_codes_error}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.dx_codes_error}
                          </span>
                        ) : (
                          log.properties.old.dx_codes_error
                        )}
                      </td>
                      <td
                        className={
                          log.properties.old.admin_error !==
                          log.properties.attributes.admin_error
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.admin_error !==
                        log.properties.attributes.admin_error ? (
                          <span>
                            {log.properties.old.admin_error}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.admin_error}
                          </span>
                        ) : (
                          log.properties.old.admin_error
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.codes_added !==
                          log.properties.attributes.codes_added
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.codes_added !==
                        log.properties.attributes.codes_added ? (
                          <span>
                            {log.properties.old.codes_added}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.codes_added}
                          </span>
                        ) : (
                          log.properties.old.codes_added
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.codes_deleted !==
                          log.properties.attributes.codes_deleted
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.codes_deleted !==
                        log.properties.attributes.codes_deleted ? (
                          <span>
                            {log.properties.old.codes_deleted}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.codes_deleted}
                          </span>
                        ) : (
                          log.properties.old.codes_deleted
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.dx_codes_updated !==
                          log.properties.attributes.dx_codes_updated
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.dx_codes_updated !==
                        log.properties.attributes.dx_codes_updated ? (
                          <span>
                            {log.properties.old.dx_codes_updated}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.dx_codes_updated}
                          </span>
                        ) : (
                          log.properties.old.dx_codes_updated
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.dos_corrected !==
                          log.properties.attributes.dos_corrected
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.dos_corrected !==
                        log.properties.attributes.dos_corrected ? (
                          <span>
                            {log.properties.old.dos_corrected}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.dos_corrected}
                          </span>
                        ) : (
                          log.properties.old.dos_corrected
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.pos_corrected !==
                          log.properties.attributes.pos_corrected
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.pos_corrected !==
                        log.properties.attributes.pos_corrected ? (
                          <span>
                            {log.properties.old.pos_corrected}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {log.properties.attributes.pos_corrected}
                          </span>
                        ) : (
                          log.properties.old.pos_corrected
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.dx_level_comment_code_corrected !==
                          log.properties.attributes
                            .dx_level_comment_code_corrected
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.dx_level_comment_code_corrected !==
                        log.properties.attributes
                          .dx_level_comment_code_corrected ? (
                          <span>
                            {log.properties.old.dx_level_comment_code_corrected}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {
                              log.properties.attributes
                                .dx_level_comment_code_corrected
                            }
                          </span>
                        ) : (
                          log.properties.old.dx_level_comment_code_corrected
                        )}
                      </td>

                      <td
                        className={
                          log.properties.old.rendering_provider_corrected !==
                          log.properties.attributes.rendering_provider_corrected
                            ? "text-warning"
                            : null
                        }
                      >
                        {log.properties.old.rendering_provider_corrected !==
                        log.properties.attributes
                          .rendering_provider_corrected ? (
                          <span>
                            {log.properties.old.rendering_provider_corrected}
                            <i className="fas fa-long-arrow-alt-right mx-2"></i>
                            {
                              log.properties.attributes
                                .rendering_provider_corrected
                            }
                          </span>
                        ) : (
                          log.properties.old.rendering_provider_corrected
                        )}
                      </td>
                    </>
                  )}
                  <td>{new Date(audit.coding_date).toDateString().slice(4)}</td>
                  {auth && auth.user.role !== "auditor" && (
                    <>
                      <td>
                        {new Date(log.properties.old.coding_at)
                          .toDateString()
                          .slice(4)}
                      </td>
                      {/* <td>{new Date(log.properties.old.coding_at).toTimeString().split(" ").shift()}</td> */}
                      <td
                        className={
                          new Date(log.properties.old.coding_at)
                            .toTimeString()
                            .split(" ")
                            .shift() !==
                          new Date(log.properties.attributes.coding_at)
                            .toTimeString()
                            .split(" ")
                            .shift()
                            ? "text-warning"
                            : null
                        }
                      >
                        {new Date(log.properties.old.coding_at)
                          .toTimeString()
                          .split(" ")
                          .shift()}
                        <i className="fas fa-long-arrow-alt-right mx-2"></i>
                        {new Date(log.properties.attributes.coding_at)
                          .toTimeString()
                          .split(" ")
                          .shift()}
                      </td>
                    </>
                  )}
                </tr>
              ))
            : null}
        </>
      )}
      <ReadMoreModal
        showReadMoreModal={showReadMoreModal}
        closeReadMoreModal={closeReadMoreModal}
        errorFeedbacks={errorFeedbacks}
        setshowReadMoreModal={setshowReadMoreModal}
        openImage={openImage}
        title="Error Logs"
        isClientRProject={isClientRProject}
      />
    </>
  );
};

export default ReadOnlyRow;
