import { useEffect, useState } from "react";
// import { Doughnut, Line, Pie } from "react-chartjs-2";

import { Chart as ChartJS } from "chart.js/auto";

import apiClient from "../services/ApiClient";
import { changeTabTitle } from "../utils/changeTabTitle";
import { Link } from "react-router-dom";
// import { useDashboard } from "../context/DasboardContext";
import UsersTable from "../components/users/UsersTable";
import { useQuery } from "react-query";
import LoadingIndicator from "../shared/LoadingIndicator";

const Dashboard = () => {
  const fetchData = async () => {
    const response = await apiClient.get("dashboard", {
      componentName: "dashboard",
    });
    return response.data.data;
  };

  const fetchRecentUsers = async () => {
    const response = await apiClient.get("dashboard/recent/users", {
      componentName: "recentUsers",
    });
    return response.data.data;
  };

  const { data: data = [], isFetching: dataFetching } = useQuery(
    "dashboard",
    fetchData,
    {
      // keepPreviousData: true,
      staleTime: 30000,
    }
  );
  const { data: users = [], isFetching: recentUsersFetching } = useQuery(
    "recentUsers",
    fetchRecentUsers,
    {
      // keepPreviousData: true,
      staleTime: 30000,
    }
  );

  useEffect(() => {
    // Change TabTitle
    changeTabTitle("Dashboard");
  }, []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i className="text-secondary fas fa-clinic-medical fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <Link className="text-light" to="/all-clients">
                      <h6>Clients</h6>
                      <h3 className="pl-2">
                        {data.clients ? data.clients : 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i className="text-secondary fas fa-project-diagram fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <Link className="text-light" to="/all-projects">
                      <h6>Projects</h6>
                      <h3 className="pl-2">
                        {data.projects ? data.projects : 0}
                        {dataFetching && <LoadingIndicator />}
                      </h3>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <img src='' alt='' /> */}
                  <i className="text-secondary fas fa-users fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <Link className="text-light" to="/all-teams">
                      <h6>Teams</h6>
                      <h3 className="pl-2">{data.teams ? data.teams : 0}</h3>
                      {dataFetching && <LoadingIndicator />}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl col-lg-6 col-md-6 card-design pr-md-3 pr-lg-0 pt-md-0">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  <i className="text-secondary fas fa-user fa-4x"></i>
                </div>
                <div className="col-lg-10 col-md-12">
                  <span className="text-end">
                    <Link className="text-light" to="/all-users">
                      <h6>Users</h6>
                      <h3 className="pl-2">{data.users ? data.users : 0}</h3>
                      {dataFetching && <LoadingIndicator />}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UsersTable users={users} title="Recently Added Users" page="dashboard" />
      {recentUsersFetching && <LoadingIndicator />}
    </>
  );
};

export default Dashboard;
