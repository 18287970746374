import axios from "axios";
import { toast } from "react-toastify";

// create axios instance
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

// // add headers for token
// apiClient.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("auth-token") || auth.token}`;

// Request
apiClient.interceptors.request.use(
  (req) => {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator

    let componentName = req.componentName;

    if (componentName) {
      document
        .querySelectorAll(`#${componentName}-loading-indicator`)
        .forEach((element) => element?.classList.remove("d-none"));
    } else {
      document.body.classList.add("loading-indicator");
    }

    // Add configurations here
    // console.log(componentName);
    return req;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

// Response
apiClient.interceptors.response.use(
  (res) => {
    // spinning hide
    // UPDATE: Add this code to hide global loading indicator

    let componentName = res.config.componentName;

    if (componentName) {
      document
        .querySelectorAll(`#${componentName}-loading-indicator`)
        .forEach((element) => element?.classList.add("d-none"));
    } else {
      document.body.classList.remove("loading-indicator");
    }

    // document.body.classList.remove("loading-indicator");
    toast.success(res.data.success);
    // throw new ErrorBoundary("example");
    // Add configurations here

    // if (res.status === 201) {
    //   console.log("Posted Successfully");
    // console.log(componentName);
    // }
    return res;
  },
  (err) => {
    let componentName = err.config.componentName;

    if (componentName) {
      document
        .querySelectorAll(`#${componentName}-loading-indicator`)
        .forEach((element) => element?.classList.add("d-none"));
      document
        .querySelectorAll(`#${componentName}-loading-indicator`)
        .forEach((element) => element?.classList.add("bg-secondary"));
    } else {
      document.body.classList.remove("loading-indicator");
    }

    // spinning hide
    // UPDATE: Add this code to hide global loading indicator
    document.body.classList.remove("loading-indicator");

    // console.log(err.response);
    // console.log(err.response);
    // console.log(err.message);
    // if (err.response.data.error === "User not found") {
    //   console.log("hello");
    // }
    console.log(err);
    if (err.response.data.code === 401) {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    if (err.response.data.code === 403) {
      localStorage.clear();
      window.location.pathname = "/accessDenied";
    }
    if (err.response.status === 422) {
      if (typeof err.response.data.error === "string") {
        toast.error(err.response.data.error);
      } else {
        toast.error(err.response.statusText);
      }
    } else {
      toast.error(err.response.statusText);
    }

    // return;

    return Promise.reject(err);
  }
);

export default apiClient;
