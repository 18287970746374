import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LogoutModal from "./modals/LogoutModal";

const Sidebar = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const auth = useAuth();

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
      <aside id="desktop_panel">
        <ul>
          {/* <li className='logo'>
            <img src='/images/SecqureOneHealth_logo-1.png' alt='logo' />
          </li> */}

          {auth && auth.user && auth.user.role === "super_admin" && (
            <>
              <li>
                <NavLink to="/">
                  <i className="fas fa-th-large"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-clients">
                  <i className="fas fa-clinic-medical"></i>
                  <span>Clients</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-projects">
                  <i className="fas fa-project-diagram"></i>
                  <span>Projects</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-teams">
                  <i className="fas fa-users"></i>
                  <span>Teams</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-users" end>
                  <i className="fas fa-user"></i>
                  <span>Users</span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink to='/chart'>
                <i className='fas fa-file-alt'></i>
                <span>Chart</span>
              </NavLink>
            </li> */}
            </>
          )}

          {/* <li>
          <NavLink to='/managers'>
            <i className='fas fa-users'></i>
            <span>Managers</span>
          </NavLink>
        </li> */}
          {auth && auth.user && auth.user.role === "manager" && (
            <>
              {/* <li>
                <NavLink to='/'>
                  <i className='fas fa-th-large'></i>
                  <span>Dashboard</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/add-teams">
                  <i className="fas fa-users"></i>
                  <span>Teams</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-users">
                  <i className="fas fa-user"></i>
                  <span>Users</span>
                </NavLink>
              </li>
              {/* <li>
              <NavLink to='/chart'>
                <i className='fas fa-file-alt'></i>
                <span>Chart</span>
              </NavLink>
            </li> */}
            </>
          )}
          {auth && auth.user && auth.user.role === "lead" && (
            <>
              <li>
                <NavLink to="/user/dashboard">
                  <i className="fas fa-th-large"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/all-users">
                  <i className="fas fa-users"></i>
                  <span>Team</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-loginName">
                  <i className="fas fa-upload"></i>
                  <span>Add Name</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-users">
                  <i className="fas fa-user-plus"></i>
                  <span>Add Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/search">
                  <i className="fas fa-search"></i>
                  <span>Search</span>
                </NavLink>
              </li>
            </>
          )}
          {auth && auth.user && auth.user.role === "user" && (
            <>
              <li>
                <NavLink to="/user/dashboard">
                  <i className="fas fa-th-large"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/chart">
                  <i className="fas fa-file-alt"></i>
                  <span>Chart</span>
                </NavLink>
              </li>
            </>
          )}

          {auth && auth.user && auth.user.role === "auditor" && (
            <>
              <li>
                <NavLink to="/user/dashboard">
                  <i className="fas fa-th-large"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/audits">
                  <i className="fas fa-file-alt"></i>
                  <span>Audits</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/add-audits">
                  <i className="fas fa-file-alt"></i>
                  <span>Add Audit</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <ul className="list-unstyled fixed-bottom pb-3" style={{ left: "14px", right: "auto" }}>
          <li>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setShowLogoutModal(true);
              }}
            >
              <i className="fas fa-power-off"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </aside>

      <LogoutModal closeLogoutModal={closeLogoutModal} showLogoutModal={showLogoutModal} />
    </>
  );
};

export default Sidebar;
