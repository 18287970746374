// import { useEffect, useState } from "react";

// import AddProject from "../components/projects/AddProject";
// import ProjectsTable from "../components/projects/ProjectsTable";
// import apiClient from "../services/ApiClient";
// import { changeTabTitle } from "../utils/changeTabTitle";

// const Projects = () => {
//   const [projects, setProjects] = useState([]);

//   const fetchData = async () => {
//     try {
//       const response = await apiClient.get(`project`);
//       setProjects(response.data.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Change TabTitle
//   changeTabTitle("Projects");
//   return (
//     <div className='row'>
//       <div className='col-12 mb-3'>
//         <AddProject projects={projects} setProjects={setProjects} fetchData={fetchData} />
//       </div>
//       <div className='col-12'>
//         <ProjectsTable projects={projects} setProjects={setProjects} fetchData={fetchData} />
//       </div>
//     </div>
//   );
// };

// export default Projects;

import { useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";

import AddProject from "../components/projects/AddProject";
import ProjectsTable from "../components/projects/ProjectsTable";
import apiClient from "../services/ApiClient";
import { changeTabTitle } from "../utils/changeTabTitle";
import { useState } from "react";

// Fetch data function
async function fetchData(offset) {
  try {
    const response = await apiClient.get(`project?page=${offset}`, {
      componentName: "projects",
    });
    return response.data;
  } catch (err) {
    // You can handle errors here
    throw err;
  }
}

const Projects = () => {
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const queryClient = useQueryClient();

  // Fetch projects
  // const {
  //   data: projects,
  //   isLoading,
  //   isError,
  //   refetch,
  // } = useQuery("projects", fetchData);

  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(
    ["projects", offset],
    () => fetchData(offset),
    {
      // keepPreviousData: true,
      staleTime: 30000,
    }
  );

  // Add new project mutation
  const addProjectMutation = useMutation(
    (newProject) => apiClient.post("project", newProject),
    {
      onSuccess: () => {
        // Refetch projects after adding a new project
        queryClient.invalidateQueries("projects");
      },
    }
  );

  const handleAddProject = async (newProject) => {
    await addProjectMutation.mutateAsync(newProject);
  };

  useEffect(() => {
    changeTabTitle("Projects");
  }, []);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // Update pageOptions count when data changes
  useEffect(() => {
    if (data) {
      setPageCount(data.meta.last_page);
      setDataCount(data.meta.total);
      setDataFrom(data.meta.from);
      setDataEnd(data.meta.to);
    }
  }, [data]);

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <AddProject onAddProject={handleAddProject} />
      </div>
      <div className="col-12">
        {/* Render ProjectsTable with projects data */}
        <ProjectsTable
          projects={data?.data || []}
          refetch={refetch}
          isFetching={isFetching}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          dataCount={dataCount}
          dataFrom={dataFrom}
          dataEnd={dataEnd}
        />
      </div>
    </div>
  );
};

export default Projects;
