import React from "react";
import DatePicker from "react-datepicker";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAuth } from "../../context/AuthContext";

const EditOnlyRow = ({ index, editFormData, handleCancel, handleEditFormChange, editFormErrors, setEditFormData, dataFrom }) => {
  const auth = useAuth();
  const auditorProjectId = auth.user.project_id;
  const isLHPProject = Number(auditorProjectId) === Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  return (
    <tr key={index} className="font-size13">
      <td className='text-nowrap'>
        <OverlayTrigger placement='top' overlay={<Tooltip>Save Chart</Tooltip>}>
          <button type='submit' className='btn btn-sm i-success shadow-none ml-2'>
            <i className='fas fa-arrow-alt-circle-right' aria-hidden='true' />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Cancel Edit</Tooltip>}>
          <button type='button' className='btn btn-sm i-primary font-15 shadow-none ml-2' onClick={handleCancel}>
            <i className='fa fa-times' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>
      {dataFrom ? <td className="text-center">{dataFrom + index}</td> : <td className="text-center">{index + 1}</td>}
      {/* <td>{editFormData.user.team.project.project_name}</td> */}
      <td>
        <input
          type='text'
          required='required'
          disabled
          placeholder='Enter a Chart ID'
          name='chart_id'
          className='form-control font-size13'
          value={editFormData.chart_id}
          onChange={handleEditFormChange}
          style={{ minWidth: "120px" }}
        ></input>
        {editFormErrors && editFormErrors.chart_id && (
          <div id='clientName' className='invalid-feedback d-block'>
            {editFormErrors.chart_id}
          </div>
        )}
      </td>
      <td>
        <input
          type='text'
          required='required'
          placeholder=''
          name='coder_name'
          disabled
          className='form-control font-size13'
          value={editFormData.coder_name}
          onChange={handleEditFormChange}
          style={{ minWidth: "120px" }}
        ></input>
        {editFormErrors && editFormErrors.coder_name && (
          <div id='coder_name' className='invalid-feedback d-block'>
            {editFormErrors.coder_name}
          </div>
        )}
      </td>
      <td>
        <input
          type='text'
          required='required'
          placeholder=''
          name='coder_name'
          disabled
          className='form-control font-size13'
          value={editFormData.coder_login_name}
          onChange={handleEditFormChange}
          style={{ minWidth: "120px" }}
        ></input>
        {editFormErrors && editFormErrors.coder_name && (
          <div id='coder_name' className='invalid-feedback d-block'>
            {editFormErrors.coder_name}
          </div>
        )}
      </td>
      <td>
        {/* <input
          type='date'
          required='required'
          placeholder=''
          name='coding_complete_date'
          className='form-control'
          value={editFormData.coding_complete_date}
          onChange={handleEditFormChange}
        ></input> */}
        <div style={{ minWidth: "120px" }}>
          <DatePicker
            disabled
            className='form-control font-size13'
            dateFormat='MM/dd/yyyy'
            selected={Date.parse(editFormData.coding_complete_date)}
            maxDate={new Date()}
            onChange={(date) => setEditFormData({ ...editFormData, coding_complete_date: date })}
            style={{ minWidth: "120px" }}
          />
        </div>
        {editFormErrors && editFormErrors.coding_complete_date && (
          <div id='coding_complete_date' className='invalid-feedback d-block'>
            {editFormErrors.coding_complete_date}
          </div>
        )}
      </td>
      <td>
        {/* <input
          type='date'
          required='required'
          placeholder=''
          name='audit_complete_date'
          className='form-control'
          value={editFormData.audit_complete_date}
          onChange={handleEditFormChange}
        ></input> */}
        <div style={{ minWidth: "120px" }}>
          <DatePicker
            className='form-control font-size13'
            disabled
            dateFormat='MM/dd/yyyy'
            selected={Date.parse(editFormData.audit_complete_date)}
            maxDate={new Date()}
            onChange={(date) => setEditFormData({ ...editFormData, audit_complete_date: date })}
          />
        </div>

        {editFormErrors && editFormErrors.audit_complete_date && (
          <div id='audit_complete_date' className='invalid-feedback d-block'>
            {editFormErrors.audit_complete_date}
          </div>
        )}
      </td>
      {/* <td>
        <input
          type='text'
          required='required'
          placeholder=''
          name='audit_name'
          className='form-control'
          value={editFormData.audit_name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.audit_name && (
          <div id='audit_name' className='invalid-feedback d-block'>
            {editFormErrors.audit_name}
          </div>
        )}
      </td> */}
      <td>
        <input
          type='text'
          required='required'
          disabled
          placeholder=''
          name='total_pages'
          className='form-control font-size13'
          value={editFormData.total_pages}
          onChange={handleEditFormChange}
          style={{ minWidth: "120px" }}
        ></input>
        {editFormErrors && editFormErrors.total_pages && (
          <div id='total_pages' className='invalid-feedback d-block'>
            {editFormErrors.total_pages}
          </div>
        )}
      </td>
      <td>
        <input
          type='text'
          name='audit_comment'
          id='comments'
          // disabled
          placeholder='Enter Comments font-size13'
          className={`form-control font-size13`}
          value={editFormData.audit_comment}
          onChange={handleEditFormChange}
          style={{ width: "300px" }}
        ></input>
        {editFormErrors && editFormErrors.audit_comment && (
          <div id='audit_comment' className='invalid-feedback d-block'>
            {editFormErrors.audit_comment}
          </div>
        )}
      </td>
      {/* <td>
        <textarea
          name='secondary_comment'
          id='secondary_comment'
          placeholder='Enter Comments'
          className={`form-control font-size13`}
          value={editFormData.secondary_comment}
          onChange={handleEditFormChange}
          style={{ width: "300px" }}
        ></textarea>
        {editFormErrors && editFormErrors.secondary_comment && (
          <div id='secondary_comment' className='invalid-feedback d-block'>
            {editFormErrors.secondary_comment}
          </div>
        )}
      </td> */}
      {isLHPProject && (
        <>
          <td className="text-center">
            <input
              type='text'
              name='cpt_errors'
              id='cpt_errors'
              placeholder='Enter CPT Errors'
              className={`form-control font-size13`}
              value={editFormData.cpt_errors}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.icd_errors && (
              <div id='cpt_errors' className='invalid-feedback d-block'>
                {editFormErrors.cpt_errors}
              </div>
            )}
          </td>
          <td className="text-center">
            <input
              type='text'
              name='icd_errors'
              id='icd_errors'
              placeholder='Enter ICD Errors'
              className={`form-control font-size13`}
              value={editFormData.icd_errors}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.icd_errors && (
              <div id='cpt_errors' className='invalid-feedback d-block'>
                {editFormErrors.icd_errors}
              </div>
            )}
          </td>

          <td>
            <input
              type='text'
              name='cpt_codes'
              id='cpt_codes'
              disabled
              placeholder='Enter CPT Codes'
              className={`form-control font-size13`}
              value={editFormData.cpt_codes}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.cpt_codes && (
              <div id='cpt_codes' className='invalid-feedback d-block'>
                {editFormErrors.cpt_codes}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              name='icd_codes'
              id='icd_codes'
              disabled
              placeholder='Enter ICD Codes'
              className={`form-control font-size13`}
              value={editFormData.icd_codes}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.icd_codes && (
              <div id='icd_codes' className='invalid-feedback d-block'>
                {editFormErrors.icd_codes}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              name='cpt_qa_score'
              id='cpt_qa_score'
              disabled
              placeholder='Enter CPT QA Score'
              className={`form-control font-size13 bg-info `}
              value={editFormData.cpt_qa_score}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.cpt_qa_score && (
              <div id='cpt_qa_score' className='invalid-feedback d-block'>
                {editFormErrors.cpt_qa_score}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              name='icd_qa_score'
              id='icd_qa_score'
              disabled
              placeholder='Enter ICD QA Score'
              className={`form-control font-size13`}
              value={editFormData.icd_qa_score}
              onChange={handleEditFormChange}
              // style={{ width: "300px" }}
            ></input>
            {editFormErrors && editFormErrors.icd_qa_score && (
              <div id='icd_qa_score' className='invalid-feedback d-block'>
                {editFormErrors.icd_qa_score}
              </div>
            )}
          </td>
        </>
      )}
      {isLHPProject ? null : (
        <>
          <td>
            <select
              name='audit_type'
              className={`form-select form-control font-size13`}
              id='audit_type'
              onChange={handleEditFormChange}
              defaultValue={editFormData.audit_type}
              style={{ minWidth: "120px" }}
            >
              <option value='traditional'>Traditional</option>
              <option value='traditional_lookback'>Traditional / Lookback</option>
              <option value='lookback'>Lookback</option>
            </select>
            {editFormErrors && editFormErrors.audit_type && (
              <div id='audit_type' className='invalid-feedback d-block'>
                {editFormErrors.audit_type}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              disabled
              name='chart_qa_score'
              className='form-control font-size13'
              value={editFormData.chart_qa_score}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.chart_qa_score && (
              <div id='chart_qa_score' className='invalid-feedback d-block'>
                {editFormErrors.chart_qa_score}
              </div>
            )}
          </td>
        </>
      )}

      {isLHPProject ? null : (
        <td>
          {isLHPProject ? null : (
            <input
              type='text'
              required='required'
              disabled
              name='dx_qa_score'
              className='form-control font-size13'
              value={editFormData.dx_qa_score}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
          )}
          {editFormErrors && editFormErrors.dx_qa_score && (
            <div id='dx_qa_score' className='invalid-feedback d-block'>
              {editFormErrors.dx_qa_score}
            </div>
          )}
        </td>
      )}

      {isLHPProject ? null : (
        <>
          <td>
            <input
              type='text'
              disabled
              required='required'
              placeholder=''
              name='original_code_found'
              className='form-control font-size13'
              value={editFormData.original_code_found}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.original_code_found && (
              <div id='original_code_found' className='invalid-feedback d-block'>
                {editFormErrors.original_code_found}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              disabled
              name='total_errors'
              className='form-control font-size13'
              value={editFormData.total_errors}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.total_errors && (
              <div id='total_errors' className='invalid-feedback d-block'>
                {editFormErrors.total_errors}
              </div>
            )}
          </td>

          <td>
            <input
              type='text'
              required='required'
              disabled
              placeholder=''
              name='dx_codes_error'
              className='form-control font-size13'
              value={editFormData.dx_codes_error}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.dx_codes_error && (
              <div id='dx_codes_error' className='invalid-feedback d-block'>
                {editFormErrors.dx_codes_error}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              disabled
              name='admin_error'
              className='form-control font-size13'
              value={editFormData.admin_error}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.admin_error && (
              <div id='admin_error' className='invalid-feedback d-block'>
                {editFormErrors.admin_error}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              disabled
              required='required'
              placeholder=''
              name='codes_added'
              className='form-control font-size13'
              value={editFormData.codes_added}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.codes_added && (
              <div id='codes_added' className='invalid-feedback d-block'>
                {editFormErrors.codes_added}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              disabled
              required='required'
              placeholder=''
              name='codes_deleted'
              className='form-control font-size13'
              value={editFormData.codes_deleted}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.codes_deleted && (
              <div id='codes_deleted' className='invalid-feedback d-block'>
                {editFormErrors.codes_deleted}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              disabled
              required='required'
              placeholder=''
              name='dx_codes_updated'
              className='form-control font-size13'
              value={editFormData.dx_codes_updated}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.dx_codes_updated && (
              <div id='dx_codes_updated' className='invalid-feedback d-block'>
                {editFormErrors.dx_codes_updated}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              placeholder=''
              name='dos_corrected'
              className='form-control font-size13'
              value={editFormData.dos_corrected}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.dos_corrected && (
              <div id='dos_corrected' className='invalid-feedback d-block'>
                {editFormErrors.dos_corrected}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              placeholder=''
              name='pos_corrected'
              className='form-control font-size13'
              value={editFormData.pos_corrected}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.pos_corrected && (
              <div id='pos_corrected' className='invalid-feedback d-block'>
                {editFormErrors.pos_corrected}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              placeholder=''
              name='dx_level_comment_code_corrected'
              className='form-control font-size13'
              value={editFormData.dx_level_comment_code_corrected}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.dx_level_comment_code_corrected && (
              <div id='dx_level_comment_code_corrected' className='invalid-feedback d-block'>
                {editFormErrors.dx_level_comment_code_corrected}
              </div>
            )}
          </td>
          <td>
            <input
              type='text'
              required='required'
              placeholder=''
              name='rendering_provider_corrected'
              className='form-control font-size13'
              value={editFormData.rendering_provider_corrected}
              onChange={handleEditFormChange}
              style={{ minWidth: "120px" }}
            ></input>
            {editFormErrors && editFormErrors.rendering_provider_corrected && (
              <div id='rendering_provider_corrected' className='invalid-feedback d-block'>
                {editFormErrors.rendering_provider_corrected}
              </div>
            )}
          </td>
        </>
      )}
      <td>{new Date(editFormData.coding_date).toDateString().slice(4)}</td>
    </tr>
  );
};

export default EditOnlyRow;
