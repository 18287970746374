import React from "react";

const LoadingIndicator = ({ name }) => {
  if (name) {
    return (
      <div class="overlay" id={`${name}-loading-indicator`}>
        <div class="d-flex justify-content-center align-items-center h-100">
          <div
            class="spinner-grow"
            role="status"
            style={{
              width: "3rem",
              height: "3rem",
              zIndex: 20,
            }}
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div class="overlay">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div
          class="spinner-grow"
          role="status"
          style={{
            width: "3rem",
            height: "3rem",
            zIndex: 20,
          }}
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
