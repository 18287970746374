const NotFound = () => {
  return (
    <div className='vh-100 vw-100' id='error_404'>
      <div className='d-flex justify-content-end align-items-end vh-100 me-5'>
        <a href='/login' className='btn btn-primary mb-5'>
          Back to Login
        </a>
      </div>
    </div>
  );
};

export default NotFound;
