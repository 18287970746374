import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";

import { Modal, Button } from "react-bootstrap";

const LogoutModal = ({ showLogoutModal, closeLogoutModal }) => {
  const auth = useAuth();
  const handleLogout = () => {
    auth.logout();
  };
  return (
    <Modal show={showLogoutModal} onHide={closeLogoutModal} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <h6 className='mb-0 font-size13'>Please Confirm!</h6>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center font-size13'>Are you sure want to logout this application?</p>
        <div className='text-center'>
          <Button variant='success' className='me-2 font-size13' onClick={handleLogout}>
            Yes
          </Button>
          <Button variant='danger' className="font-size13" onClick={closeLogoutModal}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
