import { useFormik } from "formik";
import * as yup from "yup";

import apiClient from "../../services/ApiClient";
// import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMemo } from "react";

const AddProject = () => {
  const queryClient = useQueryClient();

  // Use react-query useQuery hook to fetch data
  const {
    data: clients = [],
    isFetching,
    refetch,
  } = useQuery(["clientsForOptions"], () => fetchData(), {
    // keepPreviousData: true,
    staleTime: Infinity,
  });

  // Fetch data function
  async function fetchData() {
    try {
      const response = await apiClient.get(`client`, {
        componentName: "clientsforoptions",
      });
      return response.data.data;
    } catch (err) {
      // You can handle errors here
      throw err;
    }
  }

  const mutation = useMutation(
    (values) =>
      apiClient.post("project/store", values, { componentName: "addProject" }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["projects"]);
        formik.resetForm();
      },
      onError: (err) => {
        // Handle errors
        const { response } = err;
        if (response) {
          const { data } = response;
          formik.setErrors({
            client_id: data.error.client_id,
            project_name: data.error.project_name,
            project_code: data.error.project_code,
          });
        }
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      client_id: "",
      project_name: "",
      project_code: "",
    },
    validationSchema: yup.object({
      client_id: yup.string().required("Client is required"),
      project_name: yup
        .string()
        .min(3, "Too Short!")
        .max(25, "Must be 25 characters or less")
        .required("Project name is required"),
      project_code: yup.string().required("Project code is required"),
    }),
    onSubmit: async (values) => {
      await mutation.mutateAsync(values);
    },
  });

  const clientOptions = useMemo(() => {
    return clients.map((client) => (
      <option value={client.id} key={client.id}>
        {client.client_name} - {client.client_code}
      </option>
    ));
  }, [clients]);

  return (
    <>
      <div className="card">
        <div className="card-body font-size13">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div class="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="selectClient" className="form-label required">
                  Select Client
                </label>

                <select
                  className={`form-select form-control font-size13 ${
                    formik.touched.client_id &&
                    formik.errors.client_id &&
                    "is-invalid"
                  }`}
                  id="selectClient"
                  name="client_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.client_id}
                >
                  <option disabled value="">
                    Select...
                  </option>
                  {/* {clients &&
                    clients.map((client) => (
                      <option value={client.id} key={client.id}>
                        {client.client_name} - {client.client_code}
                      </option>
                    ))} */}
                  {clientOptions}
                  {isFetching && <option disabled>Loading...</option>}
                </select>

                {formik.touched.client_id && formik.errors.client_id ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.client_id}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="projectName" className="form-label required">
                  Project Name
                </label>
                <input
                  type="text"
                  className={`form-control font-size13 ${
                    formik.touched.project_name &&
                    formik.errors.project_name &&
                    "is-invalid"
                  }`}
                  id="projectName"
                  // placeholder='Enter project Name'
                  name="project_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_name}
                />
                {formik.touched.project_name && formik.errors.project_name ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.project_name}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="projectCode" className="form-label required">
                  Project Code
                </label>
                <input
                  type="text"
                  className={`form-control font-size13 ${
                    formik.touched.project_code &&
                    formik.errors.project_code &&
                    "is-invalid"
                  }`}
                  id="projectCode"
                  // placeholder='Enter project Code'
                  name="project_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_code}
                />
                {formik.touched.project_code && formik.errors.project_code ? (
                  <div id="role" className="invalid-feedback">
                    {formik.errors.project_code}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div class="col-lg-1 w-auto px-1 mt-4 pt-2 ms-2">
                {mutation.isLoading ? (
                  <button
                    class="btn custom-btn-primary font-size13 py-1 px-4"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn custom-btn-primary font-size13 py-1 px-4"
                  >
                    Add project
                  </button>
                )}
                <div class="invisible">
                  <span>invisible</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProject;
