import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../../../services/ApiClient";
import { changeTabTitle } from "../../../utils/changeTabTitle";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAuth } from "../../../context/AuthContext";
import ReadOnlyRow from "./ReadOnlyRow";
// import EditOnlyRow from "./EditOnlyRow";
import DeleteModal from "../../../utils/Modals/DeleteModal";
import { Link } from "react-router-dom";
import LoadingIndicator from "../../../shared/LoadingIndicator";

const AddBulkUsers = () => {
  const auth = useAuth();
  const [fileValue, setFileValue] = useState(null);
  const [bulkUsers, setbulkUsers] = useState("");
  const [fileError, setFileError] = useState("");
  const [excelError, setExcelError] = useState("");
  // const [bulkUserId, setbulkUserId] = useState("");
  // const [editFormData, setEditFormData] = useState({
  //   name: "",
  //   email: "",
  //   role: "",
  // });

  const access =
    (auth && auth.user && auth.user.role === "super_admin") ||
    auth.user.role === "manager";

  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteModalFunc = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      let id = deleteId;
      const response = await apiClient.delete(`temp/user/delete/${id}`);
      // setUsers(users.filter((user) => user.id !== id));
      setShowDeleteModal(false);
      fetchTempUsers();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTempUsers();
  }, []);

  const formData = new FormData();
  formData.append("import_user", fileValue);
  const fileSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post("temp/user/import", formData);
      fetchTempUsers();
    } catch (err) {
      const errors = err.response.data.error[0];
      const file_error =
        err.response.data.error.import_user &&
        err.response.data.error.import_user[0];
      if (file_error) {
        setFileError(file_error);
      }
      const excel_errors =
        "Row " + errors.row + " : " + errors.values[1] + " " + errors.errors[0];
      // console.log(excel_errors);
      if (excel_errors) {
        setExcelError(excel_errors);
      }
    }
  };

  const fetchTempUsers = async () => {
    try {
      const response = await apiClient.get(`temp/user`, {
        componentName: "bulkUsers",
      });
      setbulkUsers(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const userSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post("temp/user/store");
      fetchTempUsers();
    } catch (err) {
      // console.log(err.response.data);
    }
  };

  const deleteAll = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.delete("temp/user/destroy/all");
      fetchTempUsers();
    } catch (err) {
      // console.log(err.response.data);
    }
  };

  // const handleEditClick = (event, user) => {
  //   event.preventDefault();
  //   setbulkUserId(user.id);

  //   const formValues = {
  //     name: user.name,
  //     email: user.email,
  //     role: user.role,
  //   };

  //   setEditFormData(formValues);
  // };

  // const handleCancel = () => {
  //   setbulkUserId(null);
  // };

  // const handleEditFormChange = (event) => {
  //   event.preventDefault();

  //   const fieldName = event.target.getAttribute("name");
  //   const fieldValue = event.target.value;
  //   const newFormData = { ...editFormData };
  //   newFormData[fieldName] = fieldValue;

  //   setEditFormData(newFormData);
  // };

  // Change TabTitle
  changeTabTitle("Bulk Users");

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-header border-0 py-3">
              <h6 className="mb-0  font-size13 ">Add Bulk Users</h6>
            </div>
            <div className="card-body">
              <form onSubmit={fileSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label">
                    Import Users File
                  </label>
                  <input
                    type="file"
                    name="import_user"
                    accept=".xls,.xlsx"
                    className="form-control font-size13 "
                    id="import_user"
                    onChange={(e) => setFileValue(e.currentTarget.files[0])}
                  />
                  {excelError && (
                    <span className="alert invalid-feedback d-block">
                      {excelError}
                    </span>
                  )}
                  {fileError && (
                    <span className="alert invalid-feedback d-block">
                      {fileError}
                    </span>
                  )}
                </div>
                <p className=" font-size13 ">
                  <Link
                    to="/files/Add_users_template.xlsx"
                    target="_blank"
                    download
                    className="me-1"
                  >
                    Click Here
                  </Link>
                  to download Sample Template
                </p>

                <div className="text-end">
                  <button
                    type="submit"
                    className="btn custom-btn-primary px-4  font-size13 "
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-9">
          <div className="card">
            <div className="card-header border-0 py-3">
              <h6 className="mb-0 font-size13 ">Users</h6>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive">
                <table className="table">
                  <thead className=" font-size13 ">
                    <tr>
                      <th scope="col" className="text-center">
                        S.No
                      </th>
                      <th scope="col">Name</th>
                      {/* <th scope='col'>Login Name</th> */}
                      <th scope="col">Email</th>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Role</th>
                      {access && <th scope="col">Action</th>}
                    </tr>
                  </thead>
                  <tbody className=" font-size13 ">
                    <LoadingIndicator name="bulkUsers" />
                    {bulkUsers &&
                      bulkUsers.map((user, index) => (
                        <>
                          {/* {bulkUserId === user.id ? (
                        <EditOnlyRow
                          editFormData={editFormData}
                          index={index}
                          handleEditFormChange={handleEditFormChange}
                          handleCancel={handleCancel}
                        />
                      ) : ( */}
                          <ReadOnlyRow
                            user={user}
                            index={index}
                            // handleEditClick={handleEditClick}
                            deleteModalFunc={deleteModalFunc}
                          />
                          {/* )} */}
                        </>
                      ))}
                    {!bulkUsers.length && (
                      <tr className="text-center font-size13 ">
                        <td colSpan={10}>No data available at this moment</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {bulkUsers.length ? (
                <div className="text-center mb-3">
                  <button
                    type="submit"
                    onClick={userSubmit}
                    className="btn btn-success me-2 font-size13 "
                  >
                    Save
                  </button>
                  <button
                    type="submit"
                    onClick={deleteAll}
                    className="btn btn-danger font-size13 "
                  >
                    Delete All
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        title="User"
      />
    </>
  );
};

export default AddBulkUsers;
