// import { useEffect, useState } from "react";
// import AddTeam from "../components/teams/AddTeam";
// import TeamsTable from "../components/teams/TeamsTable";
// import { CommonContext } from "../context/CommonContext";
// import apiClient from "../services/ApiClient";
// import { changeTabTitle } from "../utils/changeTabTitle";

// const Teams = () => {
//   const [teams, setTeams] = useState([]);
//   const [teamLeads, setTeamLeads] = useState([]);
//   const [projects, setProjects] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [auditors, setAuditors] = useState([]);
//   const fetchData = async () => {
//     try {
//       const [
//         teamResponse,
//         leadResponse,
//         userResponse,
//         projectResponse,
//         auditorResponse,
//       ] = await Promise.all([
//         apiClient.get(`team`),
//         apiClient.get(`user/role/lead`),
//         apiClient.get(`user/role/user`),
//         apiClient.get(`project`),
//         apiClient.get(`user/role/auditor`),
//       ]);

//       // const teamResponse = await apiClient.get(`team`);
//       // const leadResponse = await apiClient.get(`user/role/lead`);
//       // const userResponse = await apiClient.get(`user/role/user`);
//       // const projectResponse = await apiClient.get(`project`);
//       // const auditorResponse = await apiClient.get(`user/role/auditor`);

//       setTeams(teamResponse.data.data);
//       setTeamLeads(leadResponse.data.data);
//       setUsers(userResponse.data.data);
//       setProjects(projectResponse.data.data);
//       setAuditors(auditorResponse.data.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Change TabTitle
//   changeTabTitle("Teams");
//   return (
//     <CommonContext.Provider
//       value={{
//         teams,
//         setTeams,
//         teamLeads,
//         setTeamLeads,
//         projects,
//         setProjects,
//         users,
//         setUsers,
//         auditors,
//         setAuditors,
//       }}
//     >
//       <div className="row">
//         <div className="col-12 mb-3">
//           <AddTeam fetchData={fetchData}   />
//         </div>
//         <div className="col-12">
//           <TeamsTable fetchData={fetchData} />
//         </div>
//       </div>
//     </CommonContext.Provider>
//   );
// };

// export default Teams;

import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import AddTeam from "../components/teams/AddTeam";
import TeamsTable from "../components/teams/TeamsTable";
import { CommonContext } from "../context/CommonContext";
import apiClient from "../services/ApiClient";
import { changeTabTitle } from "../utils/changeTabTitle";

const Teams = () => {
  const queryClient = useQueryClient();

  const fetchTeams = async () => {
    const response = await apiClient.get("team", { componentName: "teams" });
    return response.data.data;
  };

  const fetchLeads = async () => {
    const response = await apiClient.get("user/role/lead", {
      componentName: "leads",
    });
    return response.data.data;
  };

  const fetchUsers = async () => {
    const response = await apiClient.get("user/role/user", {
      componentName: "users",
    });
    return response.data.data;
  };

  const fetchProjects = async () => {
    const response = await apiClient.get("project", {
      componentName: "projects",
    });
    return response.data.data;
  };

  const fetchAuditors = async () => {
    const response = await apiClient.get("user/role/auditor", {
      componentName: "auditors",
    });
    return response.data.data;
  };

  const { data: teams = [], isFetching } = useQuery("teams", fetchTeams);
  const { data: teamLeads = [] } = useQuery("teamLeads", fetchLeads);
  const { data: users = [] } = useQuery("users", fetchUsers);
  const { data: projects = [] } = useQuery("projectsForOptions", fetchProjects);
  const { data: auditors = [] } = useQuery("auditors", fetchAuditors);

  useEffect(() => {
    changeTabTitle("Teams");
  }, []);

  const fetchData = () => {
    queryClient.invalidateQueries([
      "teams",
      "teamLeads",
      "users",
      "projectsForOptions",
      "auditors",
    ]);
  };

  return (
    <CommonContext.Provider
      value={{
        teams,
        teamLeads,
        projects,
        users,
        auditors,
      }}
    >
      <div className="row">
        <div className="col-12 mb-3">
          <AddTeam fetchData={fetchData} />
        </div>
        <div className="col-12">
          <TeamsTable fetchData={fetchData} isFetching={isFetching} />
        </div>
      </div>
    </CommonContext.Provider>
  );
};

export default Teams;
