import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import apiClient from "../services/ApiClient";
import { ucFirst } from "../utils/UcFirst";
import GlobalSearchModal from "./modals/GlobalSearchModal";
import GlobalSearch from "./modals/GlobalSearch";
import Offcanvas from "react-bootstrap/Offcanvas";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

const TopNavbar = ({ showMobileSidebar }) => {
  const auth = useAuth();

  const [showGlobalSearchModal, setShowGlobalSearchModal] = useState(false);

  const closeGlobalSearchModal = () => {
    setShowGlobalSearchModal(false);
  };

  const [timer, setTimer] = useState(new Date());
  const [show, setShow] = useState(false);
  const [usersWithStatus, setUsersWithStatus] = useState([]);
  // const [showCanvas, setShowCanvas] = useState(false);
  const [usersOnlineCount, setUsersOnlineCount] = useState("");
  const [usersOfflineCount, setUsersOfflineCount] = useState("");
  const [isinit, setisinit] = useState(false);
  const [customDate, setcustomDate] = useState(
    new Date().toDateString().slice(4)
  );
  const [pusherChannel, setPusherChannel] = useState(null);
  // const [customDate, setcustomDate] = useState(new Date().toDateString().slice(4));
  // setInterval(() => setTimer(new Date()), 1000);

  // console.log(usersWithStatus);

  const params = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // let interval = setInterval(() => setTimer(new Date()), 1000);
    // setcustomDate(new Date().toLocaleDateString);
    // fetchDate();
    if (auth && auth.user && auth.user.role === "lead") {
      fetchRealTimeStatusForTeamUsers();
    }
    // return () => clearInterval(interval);
  }, []);

  // const fetchDate = async () => {
  //   try {
  //     const response = await apiClient.get("/custom-date");

  //     setcustomDate(response.data.date);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchRealTimeStatusForTeamUsers = async () => {
    try {
      let teamId = params.teamId || auth.user.active_team_id;
      const response = await apiClient.get(`/real-time/count/${teamId}`);
      setUsersOnlineCount(response.data.online);
      setUsersOfflineCount(response.data.offline);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchUsersWithStatus = async () => {
    try {
      let teamId = params.teamId || auth.user.active_team_id;
      const response = await apiClient.get(`/real-time/users/${teamId}`);
      setShow(true);
      setUsersWithStatus(response.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setisinit(true);
    }
  };

  useEffect(() => {
    if (auth && auth.user && auth.user.role === "lead") {
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "f149eb18b98e874086f3",
        cluster: "ap2",
        forceTLS: true,
        // authEndpoint : "http://localhost:8000/broadcasting/auth",
        // auth: {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //     Accept: 'application/json',
        //   },
        // },
      });

      var channel = window.Echo.channel("real-time-status");
      setPusherChannel(channel);
      // console.log(channel);

      // Pusher.logToConsole = true;
    }
  }, []);

  useEffect(() => {
    if (auth && auth.user && auth.user.role === "lead") {
      if (!isinit) {
        return;
      }
      const abortController = new AbortController();
      if (pusherChannel) {
        pusherChannel.listen(
          `.user-live-status-${auth.user.active_team_id}`,
          function (data) {
            // console.log("0");
            // console.log(data);
            if (data.event_data) {
              // toast.info(data.event_data.notification);
              // console.log(usersWithStatus);

              setUsersOnlineCount(data.event_data.online);
              setUsersOfflineCount(data.event_data.offline);

              const updatedUsers = usersWithStatus.map((item) => {
                return item.index === data.event_data.user_id
                  ? { ...item, live_status: data.event_data.status }
                  : item;
              });

              if (updatedUsers) {
                setUsersWithStatus(updatedUsers);
              }

              // console.log(updatedUsers);
            }
          }
        );
      }
      return () => {
        abortController.abort();
      };
    }
  }, [pusherChannel, isinit]);

  return (
    <>
      <header className="mb-3 ">
        <nav className="navbar  navbar-expand-sm flex-nowrap justify-content-between top-navbar w-100">
          <ul className="navbar-nav sm-d-flex justify-content-between align-items-center w-100">
            <li className="pe-auto w-25">
              <img
                src="/images/SecqureOneHealth_logo-1.png"
                alt="logo"
                height={38}
                className="ms-3"
              />
            </li>
            {/* <li className="search w-75">
              <div className="row ">
                <div class="px-0 m-auto ">
                  <GlobalSearch />
                </div>
              </div>
            </li> */}
          </ul>

          <div
            className="navbar-brand sidebar-toggle d-lg-none"
            onClick={(e) => {
              showMobileSidebar();
            }}
          >
            <i className="fas fa-bars fa-lg"></i>
          </div>

          {auth && auth.user && auth.user?.role === "lead" && (
            <>
              <p
                className="text-light mb-0 fw-bold me-4"
                onClick={fetchUsersWithStatus}
              >
                <span class="position-relative bg-transparent">
                  <i
                    class="fas fa-user-check fa-lg"
                    style={{ color: "#7CB524" }}
                  ></i>
                  <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill text-light bg-gradient font-14">
                    {usersOnlineCount}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </span>
              </p>

              <p
                className="text-light mb-0 fw-bold me-5"
                onClick={fetchUsersWithStatus}
              >
                <span class="position-relative bg-transparent">
                  {/* <i class="fas fa-user-slash text-danger fa-lg"></i> */}
                  <i class="fas fa-user-times text-danger me-2  fa-lg"></i>
                  <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-gradient">
                    {usersOfflineCount}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </span>
              </p>
            </>
          )}

          <ul className="navbar-nav align-items-center me-3 d-none d-lg-flex justify-content-end font-size13 w-25">
            {/* <p className="text-light mb-0 fw-bold me-3"> */}
            {/* <span
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowGlobalSearchModal(true);
                }}
              > */}
            {/* <i
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
                class="fa-solid fa-magnifying-glass set-icon me-3"
              ></i> */}
            {/* <i class="fas fa-search fa-2x me-3"></i> */}
            {/* </span> */}

            {/* </p> */}
            {/* <div className="aVerticalSeparator"></div> */}

            <i class="fa fa-solid fa-calendar-week ms-2 font-size35"></i>
            <p className="text-light mb-0 ps-3 me-3 font-size14 text-nowrap">
              <span>{customDate}</span> <br />
              <small>{timer.toLocaleTimeString()}</small>
            </p>
            <hr className="text-light h-6 vr" style={{ minHeight: "45px " }} />
            {/* <div className="aVerticalSeparator"></div> */}
            <li className="ps-3">
              {/* <img src='/images/img_avatar.png' alt='avatar' className='avatar' /> */}
              {/* <i className="fas fa-user fa-2x avatar"></i> */}
              <i class="fas fa-user-circle fs-1"></i>
            </li>
            <p className="px-3 mb-0  text-nowrap">
              {auth && auth.user ? ucFirst(auth.user.name) : "Dev Test"} <br />
              <small style={{ color: "#45C4D6", fontSize: "12px" }}>
                {auth && auth.user && auth.user.role === "user"
                  ? "Coder"
                  : ucFirst(auth?.user?.role.split("_").join(" "))}
              </small>
            </p>
            {/* <p className='pe-3'></p> */}
          </ul>
        </nav>
        {/* <div className="w-100 search-d-none">
          <div class=" mt-3 d-flex justify-content-center w-100 ms-5 ">
            <GlobalSearch />
          </div>
        </div> */}
      </header>

      {auth && auth.user && auth.user.role === "lead" && (
        <>
          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <p className="text-center">
                  <span class="position-relative bg-transparent me-4">
                    <i
                      class="fas fa-user-check fa-lg"
                      style={{ color: "#7CB524" }}
                    ></i>
                    <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill text-light bg-gradient font-14">
                      {usersOnlineCount}
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </span>

                  <span class="position-relative bg-transparent">
                    {/* <i class="fas fa-user-slash text-danger fa-lg"></i> */}
                    <i class="fas fa-user-times text-danger me-2  fa-lg"></i>
                    <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-gradient">
                      {usersOfflineCount}
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </span>
                </p>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div>
                <div className="row mb-4">
                  <div className="col-12">
                    {usersWithStatus.length &&
                      usersWithStatus.map((user, index) => (
                        <p className=" pb-3">
                          <Link
                            to={`/${user.user_name.trim()}/profile`}
                            state={{ user_id: user.index }}
                            className="i-link"
                          >
                            <span className="text-white h6 ms-3 font-size13">
                              {user?.live_status === "online" ? (
                                // <i class="fas fa-user-check me-2" style={{ color: "#7CB524" }}></i>
                                <i
                                  class="fas fs-6 fa-user me-2 "
                                  style={{ color: "#7CB524" }}
                                ></i>
                              ) : (
                                <i class="fas fa-user-times text-danger me-2"></i>
                                // <i class="fas fa-user-slash text-danger me-2"></i>
                              )}
                              {user?.user_name}

                              {user.time_logs.length ? (
                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  overlay={
                                    user.time_logs.length ? (
                                      <Popover id="popover-basic">
                                        {user.time_logs.map(
                                          (timeLog, index) => (
                                            <>
                                              <Popover.Header as="h3">
                                                <div className="text-dark d-flex justify-content-between align-items-center">
                                                  <div className="ms-0 font-size13">
                                                    <p className="mb-0">Date</p>{" "}
                                                    {timeLog.date}
                                                  </div>
                                                  <div className="ms-2 font-size13">
                                                    <p className="mb-0">
                                                      Total Hours
                                                    </p>{" "}
                                                    {timeLog.totalHours}
                                                  </div>
                                                </div>
                                              </Popover.Header>
                                              <Popover.Body>
                                                <div key={index}>
                                                  {timeLog.validInOutLogs
                                                    .length ? (
                                                    timeLog.validInOutLogs.map(
                                                      (item, index) => (
                                                        <div key={index}>
                                                          <span>
                                                            <img
                                                              src="/images/green-arrow.png"
                                                              width="10px"
                                                              alt="in-time"
                                                              className="mx-2"
                                                            />
                                                            <span className="font-size12 text-nowrap">
                                                              {item.start_time}
                                                            </span>
                                                          </span>
                                                          <span>
                                                            {" "}
                                                            <img
                                                              src="/images/red-arrow.png"
                                                              width="10px"
                                                              alt="in-time"
                                                              className="mx-2"
                                                            />{" "}
                                                            <span className="font-size12 text-nowrap">
                                                              {item.end_time}
                                                            </span>
                                                          </span>
                                                          {/* <span>
                                                    Active Minutes:
                                                    {item.active_minutes}
                                                  </span> */}
                                                        </div>
                                                      )
                                                    )
                                                  ) : (
                                                    <span>
                                                      No valid logs available.
                                                    </span>
                                                  )}
                                                </div>
                                              </Popover.Body>
                                            </>
                                          )
                                        )}
                                      </Popover>
                                    ) : (
                                      <Popover id="popover-basic">
                                        <>
                                          <Popover.Header as="h3">
                                            <span className="text-dark d-flex justify-content-between align-items-center">
                                              {/* <span className="ms-auto">{timeLog.date}</span> */}
                                            </span>
                                          </Popover.Header>
                                          <Popover.Body>
                                            <p>No data available</p>
                                          </Popover.Body>
                                        </>
                                      </Popover>
                                    )
                                  }
                                >
                                  <i
                                    className="far fa-id-card ms-3"
                                    role="button"
                                  ></i>
                                </OverlayTrigger>
                              ) : null}

                              {/* <OverlayTrigger
                              trigger="click"
                              placement="right"
                              overlay={
                                user.time_logs.length ? (
                                  <Popover>
                                    <Popover.Title as="h3">
                                      Time Logs
                                    </Popover.Title>
                                    <Popover.Content>
                                      {user.time_logs.map((timeLog, index) => (
                                        <div key={index}>
                                          <span>
                                            Date: {timeLog.date} <br />
                                            Total Hours: {timeLog.totalHours}
                                          </span>
                                          {timeLog.validInOutLogs.length ? (
                                            timeLog.validInOutLogs.map(
                                              (item, index) => (
                                                <div key={index}>
                                                  <span>
                                                    Start Time:{" "}
                                                    {item.start_time}
                                                  </span>
                                                  <span>
                                                    End Time: {item.end_time}
                                                  </span>
                                                  <span>
                                                    Active Minutes:{" "}
                                                    {item.active_minutes}
                                                  </span>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <span>
                                              No valid logs available.
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    </Popover.Content>
                                  </Popover>
                                ) : null
                              }
                            >
                              <i className="far fa-id-card ms-3"></i>
                            </OverlayTrigger> */}
                            </span>
                            <br />

                            <i
                              class="fas fa-user-slash invisible ms-3 me-2"
                              style={{ color: "#7CB524" }}
                            ></i>
                            <span className="text-white">
                              <small className="text-muted font-13">
                                Employee Id:
                              </small>
                              <small className="font-13 ms-2">
                                {user?.e_id}
                              </small>
                            </span>
                            {/* <i className="fa fa-user" aria-hidden="true" /> */}
                          </Link>

                          {/* {user?.live_status === "offline" ? (
                            <span className="text-white h6 ms-3">
                              <small className="text-muted font-13">
                                Last seen:
                              </small>
                              <small className="font-13 ms-2">
                                30 mins ago
                              </small>
                            </span>
                          ) : null} */}
                          {/* <br /> */}
                          {/* <span className="text-white h6 ms-3"> {user?.e_id}</span> */}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </>
  );
};

export default TopNavbar;
