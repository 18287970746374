import { useFormik } from "formik";
import DatePicker from "react-datepicker";
// import { ucFirst } from "../../utils/UcFirst";

// import Select from "react-select";

import * as yup from "yup";
import { useEffect, useRef, useState } from "react";

import apiClient from "../../services/ApiClient";
import { useAuth } from "../../context/AuthContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import ResetAuditorErrFields from "../../utils/Modals/ResetAuditorErrFields";
import ErrorFeedback from "../ErrorFeedback";
import AddAuditsForClientR from "../auditsForClientR/AddAuditsForClientR";

const AddAudits = (props) => {
  const [chartDetails, setChartDetails] = useState(null);
  const [errorStatus, setErrorStatus] = useState("no");
  const [show, setShow] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const [isErrFieldRpActive, setisErrFieldRpActive] = useState(false);
  const [isErrFieldDosActive, setisErrFieldDosActive] = useState(false);
  const [isErrFieldCommentActive, setisErrFieldCommentActive] = useState(false);
  const [isErrFieldSignatureActive, setisErrFieldSignatureActive] =
    useState(false);
  const [showResetAuditorErrModal, setshowResetAuditorErrModal] =
    useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectItem = (e) => {
    setSelectedItems([...selectedItems, e.target.value]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [chartDetailsId, setChartDetailsId] = useState(null);
  // const selectInputRef = useRef();

  const auth = useAuth();
  // const { fetchData, editId, editFormData } = props;

  const auditorProjectId = auth.user.project_id;

  // Check whether selected project is LHP
  const isLHPProject =
    Number(auditorProjectId) ===
    Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);
  // const [showSecondaryComment, setShowSecondaryComment] = useState(() => (isLHPProject ? true : false));

  // const handleChangeChartId = ({ value }) => {
  //   formik.setFieldValue("chart_id", value);
  //   fetchChartDetails(value);
  // };

  // Fetch charts details based on the chart id
  const fetchChartDetails = async (chartId) => {
    try {
      let digits;
      if (isLHPProject) {
        digits = 7;
      } else {
        digits = 9;
      }
      if (chartId && chartId.length >= 5) {
        const { data } = await apiClient.get(
          `user-chart/chart_data/${chartId}`
        );
        if (isLHPProject) {
          formik.setFieldValue("icd_codes", data.data.icd);
          formik.setFieldValue("cpt_codes", data.data.cpt_codes);
        }
        setShowCanvas(true);
        setChartDetails(data.data);
        formik.setFieldValue("primary_chart_id", data.data.id);
        // localStorage.setItem("project", data.data.project_id);

        formik.setErrors({});
        formik.setTouched({}, false);
      } else if (chartId.length === 0) {
        formik.setErrors({
          chart_id: "Chart Id is required.",
        });
      } else {
        // formik.setErrors({
        //   chart_id: `Must be ${digits} digits.`,
        // });
      }
    } catch (err) {
      formik.setErrors({
        chart_id: err.response.data.error,
      });
    }
  };

  // Bind default attributes from coder chart id chart exist
  useEffect(() => {
    if (chartDetails) {
      formik.setFieldValue("coder_name", chartDetails.user.name);
      formik.setFieldValue("total_pages", chartDetails.pages);
      if (!isLHPProject) {
        formik.setFieldValue("original_code_found", chartDetails.icd);
      }
      formik.setFieldValue(
        "coding_complete_date",
        new Date(chartDetails.created_at)
      );
    }
  }, [chartDetails]);

  const formik = useFormik({
    initialValues: {
      chart_id: "",
      [!isLHPProject && "error_fields"]: !isLHPProject && [],
      [!isLHPProject && "audit_type"]: !isLHPProject && "traditional",
      [!isLHPProject && "chart_qa_score"]: !isLHPProject && "",
      [!isLHPProject && "dx_qa_score"]: !isLHPProject && "",
      [!isLHPProject && "original_code_found"]: !isLHPProject && "",
      [!isLHPProject && "total_errors"]: !isLHPProject && "",
      [!isLHPProject && "dx_codes_error"]: !isLHPProject && 0,
      [!isLHPProject && "admin_error"]: !isLHPProject && 0,
      [!isLHPProject && "codes_added"]: !isLHPProject && 0,
      [!isLHPProject && "codes_deleted"]: !isLHPProject && 0,
      [!isLHPProject && "dx_codes_updated"]: !isLHPProject && 0,
      [!isLHPProject && "dos_corrected"]: !isLHPProject && 0,
      [!isLHPProject && "pos_corrected"]: !isLHPProject && 0,
      [!isLHPProject && "dx_level_comment_code_corrected"]: !isLHPProject && 0,
      [!isLHPProject && "rendering_provider_corrected"]: !isLHPProject && 0,
      [isLHPProject && "dx_qa_score"]: !isLHPProject && "",
      [!isLHPProject && "primary_error_status"]: isLHPProject && "",
      // LHP
      [isLHPProject && "cpt_qa_score"]: isLHPProject && "",
      [isLHPProject && "cpt_errors"]: isLHPProject && "",
      [isLHPProject && "cpt_codes"]: isLHPProject && "",
      [isLHPProject && "icd_qa_score"]: isLHPProject && "",
      [isLHPProject && "icd_errors"]: isLHPProject && "",
      [isLHPProject && "icd_codes"]: isLHPProject && "",
      // common
      primary_chart_id: "",
      coder_name: "",
      err_field_signature: 0,
      err_field_comment: 0,
      coding_complete_date: "",
      audit_complete_date: new Date(),
      total_pages: "",
      audit_comment: isLHPProject ? "" : "No Errors, Great Job!!!",
    },
    validationSchema: yup.object({
      // chart_id: yup
      //   .number()
      //   .typeError("Must be a number")
      //   .required("Chart ID is required"),
      // coder_name: yup.string().min(3, "Too Short!").max(75, "Must be 25 characters or less").required("Name is required"),
      // total_pages: yup.number().typeError("Must be a number").required("Field is required"),
      // audit_comment: yup.string().required("Field is required"),
      // audit_type: yup.string().required("Field is required"),
      // chart_qa_score: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_qa_score: yup.number().typeError("Must be a number").required("Field is required"),
      // original_code_found: yup.number().typeError("Must be a number").required("Field is required"),
      // total_errors: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_codes_error: yup.number().typeError("Must be a number").required("Field is required"),
      // admin_error: yup.number().typeError("Must be a number").required("Field is required"),
      // codes_added: yup.number().typeError("Must be a number").required("Field is required"),
      // codes_deleted: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_codes_updated: yup.number().typeError("Must be a number").required("Field is required"),
      // dos_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // pos_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_level_comment_code_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // rendering_provider_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // error_fields: yup.array().of(
      //   yup.object().shape({
      //     err_status: yup.string().required("Error status is required"),
      //   })
      // ),
    }),
    onSubmit: async (values) => {
      try {
        // console.log(values);
        const response = await apiClient.post("audit/store", values);
        // window.location.reload();
        // fetchData();
        // fetchChartIds();
        formik.resetForm();
        setChartDetails(null);
      } catch (err) {
        // console.log("____---" + err.response.data.error);
        // console.log("Error: " + err.response.data.error.chart_id);
        formik.setErrors(err.response.data.error);
      }
    },
  });

  // Concat primary and secondary comment
  // useEffect(() => {
  //   if (!isLHPProject) {
  //     const auditorFeedback = formik.values.audit_comment.split("-").shift();
  //     formik.setFieldValue("auditor_feedback", auditorFeedback);
  //     if (auditorFeedback == "no_changes" || auditorFeedback == "") {
  //       setShowSecondaryComment(false);
  //     } else {
  //       setShowSecondaryComment(true);
  //     }
  //   }
  // }, [formik.values.audit_comment]);

  useEffect(() => {
    setChartDetails("");
    formik.setFieldValue("coder_name", "");
    formik.setFieldValue("total_pages", "");
    if (!isLHPProject) {
      formik.setFieldValue("original_code_found", "");
    }
    formik.setFieldValue("coding_complete_date", "");
  }, [formik.values.chart_id]);

  // Calculation for Admin Errors Starts
  useEffect(() => {
    if (
      formik.values.dos_corrected ||
      formik.values.rendering_provider_corrected ||
      formik.values.pos_corrected ||
      formik.values.dx_level_comment_code_corrected ||
      formik.values.err_field_signature ||
      formik.values.err_field_comment
    ) {
      let totalAdminErrors =
        Number(formik.values.dos_corrected) +
        Number(formik.values.pos_corrected) +
        Number(formik.values.dx_level_comment_code_corrected) +
        Number(formik.values.err_field_signature) +
        Number(formik.values.err_field_comment) +
        Number(formik.values.rendering_provider_corrected);
      if (!isLHPProject) {
        formik.setFieldValue("admin_error", totalAdminErrors);
      }
    }
  }, [
    formik.values.dos_corrected,
    formik.values.rendering_provider_corrected,
    formik.values.pos_corrected,
    formik.values.dx_level_comment_code_corrected,
    formik.values.err_field_signature,
    formik.values.err_field_comment,
  ]);
  // Calculation for Admin Errors Ends

  // Calculation for DX codes Error Starts
  useEffect(() => {
    if (
      formik.values.codes_added ||
      formik.values.codes_deleted ||
      formik.values.dx_codes_updated
    ) {
      let totalErrors =
        Number(formik.values.codes_added) +
        Number(formik.values.codes_deleted) +
        Number(formik.values.dx_codes_updated);
      if (!isLHPProject) {
        formik.setFieldValue("dx_codes_error", totalErrors);
      }
    }
  }, [
    formik.values.codes_added,
    formik.values.dx_codes_updated,
    formik.values.codes_deleted,
  ]);
  // Calculation for DX codes Error Ends

  // Calculation for Chart QA Score Starts
  useEffect(() => {
    if (
      formik.values.dx_codes_error ||
      formik.values.admin_error ||
      formik.values.original_code_found
    ) {
      let totalAbsoluteErrors =
        Number(formik.values.dx_codes_error) +
        Number(formik.values.admin_error);
      if (!isLHPProject) {
        formik.setFieldValue("total_errors", totalAbsoluteErrors);
      }

      let dividedValue =
        totalAbsoluteErrors / Number(formik.values.original_code_found);

      let finalValue = 100 - dividedValue * 100;

      if (!isLHPProject) {
        if (Number.isNaN(finalValue)) {
          formik.setFieldValue("chart_qa_score", 100);
        } else {
          if (finalValue === Number.NEGATIVE_INFINITY) {
            formik.setFieldValue("chart_qa_score", 100);
          } else {
            formik.setFieldValue("chart_qa_score", finalValue);
          }
        }
      }
    }
  }, [
    formik.values.dx_codes_error,
    formik.values.admin_error,
    formik.values.original_code_found,
  ]);
  // Calculation for Chart QA Score Ends

  // Calculation for DX QA Score Starts
  useEffect(() => {
    if (
      formik.values.dx_codes_updated ||
      formik.values.codes_deleted ||
      formik.values.original_code_found ||
      formik.values.codes_added
    ) {
      let value1 =
        Number(formik.values.original_code_found) -
        Number(formik.values.codes_deleted) -
        Number(formik.values.dx_codes_updated);
      let value2 =
        Number(formik.values.original_code_found) +
        Number(formik.values.codes_added);

      let finalValueForDX = (value1 / value2) * 100;
      if (!isLHPProject) {
        if (Number.isNaN(finalValueForDX)) {
          formik.setFieldValue("dx_qa_score", 100);
        } else {
          if (finalValueForDX === Number.NEGATIVE_INFINITY) {
            formik.setFieldValue("dx_qa_score", 100);
          } else {
            formik.setFieldValue("dx_qa_score", finalValueForDX);
          }
        }
      }
    }
  }, [
    formik.values.dx_codes_updated,
    formik.values.codes_deleted,
    formik.values.original_code_found,
    formik.values.codes_added,
  ]);
  // Calculation for DX QA Score Ends

  // Operation for changing error status
  useEffect(() => {
    if (!isLHPProject) {
      const errFieldsCount = formik.values.error_fields.length;
      if (errorStatus === "no" && !isLHPProject) {
        formik.setFieldValue("audit_comment", "No Errors, Great Job!!!");
        if (errFieldsCount > 0) {
          setshowResetAuditorErrModal(true);
        }
      }
      formik.setFieldValue("primary_error_status", errorStatus);
    }
  }, [errorStatus]);

  // Add new error fields
  const addErrorFields = (e) => {
    if (!isLHPProject) {
      e.preventDefault();
      const newField = [
        {
          err_status: "",
          dx_comment: "",
          comment_2: "",
          comment_2_count: "",
          comment_3: "",
          secondary_comment: "",
        },
      ];

      formik.setFieldValue("error_fields", [
        ...formik.values.error_fields,
        ...newField,
      ]);
    }
  };

  // Remove error row based on key
  const removeErrorFields = (e, index) => {
    if (!isLHPProject) {
      e.preventDefault();
      const overAllErrFields = [...formik.values.error_fields];
      overAllErrFields.splice(index, 1);
      formik.setFieldValue("error_fields", overAllErrFields);
    }
  };

  // Calculate codes added, deleted and updated. Based on selected values of dynamic fields
  useEffect(() => {
    if (!isLHPProject) {
      const errFieldsArr = formik.values.error_fields;
      console.log(errFieldsArr);
      let addedCount = 0;
      let deletedCount = 0;
      let updatedCount = 0;
      let dosCorrected = 0;
      let rpCorrected = 0;
      let signatureCount = 0;
      let commentCount = 0;

      errFieldsArr.map((field, index) => {
        const errStatus = field.err_status;
        const errComment2 = field.comment_2;
        const errComment2Count = Number(field.comment_2_count);

        if (errStatus === "added") {
          addedCount++;
        }
        if (errStatus === "deleted") {
          deletedCount++;
        }
        if (errStatus === "updated") {
          updatedCount++;
        }
        if (errStatus === "admin") {
          if (errComment2 === "RP") {
            rpCorrected = errComment2Count;
            setisErrFieldRpActive(true);
          }
          if (errComment2 === "DOS") {
            dosCorrected = errComment2Count;
            setisErrFieldDosActive(true);
          }
          if (errComment2 === "Comment") {
            commentCount = errComment2Count;
            setisErrFieldCommentActive(true);
          }
          if (errComment2 === "Signature") {
            signatureCount = errComment2Count;
            setisErrFieldSignatureActive(true);
          }
        }
      });
      formik.setFieldValue("codes_added", addedCount);
      formik.setFieldValue("dx_codes_updated", updatedCount);
      formik.setFieldValue("dos_corrected", dosCorrected);
      formik.setFieldValue("err_field_comment", commentCount);
      formik.setFieldValue("err_field_signature", signatureCount);
      formik.setFieldValue("rendering_provider_corrected", rpCorrected);
      formik.setFieldValue("codes_deleted", deletedCount);
    }
  }, [formik.values.error_fields]);

  // Closes Reset modal
  const closeResetAuditorErModal = () => {
    setshowResetAuditorErrModal(false);
    setErrorStatus("yes");
  };

  // Reset error fileds if "proceed" is opted in Reset err modal
  const resetErrorFields = () => {
    formik.setFieldValue("error_fields", []);
    setshowResetAuditorErrModal(false);
  };

  // Calculate CPT QA Score
  useEffect(() => {
    if (isLHPProject) {
      if (formik.values.cpt_codes && formik.values.cpt_errors) {
        let finalValue =
          100 -
          (Number(formik.values.cpt_errors) / Number(formik.values.cpt_codes)) *
            100;
        formik.setFieldValue("cpt_qa_score", finalValue);
      }
    }
  }, [formik.values.cpt_codes, formik.values.cpt_errors]);

  // Calculate ICD QA Score
  useEffect(() => {
    if (isLHPProject) {
      if (formik.values.icd_codes && formik.values.icd_errors) {
        let finalValue =
          100 -
          (Number(formik.values.icd_errors) / Number(formik.values.icd_codes)) *
            100;
        formik.setFieldValue("icd_qa_score", finalValue);
      }
    }
  }, [formik.values.icd_codes, formik.values.icd_errors]);

  return (
    <>
      {auth.user.active_team_id ? (
        auth.user.active_login_id ? (
          <>
            {auth.user.is_user_error_free === "true" ? (
              <>
                {auth.user.project_id == process.env.REACT_APP_R_PROJECT_ID ? (
                  <>
                    <AddAuditsForClientR />
                  </>
                ) : (
                  <>
                    <div className="card mb-3">
                      {showCanvas && chartDetails && (
                        <div className="card-header text-end">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={handleShow}
                          >
                            {chartDetails.chart_id ?? ""}
                            <i className="fas fa-arrow-circle-right ms-2"></i>
                          </button>
                        </div>
                      )}
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit} autoComplete="off">
                          <div className="row align-items-center justify-content-center px-lg-2">
                            <div className="col-lg px-1">
                              <label
                                htmlFor="chart_id"
                                className="form-label required"
                              >
                                {isLHPProject ? "Encounter Number" : "Chart Id"}
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="chart_id"
                                  className={`form-control font-size13  ${
                                    formik.touched.chart_id &&
                                    formik.errors.chart_id &&
                                    "is-invalid"
                                  }`}
                                  id="chart_id"
                                  // placeholder='Chart ID'
                                  maxLength={9}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.chart_id}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      fetchChartDetails(formik.values.chart_id);
                                    }
                                  }}
                                />
                                <button
                                  className="btn btn-primary shadow-none outline-none border-0"
                                  type="button"
                                  id="button-addon2"
                                  onClick={() =>
                                    fetchChartDetails(formik.values.chart_id)
                                  }
                                >
                                  <i className="fas fa-search"></i>
                                </button>
                              </div>
                              {formik.touched.chart_id &&
                              formik.errors.chart_id ? (
                                <div
                                  id="chart_id"
                                  className="invalid-feedback d-block"
                                >
                                  {formik.errors.chart_id}
                                </div>
                              ) : (
                                <div className="invisible">
                                  <span>invisible</span>
                                </div>
                              )}
                            </div>
                            {/* <div className='col-lg px-1'>
              <label htmlFor='chart_id' className='form-label required'>
                Chart Id
              </label>
              <Select
                name='chart_id'
                ref={selectInputRef}
                isClearable={true}
                options={chartIdOptions}
                isMulti={false}
                closeMenuOnSelect={true}
                isSearchable
                onChange={handleChangeChartId}
                styles={{ border: 0, backgroundColor: "#765674" }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    text: "orangered",

                    primary25: "skyblue",
                    primary: "black",

                    neutral0: "#062549",
                    neutral10: "#062549",
                  },
                })}
              />

              {formik.errors.chart_id ? (
                <div id='chart_id' className='invalid-feedback'>
                  {formik.errors.chart_id}
                </div>
              ) : (
                <div className='invisible'>
                  <span>invisible</span>
                </div>
              )}
            </div> */}
                            <div className="col-lg px-1">
                              <label
                                htmlFor="audit_complete_date"
                                className="form-label"
                              >
                                {isLHPProject
                                  ? "Date of audit "
                                  : "Audit Complete Date "}
                                <small>(MM/DD/YYYY)</small>
                              </label>
                              {/* <input
                type='date'
                // placeholder='D.O.B'
                name='audit_complete_date'
                className={`form-control ${formik.touched.audit_complete_date && formik.errors.audit_complete_date && "is-invalid"}`}
                id='audit_complete_date'
                // placeholder='D.O.B'
                onChange={formik.handleChange}
                // onFocus={(e) => (e.target.type = "date")}
                onBlur={formik.handleBlur}
                value={formik.values.audit_complete_date}
              /> */}
                              <DatePicker
                                className="form-control font-size13 "
                                // dateFormat='dd/MM/yyyy'
                                disabled
                                selected={formik.values.audit_complete_date}
                                maxDate={new Date()}
                                onChange={(date) =>
                                  formik.setFieldValue(
                                    "audit_complete_date",
                                    date
                                  )
                                }
                              />
                              {formik.touched.audit_complete_date &&
                              formik.errors.audit_complete_date ? (
                                <div
                                  id="audit_complete_date"
                                  className="invalid-feedback"
                                >
                                  {formik.errors.audit_complete_date}
                                </div>
                              ) : (
                                <div className="invisible">
                                  <span>invisible</span>
                                </div>
                              )}
                            </div>
                            {!isLHPProject && (
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="audit_type"
                                  className="form-label required"
                                >
                                  Audit Type
                                </label>
                                {/* <input
                type='text'
                name='audit_type'
                className={`form-control ${formik.touched.audit_type && formik.errors.audit_type && "is-invalid"}`}
                id='audit_type'
                // placeholder='Chart ID'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.audit_type}
              /> */}

                                <select
                                  name="audit_type"
                                  className={`form-select form-control font-size13  ${
                                    formik.touched.audit_type &&
                                    formik.errors.audit_type &&
                                    "is-invalid"
                                  }`}
                                  id="audit_type"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.audit_type}
                                >
                                  <option value="traditional">
                                    Traditional
                                  </option>
                                  <option value="traditional_lookback">
                                    Traditional / Lookback
                                  </option>
                                  <option value="lookback">Lookback</option>
                                </select>

                                {formik.touched.audit_type &&
                                formik.errors.audit_type ? (
                                  <div
                                    id="audit_type"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.audit_type}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            )}

                            {!isLHPProject && (
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="original_code_found"
                                  className="form-label required"
                                >
                                  Original Codes Found
                                </label>
                                <input
                                  type="text"
                                  name="original_code_found"
                                  className={`form-control font-size13 ${
                                    formik.touched.original_code_found &&
                                    formik.errors.original_code_found &&
                                    "is-invalid"
                                  }`}
                                  id="original_code_found"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.original_code_found}
                                />
                                {formik.touched.original_code_found &&
                                formik.errors.original_code_found ? (
                                  <div
                                    id="original_code_found"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.original_code_found}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            )}

                            {isLHPProject ? (
                              <div className={`col-lg px-1`}>
                                <label
                                  htmlFor="audit_comment"
                                  className="form-label required"
                                >
                                  Error Comment
                                </label>
                                <textarea
                                  type="text"
                                  name="audit_comment"
                                  // rows={2}
                                  className={`form-control font-size13 ${
                                    formik.touched.audit_comment &&
                                    formik.errors.audit_comment &&
                                    "is-invalid"
                                  }`}
                                  id="audit_comment"
                                  style={{ height: "10px" }}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.audit_comment}
                                ></textarea>

                                {formik.touched.audit_comment &&
                                formik.errors.audit_comment ? (
                                  <div
                                    id="audit_comment"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.audit_comment}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>

                          {isLHPProject ? null : (
                            <div className="row align-items-center justify-content-start px-lg-2">
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="dos_corrected"
                                  className="form-label required"
                                >
                                  Dos Corrected
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="dos_corrected"
                                  className={`form-control  font-size13 ${
                                    formik.touched.dos_corrected &&
                                    formik.errors.dos_corrected &&
                                    "is-invalid"
                                  }`}
                                  id="dos_corrected"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.dos_corrected}
                                />
                                {formik.touched.dos_corrected &&
                                formik.errors.dos_corrected ? (
                                  <div
                                    id="dos_corrected"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.dos_corrected}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg px-1">
                                <label
                                  htmlFor="rendering_provider_corrected"
                                  className="form-label required"
                                >
                                  Rendering Provider Corrected
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="rendering_provider_corrected"
                                  className={`form-control font-size13  ${
                                    formik.touched
                                      .rendering_provider_corrected &&
                                    formik.errors
                                      .rendering_provider_corrected &&
                                    "is-invalid"
                                  }`}
                                  id="rendering_provider_corrected"
                                  // placeholder='rendering_provider_corrected'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.rendering_provider_corrected
                                  }
                                />
                                {formik.touched.rendering_provider_corrected &&
                                formik.errors.rendering_provider_corrected ? (
                                  <div
                                    id="rendering_provider_corrected"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.rendering_provider_corrected}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg px-1">
                                <label
                                  htmlFor="dx_level_comment_code_corrected"
                                  className="form-label required"
                                >
                                  Dx Level Comment Code Corrected
                                </label>
                                <input
                                  type="text"
                                  // placeholder='D.O.B'
                                  name="dx_level_comment_code_corrected"
                                  className={`form-control font-size13  ${
                                    formik.touched
                                      .dx_level_comment_code_corrected &&
                                    formik.errors
                                      .dx_level_comment_code_corrected &&
                                    "is-invalid"
                                  }`}
                                  id="dx_level_comment_code_corrected"
                                  // placeholder='D.O.B'
                                  onChange={formik.handleChange}
                                  // onFocus={(e) => (e.target.type = "date")}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values
                                      .dx_level_comment_code_corrected
                                  }
                                />
                                {formik.touched
                                  .dx_level_comment_code_corrected &&
                                formik.errors
                                  .dx_level_comment_code_corrected ? (
                                  <div
                                    id="dx_level_comment_code_corrected"
                                    className="invalid-feedback"
                                  >
                                    {
                                      formik.errors
                                        .dx_level_comment_code_corrected
                                    }
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>

                              <div className="col-lg px-1">
                                <label
                                  htmlFor="pos_corrected"
                                  className="form-label required"
                                >
                                  Pos Corrected
                                </label>
                                <input
                                  type="text"
                                  name="pos_corrected"
                                  className={`form-control font-size13  ${
                                    formik.touched.pos_corrected &&
                                    formik.errors.pos_corrected &&
                                    "is-invalid"
                                  }`}
                                  id="pos_corrected"
                                  // placeholder='Member Name'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.pos_corrected}
                                />
                                {formik.touched.pos_corrected &&
                                formik.errors.pos_corrected ? (
                                  <div
                                    id="pos_corrected"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.pos_corrected}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {isLHPProject ? null : (
                            <div className="row align-items-center justify-content-center px-lg-2">
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="codes_added"
                                  className="form-label required"
                                >
                                  Codes Added
                                </label>
                                <input
                                  type="text"
                                  name="codes_added"
                                  className={`form-control font-size13  ${
                                    formik.touched.codes_added &&
                                    formik.errors.codes_added &&
                                    "is-invalid"
                                  }`}
                                  id="codes_added"
                                  disabled
                                  // placeholder='codes_added'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.codes_added}
                                />
                                {formik.touched.codes_added &&
                                formik.errors.codes_added ? (
                                  <div
                                    id="codes_added"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.codes_added}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="codes_deleted"
                                  className="form-label required"
                                >
                                  Codes Deleted
                                </label>
                                <input
                                  type="text"
                                  name="codes_deleted"
                                  className={`form-control font-size13  ${
                                    formik.touched.codes_deleted &&
                                    formik.errors.codes_deleted &&
                                    "is-invalid"
                                  }`}
                                  disabled
                                  id="codes_deleted"
                                  // placeholder='No of codes_deleted'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.codes_deleted}
                                />
                                {formik.touched.codes_deleted &&
                                formik.errors.codes_deleted ? (
                                  <div
                                    id="codes_deleted"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.codes_deleted}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="dx_codes_updated"
                                  className="form-label required"
                                >
                                  Dx Codes Updated
                                </label>
                                <input
                                  type="text"
                                  name="dx_codes_updated"
                                  className={`form-control font-size13  ${
                                    formik.touched.dx_codes_updated &&
                                    formik.errors.dx_codes_updated &&
                                    "is-invalid"
                                  }`}
                                  disabled
                                  id="dx_codes_updated"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.dx_codes_updated}
                                />

                                {formik.touched.dx_codes_updated &&
                                formik.errors.dx_codes_updated ? (
                                  <div
                                    id="dx_codes_updated"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.dx_codes_updated}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="dx_codes_error"
                                  className="form-label"
                                >
                                  No of Dx Codes Error
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="dx_codes_error"
                                  className={`form-control font-size13  ${
                                    formik.touched.dx_codes_error &&
                                    formik.errors.dx_codes_error &&
                                    "is-invalid"
                                  }`}
                                  id="dx_codes_error"
                                  // placeholder='D.O.B'
                                  // onChange={formik.handleChange}
                                  // // onFocus={(e) => (e.target.type = "date")}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.dx_codes_error}
                                />
                                {formik.touched.dx_codes_error &&
                                formik.errors.dx_codes_error ? (
                                  <div
                                    id="dx_codes_error"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.dx_codes_error}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {isLHPProject ? null : (
                            <div className="row align-items-center justify-content-center px-lg-2">
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="admin_error"
                                  className="form-label"
                                >
                                  No of Admin Errors
                                </label>
                                <input
                                  type="text"
                                  name="admin_error"
                                  disabled
                                  className={`form-control font-size13  ${
                                    formik.touched.admin_error &&
                                    formik.errors.admin_error &&
                                    "is-invalid"
                                  }`}
                                  id="admin_error"
                                  // placeholder='admin_error'
                                  // onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.admin_error}
                                />
                                {formik.touched.admin_error &&
                                formik.errors.admin_error ? (
                                  <div
                                    id="admin_error"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.admin_error}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="total_errors"
                                  className="form-label"
                                >
                                  Total Absolute Errors
                                </label>
                                <input
                                  type="text"
                                  name="total_errors"
                                  disabled
                                  className={`form-control font-size13  ${
                                    formik.touched.total_errors &&
                                    formik.errors.total_errors &&
                                    "is-invalid"
                                  }`}
                                  id="total_errors"
                                  // placeholder='Member Name'
                                  // onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.total_errors}
                                />
                                {formik.touched.total_errors &&
                                formik.errors.total_errors ? (
                                  <div
                                    id="total_errors"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.total_errors}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="chart_qa_score"
                                  className="form-label"
                                >
                                  Chart Level QA Score
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="chart_qa_score"
                                  className={`form-control font-size13  ${
                                    formik.touched.chart_qa_score &&
                                    formik.errors.chart_qa_score &&
                                    "is-invalid"
                                  }`}
                                  id="chart_qa_score"
                                  // placeholder='Chart ID'
                                  // onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.chart_qa_score}
                                />

                                {formik.touched.chart_qa_score &&
                                formik.errors.chart_qa_score ? (
                                  <div
                                    id="chart_qa_score"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.chart_qa_score}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="dx_qa_score"
                                  className="form-label"
                                >
                                  DX Level QA Score
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="dx_qa_score"
                                  className={`form-control font-size13  ${
                                    formik.touched.dx_qa_score &&
                                    formik.errors.dx_qa_score &&
                                    "is-invalid"
                                  }`}
                                  id="dx_qa_score"
                                  // placeholder='Chart ID'
                                  // onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.dx_qa_score}
                                />

                                {formik.touched.dx_qa_score &&
                                formik.errors.dx_qa_score ? (
                                  <div
                                    id="dx_qa_score"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.dx_qa_score}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <Offcanvas
                            show={show}
                            onHide={handleClose}
                            placement="end"
                            style={{ width: "40%" }}
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title></Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div>
                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      {isLHPProject
                                        ? "Encounter number"
                                        : "Chart ID"}
                                      <span className="text-white h6 ms-3">
                                        {formik.values.chart_id}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      Coder name
                                      <span className="text-white h6 ms-3">
                                        {formik.values.coder_name}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      {isLHPProject
                                        ? "Patient name"
                                        : "Member name"}
                                    </p>
                                    <span className="h6">
                                      {chartDetails && chartDetails.member_name}
                                    </span>
                                  </div>

                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      Total pages
                                    </p>
                                    <span className="text-white h6">
                                      {formik.values.total_pages}
                                    </span>
                                  </div>
                                </div>

                                <div className="row mb-4">
                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      DOB <small>(YYYY-MM-DD)</small>
                                    </p>
                                    <span className="h6">
                                      {chartDetails &&
                                        chartDetails.dob.slice(0, 10)}
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <p className="text-info mb-0">
                                      Coding complete date{" "}
                                      <small>(YYYY-MM-DD)</small>
                                    </p>
                                    <span className="h6">
                                      {chartDetails &&
                                        chartDetails.coding_at.slice(0, 10)}
                                    </span>
                                  </div>
                                </div>
                                {!isLHPProject && (
                                  <div className="row mb-4">
                                    <div className="col-12">
                                      <p className="text-info mb-0">
                                        Coder comment
                                      </p>
                                      <span className="h6">
                                        {chartDetails &&
                                        chartDetails.comments ? (
                                          chartDetails.comments
                                        ) : (
                                          <span className="text-muted">NA</span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {isLHPProject && (
                                  <>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <p className="text-info mb-0">
                                          Facility
                                        </p>
                                        <span className="h6">
                                          {chartDetails &&
                                            chartDetails.facility_name}
                                        </span>
                                      </div>
                                      <div className="col-md-6">
                                        <p className="text-info mb-0">State</p>
                                        <span className="h6">
                                          {chartDetails && chartDetails.state}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {isLHPProject && (
                                  <div className="row mb-4">
                                    <div className="col-12">
                                      <p className="text-info mb-0">
                                        Date of service{" "}
                                        <small>(YYYY-MM-DD)</small>
                                      </p>
                                      <span className="h6">
                                        {chartDetails &&
                                          chartDetails.date_of_service.slice(
                                            0,
                                            10
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>

                          {isLHPProject && (
                            <div className="row align-items-center justify-content-center px-lg-2">
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="cpt_errors"
                                  className="form-label required"
                                >
                                  No of CPT errors
                                </label>
                                <input
                                  type="text"
                                  name="cpt_errors"
                                  className={`form-control font-size13  ${
                                    formik.touched.cpt_errors &&
                                    formik.errors.cpt_errors &&
                                    "is-invalid"
                                  }`}
                                  id="cpt_errors"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.cpt_errors}
                                />
                                {formik.touched.cpt_errors &&
                                formik.errors.cpt_errors ? (
                                  <div
                                    id="cpt_errors"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.cpt_errors}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="cpt_codes"
                                  className="form-label required"
                                >
                                  Total CPT codes
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="cpt_codes"
                                  className={`form-control font-size13  ${
                                    formik.touched.cpt_codes &&
                                    formik.errors.cpt_codes &&
                                    "is-invalid"
                                  }`}
                                  id="cpt_codes"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.cpt_codes}
                                />
                                {formik.touched.cpt_codes &&
                                formik.errors.cpt_codes ? (
                                  <div
                                    id="cpt_codes"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.cpt_codes}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="cpt_qa_score"
                                  className="form-label required"
                                >
                                  CPT QA score
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="cpt_qa_score"
                                  className={`form-control font-size13 ${
                                    formik.touched.cpt_qa_score &&
                                    formik.errors.cpt_qa_score &&
                                    "is-invalid"
                                  }`}
                                  id="cpt_qa_score"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.cpt_qa_score}
                                />

                                {formik.touched.cpt_qa_score &&
                                formik.errors.cpt_qa_score ? (
                                  <div
                                    id="cpt_qa_score"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.cpt_qa_score}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {isLHPProject && (
                            <div className="row align-items-center justify-content-center px-lg-2">
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="icd_errors"
                                  className="form-label required"
                                >
                                  No of ICD errors
                                </label>
                                <input
                                  type="text"
                                  name="icd_errors"
                                  className={`form-control font-size13 ${
                                    formik.touched.icd_errors &&
                                    formik.errors.icd_errors &&
                                    "is-invalid"
                                  }`}
                                  id="icd_errors"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.icd_errors}
                                />
                                {formik.touched.icd_errors &&
                                formik.errors.icd_errors ? (
                                  <div
                                    id="icd_errors"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.icd_errors}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="icd_codes"
                                  className="form-label required"
                                >
                                  Total ICD codes
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="icd_codes"
                                  className={`form-control font-size13 ${
                                    formik.touched.icd_codes &&
                                    formik.errors.icd_codes &&
                                    "is-invalid"
                                  }`}
                                  id="icd_codes"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.icd_codes}
                                />
                                {formik.touched.icd_codes &&
                                formik.errors.icd_codes ? (
                                  <div
                                    id="icd_codes"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.icd_codes}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg px-1">
                                <label
                                  htmlFor="icd_qa_score"
                                  className="form-label required"
                                >
                                  ICD QA score
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  name="icd_qa_score"
                                  className={`form-control font-size13 ${
                                    formik.touched.icd_qa_score &&
                                    formik.errors.icd_qa_score &&
                                    "is-invalid"
                                  }`}
                                  id="icd_qa_score"
                                  // placeholder='Chart ID'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.icd_qa_score}
                                />

                                {formik.touched.icd_qa_score &&
                                formik.errors.icd_qa_score ? (
                                  <div
                                    id="icd_qa_score"
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.icd_qa_score}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {/* Dynamic error fields */}
                          <div className="row align-items-center justify-content-center px-lg-2">
                            {isLHPProject ? null : (
                              <div className="col-lg px-1 mb-2">
                                <label
                                  htmlFor="error_check"
                                  className="form-label required"
                                >
                                  Need to add error?
                                </label>

                                <div
                                  onChange={(e) =>
                                    setErrorStatus(e.target.value)
                                  }
                                  className="d-inline ms-3"
                                >
                                  <input
                                    className={`form-check-input ${
                                      formik.touched.error_fields &&
                                      formik.errors.error_fields &&
                                      "is-invalid"
                                    }`}
                                    type="radio"
                                    value="yes"
                                    id="yes"
                                    name="error"
                                    checked={errorStatus === "yes"}
                                  />
                                  <label
                                    class="form-check-label me-3 ms-1 font-size13"
                                    htmlFor="yes"
                                  >
                                    Yes
                                  </label>
                                  <input
                                    className={`form-check-input ${
                                      formik.touched.error_fields &&
                                      formik.errors.error_fields &&
                                      "is-invalid"
                                    }`}
                                    type="radio"
                                    value="no"
                                    id="no"
                                    name="error"
                                    checked={errorStatus === "no"}
                                  />
                                  <label
                                    class="form-check-label me-3 ms-1 font-size13"
                                    htmlFor="no"
                                  >
                                    No
                                  </label>
                                </div>
                                {formik.touched.error_fields &&
                                formik.errors.error_fields ? (
                                  <div
                                    id="error_fields"
                                    className="invalid-feedback d-block"
                                  >
                                    {formik.errors.error_fields}
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </div>
                            )}

                            {errorStatus === "yes" && chartDetails ? (
                              <>
                                <button
                                  className="btn btn-info text-white btn-sm w-auto mb-3 me-2"
                                  onClick={addErrorFields}
                                >
                                  <i className="fas fa-plus"></i> Add errors
                                </button>
                              </>
                            ) : null}

                            {!isLHPProject && errorStatus === "no" ? (
                              <>
                                <div className="col-lg-3 px-1">
                                  <label
                                    htmlFor="auditor_comment"
                                    className="form-label required"
                                  >
                                    Auditor comments
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control font-size13 "
                                    value="No Errors, Great Job!!!"
                                    disabled
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          {isLHPProject
                            ? null
                            : errorStatus === "yes" && (
                                <div
                                  className="table-responsive"
                                  style={{ maxHeight: "300px" }}
                                >
                                  <table class="table">
                                    <thead className="font-size13">
                                      <tr>
                                        <th scope="col" className="text-center">
                                          #
                                        </th>
                                        <th scope="col">
                                          <span className="required">
                                            Error Status
                                          </span>
                                        </th>
                                        <th scope="col">
                                          <span className="required">
                                            Comments for DX
                                          </span>
                                        </th>
                                        <th scope="col">
                                          <span className="required">
                                            Comments 2
                                          </span>
                                        </th>
                                        <th scope="col">
                                          <span className="required">
                                            Comments 3
                                          </span>
                                        </th>
                                        <th scope="col">
                                          <span className="required">
                                            Secondary Comments
                                          </span>
                                        </th>
                                        <th scope="col" className="text-center">
                                          <span className="required">
                                            Action
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="font-size13 font-weight-light">
                                      {formik.values.error_fields.length ? (
                                        formik.values.error_fields.map(
                                          (inputFields, index) => (
                                            <tr key={index}>
                                              <td className="text-white border-bottom-0 text-center">
                                                <div className="invisible">
                                                  <span>invisible</span>
                                                </div>
                                                {index + 1}
                                              </td>
                                              <td className="border-bottom-0">
                                                <div className="invisible">
                                                  <span>invisible</span>
                                                </div>
                                                <select
                                                  name={`error_fields.${index}.err_status`}
                                                  className={`form-select form-control font-size13 `}
                                                  id={`err_status${index}`}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={inputFields.err_status}
                                                >
                                                  <option
                                                    disabled
                                                    selected
                                                    value=""
                                                  >
                                                    Select status
                                                  </option>
                                                  <option value="added">
                                                    Added
                                                  </option>
                                                  <option value="deleted">
                                                    Deleted
                                                  </option>
                                                  <option value="updated">
                                                    Updated
                                                  </option>
                                                  <option value="admin">
                                                    Admin
                                                  </option>
                                                </select>
                                                {formik.errors ? (
                                                  <div
                                                    id="err_status"
                                                    className="invalid-feedback d-block"
                                                  >
                                                    {
                                                      formik.errors[
                                                        `error_fields.${index}.err_status`
                                                      ]
                                                    }
                                                  </div>
                                                ) : (
                                                  <div className="invisible">
                                                    <span>invisible</span>
                                                  </div>
                                                )}
                                              </td>
                                              {inputFields.err_status !=
                                              "admin" ? (
                                                <>
                                                  <td className="border-bottom-0">
                                                    <div className="invisible">
                                                      <span>invisible</span>
                                                    </div>
                                                    <textarea
                                                      type="text"
                                                      name={`error_fields.${index}.dx_comment`}
                                                      // rows={2}
                                                      className={`form-control font-size13 ${
                                                        formik.touched
                                                          .dx_comment &&
                                                        formik.errors
                                                          .dx_comment &&
                                                        "is-invalid"
                                                      }`}
                                                      id={`dx_comment_${index}`}
                                                      style={{ height: "10px" }}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      value={
                                                        inputFields.dx_comment
                                                      }
                                                      // placeholder="Comments for DX"
                                                    ></textarea>
                                                    {formik.errors ? (
                                                      <div
                                                        id="err_status"
                                                        className="invalid-feedback d-block"
                                                      >
                                                        {
                                                          formik.errors[
                                                            `error_fields.${index}.dx_comment`
                                                          ]
                                                        }
                                                      </div>
                                                    ) : (
                                                      <div className="invisible">
                                                        <span>invisible</span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td className="border-bottom-0"></td>
                                                </>
                                              )}
                                              <td className="border-bottom-0">
                                                <div className="invisible">
                                                  <span>invisible</span>
                                                </div>
                                                <select
                                                  name={`error_fields.${index}.comment_2`}
                                                  className={`form-select form-control font-size13 `}
                                                  id="comment_2"
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={inputFields.comment_2}
                                                >
                                                  <option
                                                    disabled
                                                    selected
                                                    value=""
                                                  >
                                                    Select a value
                                                  </option>
                                                  {inputFields.err_status !=
                                                    "admin" && (
                                                    <>
                                                      <option value="Acute">
                                                        Acute
                                                      </option>
                                                      <option value="Chronic">
                                                        Chronic
                                                      </option>
                                                    </>
                                                  )}
                                                  <option
                                                    disabled={
                                                      inputFields.err_status ===
                                                        "admin" &&
                                                      isErrFieldRpActive
                                                        ? "true"
                                                        : null
                                                    }
                                                    value="RP"
                                                  >
                                                    RP
                                                  </option>
                                                  <option
                                                    disabled={
                                                      inputFields.err_status ===
                                                        "admin" &&
                                                      isErrFieldDosActive
                                                        ? "true"
                                                        : null
                                                    }
                                                    value="DOS"
                                                  >
                                                    DOS
                                                  </option>

                                                  <option
                                                    disabled={
                                                      inputFields.err_status ===
                                                        "admin" &&
                                                      isErrFieldCommentActive
                                                        ? "true"
                                                        : null
                                                    }
                                                    value="Comment"
                                                  >
                                                    Comment
                                                  </option>

                                                  <option
                                                    disabled={
                                                      inputFields.err_status ===
                                                        "admin" &&
                                                      isErrFieldSignatureActive
                                                        ? "true"
                                                        : null
                                                    }
                                                    value="Signature"
                                                  >
                                                    Signature
                                                  </option>
                                                </select>
                                                {formik.errors ? (
                                                  <div
                                                    id="err_status"
                                                    className="invalid-feedback d-block"
                                                  >
                                                    {
                                                      formik.errors[
                                                        `error_fields.${index}.comment_2`
                                                      ]
                                                    }
                                                  </div>
                                                ) : (
                                                  <div className="invisible">
                                                    <span>invisible</span>
                                                  </div>
                                                )}
                                              </td>
                                              {inputFields.err_status !=
                                              "admin" ? (
                                                <>
                                                  <td className="border-bottom-0">
                                                    <div className="invisible">
                                                      <span>invisible</span>
                                                    </div>
                                                    <select
                                                      name={`error_fields.${index}.comment_3`}
                                                      className={`form-select form-control font-size13 `}
                                                      id="comment_3"
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      value={
                                                        inputFields.comment_3
                                                      }
                                                    >
                                                      <option
                                                        disabled
                                                        selected
                                                        value=""
                                                      >
                                                        Select a value
                                                      </option>
                                                      <option value="From HPI">
                                                        From HPI
                                                      </option>
                                                      <option value="From PMH">
                                                        From PMH
                                                      </option>
                                                      <option value="From PL">
                                                        From PL
                                                      </option>
                                                      <option value="From ROS">
                                                        From ROS
                                                      </option>
                                                      <option value="From Surgical History">
                                                        From Surgical History
                                                      </option>
                                                      <option value="From PE">
                                                        From PE
                                                      </option>
                                                      <option value="From Assessment">
                                                        From Assessment
                                                      </option>
                                                      <option value="From Plan">
                                                        From Plan
                                                      </option>
                                                    </select>
                                                    {formik.errors ? (
                                                      <div
                                                        id="err_status"
                                                        className="invalid-feedback d-block"
                                                      >
                                                        {
                                                          formik.errors[
                                                            `error_fields.${index}.comment_3`
                                                          ]
                                                        }
                                                      </div>
                                                    ) : (
                                                      <div className="invisible">
                                                        <span>invisible</span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td className="border-bottom-0">
                                                    <div className="invisible">
                                                      <span>invisible</span>
                                                    </div>
                                                    <input
                                                      type="number"
                                                      name={`error_fields.${index}.comment_2_count`}
                                                      className={`form-select form-control font-size13 `}
                                                      id="comment_2_count"
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      value={
                                                        inputFields.comment_2_count
                                                      }
                                                      placeholder="Enter count"
                                                    ></input>
                                                    {formik.errors ? (
                                                      <div
                                                        id="comment_2_count"
                                                        className="invalid-feedback d-block"
                                                      >
                                                        {
                                                          formik.errors[
                                                            `error_fields.${index}.comment_2_count`
                                                          ]
                                                        }
                                                      </div>
                                                    ) : (
                                                      <div className="invisible">
                                                        <span>invisible</span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </>
                                              )}
                                              <td className="border-bottom-0">
                                                <div className="invisible">
                                                  <span>invisible</span>
                                                </div>
                                                <textarea
                                                  type="text"
                                                  name={`error_fields.${index}.secondary_comment`}
                                                  // rows={2}
                                                  className={`form-control font-size13 ${
                                                    formik.touched
                                                      .secondary_comment &&
                                                    formik.errors
                                                      .secondary_comment &&
                                                    "is-invalid"
                                                  }`}
                                                  id="secondary_comment"
                                                  style={{ height: "10px" }}
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  value={
                                                    inputFields.secondary_comment
                                                  }
                                                ></textarea>
                                                {formik.errors ? (
                                                  <div
                                                    id="err_status"
                                                    className="invalid-feedback d-block"
                                                  >
                                                    {
                                                      formik.errors[
                                                        `error_fields.${index}.secondary_comment`
                                                      ]
                                                    }
                                                  </div>
                                                ) : (
                                                  <div className="invisible">
                                                    <span>invisible</span>
                                                  </div>
                                                )}
                                              </td>

                                              <td className="border-bottom-0 text-center">
                                                <div className="invisible">
                                                  <span>invisible</span>
                                                </div>
                                                <button
                                                  className="btn btn-sm btn-danger shadow-none mt-1"
                                                  onClick={(e) =>
                                                    removeErrorFields(e, index)
                                                  }
                                                >
                                                  <i className="fas fa-trash"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={15}
                                            className="text-center text-white"
                                          >
                                            There's no errors added yet.
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                          {/* {isLHPProject
            ? null
            : errorStatus === "yes"
            ? formik.values.error_fields.map((inputFields, index) => (
                <>
                  <span className='mt-1'>{`Error ${index + 1}`}</span>
                  <div className='row align-items-center justify-content-center px-lg-3'>
                    <div className='col-lg px-1'>
                      <label htmlFor='audit_comment_type' className='form-label required'>
                        Error Status
                      </label>

                      <select
                        name={`error_fields.${index}.err_status`}
                        className={`form-select form-control`}
                        id={`err_status${index}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={`error_fields.${index}.comment_1`}
                      >
                        <option disabled selected value=''>
                          Select a value
                        </option>
                        <option value='added'>Added</option>
                        <option value='deleted'>Deleted</option>
                        <option value='updated'>Updated</option>
                      </select>

                      {formik.touched.error_fields && formik.touched.error_fields && formik.touched.error_fields ? (
                        <div id='err_status' className='invalid-feedback'>
                          {formik.errors.error_fields}
                        </div>
                      ) : (
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      )}
                    </div>
                    <div className={`col-lg px-1`}>
                      <label htmlFor='dx_comment' className='form-label required'>
                        Comments For DX
                      </label>
                      <textarea
                        type='text'
                        name={`error_fields.${index}.dx_comment`}
                        // rows={2}
                        className={`form-control ${formik.touched.dx_comment && formik.errors.dx_comment && "is-invalid"}`}
                        id={`dx_comment_${index}`}
                        style={{ height: "10px" }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={formik.values.dx_comment}
                      ></textarea>

                      {formik.touched.dx_comment && formik.errors.dx_comment ? (
                        <div id='dx_comment' className='invalid-feedback'>
                          {formik.errors.dx_comment}
                        </div>
                      ) : (
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      )}
                    </div>
                    <div className='col-lg px-1'>
                      <label htmlFor='comment_2' className='form-label required'>
                        Comments 2
                      </label>

                      <select
                        name={`error_fields.${index}.comment_2`}
                        className={`form-select form-control`}
                        id='comment_2'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={formik.values.comment_2}
                      >
                        <option disabled selected value=''>
                          Select a value
                        </option>
                        <option value='Acute'>Acute</option>
                        <option value='Chronic'>Chronic</option>
                        <option value='RP'>RP</option>
                        <option value='DOS'>DOS</option>
                        <option value='Comment'>Comment</option>
                        <option value='Signature'>Signature</option>
                      </select>

                      {formik.touched.comment_2 && formik.errors.comment_2 ? (
                        <div id='comment_2' className='invalid-feedback'>
                          {formik.errors.comment_2}
                        </div>
                      ) : (
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      )}
                    </div>
                    <div className='col-lg px-1'>
                      <label htmlFor='comment_3' className='form-label required'>
                        Comments 3
                      </label>

                      <select
                        name={`error_fields.${index}.comment_3`}
                        className={`form-select form-control`}
                        id='comment_3'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={formik.values.comment_3}
                      >
                        <option disabled selected value=''>
                          Select a value
                        </option>
                        <option value='From HPI'>From HPI</option>
                        <option value='From PMH'>From PMH</option>
                        <option value='From PL'>From PL</option>
                        <option value='From ROS'>From ROS</option>
                        <option value='From Surgical History'>From Surgical History</option>
                        <option value='From PE'>From PE</option>
                        <option value='From Assessment'>From Assessment</option>
                        <option value='From Plan'>From Plan</option>
                      </select>

                      {formik.touched.comment_3 && formik.errors.comment_3 ? (
                        <div id='comment_3' className='invalid-feedback'>
                          {formik.errors.comment_3}
                        </div>
                      ) : (
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      )}
                    </div>
                    <div className={`col-lg px-1`}>
                      <label htmlFor='secondary_comment' className='form-label required'>
                        Secondary Comment
                      </label>
                      <textarea
                        type='text'
                        name={`error_fields.${index}.secondary_comment`}
                        // rows={2}
                        className={`form-control ${formik.touched.secondary_comment && formik.errors.secondary_comment && "is-invalid"}`}
                        id='secondary_comment'
                        style={{ height: "10px" }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // value={formik.values.secondary_comment}
                      ></textarea>

                      {formik.touched.secondary_comment && formik.errors.secondary_comment ? (
                        <div id='secondary_comment' className='invalid-feedback'>
                          {formik.errors.secondary_comment}
                        </div>
                      ) : (
                        <div className='invisible'>
                          <span>invisible</span>
                        </div>
                      )}
                    </div>
                    <div className='col-auto'>
                      <button className='btn btn-sm btn-danger shadow-none' onClick={(e) => removeErrorFields(e, index)}>
                        <i className='fas fa-trash'></i>
                      </button>
                    </div>
                  </div>
                </>
              ))
            : null} */}

                          <div className="text-end mt-3">
                            <button
                              type="submit"
                              className="btn btn-sm btn-success shadow-none px-4 py-2 font-size13"
                            >
                              ADD Audit
                            </button>
                          </div>
                        </form>

                        <ResetAuditorErrFields
                          showModal={showResetAuditorErrModal}
                          hideModal={closeResetAuditorErModal}
                          resetErrFileds={resetErrorFields}
                        ></ResetAuditorErrFields>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <ErrorFeedback></ErrorFeedback>
              </>
            )}
          </>
        ) : (
          <div className="card">
            <div className="card-body">
              <p className="mb-0 text-center">
                Login name not assigned. Please contact your team lead.
              </p>
            </div>
          </div>
        )
      ) : (
        <div className="card">
          <div className="card-body">
            <p className="mb-0 text-center">You are not assigned to any team</p>
          </div>
        </div>
      )}
    </>
  );
};

export default AddAudits;
