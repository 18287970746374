import React, { useContext, useEffect, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";
import { CommonContext } from "../../context/CommonContext";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const EditOnlyRow = ({ index, editFormData, handleCancel, handleEditFormChange }) => {
  const selectInputRef = useRef();
  const context = useContext(CommonContext);
  const { teams, setTeams, teamLeads, setTeamLeads, projects, setProjects, users, setUsers } = context;

  const userOptions = users.map((user) => {
    let tempobj = {};
    tempobj.value = user.id;
    tempobj.label = user.name;
    return tempobj;
  });

  const fetchData = (inputValue, callback) => {
    callback(userOptions);
  };

  const selectedUserOptions = editFormData.user_role.map((user) => {
    let tempobj = {};
    tempobj.value = user.id;
    tempobj.label = user.name;
    return tempobj;
  });

  let leadRole = editFormData.lead_role.map((item) => {
    return item;
  });

  // Creating a state for the selected options
  const [selectedOption, setSelectedOption] = useState("");

  // To set/load the initial value that is saved for the current item to edit/manage
  useEffect(() => {
    // Your code to get the initial values, then set it to the state that you've created
    setSelectedOption(selectedUserOptions);
  }, [editFormData]);

  // A handle change method to change the selected value/options
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    let difference = selectedUserOptions.filter((x) => selectedOption.value === x.value); // calculates diff
    // console.log("Removed: ", difference);
    // console.log(selectedOption);
  };

  const setQuery = (value) => {
    // console.log(value);
    // console.log(userOptions);
  };

  const options = { ...userOptions, ...selectedUserOptions };

  // console.log(options);

  return (
    <>
      <td>{index + 1}</td>

      <td>
        {/* <input
          type='text'
          required='required'
          placeholder='Enter Project Name'
          name='project_name'
          className='form-control'
          value={editFormData.project.project_name}
          onChange={handleEditFormChange}
        ></input> */}
        <select
          className={`form-select form-control`}
          id='selectproject'
          name='project_id'
          onChange={handleEditFormChange}
          value={editFormData.project_id}
        >
          {projects &&
            projects.map((project) => (
              <option value={project.id} key={project.id}>
                {project.project_name} - {project.project_code}
              </option>
            ))}
        </select>
      </td>
      <td>
        <select className={`form-select form-control`} id='selectLead' name='team_lead' onChange={handleEditFormChange} value={leadRole.id}>
          {teamLeads &&
            teamLeads.map((teamLead) => (
              <option value={teamLead.id} key={teamLead.id}>
                {teamLead.name}
              </option>
            ))}
        </select>
      </td>
      <td className='text-center'>
        {/* <Select
          ref={selectInputRef}
          value={selectedUserOptions}
          // value={userOptions.filter((item) => selectedUserOptions.includes(item.value))}
          isClearable={true}
          options={userOptions}
          isMulti={true}
          closeMenuOnSelect={false}
          isSearchable
          // onChange={selectHandleChange}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: "orangered",

              primary25: "skyblue",
              primary: "black",

              neutral0: "#303c4a",
              neutral10: "#1898a9",
            },
          })}
        /> */}
        <AsyncSelect
          onChange={handleChange}
          value={selectedOption}
          loadOptions={fetchData}
          options={options}
          // cacheOptions
          defaultOptions
          isMulti
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: "orangered",

              primary25: "skyblue",
              primary: "black",

              neutral0: "#303c4a",
              neutral10: "#1898a9",
            },
          })}
          onInputChange={(value) => setQuery(value)}
        />
      </td>

      <td>{new Date(editFormData.created_at).toDateString().slice(4)}</td>

      <td className='text-nowrap'>
        <OverlayTrigger placement='top' overlay={<Tooltip>Save Team</Tooltip>}>
          <button type='submit' className='btn btn-sm i-success shadow-none ml-2'>
            <i className='fas fa-arrow-alt-circle-right' aria-hidden='true' />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement='top' overlay={<Tooltip>Cancel Edit</Tooltip>}>
          <button type='button' className='btn btn-sm i-primary font-15 shadow-none ml-2' onClick={handleCancel}>
            <i className='fa fa-times' aria-hidden='true' />
          </button>
        </OverlayTrigger>
      </td>
    </>
  );
};

export default EditOnlyRow;
