import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const CardHeader = (props) => {
  const { title, count, icon, showGoBack } = props;
  const auth = useAuth();

  const navigate = useNavigate();
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center py-3'>
            <h6 className='mb-0'>
              {icon}
              {title}
              {count ? <span className='badge bg-success ms-1  rounded-pill'>{count}</span> : null}
            </h6>
            <div>
              {/* {showGoBack ? (
                auth && auth.user && auth.user.role == "lead" ? (
                  <button className='btn btn-danger btn-sm invisible'>Go Back</button>
                ) : (
                  <button className='btn btn-danger btn-sm' onClick={() => navigate(-1)}>
                    Go Back
                  </button>
                )
              ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
