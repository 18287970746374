import { useFormik } from "formik";
import DatePicker from "react-datepicker";
// import { ucFirst } from "../../utils/UcFirst";

// import Select from "react-select";

import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import apiClient from "../../services/ApiClient";
import { useAuth } from "../../context/AuthContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import ResetAuditorErrFields from "../../utils/Modals/ResetAuditorErrFields";
import ErrorFeedback from "../ErrorFeedback";

const AddAuditsForClientR = (props) => {
  const [chartDetails, setChartDetails] = useState(null);
  const [errorStatus, setErrorStatus] = useState("no");
  const [show, setShow] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [totalErrors, setTotalErrors] = useState("");
  const [showResetAuditorErrModal, setshowResetAuditorErrModal] =
    useState(false);

  const handleSelectItem = (e) => {
    setSelectedItems([...selectedItems, e.target.value]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [chartDetailsId, setChartDetailsId] = useState(null);
  // const selectInputRef = useRef();

  const auth = useAuth();
  // const { fetchData, editId, editFormData } = props;

  const auditorProjectId = auth.user.project_id;

  // Check whether selected project is LHP
  const isLHPProject =
    Number(auditorProjectId) ===
    Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  // const [showSecondaryComment, setShowSecondaryComment] = useState(() => (isLHPProject ? true : false));

  // const handleChangeChartId = ({ value }) => {
  //   formik.setFieldValue("chart_id", value);
  //   fetchChartDetails(value);
  // };

  // Fetch charts details based on the chart id
  const fetchChartDetails = async (chartId) => {
    try {
      if (chartId && chartId.length >= 8) {
        const { data } = await apiClient.get(
          `user-chart/chart_data/${chartId}`
        );

        setShowCanvas(true);
        setChartDetails(data.data);
        formik.setFieldValue("primary_chart_id", data.data.id);
        // localStorage.setItem("project", data.data.project_id);

        formik.setErrors({});
        formik.setTouched({}, false);
      } else if (chartId.length === 0) {
        formik.setErrors({
          chart_id: "Chart Id is required.",
        });
      } else {
        // formik.setErrors({
        //   chart_id: `Must be ${digits} digits.`,
        // });
      }
    } catch (err) {
      formik.setErrors({
        chart_id: err.response.data.error,
      });
    }
  };

  // Bind default attributes from coder chart id chart exist
  useEffect(() => {
    if (chartDetails) {
      formik.setFieldValue("coder_name", chartDetails.user.name);
      formik.setFieldValue("total_pages", chartDetails.pages);
      formik.setFieldValue("icd_codes", chartDetails.icd);
      formik.setFieldValue("primary_error_status", "no");
      formik.setFieldValue("total_errors", 0);
      setErrorStatus("no");

      formik.setFieldValue(
        "coding_complete_date",
        new Date(chartDetails.created_at)
      );
    }
  }, [chartDetails]);

  const formik = useFormik({
    initialValues: {
      chart_id: "",
      coding_complete_date: "",
      audit_complete_date: new Date(),
      primary_error_status: "no",
      error_fields: [],
      primary_chart_id: "",
      icd_codes: "",
      coder_name: "",
      total_pages: "",
      audit_comment: "No Errors, Great Job!!!",
      icd_qa_score: 100,
    },
    validationSchema: yup.object({
      // chart_id: yup
      //   .number()
      //   .typeError("Must be a number")
      //   .required("Chart ID is required"),
      // coder_name: yup.string().min(3, "Too Short!").max(75, "Must be 25 characters or less").required("Name is required"),
      // total_pages: yup.number().typeError("Must be a number").required("Field is required"),
      // audit_comment: yup.string().required("Field is required"),
      // audit_type: yup.string().required("Field is required"),
      // chart_qa_score: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_qa_score: yup.number().typeError("Must be a number").required("Field is required"),
      // original_code_found: yup.number().typeError("Must be a number").required("Field is required"),
      // total_errors: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_codes_error: yup.number().typeError("Must be a number").required("Field is required"),
      // admin_error: yup.number().typeError("Must be a number").required("Field is required"),
      // codes_added: yup.number().typeError("Must be a number").required("Field is required"),
      // codes_deleted: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_codes_updated: yup.number().typeError("Must be a number").required("Field is required"),
      // dos_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // pos_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // dx_level_comment_code_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // rendering_provider_corrected: yup.number().typeError("Must be a number").required("Field is required"),
      // error_fields: yup.array().of(
      //   yup.object().shape({
      //     error_count: yup.string().required("Error status is required"),
      //   })
      // ),
    }),
    onSubmit: async (values) => {
      try {
        // console.log(values);
        const response = await apiClient.post("audit/store/client_r", values);
        // window.location.reload();
        // fetchData();
        // fetchChartIds();
        formik.resetForm();
        setChartDetails(null);
        setErrorStatus("no");
      } catch (err) {
        // console.log("____---" + err.response.data.error);
        // console.log("Error: " + err.response.data.error.chart_id);
        formik.setErrors(err.response.data.error);
      }
    },
  });

  // Concat primary and secondary comment
  // useEffect(() => {
  //   if (!isLHPProject) {
  //     const auditorFeedback = formik.values.audit_comment.split("-").shift();
  //     formik.setFieldValue("auditor_feedback", auditorFeedback);
  //     if (auditorFeedback == "no_changes" || auditorFeedback == "") {
  //       setShowSecondaryComment(false);
  //     } else {
  //       setShowSecondaryComment(true);
  //     }
  //   }
  // }, [formik.values.audit_comment]);

  useEffect(() => {
    setChartDetails("");
    formik.setFieldValue("coder_name", "");
    formik.setFieldValue("total_pages", "");
    formik.setFieldValue("coding_complete_date", "");
    formik.setFieldValue("icd_codes", "");
  }, [formik.values.chart_id]);

  // Operation for changing error status
  useEffect(() => {
    const errFieldsCount = formik.values.error_fields.length;
    if (errorStatus === "no") {
      formik.setFieldValue("audit_comment", "No Errors, Great Job!!!");
      if (errFieldsCount > 0) {
        setshowResetAuditorErrModal(true);
      }
    }

    formik.setFieldValue("primary_error_status", errorStatus);
  }, [errorStatus]);

  // Add new error fields
  const addErrorFields = (e) => {
    e.preventDefault();
    const newField = [
      {
        error_count: "",
        hcc_category: "",
        primary_error: "",
        secondary_comments: "",
        comment_3: "",
        secondary_comment: "",
      },
    ];

    formik.setFieldValue("error_fields", [
      ...formik.values.error_fields,
      ...newField,
    ]);
  };

  // Remove error row based on key
  const removeErrorFields = (e, index) => {
    e.preventDefault();
    const overAllErrFields = [...formik.values.error_fields];
    overAllErrFields.splice(index, 1);
    formik.setFieldValue("error_fields", overAllErrFields);
  };

  // Calculate total Errors
  useEffect(() => {
    const errFieldsArr = formik.values.error_fields;
    // console.log(errFieldsArr);
    const totalErrorCount = errFieldsArr.reduce((accumulator, field) => {
      return accumulator + Number(field.error_count);
    }, 0);
    formik.setFieldValue("total_errors", totalErrorCount);
    // console.log(totalErrorCount);

    setTotalErrors(totalErrorCount);
  }, [formik.values.error_fields]);

  // Closes Reset modal
  const closeResetAuditorErModal = () => {
    setshowResetAuditorErrModal(false);
    setErrorStatus("yes");
  };

  // Calculate QA Score
  useEffect(() => {
    if (totalErrors && chartDetails) {
      let finalValue =
        100 - (Number(totalErrors) / Number(formik.values.icd_codes)) * 100;

      // console.log(Number(totalErrors));
      // console.log(Number(Number(chartDetails.icd)));
      // console.log(Number(finalValue));
      if (formik.values.error_fields.length) {
        formik.setFieldValue("icd_qa_score", finalValue);
      } else {
        formik.setFieldValue("icd_qa_score", 100);
      }
    }
  }, [totalErrors, formik.values.error_fields, formik.values.icd_codes]);

  // Reset error fileds if "proceed" is opted in Reset err modal
  const resetErrorFields = () => {
    formik.setFieldValue("error_fields", []);
    setshowResetAuditorErrModal(false);
  };

  return (
    <>
      <div className="card mb-3">
        {showCanvas && chartDetails && (
          <div className="card-header text-end">
            <button className="btn btn-primary btn-sm" onClick={handleShow}>
              {chartDetails.chart_id ?? ""}
              <i className="fas fa-arrow-circle-right ms-2"></i>
            </button>
          </div>
        )}
        <div className="card-body">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="row align-items-center justify-content-center px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="chart_id" className="form-label required">
                  Chase Id
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="chart_id"
                    className={`form-control  font-size13 ${
                      formik.touched.chart_id &&
                      formik.errors.chart_id &&
                      "is-invalid"
                    }`}
                    id="chart_id"
                    // placeholder='Chart ID'
                    maxLength={9}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.chart_id}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        fetchChartDetails(formik.values.chart_id);
                      }
                    }}
                  />
                  <button
                    className="btn btn-primary shadow-none outline-none border-0 font-size13"
                    type="button"
                    id="button-addon2"
                    onClick={() => fetchChartDetails(formik.values.chart_id)}
                  >
                    <i className="fas fa-search font-size13"></i>
                  </button>
                </div>
                {formik.touched.chart_id && formik.errors.chart_id ? (
                  <div id="chart_id" className="invalid-feedback d-block">
                    {formik.errors.chart_id}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
              <div className="col-lg px-1">
                <label htmlFor="icd_codes" className="form-label required">
                  Total HCC Category Reviewed
                </label>
                <input
                  type="text"
                  // disabled
                  name="icd_codes"
                  className={`form-control font-size13 `}
                  id="icd_codes"
                  // placeholder='Chart ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.icd_codes}
                />

                <div className="invisible">
                  <span>invisible</span>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-start px-lg-2">
              <div className="col-lg px-1">
                <label htmlFor="audit_complete_date" className="form-label">
                  Audit Complete Date
                  <small>(MM/DD/YYYY)</small>
                </label>
                <DatePicker
                  className="form-control font-size13"
                  // dateFormat='dd/MM/yyyy'
                  disabled
                  selected={formik.values.audit_complete_date}
                  maxDate={new Date()}
                  onChange={(date) =>
                    formik.setFieldValue("audit_complete_date", date)
                  }
                />
                {formik.touched.audit_complete_date &&
                formik.errors.audit_complete_date ? (
                  <div id="audit_complete_date" className="invalid-feedback">
                    {formik.errors.audit_complete_date}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>

              <div className="col-lg px-1">
                <label htmlFor="icd_qa_score" className="form-label required">
                  QA score
                </label>
                <input
                  type="text"
                  disabled
                  name="icd_qa_score"
                  className={`form-control  font-size13 ${
                    formik.touched.icd_qa_score &&
                    formik.errors.icd_qa_score &&
                    "is-invalid"
                  }`}
                  id="icd_qa_score"
                  // placeholder='Chart ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.icd_qa_score}
                />

                {formik.touched.icd_qa_score && formik.errors.icd_qa_score ? (
                  <div id="icd_qa_score" className="invalid-feedback">
                    {formik.errors.icd_qa_score}
                  </div>
                ) : (
                  <div className="invisible">
                    <span>invisible</span>
                  </div>
                )}
              </div>
            </div>

            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              style={{ width: "40%" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <p className="text-info mb-0">
                        Chase ID
                        <span className="text-white h6 ms-3">
                          {formik.values.chart_id}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-info mb-0">
                        Coder name
                        <span className="text-white h6 ms-3">
                          {formik.values.coder_name}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <p className="text-info mb-0">Member name</p>
                      <span className="h6">
                        {chartDetails && chartDetails.member_name}
                      </span>
                    </div>

                    <div className="col-md-6">
                      <p className="text-info mb-0">Total pages</p>
                      <span className="text-white h6">
                        {formik.values.total_pages}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <p className="text-info mb-0">
                        DOB <small>(YYYY-MM-DD)</small>
                      </p>
                      <span className="h6">
                        {chartDetails && chartDetails.dob.slice(0, 10)}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <p className="text-info mb-0">
                        Coding complete date <small>(YYYY-MM-DD)</small>
                      </p>
                      <span className="h6">
                        {chartDetails && chartDetails.coding_at.slice(0, 10)}
                      </span>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            {/* Dynamic error fields */}
            <div className="row align-items-center justify-content-center px-lg-2">
              {isLHPProject ? null : (
                <div className="col-lg px-1 mb-2">
                  <label htmlFor="error_check" className="form-label required">
                    Need to add error?
                  </label>

                  <div
                    onChange={(e) => setErrorStatus(e.target.value)}
                    className="d-inline ms-3"
                  >
                    <input
                      className={`form-check-input ${
                        formik.touched.error_fields &&
                        formik.errors.error_fields &&
                        "is-invalid"
                      }`}
                      type="radio"
                      value="yes"
                      id="yes"
                      name="error"
                      checked={errorStatus === "yes"}
                    />
                    <label
                      class="form-check-label me-3 ms-1 font-size13"
                      htmlFor="yes"
                    >
                      Yes
                    </label>
                    <input
                      className={`form-check-input ${
                        formik.touched.error_fields &&
                        formik.errors.error_fields &&
                        "is-invalid"
                      }`}
                      type="radio"
                      value="no"
                      id="no"
                      name="error"
                      checked={errorStatus === "no"}
                    />
                    <label
                      class="form-check-label me-3 ms-1 font-size13"
                      htmlFor="no"
                    >
                      No
                    </label>
                  </div>
                  {formik.touched.error_fields && formik.errors.error_fields ? (
                    <div id="error_fields" className="invalid-feedback d-block">
                      {formik.errors.error_fields}
                    </div>
                  ) : (
                    <div className="invisible">
                      <span>invisible</span>
                    </div>
                  )}
                </div>
              )}

              {errorStatus === "yes" && chartDetails ? (
                <>
                  <button
                    className="btn btn-info text-white btn-sm w-auto mb-3 me-2 font-size13"
                    onClick={addErrorFields}
                  >
                    <i className="fas fa-plus "></i> Add errors
                  </button>
                </>
              ) : null}

              {errorStatus === "no" ? (
                <>
                  <div className="col-lg-3 px-1">
                    <label
                      htmlFor="auditor_comment"
                      className="form-label required"
                    >
                      Auditor comments
                    </label>

                    <input
                      type="text"
                      className="form-control font-size13"
                      value="No Errors, Great Job!!!"
                      disabled
                    />
                  </div>
                </>
              ) : null}
            </div>

            {errorStatus === "yes" && chartDetails && (
              <div className="table-responsive" style={{ maxHeight: "300px" }}>
                <table class="table">
                  <thead className="font-size13 text-nowrap">
                    <tr>
                      <th scope="col" className="text-center">
                        #
                      </th>
                      <th scope="col">
                        <span className="required">Total No of Error</span>
                      </th>
                      <th scope="col">
                        <span className="required">HCC Category</span>
                      </th>
                      <th scope="col">
                        <span className="required">Primary Error Comments</span>
                      </th>

                      <th scope="col">
                        <span className="required">
                          Secondary Error Comments
                        </span>
                      </th>
                      <th scope="col" className="text-center">
                        <span className="required">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-size13 font-weight-light">
                    {formik.values.error_fields.length ? (
                      formik.values.error_fields.map((inputFields, index) => (
                        <tr key={index}>
                          <td className="text-white border-bottom-0 text-center">
                            <div className="invisible">
                              <span>invisible</span>
                            </div>
                            {index + 1}
                          </td>
                          <td className="border-bottom-0">
                            <div className="invisible">
                              <span>invisible</span>
                            </div>
                            <select
                              name={`error_fields.${index}.error_count`}
                              className={`form-select form-control  font-size13`}
                              id={`error_count${index}`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={inputFields.error_count}
                            >
                              <option disabled selected value="">
                                Select no of Error
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                            {formik.errors ? (
                              <div
                                id="error_count"
                                className="invalid-feedback d-block"
                              >
                                {
                                  formik.errors[
                                    `error_fields.${index}.error_count`
                                  ]
                                }
                              </div>
                            ) : (
                              <div className="invisible">
                                <span>invisible</span>
                              </div>
                            )}
                          </td>
                          {inputFields.error_count != "admin" ? (
                            <>
                              <td className="border-bottom-0 ">
                                <div className="invisible">
                                  <span>invisible</span>
                                </div>
                                <textarea
                                  type="text"
                                  name={`error_fields.${index}.hcc_category`}
                                  // rows={2}
                                  className={`form-control font-size13  ${
                                    formik.touched.hcc_category &&
                                    formik.errors.hcc_category &&
                                    "is-invalid"
                                  }`}
                                  id={`hcc_category_${index}`}
                                  style={{ height: "10px" }}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={inputFields.hcc_category}
                                  // placeholder="HCC Category"
                                ></textarea>
                                {formik.errors ? (
                                  <div
                                    id="error_count"
                                    className="invalid-feedback d-block"
                                  >
                                    {
                                      formik.errors[
                                        `error_fields.${index}.hcc_category`
                                      ]
                                    }
                                  </div>
                                ) : (
                                  <div className="invisible">
                                    <span>invisible</span>
                                  </div>
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="border-bottom-0"></td>
                            </>
                          )}
                          <td className="border-bottom-0">
                            <div className="invisible">
                              <span>invisible</span>
                            </div>
                            <select
                              name={`error_fields.${index}.primary_error`}
                              className={`form-select form-control font-size13`}
                              id="primary_error"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={inputFields.primary_error}
                            >
                              <option disabled selected value="">
                                Select a value
                              </option>
                              <option value="valid_changed_to_invalid">
                                Valid changed to Invalid
                              </option>
                              <option value="invalid_changed_to_valid">
                                Invalid Changed to Valid
                              </option>
                            </select>
                            {formik.errors ? (
                              <div
                                id="error_count"
                                className="invalid-feedback d-block"
                              >
                                {
                                  formik.errors[
                                    `error_fields.${index}.primary_error`
                                  ]
                                }
                              </div>
                            ) : (
                              <div className="invisible">
                                <span>invisible</span>
                              </div>
                            )}
                          </td>

                          <td className="border-bottom-0">
                            <div className="invisible">
                              <span>invisible</span>
                            </div>
                            <textarea
                              type="text"
                              name={`error_fields.${index}.secondary_comment`}
                              // rows={2}
                              className={`form-control font-size13 ${
                                formik.touched.secondary_comment &&
                                formik.errors.secondary_comment &&
                                "is-invalid"
                              }`}
                              id="secondary_comment"
                              style={{ height: "10px" }}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={inputFields.secondary_comment}
                              // placeholder="HCC Category"
                            ></textarea>
                            {formik.errors ? (
                              <div
                                id="error_count"
                                className="invalid-feedback d-block"
                              >
                                {
                                  formik.errors[
                                    `error_fields.${index}.secondary_comment`
                                  ]
                                }
                              </div>
                            ) : (
                              <div className="invisible">
                                <span>invisible</span>
                              </div>
                            )}
                          </td>

                          <td className="border-bottom-0 text-center">
                            <div className="invisible">
                              <span>invisible</span>
                            </div>
                            <button
                              className="btn btn-sm btn-danger shadow-none mt-1 "
                              onClick={(e) => removeErrorFields(e, index)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={15} className="text-center text-white">
                          There's no errors added yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="text-end mt-3">
              <button
                type="submit"
                className="btn btn-sm btn-success shadow-none px-4 py-2 font-size13"
              >
                ADD Audit
              </button>
            </div>
          </form>

          <ResetAuditorErrFields
            showModal={showResetAuditorErrModal}
            hideModal={closeResetAuditorErModal}
            resetErrFileds={resetErrorFields}
          ></ResetAuditorErrFields>
        </div>
      </div>
    </>
  );
};

export default AddAuditsForClientR;
