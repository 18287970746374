import { useState } from "react";
import apiClient from "../../services/ApiClient";

import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import DeleteModal from "../../utils/Modals/DeleteModal";
import ReactPaginate from "react-paginate";
import { useMutation, useQueryClient } from "react-query";
import LoadingIndicator from "../../shared/LoadingIndicator";

const ProjectsTable = ({
  projects,
  isFetching,
  pageCount,
  handlePageClick,
  dataCount,
  dataFrom,
  dataEnd,
}) => {
  const [editId, setEditId] = useState(null);
  const [editFormData, setEditFormData] = useState();

  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editFormErrors, setEditFormErrors] = useState(null);

  const queryClient = useQueryClient();

  // Edit Function Starts
  const handleEditClick = (event, project) => {
    event.preventDefault();
    setEditId(project.id);

    const formValues = {
      ...project,
    };

    setEditFormData(formValues);
  };

  // Edit Functions Starts
  const editMutation = useMutation(
    (editedProject) =>
      apiClient.post(`project/edit/${editedProject.id}`, editedProject, {
        componentName: "editProject",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("clients");
        setEditId(null);
        setEditFormErrors(null);
      },
      onError: (err) => {
        setEditFormErrors(err.response.data.error);
      },
    }
  );

  // Delete Functions Starts
  const deleteMutation = useMutation(
    (id) =>
      apiClient.delete(`project/delete/${id}`, {
        componentName: "deleteProject",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
        setShowDeleteModal(false);
      },
    }
  );

  const handleCancel = () => {
    setEditId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    // console.log(editFormData);

    const editedProject = {
      ...editFormData,
    };
    console.log(editedProject);
    await editMutation.mutateAsync(editedProject);
    // try {
    //   const response = await apiClient.post(
    //     `project/edit/${editId}`,
    //     editedProject
    //   );
    //   fetchData();
    //   setEditId(null);
    // } catch (err) {
    //   setEditFormErrors(err.response.data.error);
    //   console.log(err);
    // }
  };
  // Edit Function Ends

  // Delete  Function Starts
  const handleDelete = async (id) => {
    await deleteMutation.mutateAsync(deleteId);
    // try {
    //   let id = deleteId;
    //   const response = await apiClient.delete(`project/delete/${id}`);
    //   setProjects(projects.filter((client) => client.id !== id));
    //   setShowDeleteModal(false);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteModalFunc = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  // Delete  Function Ends

  return (
    <>
      <div className="card">
        <div className="card-header pb-0 border-0 pt-3">
          <p className="mb-0 font-size13">
            <i className="fas fa-project-diagram me-2"></i>
            Projects
            {dataCount ? (
              <span className="badge count-badge-success ms-1 px-2 py-1">
                {dataCount}
              </span>
            ) : null}
          </p>
        </div>
        <div className="card-body px-0">
          <form onSubmit={handleEditFormSubmit}>
            <div className="table-responsive">
              <table className="table">
                <thead className="font-size13">
                  <tr>
                    <th scope="col" className="text-center">
                      S.No
                    </th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Project Code</th>
                    <th scope="col">Client Name</th>
                    <th scope="col" className="text-center">
                      Teams
                    </th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="font-size13 font-weight-light">
                  {isFetching && <LoadingIndicator />}
                  {projects &&
                    projects.map((project, index) => (
                      <tr key={index}>
                        {editId === project.id ? (
                          <EditOnlyRow
                            editFormData={editFormData}
                            index={index}
                            handleEditFormChange={handleEditFormChange}
                            handleCancel={handleCancel}
                            editFormErrors={editFormErrors}
                          />
                        ) : (
                          <ReadOnlyRow
                            project={project}
                            index={index}
                            handleEditClick={handleEditClick}
                            // deleteModalFunc={deleteModalFunc}
                            dataFrom={dataFrom}
                          />
                        )}
                      </tr>
                    ))}

                  {!projects.length && (
                    <tr className="text-center font-size13">
                      <td colSpan={10}>
                        {isFetching
                          ? "Loading..."
                          : "No data available at this moment"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </form>

          <div className="d-flex justify-content-between align-items-center flex-row-reverse px-3 pt-2">
            <div>
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            {dataFrom && dataEnd && (
              <span className="ms-2 showing  font-size13 grey-clr">
                <i class="fas fa-book-open me-1"></i>
                Showing {dataFrom} - {dataEnd} of {dataCount} entires
              </span>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        title="Project"
      />
    </>
  );
};

export default ProjectsTable;
