import { useEffect, useState } from "react";
import apiClient from "../services/ApiClient";
import { useAuth } from "../context/AuthContext";
import { changeTabTitle } from "../utils/changeTabTitle";
import UsersTable from "../components/users/UsersTable";
import AddUser from "../components/users/AddUsers";
import { useQuery } from "react-query";

const Users = () => {
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);

  const auth = useAuth();

  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(["users", offset, searchTerm], () => fetchData(offset, searchTerm), {
    // keepPreviousData: true,
    staleTime: 30000,
  });

  // Fetch data function
  async function fetchData(offset, searchTerm) {
    try {
      let endPoint = searchTerm ? `user?page=1&search=${searchTerm}` : `user?page=${offset}`;
      const response = await apiClient.get(endPoint, {
        componentName: "users",
      });
      return response.data;
    } catch (err) {
      // You can handle errors here
      throw err;
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // Change TabTitle
  useEffect(() => {
    changeTabTitle("Users");
  }, []);

  // Update pageOptions count when data changes
  useEffect(() => {
    if (data) {
      setPageCount(data.meta.last_page);
      setDataCount(data.meta.total);
      setDataFrom(data.meta.from);
      setDataEnd(data.meta.to);
    }
  }, [data]);

  const access = (auth && auth.user && auth.user.role === "super_admin") || auth.user.role === "manager";

  return (
    <div className="row">
      {access && (
        <div className="col-12 mb-3">
          <AddUser refetch={refetch} />
        </div>
      )}

      <div className={` col-12 `}>
        <UsersTable
          users={data?.data || []}
          refetch={refetch}
          isFetching={isFetching}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          dataCount={dataCount}
          dataFrom={dataFrom}
          dataEnd={dataEnd}
          setSearchTerm={setSearchTerm}
        />
      </div>
    </div>
  );
};

export default Users;
