import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";

const ProjectCard = () => {
  const [projects, setProjects] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const endPoint = params.clientId ? `project/${params.clientId}/client` : "project";
  const fetchData = async () => {
    try {
      const response = await apiClient.get(`${endPoint}`);
      setProjects(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Change TabTitle
  changeTabTitle("Projects");
  return (
    <>
      <div className='text-end'>
        <button className='btn btn-danger btn-sm mb-3' onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
      <CardHeader title='Projects' count={projects.length} icon={<i className='fas fa-project-diagram me-1'></i>} showGoBack={true} />
      <CardBody>
        {projects.map((item, index) => (
          <div className='col-md-3 mb-md-3'>
            <div className='card view-card'>
              <div className='card-body d-flex justify-content-center align-items-center' style={{ height: "220px" }}>
                <div className='col-md-6  p-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <h5 style={{ color: "#9190ff" }}> {item.project_name}</h5>
                      <p className='invisible'>{item.project_name}</p>
                    </div>
                    <div className='col-12'>
                      <h5 style={{ color: "#9190ff" }}>{item.project_code}</h5>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 text-center p-2'>
                  <h1 style={{ fontSize: "4.5rem" }}>{item.teams_count ? <span>{item.teams_count}</span> : 0}</h1>
                  <p>Teams</p>

                  {item.teams_count ? (
                    <Link to={`/all-teams/${item.id}`} className='btn btn-success btn-sm'>
                      View
                    </Link>
                  ) : (
                    <button className='btn btn-secondary btn-sm' disabled>
                      View
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='card view-card'>
              <div className='card-body'>
                <div className=' mt-3 mb-0' style={{ height: 150 }}>
                  <div className='table-responsive'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <td>
                            <span>Project Name</span>
                          </td>
                          <td>
                            <span>{item.project_name}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>Project Code</span>
                          </td>
                          <td>
                            <span>{item.project_code}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>Teams</span>
                          </td>
                          <td>
                            {item.teams.length ? (
                             
                              <span> {item.teams.length}</span>
                            ) : (
                              <span className='text-muted'>NA</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='text-center text-muted'>
                    {item.teams.length ? (
                      <Link to={`/all-teams/${item.id}`} className='btn btn-success btn-sm'>
                        View
                      </Link>
                    ) : (
                      <button className='btn btn-success btn-sm' disabled>
                        View
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ))}
        {!projects.length && <p className='text-center font-size13'>No data available at this moment</p>}
      </CardBody>
    </>
  );
};

export default ProjectCard;
