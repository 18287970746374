import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";
// import CardBody from "./CardBody";
// import CardHeader from "./CardHeader";
import { useAuth } from "../../context/AuthContext";
// import { Breadcrumb } from "react-bootstrap";
import { ucFirst } from "../../utils/UcFirst";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import LoadingIndicator from "../../shared/LoadingIndicator";

const UserCard = () => {
  const [users, setUsers] = useState([]);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [isShowPaginate, setIsShowPaginate] = useState(false);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const auth = useAuth();

  const navigate = useNavigate();

  const params = useParams();
  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(
    ["auditcard", offset, params?.teamId],
    () => fetchData(offset),
    {
      // keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const endPoint = params.teamId
    ? `user/team/${params.teamId}?role=auditor&page=${offset}`
    : `user/team?role=auditor&page=${offset}`;
  // const endPoint = params.teamId ? `user/audit/team/${params.teamId}` : "user/audit/team";

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`${endPoint}`, {
        componentName: "auditcard",
      });
      return response.data;
      // setUsers(response.data.data);
      // let showPaginate = response.data.total / response.data.per_page > 1;
      // setIsShowPaginate(showPaginate);
      // setPageCount(response.data.last_page);
      // setDataCount(response.data.total);
      // setDataFrom(response.data.from);
      // setDataEnd(response.data.to);

      // let filteredAuditors = response.data.filter((item) => {
      //   return item.user_role === "auditor";
      // });

      // const modifiedUsers = filteredAuditors.map((item, index) => {
      //   let tempObj = { ...item };
      //   // tempObj.value = item.id;
      //   // tempObj.label = item.name;
      //   if (item.team_auditor_chart) {
      //     tempObj.totalPagesCount = item.user.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.total_pages;
      //     }, 0);
      //     tempObj.totalErrors = item.user.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.total_errors;
      //     }, 0);
      //     tempObj.adminErrors = item.user.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.admin_error;
      //     }, 0);
      //   } else {
      //     tempObj.totalPagesCount = item.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.total_pages;
      //     }, 0);
      //     tempObj.totalErrors = item.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.total_errors;
      //     }, 0);
      //     tempObj.adminErrors = item.auditors_today_chart.reduce(function (prev, current) {
      //       return prev + +current.admin_error;
      //     }, 0);
      //     tempObj.team_auditor_chart = item.auditor_chart;
      //     tempObj.user = item;
      //   }

      //   return tempObj;
      // });
      // setUsers(filteredAuditors);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [offset]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  useEffect(() => {
    if (data) {
      let showPaginate = data.total / data.per_page > 1;
      setUsers(data.data);
      setIsShowPaginate(showPaginate);
      setPageCount(data.last_page);
      setDataCount(data.total);
      setDataFrom(data.from);
      setDataEnd(data.to);
    }
  });

  let teamId = params.teamId || auth.user.active_team_id;
  // users.map((item, index) => {
  //   console.log(item.user.team_id);
  //   teamId = item.user.team_id;
  // });

  // const breadcrumbs = ["home", "about", "users"];
  // Change TabTitle
  changeTabTitle("Audit-Users");

  return (
    <>
      {/* <div className='text-start'>
        {auth && auth.user && auth.user.role === "lead" ? (
          <button className='btn btn-danger btn-sm invisible mb-3'>Go Back</button>
        ) : (
          <div className='text-end'>
          <button className='btn btn-danger btn-sm mb-3' onClick={() => navigate(-1)}>
            Go Back
          </button>
          </div>
         
        )}
        <Link to={`/all-users/${teamId}`} className='btn btn-primary btn-sm me-2 mb-3'>
            Users
        </Link>
        <Link to={`/auditor-users/${teamId}`} className='btn btn-primary btn-sm me-2 mb-3'>
            Auditor
        </Link>
      </div> */}
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <ul className="nav nav-pills  me-auto">
                  <li className="nav-item">
                    {params.teamId ? (
                      <NavLink
                        to={`/all-users/${params.teamId}`}
                        className="nav-link me-2 py-1"
                      >
                        Coders
                        <img
                          className="tri-img-content"
                          style={{ left: "26px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    ) : (
                      <NavLink to={`/all-users`} className="nav-link me-2 py-1">
                        Coders
                        <img
                          className="tri-img-content"
                          style={{ left: "26px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    )}
                  </li>
                  <li className="nav-item">
                    {params.teamId ? (
                      <NavLink
                        to={`/auditor-users/${params.teamId}`}
                        className="nav-link me-2 py-1"
                      >
                        Auditors
                        <img
                          className="tri-img-content"
                          style={{ left: "32px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/auditor-users`}
                        className="nav-link me-2 py-1"
                      >
                        Auditors
                        <img
                          className="tri-img-content"
                          style={{ left: "32px" }}
                          src="/images/blue-tri.png"
                          width="15px"
                          alt="triangle-img"
                        />
                      </NavLink>
                    )}
                  </li>
                </ul>
                {auth && auth.user && auth.user.role === "lead" ? (
                  <button className="btn btn-danger btn-sm invisible ms-auto">
                    Go Back
                  </button>
                ) : (
                  <div className="text-end">
                    <button
                      className="btn btn-danger btn-sm ms-auto "
                      onClick={() => navigate(-1)}
                    >
                      Go Back
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div
              className={`card-header mb-3 pb-0 border-0 pt-3  d-flex justify-content-between align-items-center ${
                params.teamId ? null : "py-3"
              }`}
            >
              <h6 className="font-size13">
                <i className="fas fa-user me-2"></i>
                Auditors
                {dataCount ? (
                  <span className="badge bg-success ms-1  rounded-pill">
                    {dataCount}
                  </span>
                ) : null}
              </h6>
              {/* <span className='mb-0 me-2 i-primary'>
                      <span className="white">Lead Name : </span> 
                       {users[0]?.lead_name}
                      </span> */}
              <div>
                {params.teamId || auth.user.role === "lead" ? (
                  <Link
                    to={`/auditor-charts/${teamId}/today?viewBy=team`}
                    className="btn  export-bg-success text-light shadow-none ms-4 btn-sm mb-2 font-size13 "
                  >
                    Team Summary
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <CardHeader title='Users' count={users.length} icon={<i className='fas fa-user me-2'></i>} showGoBack={true} /> */}
      {/* <CardBody>
        {users.map((item) => (
          <div className='col-xl-4 col-lg-6 mb-4' key={item.id}>
            <div className='card view-card'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <p className='mb-0'>
                  <i className='fas fa-user me-2'></i> {ucFirst(item.user_name)}
                </p>
                {teamId ? (
                  <span className={`badge ${item.team_status === "active" ? "bg-success" : "bg-danger"}`}>{ucFirst(item.team_status)}</span>
                ) : (
                  <span className={`badge ${item.user_status === "active" ? "bg-success" : "bg-danger"}`}>{ucFirst(item.user_status)}</span>
                )}
              </div>
              <div className='card-body d-flex justify-content-center align-items-center'> */}
      {/* <div className='col-md-6  p-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <p style={{ color: "#9190ff" }}> Auditor Name</p>
                      <p>{ucFirst(item.name)}</p>
                    </div>
                    <div className='col-12'>
                      <p style={{ color: "#9190ff" }}> Team Lead</p>
                      <p className='mb-0'>
                        {item.team && item.team.lead_role ? (
                          item.team.lead_role.map((item) => ucFirst(item.name))
                        ) : (
                          <span className='text-muted'>NA</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div> */}

      {/* <div className='col-12 p-2'>
                  <div className='row'>
                    <p className='col-12'>
                      {item.login_name ? (
                        <span style={{ color: "#5ca360" }}>{item.login_name}</span>
                      ) : (
                        <span className='text-muted'>NA</span>
                      )}
                    </p>
                  </div>
                  <div className='row'>
                    <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Audits
                    </p>

                    <p className='col-4'>
                      {item.today_chart_count ? <span>{item.today_chart_count}</span> : <span className='text-muted'>NA</span>}
                    </p>
                  </div>
                  <div className='row'>
                    <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Errors
                    </p>
                    <p className='col-4 '>{item.today_total_errors ? item.today_total_errors : <span className='text-muted'>NA</span>}</p>
                  </div>
                  <div className='row'>
                    <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Pages
                    </p>
                    <p className='col-4 '>{item.today_pages ? item.today_pages : <span className='text-muted'>NA</span>}</p>
                  </div>
                  <div className='row'>
                    <p className='col-8 text-nowrap' style={{ color: "#9190ff" }}>
                      Admin Errors
                    </p>
                    <p className='col-4 '>{item.today_admin_errors ? item.today_admin_errors : <span className='text-muted'>NA</span>}</p>
                  </div>

                  <div className='row'>
                    <p className='col-8 invisible'></p>
                    <p className='col-4 '>
                      {item.total_chart_count ? (
                        item.total_chart_count ? (
                          <Link
                            to={`/auditor-charts/${item.user_id}/today/${item.user_log_id}/?viewBy=user`}
                            className='btn btn-success btn-sm'
                          >
                            View
                          </Link>
                        ) : (
                          <Link to={`/auditor-charts/${item.id}/today/${item.user_id}/?viewBy=user`} className='btn btn-success btn-sm'>
                            View
                          </Link>
                        )
                      ) : (
                        <button className='btn btn-secondary btn-sm' disabled>
                          View
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-between align-items-center'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex'>
                    <p className='mb-0 me-2' style={{ color: "#9190ff" }}>
                      Lead
                    </p>
                    <p className='mb-0'>
                      <p className='mb-0'>{item.lead_name ? ucFirst(item.lead_name) : <span className='text-muted'>NA</span>}</p>
                    </p>
                  </div>
                </div>
                <div className='text-end'>
                  <span style={{ color: "#9190ff" }}>Total Audits</span>
                  {item.total_chart_count ? (
                    <span className='badge bg-secondary ms-2'>{item.total_chart_count}</span>
                  ) : (
                    <span className='text-muted ms-2'>NA</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))} */}
      {/* {!users.length && <p className='text-center'>No data available at this moment</p>} */}
      {/* </CardBody> */}
      <div className="table-responsive">
        <table className="table mb-0 ">
          <thead className=" font-size13">
            <tr>
              <th scope="col" className="text-center">
                S.No
              </th>

              <th scope="col">Auditor Name</th>
              <th scope="col">Login Name</th>
              <th scope="col">Lead Name</th>
              <th scope="col" className="text-center">
                Today Audits
              </th>
              <th scope="col" className="text-center">
                Today Errors
              </th>
              <th scope="col" className="text-center">
                Today Pages
              </th>
              <th scope="col" className="text-center">
                Today Admin Errors
              </th>
              <th scope="col">Total Audits</th>
              <th scope="col">Status</th>
              <th scope="col" className="text-center">
                View
              </th>
            </tr>
          </thead>
          <tbody className=" font-size13 font-weight-light">
            {isFetching && <LoadingIndicator />}
            {users &&
              users.map((item, index) => (
                <tr key={item.id}>
                  <td className="text-center">{dataFrom + index}</td>
                  <td>{ucFirst(item.user_name)}</td>
                  <td>{item.login_name} </td>
                  <td>
                    {item.lead_name ? (
                      ucFirst(item.lead_name)
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.today_chart_count ? (
                      <span>{item.today_chart_count}</span>
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.today_total_errors ? (
                      <span>{item.today_total_errors}</span>
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.today_pages ? (
                      <span>{item.today_pages}</span>
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.today_admin_errors ? (
                      <span>{item.today_admin_errors}</span>
                    ) : (
                      <span className="text-muted">NA</span>
                    )}
                  </td>
                  <td>
                    {item.total_chart_count ? (
                      <span className="badge custom-btn-pink  ms-4 ">
                        {item.total_chart_count}
                      </span>
                    ) : (
                      <span className="text-muted ms-4">NA</span>
                    )}
                  </td>

                  <td>
                    {teamId ? (
                      <span
                        className={`badge ${
                          item.team_status === "active"
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {ucFirst(item.team_status)}
                      </span>
                    ) : (
                      <span
                        className={`badge ${
                          item.user_status === "active"
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {ucFirst(item.user_status)}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {item.total_chart_count ? (
                      item.total_chart_count ? (
                        <Link
                          to={`/auditor-charts/${item.user_id}/today/${item.user_log_id}/?viewBy=user`}
                        >
                          <i class="fa fa-solid fa-eye i-info"></i>
                        </Link>
                      ) : (
                        <Link
                          to={`/auditor-charts/${item.id}/today/${item.user_id}/?viewBy=user`}
                          className="btn btn-success btn-sm"
                        >
                          <i class="fa fa-solid fa-eye i-info"></i>
                        </Link>
                      )
                    ) : (
                      <button className="btn p-0" disabled>
                        <i class="fa fa-solid fa-eye i-info"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            {!users.length && (
              <tr className="text-center font-size13">
                <td colSpan={25}>
                  {isFetching
                    ? "Loading..."
                    : "No data available at this moment"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="td-bg-clr pt-4">
        {isShowPaginate && (
          <div className="d-flex justify-content-between align-items-center flex-row-reverse px-4 pb-4">
            <div>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            {dataFrom && dataEnd && (
              <span className="ms-2 showing font-size13 grey-clr">
                <i class="fas fa-book-open me-1"></i>
                Showing {dataFrom} - {dataEnd} of {dataCount} entries
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserCard;
