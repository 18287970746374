import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import apiClient from "../services/ApiClient";
import { ucFirst } from "../utils/UcFirst";
import AgreeModal from "../utils/Modals/AgreeModal";
import DisagreeModal from "../utils/Modals/DisagreeModal";

const ErrorFeedback = () => {
  const [data, setData] = useState([]);
  const [agreeId, setAgreeId] = useState(null);
  const [evidence, setEvidence] = useState([]);
  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const [showDisagreeModal, setShowDisagreeModal] = useState(false);
  const [disagreeComments, setdisagreeComments] = useState("");
  const [agreeType, setAgreeType] = useState("");
  const [agreeErrors, setAgreeErrors] = useState({});
  const [disAgreeErrors, setDisAgreeErrors] = useState({});
  const auth = useAuth();

  useEffect(() => {
    fetchData();
  }, []);

  const userLogId = auth.user.active_log_id;

  const isClientRProject =
    auth.user.project_id == process.env.REACT_APP_R_PROJECT_ID;

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`feedback/${userLogId}`);
      setData(response.data);
      if (!response.data.length) {
        const exisitingUserData = auth.user;
        exisitingUserData.is_user_error_free = "true";
        localStorage.setItem("user", JSON.stringify(exisitingUserData));
        auth.setUser(exisitingUserData);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Agree Modal starts

  const closeAgreeModal = () => {
    setShowAgreeModal(false);
    setEvidence(null);
  };

  const agreeModalFunc = (id, agreeType) => {
    setShowAgreeModal(true);
    setAgreeId(id);
    setAgreeType(agreeType);
  };

  const handleFiles = (files) => {
    const uploaded = [...files];
    setEvidence(uploaded);
  };

  useEffect(() => {
    console.log(evidence);
  }, [evidence]);

  const handleAgree = async () => {
    try {
      let id = agreeId;
      let uri =
        agreeType == "agree_one"
          ? `feedback/agree/${id}`
          : `feedback/agree/all/${id}`;
      const formData = new FormData();
      if (auth.user.role === "user" && !isClientRProject) {
        evidence.map((element) => {
          formData.append("evidences[]", element);
        });
      }
      const response = await apiClient.post(uri, formData);
      fetchData();
      setShowAgreeModal(false);
    } catch (err) {
      setAgreeErrors(err.response.data.error);
    }
  };

  // Agree modal ends

  // DisAgree Modal starts

  const closeDisagreeModal = () => {
    setShowDisagreeModal(false);
    setEvidence(null);
  };

  const disagreeModalFunc = (id) => {
    setShowDisagreeModal(true);
    setAgreeId(id);
  };

  const handleDisAgree = async () => {
    try {
      let id = agreeId;

      const formData = new FormData();
      if (auth.user.role === "user" && !isClientRProject) {
        evidence.map((element) => {
          formData.append("evidences[]", element);
        });
      }
      formData.append("comment", disagreeComments);
      const response = await apiClient.post(
        `feedback/disagree/${id}`,
        formData
      );

      // const response = await apiClient.post(`feedback/disagree/${id}`, {
      //   comment: disagreeComments,
      //   evidence,
      //   evidence,
      // });
      fetchData();
      setShowDisagreeModal(false);
    } catch (err) {
      console.log(err.response.data.error);
      setDisAgreeErrors(err.response.data.error);
    }
  };

  // DisAgree modal ends

  return (
    <>
      <div className="card mb-3 ">
        <div className="card-body">
          <h6 className="text-warning mb-0 font-size13">
            <i class="fas fa-file-alt"></i> Error Charts
          </h6>
        </div>
      </div>
      {data.map((data) => (
        <>
          <div className="card mb-3 font-size13">
            <div className="card-header d-flex justify-content-between align-items-center">
              <p className="mb-0">
                <span className="text-info">
                  {isClientRProject ? "Chase Id" : " Chart Id"}
                </span>
                <span className="ms-3">
                  {data.auditor_chart.coder_chart.chart_id}
                </span>
              </p>
              <p className="d-flex justify-content-between align-items-center mb-0 ms-3">
                <span className="text-info">Project Name</span>
                <span className="ms-3">
                  {data.auditor_chart.coder_chart.project.project_name}
                </span>
              </p>
              {auth.user.role === "auditor" ? (
                <>
                  <p className="d-flex justify-content-between align-items-center mb-0 ms-3">
                    <span className="text-info">Coder Name</span>
                    <span className="ms-3">
                      {data.auditor_chart.coder_chart.user.name}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between align-items-center mb-0 ms-3">
                    <span className="text-info">Coder Login Name</span>
                    <span className="ms-3">
                      {data.auditor_chart.coder_chart.user_login.login_name}
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p className="d-flex justify-content-between align-items-center mb-0 ms-3">
                    <span className="text-info">Auditor Name</span>
                    <span className="ms-3">{data.auditor_chart.user.name}</span>
                  </p>
                  <p className="d-flex justify-content-between align-items-center mb-0 ms-3">
                    <span className="text-info">Auditor Login Name</span>
                    <span className="ms-3">
                      {data.auditor_chart.user_login.login_name}
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="card-body">
              {isClientRProject ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">Sub Project</span>
                        <span>
                          {data.auditor_chart.coder_chart.sub_project}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">Member Name</span>
                        <span>
                          {data.auditor_chart.coder_chart.member_name}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">D.O.B</span>
                        <span>
                          {new Date(data.auditor_chart.coder_chart.dob)
                            .toDateString()
                            .slice(4)}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">
                          Total HCC Category Reviewed
                        </span>
                        <span>{data.auditor_chart.coder_chart.icd}</span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">Total Pages</span>
                        <span>{data.auditor_chart.coder_chart.pages}</span>
                      </p>
                    </div>

                    <div className="col-md-4">
                      <p className="d-flex justify-content-between align-items-center">
                        <span className="text-info">Coding Complete Date</span>
                        <span>
                          {new Date(data.auditor_chart.coding_complete_date)
                            .toDateString()
                            .slice(4)}
                        </span>
                      </p>
                    </div>
                    {/* <p>
                    <span>Comments</span>
                    <span>Project Name</span>
                  </p> */}
                    {/* <p>
                <span>Coding Date</span>
                <span>Project Name</span>
              </p> */}
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-4">
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Chart Level QAScore</span>
                      <span>{data.auditor_chart.chart_qa_score}</span>
                    </p>

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">No of DX codes Error</span>
                      <span>{data.auditor_chart.dx_codes_error}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">No of Admin errors</span>
                      <span>{data.auditor_chart.admin_error}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Original Codes Found</span>
                      <span>{data.auditor_chart.original_code_found}</span>
                    </p>

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">DX Level QAScore</span>
                      <span>{data.auditor_chart.dx_qa_score}</span>
                    </p>

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Total Absolute Errors</span>
                      <span>{data.auditor_chart.total_errors}</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Codes Added</span>
                      <span>{data.auditor_chart.codes_added}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Codes Deleted</span>
                      <span>{data.auditor_chart.codes_deleted}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">DOS Corrected</span>
                      <span>{data.auditor_chart.dos_corrected}</span>
                    </p>

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">
                        DX Level Comment Code Corrected
                      </span>
                      <span>
                        {data.auditor_chart.dx_level_comment_code_corrected}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">
                        Rendering Provider Corrected
                      </span>
                      <span>
                        {data.auditor_chart.rendering_provider_corrected}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Audit Complete Date</span>
                      <span>
                        {new Date(data.auditor_chart.audit_complete_date)
                          .toDateString()
                          .slice(4)}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Member Name</span>
                      <span>{data.auditor_chart.coder_chart.member_name}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">D.O.B</span>
                      <span>
                        {new Date(data.auditor_chart.coder_chart.dob)
                          .toDateString()
                          .slice(4)}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Total ICD</span>
                      <span>{data.auditor_chart.coder_chart.icd}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Total Pages</span>
                      <span>{data.auditor_chart.coder_chart.pages}</span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Coding Status</span>
                      <span>
                        {data.auditor_chart.coder_chart.action ===
                        "code_completed"
                          ? "Completed"
                          : ucFirst(data.auditor_chart.coder_chart.action)
                              ?.split("_")
                              .join(" ")}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">POS Corrected</span>
                      <span>{data.auditor_chart.pos_corrected}</span>
                    </p>

                    {/* <p>
                    <span>{data.auditor_chart.coding_complete_date}</span>

                    <span>{data.auditor_chart.coding_complete_date}</span>
                  </p> */}

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Total DOS</span>
                      <span>{data.auditor_chart.coder_chart.dos}</span>
                    </p>

                    <p className="d-flex justify-content-between align-items-center">
                      <span className="text-info">Coding Complete Date</span>
                      <span>
                        {new Date(data.auditor_chart.coding_complete_date)
                          .toDateString()
                          .slice(4)}
                      </span>
                    </p>
                    {/* <p>
                    <span>Comments</span>
                    <span>Project Name</span>
                  </p> */}
                    {/* <p>
                <span>Coding Date</span>
                <span>Project Name</span>
              </p> */}
                  </div>
                </div>
              )}
            </div>
            <hr />
            <div className="px-3">
              <div className="d-flex justify-content-between pb-2 align-items-center">
                <h5 className="font-size13">Feedbacks</h5>
                {/* <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    agreeModalFunc(data.error_log_id, "agree_all");
                  }}
                >
                  Agree all
                </button> */}
                {isClientRProject ? null : (
                  <div className="font-size13">
                    <i class="fas fa-plus-circle text-success m-2"></i> Added
                    <i class="fas fa-minus-circle text-danger m-2"></i> Deleted
                    <i class="fas fa-arrow-circle-up text-secondary m-2"></i>
                    Updated
                    <i class="fas fa-user-cog text-warning m-2"></i> Admin
                  </div>
                )}
              </div>
              <div
                className="p-3 pb-0 mb-3 rounded-3 font-size13"
                style={{ backgroundColor: "#1b1b50" }}
              >
                {data.error_feedback.map((feedback, index) => (
                  <>
                    <div
                      className="d-flex justify-content-between font-size13"
                      style={{ backgroundColor: "#1b1b50" }}
                    >
                      <div>
                        <p className="mb-3 d-flex align-items-start font-size13">
                          {feedback.error_status === "added" && (
                            <i class="fas fa-plus-circle text-success me-2 mt-1"></i>
                          )}
                          {feedback.error_status === "deleted" && (
                            <i class="fas fa-minus-circle text-danger me-2 mt-1"></i>
                          )}
                          {feedback.error_status === "updated" && (
                            <i class="fas fa-arrow-circle-up text-secondary me-2 mt-1"></i>
                          )}
                          {feedback.error_status === "admin" && (
                            <i class="fas fa-user-cog text-warning me-2 mt-1"></i>
                          )}
                          {feedback.comments?.split("_").join(" ")}
                        </p>

                        <div className="px-5">
                          {feedback.coder_status && (
                            <p>
                              <span className="text-info">Coder Status : </span>
                              {ucFirst(feedback.coder_status)}
                            </p>
                          )}
                          {feedback.coder_status &&
                            feedback.coder_status == "disagree" && (
                              <p>
                                <span className="text-info">
                                  Coder comment :{" "}
                                </span>
                                {ucFirst(feedback.coder_comment)}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="text-nowrap">
                        <button
                          className="btn btn-sm btn-success me-3 font-size13"
                          onClick={() => {
                            agreeModalFunc(feedback.id, "agree_one");
                          }}
                        >
                          Agree
                        </button>
                        <button
                          className="btn btn-sm btn-danger font-size13"
                          onClick={() => {
                            disagreeModalFunc(feedback.id);
                          }}
                        >
                          Disagree
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      ))}

      <AgreeModal
        showAgreeModal={showAgreeModal}
        closeAgreeModal={closeAgreeModal}
        handleAgree={handleAgree}
        setEvidence={setEvidence}
        agreeErrors={agreeErrors}
        handleFiles={handleFiles}
        auth={auth}
        isClientRProject={isClientRProject}
      />
      <DisagreeModal
        showDisagreeModal={showDisagreeModal}
        closeDisagreeModal={closeDisagreeModal}
        handleDisAgree={handleDisAgree}
        handleFiles={handleFiles}
        setdisagreeComments={setdisagreeComments}
        disAgreeErrors={disAgreeErrors}
        evidence={evidence}
        auth={auth}
        setEvidence={setEvidence}
        isClientRProject={isClientRProject}
      />
    </>
  );
};

export default ErrorFeedback;
