import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";

const EditOnlyRow = ({
  index,
  editFormData,
  handleCancel,
  handleEditFormChange,
  editFormErrors,
  isLoading,
}) => {
  // console.log(editFormData);
  return (
    <>
      <td>{index + 1}</td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter a Client Name"
          name="client_name"
          className="form-control"
          value={editFormData.client_name}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.client_name && (
          <div id="clientName" className="invalid-feedback d-block">
            {editFormErrors.client_name}
          </div>
        )}
      </td>
      <td>
        <input
          type="text"
          required="required"
          placeholder="Enter Client Code"
          name="client_code"
          className="form-control"
          value={editFormData.client_code}
          onChange={handleEditFormChange}
        ></input>
        {editFormErrors && editFormErrors.client_code && (
          <div id="clientName" className="invalid-feedback d-block">
            {editFormErrors.client_code}
          </div>
        )}
      </td>
      <td className="text-center">
        {editFormData.project_count ? editFormData.project_count : 0}
      </td>

      <td>{new Date(editFormData.created_at).toDateString().slice(4)}</td>
      <td className="text-nowrap">
        {isLoading ? (
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Save Client</Tooltip>}
            >
              <button
                type="submit"
                className="btn btn-sm i-success shadow-none ml-2"
              >
                <i
                  className="fas fa-arrow-alt-circle-right"
                  aria-hidden="true"
                />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Cancel Edit</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm i-primary font-15 shadow-none ml-2"
                onClick={handleCancel}
              >
                <i className="fa fa-times" aria-hidden="true" />
              </button>
            </OverlayTrigger>
          </>
        )}
      </td>
    </>
  );
};

export default EditOnlyRow;
