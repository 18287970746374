import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import apiClient from "../../services/ApiClient";
import { useRef, useState } from "react";

const AddTeamUsersModal = (props) => {
  const { showAddTeamUserModal, closeAddTeamUserModal, users, teamUserId, fetchData } = props;

  const [selectedusers, setSelectedUsers] = useState(null);
  const selectInputRef = useRef();

  const userOptions = users.map((user) => {
    let tempobj = {};
    tempobj.value = user.id;
    tempobj.label = `${user.name} - ${user.email}`;
    return tempobj;
  });

  const handleTeamUserSubmit = async (e) => {
    e.preventDefault();
    if (selectedusers) {
      let usersValues = selectedusers.map((user) => user.value);
      const combinedValues = { team_id: teamUserId, team_members: usersValues };
      try {
        const response = await apiClient.post("/user/assign/team", combinedValues);

        selectInputRef.current.clearValue();
        closeAddTeamUserModal();
        fetchData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Modal show={showAddTeamUserModal} onHide={closeAddTeamUserModal} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <h6 className='mb-0 font-size13'>Add Users</h6>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleTeamUserSubmit}>
          <div className='form-group mb-3'>
            <Select
              ref={selectInputRef}
              isClearable={true}
              options={userOptions}
              isMulti={true}
              closeMenuOnSelect={true}
              isSearchable
              onChange={setSelectedUsers}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  text: "orangered",

                  primary25: "skyblue",
                  primary: "black",

                  neutral0: "#303c4a",
                  neutral10: "#1898a9",
                },
              })}
            />
          </div>

          <div className='text-end'>
            <button type='submit' className='btn custom-btn-primary px-4 font-size13'>
              Add
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTeamUsersModal;
