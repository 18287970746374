import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import { useAuth } from "../../../context/AuthContext";

const ReadOnlyRow = ({ user, index, deleteModalFunc }) => {
  const auth = useAuth();
  const access = (auth && auth.user && auth.user.role === "super_admin") || auth.user.role === "manager";

  return (
    <tr key={user.id}>
      <td className="text-center">{index + 1}</td>
      <td>{user.name}</td>
      {/* <td>{user.login_name}</td> */}
      <td>{user.email}</td>
      <td>{user.employee_id}</td>
      <td>{user.role}</td>
      {access && (
        <td className='text-nowrap'>
          <OverlayTrigger placement='top' overlay={<Tooltip>Delete User</Tooltip>}>
            <button
              type='button'
              className='btn btn-sm i-primary font-15 shadow-none ml-2'
              onClick={() => {
                deleteModalFunc(user.id);
              }}
            >
              <i className='fa fa-trash' aria-hidden='true' />
            </button>
          </OverlayTrigger>
        </td>
      )}
    </tr>
  );
};

export default ReadOnlyRow;
