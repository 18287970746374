import { useState } from "react";

// import AddAudits from "./AddAudits";
import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import apiClient from "../../services/ApiClient";
import { useAuth } from "../../context/AuthContext";
import { ucFirst } from "../../utils/UcFirst";
import { useParams } from "react-router";
import ReactPaginate from "react-paginate";
// import ErrorFeedback from "../ErrorFeedback";

const AuditsTableForClientR = ({
  audits,
  setAudits,
  fetchData,
  coderInfo,
  viewType,
  teamsInfo,
  filterByTeam,
  selectTeamID,
  pageCount,
  handlePageClick,
  dataCount,
  setPageCount,
  setDataCount,
  timeLine,
  dataFrom,
  dataEnd,
  setDataFrom,
  setDataEnd,
  isShowPaginate,
  setIsShowPaginate,
  exportHandler,
  projectId,
  setprojectId,
}) => {
  // console.log(dataEnd);
  const [editId, setEditId] = useState(null);

  const [editFormData, setEditFormData] = useState(null);

  const [editFormErrors, setEditFormErrors] = useState(null);

  const auth = useAuth();

  const params = useParams();

  console.log(audits && dataCount);
  // console.log(audits);
  const auditorProjectId = auth.user.project_id || projectId;

  const isLHPProject = Number(auditorProjectId) === Number(`${process.env.REACT_APP_LHP_PROJECT_ID}`);

  const handleEditClick = (event, audit) => {
    event.preventDefault();
    setEditId(audit.id);

    const formValues = {
      ...audit,
    };

    setEditFormData(formValues);
    setEditFormErrors(null);
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };

    newFormData[fieldName] = fieldValue;

    delete newFormData.cpt_codes;
    delete newFormData.cpt_errors;
    delete newFormData.cpt_qa_score;
    delete newFormData.icd_codes;
    delete newFormData.icd_errors;
    delete newFormData.icd_qa_score;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    const editedChart = {
      ...editFormData,
    };

    try {
      const response = await apiClient.post(`audit/edit/${editId}`, editedChart);
      setEditId(null);
      fetchData();
    } catch (err) {
      setEditFormErrors(err.response.data.error);
      // console.log(err);
    }
  };

  return (
    <>
      <>
        <div className="card">
          <div className="card-header pb-0 border-0 pt-3 d-flex justify-content-between align-items-center">
            <h6 className="mb-0 font-size13">
              <i className="fas fa-file-alt me-2"></i>
              Audits
              {dataCount ? <span className="badge count-badge-success ms-1 px-2 py-1">{dataCount}</span> : null}
            </h6>

            {auth && auth.user.role !== "auditor" && coderInfo.coderName && viewType === "user" && (
              <p className="mb-0 d-flex align-items-center  font-size13 ">
                <span className="text-nowrap  light-text-op1">
                  <span>
                    <i class="fas fs-6 fa-user me-2 result-key"></i>Auditor Name:
                  </span>{" "}
                  {ucFirst(`${coderInfo.coderName}`)}
                </span>
              </p>
            )}
            {params.id && (
              <button onClick={exportHandler} className="btn btn-sm export-bg-success text-light shadow-none ms-4">
                Export
              </button>
            )}
          </div>
          <div className="card-body  px-0">
            <div className="table-responsive" style={{ overflowX: "auto" }}>
              <form onSubmit={handleEditFormSubmit}>
                <table className="table">
                  <thead className="font-size13 text-nowrap">
                    <tr>
                      {/* {auth && auth.user.role === "auditor" && (
                        <th scope="col"></th>
                      )} */}
                      {auth && auth.user.role !== "auditor" && <th scope="col">Log</th>}
                      <th scope="col" className="text-center">
                        S.No
                      </th>
                      {auth && auth.user.role !== "auditor" && <th scope="col">Team ID</th>}
                      {auth && auth.user.role !== "auditor" && <th scope="col">Project Name</th>}
                      <th scope="col">Sub Project</th>
                      <th scope="col">Chase ID </th>
                      <th scope="col" className="text-wrap">
                        Coder Name
                      </th>
                      <th scope="col" className="text-wrap">
                        Coder Login Name
                      </th>
                      <th scope="col" className="text-wrap text-center">
                        Coding Complete Date
                      </th>
                      <th scope="col" className="text-wrap text-center">
                        Audit Complete Date
                      </th>
                      <th scope="col" className="text-wrap text-center">
                        Total HCC Category Reviewed
                      </th>
                      {auth && auth.user.role !== "auditor" && viewType === "team" && <th scope="col">Auditor Name</th>}
                      {auth && auth.user.role !== "auditor" && viewType === "team" && <th scope="col">Auditor Login Name</th>}
                      <th scope="col" className="text-wrap">
                        Total Pages
                      </th>
                      <th scope="col" className="text-wrap">
                        Total Errors
                      </th>
                      <th scope="col" className="text-wrap">
                        Auditor Comment
                      </th>

                      <th scope="col" className="text-wrap">
                        QAScore
                      </th>

                      <th scope="col" className="text-nowrap">
                        Coding Date
                      </th>

                      {auth && auth.user.role !== "auditor" && (
                        <>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {audits &&
                      audits.map((audit, index) => (
                        <>
                          {editId === audit.id ? (
                            <EditOnlyRow
                              key={index}
                              editFormData={editFormData}
                              setEditFormData={setEditFormData}
                              index={index}
                              handleEditFormChange={handleEditFormChange}
                              handleCancel={handleCancel}
                              editFormErrors={editFormErrors}
                              dataFrom={dataFrom}
                            />
                          ) : (
                            <ReadOnlyRow
                              key={index}
                              audit={audit}
                              index={index}
                              handleEditClick={handleEditClick}
                              viewType={viewType}
                              dataFrom={dataFrom}
                              projectId={projectId}
                            />
                          )}
                        </>
                      ))}

                    {!audits.length && (
                      <tr className="text-center font-size13">
                        <td colSpan={25}>No data available at this moment</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
            </div>
            {isShowPaginate && (
              <div className="d-flex justify-content-between align-items-center flex-row-reverse px-3">
                <div>
                  <ReactPaginate
                    key={timeLine}
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
                {dataFrom && dataEnd && (
                  <span className="ms-2 showing font-size13 grey-clr">
                    <i class="fas fa-book-open  me-1"></i>
                    Showing {dataFrom} -{dataEnd} of {dataCount} entries
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default AuditsTableForClientR;
