import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

const ReadOnlyRow = ({ project, index, handleEditClick, dataFrom }) => {
  return (
    <>
      {dataFrom ? (
        <td className="text-center">{dataFrom + index}</td>
      ) : (
        <td className="text-center">{index + 1}</td>
      )}
      <td>{project.project_name}</td>
      <td>{project.project_code}</td>
      <td>{project.client_name}</td>
      <td className="text-center">
        {project.teams_count ? (
          <Link
            to={`/all-teams/${project.id}`}
            className="btn-sm btn-primary font-size13 text-decoration-none"
          >
            {project.teams_count}
          </Link>
        ) : (
          <span className="text-muted">NA</span>
        )}
      </td>
      <td>{new Date(project.created_at).toDateString().slice(4)}</td>
      <td className="text-nowrap">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Edit project</Tooltip>}
        >
          <button
            type="button"
            className="btn btn-sm i-secondary shadow-none ml-2"
            onClick={(event) => handleEditClick(event, project)}
          >
            <i className="fa fa-edit" aria-hidden="true" />
          </button>
        </OverlayTrigger>

        {/* <OverlayTrigger placement='top' overlay={<Tooltip>Delete project</Tooltip>}>
          <button
            type='button'
            className='btn btn-sm i-primary font-15 shadow-none ml-2'
            onClick={() => {
              deleteModalFunc(project.id);
            }}
          >
            <i className='fa fa-trash' aria-hidden='true' />
          </button>
        </OverlayTrigger> */}
      </td>
    </>
  );
};

export default ReadOnlyRow;
