import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../services/ApiClient";
import { changeTabTitle } from "../../utils/changeTabTitle";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";

const ClientCard = () => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`client`);
      setClients(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Change TabTitle
  changeTabTitle("Clients");

  return (
    <>
      <div className='text-end'>
        <button className='btn btn-danger btn-sm mb-3' onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
      <CardHeader title='Clients' count={clients.length} icon={<i className='fas fa-clinic-medical me-1'></i>} showGoBack={true} />
      <CardBody>
        {clients.map((item, index) => (
          <div className='col-md-3 mb-md-3'>
            <div className='card view-card'>
              <div className='card-body d-flex justify-content-center align-items-center' style={{ height: "220px" }}>
                <div className='col-md-6  p-2'>
                  <div className='row'>
                    <div className='col-12'>
                      <h5 style={{ color: "#9190ff" }}>{item.client_name}</h5>
                      <p className='invisible'>{item.client_name}</p>
                    </div>
                    <div className='col-12'>
                      <h5 style={{ color: "#9190ff" }}>{item.client_code}</h5>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 text-center p-2'>
                  <h1 style={{ fontSize: "4.5rem" }}>{item.project_count ? <span>{item.project_count}</span> : 0}</h1>
                  <p>Projects</p>

                  {item.project_count ? (
                    <Link to={`/all-projects/${item.id}`} className='btn btn-success btn-sm px-3'>
                      View
                    </Link>
                  ) : (
                    <button className='btn btn-secondary btn-sm px-3' disabled>
                      View
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        {!clients.length && <p className='text-center font-size13'>No data available at this moment</p>}
      </CardBody>
    </>
  );
};

export default ClientCard;
