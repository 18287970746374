import { useQuery } from "react-query";
import { changeTabTitle } from "../utils/changeTabTitle";
import apiClient from "../services/ApiClient";
import ClientsTable from "../components/clients/ClientsTable";
import AddClient from "../components/clients/AddClient";
import { useEffect } from "react";
import { useState } from "react";

const Clients = () => {
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataCount, setDataCount] = useState("");
  const [dataFrom, setDataFrom] = useState("");
  const [dataEnd, setDataEnd] = useState("");

  // Use react-query useQuery hook to fetch data
  const { data, isFetching, refetch } = useQuery(
    ["clients", offset],
    () => fetchData(offset),
    {
      // keepPreviousData: true,
      staleTime: 30000,
    }
  );

  // Fetch data function
  async function fetchData(offset) {
    try {
      const response = await apiClient.get(`client?page=${offset}`, {
        componentName: "clients",
      });
      return response.data;
    } catch (err) {
      // You can handle errors here
      throw err;
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  // useEffect for changing tab title
  useEffect(() => {
    changeTabTitle("Clients");
  }, []);

  // Update pageOptions count when data changes
  useEffect(() => {
    if (data) {
      setPageCount(data.meta.last_page);
      setDataCount(data.meta.total);
      setDataFrom(data.meta.from);
      setDataEnd(data.meta.to);
    }
  }, [data]);

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <AddClient refetch={refetch} />
      </div>
      <div className="col-12">
        <ClientsTable
          clients={data?.data || []}
          refetch={refetch}
          isFetching={isFetching}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          dataCount={dataCount}
          dataFrom={dataFrom}
          dataEnd={dataEnd}
        />
      </div>
    </div>
  );
};

export default Clients;
