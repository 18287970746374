import { useState } from "react";

import apiClient from "../../services/ApiClient";
import EditOnlyRow from "./EditOnlyRow";
import ReadOnlyRow from "./ReadOnlyRow";
import DeleteModal from "../../utils/Modals/DeleteModal";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

import ReactPaginate from "react-paginate";
import LoadingIndicator from "../../shared/LoadingIndicator";

// import { spacing } from "react-select/dist/declarations/src/theme";

const UsersTable = ({
  users,
  isFetching,
  pageCount,
  handlePageClick,
  dataCount,
  dataFrom,
  dataEnd,
  title,
  page,
  fetchData,
  refetch,
  setSearchTerm,
}) => {
  const [editId, setEditId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editFormErrors, setEditFormErrors] = useState(null);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteModalFunc = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      let id = deleteId;
      const response = await apiClient.delete(`user/delete/${id}`);
      // setUsers(users.filter((user) => user.id !== id));
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditClick = (event, user) => {
    event.preventDefault();
    setEditId(user.id);

    const formValues = {
      ...user,
    };

    setEditFormData(formValues);
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();

    const editedUser = {
      ...editFormData,
    };

    try {
      const response = await apiClient.post(`user/edit/${editId}`, editedUser);
      fetchData();
      setEditId(null);
    } catch (err) {
      setEditFormErrors(err.response.data.error);
      console.log(err);
    }
  };

  const auth = useAuth();
  const access =
    (auth && auth.user && auth.user.role === "super_admin") ||
    auth.user.role === "manager";
  return (
    <>
      <div className="card">
        <div className="card-header  pb-0 border-0 pt-3 d-flex justify-content-between align-items-center">
          <p className="mb-0 font-size13">
            <i className="fas fa-user me-2"></i>
            {title ? title : "Users"}
            {dataCount ? (
              <span className="badge count-badge-success ms-1 px-2 py-1">
                {dataCount}
              </span>
            ) : null}
          </p>
          {page === "dashboard" ? null : (
            <div className="w-25">
              <div className="input-group">
                <input
                  type="text"
                  className={`form-control font-size13`}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by name / email..."
                  // onBlur={formik.handleBlur}
                  // value={formik.values.chart_id}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter") {
                  //     e.preventDefault();
                  //     refetch();
                  //   }
                  // }}
                />
                <button
                  className="btn custom-btn-primary shadow-none outline-none border-0"
                  type="button"
                  id="button-addon2"
                  // onClick={() => fetchChartDetails(formik.values.chart_id)}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
              {/* {formik.touched.chart_id && formik.errors.chart_id ? (
              <div id="chart_id" className="invalid-feedback d-block">
                {formik.errors.chart_id}
              </div>
            ) : (
              <div className="invisible">
                <span>invisible</span>
              </div>
            )} */}
            </div>
          )}
          {page === "dashboard"
            ? null
            : access && (
                <Link
                  to="/add-bulkusers"
                  className="btn  export-bg-success text-light shadow-none ms-4 btn-sm  font-size13 "
                >
                  <i className="fa fa-plus me-1"></i>
                  Add bulk Users
                </Link>
              )}
        </div>
        <div className="card-body px-0">
          <form onSubmit={handleEditFormSubmit}>
            <div className="table-responsive">
              <table className="table">
                <thead className="font-size13">
                  <tr>
                    <th scope="col" className=" ps-2 text-center">
                      S.No
                    </th>
                    <th scope="col" className="text-nowrap">
                      Emp ID
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col" className="text-nowrap">
                      Login Name
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Team ID</th>
                    <th scope="col">Client</th>
                    <th scope="col">Project</th>
                    {/* {page === "dashboard" ? null : ( */}
                    <th scope="col" className="text-center">
                      Charts
                    </th>
                    <th scope="col" className="text-center">
                      Profile
                    </th>
                    {/* )} */}
                    {page === "dashboard"
                      ? null
                      : access && <th scope="col">Action</th>}
                  </tr>
                </thead>
                <tbody className="font-size13 font-weight-light">
                  {isFetching && <LoadingIndicator />}
                  {users &&
                    users.map((user, index) => (
                      <tr key={index}>
                        {editId === user.id ? (
                          <EditOnlyRow
                            editFormData={editFormData}
                            dataFrom={dataFrom}
                            index={index}
                            handleEditFormChange={handleEditFormChange}
                            handleCancel={handleCancel}
                            editFormErrors={editFormErrors}
                          />
                        ) : (
                          <ReadOnlyRow
                            user={user}
                            index={index}
                            dataFrom={dataFrom}
                            handleEditClick={handleEditClick}
                            deleteModalFunc={deleteModalFunc}
                            page={page}
                          />
                        )}
                      </tr>
                    ))}
                  {!users.length && (
                    <tr className="text-center font-size13">
                      <td colSpan={15}>
                        {isFetching
                          ? "Loading..."
                          : "No data available at this moment"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </form>
          {page === "dashboard" ? null : (
            <div className="d-flex justify-content-between align-items-center flex-row-reverse px-3 pt-2">
              <div>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              {dataFrom && dataEnd && (
                <span className="ms-2 showing  font-size13 grey-clr">
                  <i class="fas fa-book-open me-1"></i>
                  Showing {dataFrom} - {dataEnd} of {dataCount} entires
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        title="Users"
      />
    </>
  );
};

export default UsersTable;
