import { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../services/ApiClient";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [token, setToken] = useState(localStorage.getItem("auth-token") || null);
  const [log, setLog] = useState(localStorage.getItem("log") || []);

  const navigate = useNavigate();

  const logout = async () => {
    const res = await apiClient.get("/logout");

    setUser(null);
    setToken(null);
    navigate("/login", { replace: true });

    localStorage.clear();
  };

  return <AuthContext.Provider value={{ user, setUser, token, setToken, log, setLog, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
