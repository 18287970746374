import { Modal, Button } from "react-bootstrap";

const DisagreeModal = (props) => {
  const {
    showDisagreeModal,
    closeDisagreeModal,
    handleDisAgree,
    handleFiles,
    setdisagreeComments,
    disAgreeErrors,
    setEvidence,
    evidence,
    auth,
    isClientRProject,
  } = props;
  return (
    <Modal
      show={showDisagreeModal}
      onHide={closeDisagreeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <h6 className="mb-0 font-size13">Please Confirm!</h6>
      </Modal.Header>
      <Modal.Body>
        {auth.user.role === "user" && !isClientRProject && (
          <div className="mb-3 font-size13">
            <label htmlFor="evidence" className="form-label required">
              Evidence
            </label>
            <input
              type="file"
              multiple
              className="form-control"
              id="evidence"
              onChange={(e) =>
                handleFiles(Array.prototype.slice.call(e.target.files))
              }
            />
            <div id="evidenceHelp" className="form-text">
              <small className="text-muted">
                Do not upload any sensitive data. Supported file
                types:jpeg.jpg,png
              </small>
              <br />
              {disAgreeErrors && (
                <small className="text-danger">{disAgreeErrors.evidence}</small>
              )}
            </div>
          </div>
        )}

        <label htmlFor="comments" className="form-label required">
          Comments
        </label>
        <textarea
          className="form-control font-size13"
          name="comments"
          id="comments"
          cols="30"
          rows="3"
          onChange={(e) => setdisagreeComments(e.target.value)}
        ></textarea>
        {disAgreeErrors && (
          <small className="text-danger">{disAgreeErrors.comment}</small>
        )}
        <div>
          <div className="text-center mt-3">
            <Button variant="success" className="me-2 font-size13" onClick={handleDisAgree}>
              Confirm
            </Button>
            <Button variant="danger" className="font-size13" onClick={closeDisagreeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisagreeModal;
